import { WritableAtom, atom } from 'jotai';
import { atomWithLocation } from 'jotai-location';
import { difference } from 'es-toolkit';

const locationAtom = atomWithLocation({ replace: true });

export function createQueryParameterAtom<T extends string>(
    key: string,
    allowMultipleValues: false,
): WritableAtom<T | null | undefined, [T], void>;
export function createQueryParameterAtom<T extends string>(
    key: string,
    allowMultipleValues: true,
): WritableAtom<T[], [T[]], void>;
export function createQueryParameterAtom<T extends string>(
    key: string,
    allowMultipleValues: boolean,
) {
    if (allowMultipleValues) {
        return atom<T[], [T[]], void>(
            (get) => (get(locationAtom).searchParams?.getAll(key) as T[]) ?? [],
            (get, set, newValues) => {
                set(locationAtom, (prev) => {
                    const newSearchParams = new URLSearchParams(
                        prev.searchParams,
                    );
                    if (!newValues.length) {
                        newSearchParams.delete(key);
                    } else {
                        const currentValues =
                            get(locationAtom).searchParams?.getAll(key) ?? [];
                        const diffCurrentAndNew = difference(
                            currentValues,
                            newValues,
                        );
                        const diffNewAndCurrent = difference(
                            newValues,
                            currentValues,
                        );
                        diffCurrentAndNew.forEach((v) =>
                            newSearchParams.delete(key, v),
                        );
                        diffNewAndCurrent.forEach((v) =>
                            newSearchParams.append(key, v),
                        );
                    }
                    return {
                        ...prev,
                        searchParams: newSearchParams,
                    };
                });
            },
        );
    } else {
        return atom<T | undefined | null, [string], void>(
            (get) => get(locationAtom).searchParams?.get(key) as T,
            (_get, set, name) => {
                set(locationAtom, (prev) => {
                    const searchParams = new URLSearchParams(prev.searchParams);
                    if (name) {
                        searchParams.set(key, name);
                    } else {
                        searchParams.delete(key);
                    }
                    return {
                        ...prev,
                        searchParams,
                    };
                });
            },
        );
    }
}
