import { useAtomValue } from 'jotai/index';

import { pageTitleAtom } from '../../usePageContext';

function setPageTitle(pageTitle: string, title?: string) {
    if (title) {
        document.title = `${title} | Saidot`;
        return;
    }
    document.title =
        pageTitle !== 'Saidot' ? `${pageTitle} | Saidot` : 'Saidot';
}

/** Sets the page title in the browser tab. We are setting only title, so Helmet is not needed. */
const SetPageTitle = ({ title }: { title?: string }) => {
    const pageTitle = useAtomValue(pageTitleAtom);

    setPageTitle(
        Array.isArray(pageTitle) ? pageTitle.join(' / ') : pageTitle,
        title,
    );

    return null;
};

export default SetPageTitle;
