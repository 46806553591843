import React, { Context } from 'react';

import {
    IUserContext,
    IUserContextAuthenticated,
    IUserContextGuest,
} from '../@types';

export const UserContext = React.createContext<IUserContext>(
    {} as IUserContext,
);

// aliases to same context for different situations
export const UserContextAuthenticated =
    UserContext as Context<IUserContextAuthenticated>;
export const UserContextGuest = UserContext as Context<IUserContextGuest>;
