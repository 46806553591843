import React from 'react';

import { ModalProvider } from 'components/Modal/useModal';

export function withModalProvider(Component) {
    return (props) => {
        return (
            <ModalProvider>
                <Component {...props} />
            </ModalProvider>
        );
    };
}
