import React from 'react';

import { LiveblocksProvider } from '@liveblocks/react/suspense';
import * as Sentry from '@sentry/react';
import { useAtomValue } from 'jotai';

import { liveblocksAtom } from 'tools/atoms';
import { useApiCall } from 'tools/hooks';
import { konsole } from 'tools/hooks/konsole';

/**
 * Enables/Disables Liveblocks globally
 * @param Component
 */
export function withCollaborativeGlobalProvider(Component) {
    return (props) => {
        const liveBlocksOn = useAtomValue(liveblocksAtom);

        const apiCall = useApiCall(true);
        const authorize = React.useCallback(
            async (room) => {
                try {
                    return await apiCall('post', `/v2/liveblocks/auth`, {
                        room,
                    });
                } catch (error) {
                    Sentry.captureException(error);
                    konsole.error(error);
                }
            },
            [apiCall],
        );

        if (liveBlocksOn) {
            return (
                <LiveblocksProvider authEndpoint={authorize}>
                    <Component {...props} />
                </LiveblocksProvider>
            );
        }

        return (
            <>
                <Component {...props} />
            </>
        );
    };
}
