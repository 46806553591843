import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import { GovernSpace } from 'src/generated';
import { SecondLevelItem, TopLevelMenuItem } from './Menus/useNavMenuData';

export const navMenuSelections = atomWithStorage<{
    topLevel: TopLevelMenuItem;
    secondLevel?: SecondLevelItem | null;
} | null>(
    'navMenuSelections',
    null,
    createJSONStorage(() => sessionStorage),
);

export const secondLevelMenuExpandedAtom = atomWithStorage<boolean>(
    'secondLevelMenuExpandedAtom',
    true,
    createJSONStorage<boolean>(() => sessionStorage),
);

export const activeSpaceAtom = atomWithStorage<GovernSpace | null>(
    'activeSpaceAtom',
    null,
    // this one actually makes sense to be localStorage as you can only have one active space at a time
    createJSONStorage(() => localStorage),
);
