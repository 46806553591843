import React, { forwardRef } from 'react';
import Markdown from 'react-markdown';

import classNames from 'classnames';

type TextProps = {
    id?: string;
    children: React.ReactNode;
    classes?: string;
};

export const Text = forwardRef<HTMLDivElement, TextProps>(
    ({ children, classes, id }, ref) => {
        return (
            <div
                {...(id ? { id } : {})}
                className={classNames(
                    'font-body text-18 leading-27 tracking-[0.389px] text-gray-900',
                    classes,
                )}
                ref={ref}
                data-testid="text-container"
            >
                {(children && typeof children === 'string' && (
                    <Markdown>{children}</Markdown>
                )) ||
                    children}
            </div>
        );
    },
);
