import React from 'react';

import { FlagStore } from 'tools/helpers';

export function withFeatureFlags(Component) {
    return (props) => {
        return (
            <FlagStore>
                <Component {...props} />
            </FlagStore>
        );
    };
}
