import React from 'react';

import { withSnackbar } from 'notistack';

import { konsole } from 'tools/hooks/konsole';

import version from '../version.json';
import { UserContext } from './UserContext';

class Snacks extends React.Component {
    static contextType = UserContext;
    channelName() {
        const { user } = this.context;
        return `user_${user.id}`;
    }

    componentDidMount() {
        if (!this.context.user) {
            return;
        }
        const { enqueueSnackbar, setDialog } = this.context;

        this.watchSnacks = (data) => {
            enqueueSnackbar(data.info, {
                variant: data.variant,
                preventDuplicate: true,
            });
        };

        this.watchVersion = (latestVersion) => {
            konsole.log(
                `Latest ui build is: ${latestVersion.long} and you have ${version.long}`,
            );
        };

        this.watchPromptReload = (latestVersion) => {
            if (latestVersion.date > version.date) {
                const title = 'New version';
                const message = [
                    'There is a new version of the application available. Do you want reload the page now? ',
                    'If you are middle of something you can cancel the reload, but you need to reload the page later.',
                ];
                const button = 'Reload';
                const onConfirm = () => window.location.reload(true);
                const onCancel = () =>
                    setTimeout(
                        () => this.promptReload(latestVersion),
                        2 * 60 * 1000,
                    );
                setDialog({ title, message, button, onConfirm, onCancel });
            }
        };
    }
    render() {
        return null;
    }
}

export default withSnackbar(Snacks);
