export const colors = {
    brandColorBlack: '#2C2C2C',
    brandColorDarkBlue: '#3A3C4A',
    brandColorAccentBlue: '#3381F7',
    brandColorDarkAccentBlue: '#0084DD',
    brandColorLightBlue: '#FFF',
    brandColorMidBlue: '#74C6FD',
    brandColorWhite: '#FFFFFF',
    brandColorBrightGreen: '#4EC5A5',
    brandColorLightGreen: '#71D1B7',
    brandColorBrightOrange: '#FD823E',
    brandColorLightOrange: '#FD945A',
    brandColorLighterOrange: '#FEB48B',
    brandColorMidGrey: '#B3B3B3',
    brandColorLighterGrey: '#D5D5D5',
    brandColorLightGrey: '#EFEFEF',
    brandColorDarkGrey: '#808080',
    brandColorDarkRed: '#D0393E',
    brandColorMediumRed: '#ECB0B2',
    brandColorLightRed: '#D96165', // #ECB0B2
    brandColorLighterRed: '#FF917A',
    platform: {
        primary: {
            black: '#000',
            pressed: '#074CB3',
            notActive: '#888888',
        },
        hover: '#ECF3FE',
    },
    white: '#FFFFFF',
};

export const fonts = {
    bodyTextRegular: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
    },
    fontHeadingH1: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '32px',
    },
    fontHeadingH2: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '30px',
    },
    fontHeadingH2Alt: {
        fontFamily: "'Merriweather', serif",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: '30px',
    },
    fontHeadingH3: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '30px',
    },
    fontHeadingH4: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '30px',
    },
    fontSmallText: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
    },
    fontDescriptionText: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        lineHeight: '20px',
    },
    fontTitleText: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
    },
    fontTitleTight: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
    },
    fontTagText: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
    },
    fontBodyTextList: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        lineHeight: '32px',
    },
    fontInputTextSmall: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '11px',
        lineHeight: '11px',
    },
};

export const drawerWidth = 260;
