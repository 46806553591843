import { useAtom } from 'jotai';
import { useFeatureFlagsQuery } from 'src/generated';

import { featureFlagsAtom } from 'tools/atoms';

export function FlagStore({ children }) {
    const [flags, setFlags] = useAtom(featureFlagsAtom);
    useFeatureFlagsQuery(
        {},
        {
            // refetchInterval: sessionStorage.getItem('stopFeatureFlagCheck') ? undefined : 5_000,
            // refetchOnWindowFocus: !sessionStorage.getItem('stopFeatureFlagCheck'),
            // refetchOnReconnect: !sessionStorage.getItem('stopFeatureFlagCheck'),
            // refetchOnMount: !sessionStorage.getItem('stopFeatureFlagCheck'),
            onSuccess: (data) => {
                if (
                    !flags.data ||
                    JSON.stringify(flags.data) !==
                        JSON.stringify(data.featureFlags)
                ) {
                    setFlags({
                        _loaded: true,
                        data: data.featureFlags,
                        refreshed: new Date(),
                    });
                }
            },
        },
    );

    return children;
}
