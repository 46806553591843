import React from 'react';

import { atom, useAtom } from 'jotai';

import { StickyNotification } from 'components/Library/common';

const initialVisibilityAtom = atom(true);
export const useStickyNotification = (
    _initialVisibility: boolean,
    notificationText: string,
) => {
    const [notificationVisible, setNotificationVisibility] = useAtom(
        initialVisibilityAtom,
    );

    const renderStickyNotification = () => (
        <StickyNotification
            initialVisibility={notificationVisible}
            setVisibility={setNotificationVisibility}
            notificationText={notificationText}
        />
    );

    return {
        notificationVisible,
        setNotificationVisibility,
        renderStickyNotification,
    };
};
