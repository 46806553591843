import React from 'react';

import { UserStoreNeo } from 'components/UserStore/UserStore';

import { useLogger } from './useLogger';

export function withUserStore(Component) {
    return (props) => {
        const logger = useLogger();
        return (
            <UserStoreNeo logger={logger}>
                <Component {...props} />
            </UserStoreNeo>
        );
    };
}
