import { useCallback, useState } from 'react';

import { useAtomValue } from 'jotai';

import { translationsAtom, usePageContext } from 'components/usePageContext';

import { useOnMount } from 'tools/hooks';

import { axiosCall } from './tools/Api/apiCall';

export default function LoadAppTranslations() {
    const translations = useAtomValue(translationsAtom);
    const { setPlatformTranslations } = usePageContext();
    const [loading, setLoading] = useState(false);
    const [translationsError, setError] = useState(false);

    const loadTranslations = useCallback(async () => {
        if (
            loading ||
            Object.keys(translations || {}).length !== 0 ||
            translationsError
        ) {
            return;
        }

        try {
            const translations = await axiosCall(
                'get',
                '/v2/schema-skeletons/translations',
            );
            setPlatformTranslations(translations || {});
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [
        loading,
        setLoading,
        setPlatformTranslations,
        translations,
        translationsError,
        setError,
    ]);

    useOnMount(() => {
        setLoading(true);
        loadTranslations();
    });

    return null;
}
