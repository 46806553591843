import React, { lazy } from 'react';
import { useLocation } from 'react-router-dom';

import { CircularProgress, withStyles } from '@material-ui/core';

import commonStyles from './styles/commonStyles';

const LoaderPage = lazy(() => import('./transparencyReport/pages/LoaderPage'));

function SpinnerCircular({ classes }) {
    return (
        <div className={classes.spinner} id="spinner">
            <CircularProgress
                className={classes.circle}
                size={60}
                thickness={4}
            />
        </div>
    );
}

const StyledSpinner = withStyles(commonStyles)(SpinnerCircular);

export function Spinner({ active }: { active?: boolean }) {
    const location = useLocation();

    if (!active) {
        return null;
    }

    if (location.search.includes('nl=1')) {
        return <LoaderPage loadingMessage="Shedding light on a black box..." />;
    }

    return <StyledSpinner />;
}

export default Spinner;
