import type { CustomFlowbiteTheme } from 'flowbite-react';

// Put custom theme for Flowbite here
const customTheme: CustomFlowbiteTheme = {
    accordion: {
        root: {
            base: '!border-0',
        },
        title: {
            base: 'flex w-full items-center justify-between rounded-lg p-2 text-left font-medium text-gray-500 bg-gray-100 border border-[1px] border-gray-300 hover:border-gray-550',
            heading:
                'flex items-center text-18 font-body text-gray-800 font-semibold leading-[150%] tracking-custom-2',
            flush: {
                off: 'hover:bg-saidot-blue-100',
                on: '',
            },
            arrow: {
                base: 'shrink-0 h-24 w-24 text-gray-800',
            },
            open: {
                off: 'mb-2',
                on: 'rounded-b-none',
            },
        },
        content: {
            base: 'mb-2 p-2 bg-white rounded-b-lg border border-gray-300 border-[1px] text-18 font-body text-gray-700 leading-[150%] tracking-custom-2',
        },
    },
    dropdown: {
        content: 'p-0 ml-0 list-none',
        floating: {
            content: 'bg-blue-700 border border-gray-200',
            divider: 'my-1 h-px bg-gray-500',
            item: {
                container: 'mb-0',
                base: 'flex w-full',
            },
        },
    },
    pagination: {
        pages: {
            base: 'flex list-none',
            selector: {
                base: 'flex items-center justify-center p-[6px] border border-gray-300 text-gray-700 bg-white hover:bg-gray-300',
                active: 'bg-blue-50 text-gray-700',
            },
        },
    },
    table: {
        head: {
            base: 'bg-gray-100 text-gray-700 text-sm font-semibold leading-150 tracking-[0.28px] border-b border-gray-300 rounded-t-[8px]',
            cell: {
                base: 'p-2 hover:bg-gray-300 focus-visible:bg-gray-300',
            },
        },
        body: {
            cell: {
                base: 'px-2 py-[10px]',
            },
        },
        row: {
            base: 'bg-white text-gray-700 text-base font-normal leading-150 tracking-custom-1 border-b border-gray-300',
            hovered: 'hover:bg-blue-50',
        },
    },
    label: {
        root: {
            base: 'text-gray-700 font-body text-base font-semibold leading-[150%] tracking-[0.307px]',
        },
    },
    textInput: {
        addon: 'bg-white',
        field: {
            input: {
                sizes: {
                    md: 'p-2.5 text-[1.125rem] leading-150 tracking-custom-2',
                },
                colors: {
                    gray: 'border-gray-600 text-gray-800 placeholder:text-gray-600 bg-white text-lg',
                    failure:
                        'border-red-500 !text-lg placeholder:text-lg bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500',
                },
            },
        },
    },
    textarea: {
        base: 'block w-full rounded-lg border disabled:cursor-not-allowed disabled:opacity-50 text-[1.125rem] leading-150 tracking-custom-2',
        colors: {
            gray: 'bg-white border-gray-600 text-gray-800 placeholder:text-gray-600 bg-white',
            failure:
                'border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400',
        },
    },
    tooltip: {
        style: {
            dark: 'bg-gray-800 text-white font-body text-sm font-semibold leading-[150%] tracking-[0.28px] p-2 rounded-lg',
        },
        arrow: {
            style: {
                dark: 'bg-gray-800 rotate-45',
            },
            placement: '-2px',
        },
    },
};

export default customTheme;
