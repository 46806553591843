import React, { useContext } from 'react';

import { atom, getDefaultStore, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { atomWithStorage } from 'jotai/utils';
import {
    IPageContext,
    Item,
    SmartSidebarModes,
    SystemMetadata,
} from 'src/@types';
import en from 'src/translations/download/dynamic_en.json';
import fi from 'src/translations/download/dynamic_fi.json';
import fr from 'src/translations/download/dynamic_fr.json';
import nl from 'src/translations/download/dynamic_nl.json';
import sv from 'src/translations/download/dynamic_sv.json';

import { snackBarAtom } from 'components/SnackBar';

export const PageContext = React.createContext<Omit<IPageContext, 'state'>>(
    {} as IPageContext,
);

export const isMobileViewerAtom = atom(false);
export const dialogParamsAtom = atom<Record<string, any>>({ isOpen: false });

export const attachmentPreviewContentAtom = atom(false);

const titleAtom = atom<string | string[]>('Saidot');
export const pageTitleAtom = atom(
    (get) => get(titleAtom),
    (_, set, value: string | string[]) => {
        value = value ?? 'Saidot';
        if (window?.document?.title) {
            window.document.title = String(
                Array.isArray(value) ? value.join(' / ') : value,
            );
        }

        set(titleAtom, value);
    },
);

export const pageSectionAtom = atom('overview');

export const pageSectionIndexAtom = atom(0);

type translationsType = {
    en: Record<string, any>;
    fr: Record<string, any>;
    fi: Record<string, any>;
    nl: Record<string, any>;
    sv: Record<string, any>;
};
export const translationsLoadedAtom = atom(true);
export const translationsAtom = atom<translationsType>({
    en,
    fr,
    fi,
    nl,
    sv,
});

export const revisionsAtom = atom<any[]>([]);

export const systemFeedbackAtom = atom(false);

export const currentSystemTitleAtom = atom('');

export const systemMetadataAtom = atom<SystemMetadata | Record<string, never>>(
    {},
);

export const appBarNotificationAtom = atom<JSX.Element | null>(null);

export const createdByAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('created_by'),
);
export const createdAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('created'),
);
export const createTimeAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('create_time'),
);
export const updatedAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('updated'),
);
export const lastUpdatedByAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('last_update_by'),
);
export const lastUpdatedTimeAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('last_update_time'),
);

export const systemLangAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('systemLang'),
);
export const languageVersionsAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('languageVersions'),
);
export const industryModelAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('industryModel'),
);
export const industryModelIdAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('industry_model_id'),
);
export const translationCodeAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('translation_code'),
);
export const metadataSectionsAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('metadataSections'),
);
export const masterSectionsAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('masterSections'),
);
export const systemPoliciesAtom = focusAtom(systemMetadataAtom, (optic) =>
    optic.prop('system_policies'),
);
export const defaultRefreshIntervalAtom = atom(5_000); // 5s

// ok to be in local storage ( want to sync between tabs )
export const feedStatusAtom = atomWithStorage('hasUnreadNotifications', false);
export const systemPolicyIdAtom = atom<number | string | null>(null);
export const systemIdAtom = atom<number | string | null>(null);

export const systemOpenedAtom = atom(false);

export const documentationNavigationAtom = atom<Item[]>([]);

export const loadingAtom = atom(false);

export const systemLevelAtom = atom('none');

export const sidebarModeAtom = atom(SmartSidebarModes.OVERALL);

export const sidebarSectionAtom = atom('overview');

export const sidebarSectionAttributesAtom = atom<Record<string, any>>({});

export const sectionCompletenessAtom = atom<Record<string, any>>({});

export const systemSidebarAtom = atom(false);

export const canEditSystemAtom = atom(false);

export const mlOpsIntegrationsAtom = atom<any[]>([]);

export const vendorModalIsOpen = atom(false);

export function PageProvider({ children }) {
    const setPageTitle = useSetAtom(pageTitleAtom);
    const setCurrentSystemTitle = useSetAtom(currentSystemTitleAtom);

    const setSnackBar = useSetAtom(snackBarAtom);

    const myFns = {
        showSystemFeedback: () => {
            const v = getDefaultStore().get(systemFeedbackAtom);
            getDefaultStore().set(systemFeedbackAtom, !v);
        },
        setPageTitle: (title = '') => {
            setPageTitle(title);
        },

        setPlatformTranslations: (tr: translationsType) => {
            getDefaultStore().set(translationsAtom, tr);
            getDefaultStore().set(translationsLoadedAtom, true);
        },

        setSystemRevisions: (revs = []) => {
            getDefaultStore().set(revisionsAtom, revs);
        },

        setSystemLevel: (level = 'none') => {
            getDefaultStore().set(systemLevelAtom, level);
        },

        setSectionCompleteness: (completeness = {}) => {
            getDefaultStore().set(sectionCompletenessAtom, completeness);
        },

        setSidebarMode: (
            mode: SmartSidebarModes = SmartSidebarModes.OVERALL,
        ) => {
            let v: string = mode;
            if (mode === SmartSidebarModes.OVERALL) {
                v = '';
            }
            getDefaultStore().set(sidebarModeAtom, v as SmartSidebarModes);
        },
        setSidebarSection: (section = 'overview') => {
            getDefaultStore().set(sidebarSectionAtom, section);
        },

        setActiveSection: (section = 'overview') => {
            getDefaultStore().set(pageSectionAtom, section);
        },

        setActiveSectionIndex: (section = 0) => {
            getDefaultStore().set(pageSectionIndexAtom, section);
        },

        setCurrentSystemTitle: (title = '') => {
            window.document.title = title ?? 'Saidot';
            setCurrentSystemTitle(title);
        },

        setAttachmentPreviewContent: (content = false) => {
            getDefaultStore().set(attachmentPreviewContentAtom, content);
        },

        setLoading: (l = false) => {
            getDefaultStore().set(loadingAtom, l);
        },
        setSystemMetadata: (
            metadata: SystemMetadata | Record<string, never> = {},
        ) => {
            getDefaultStore().set(
                systemMetadataAtom,
                metadata as SystemMetadata,
            );
        },
        setCanEditSystem: (can_edit = false) => {
            getDefaultStore().set(canEditSystemAtom, can_edit);
        },
        toggleSnackBar: (message = '', variant = 'success', time = 3000) => {
            setSnackBar({ message, variant, show: true, time });
        },
        toggleVendorModal: () => {
            getDefaultStore().set(
                vendorModalIsOpen,
                !getDefaultStore().get(vendorModalIsOpen),
            );
        },

        setMLOpsIntegrations: (integrations = []) => {
            getDefaultStore().set(mlOpsIntegrationsAtom, integrations);
        },
    };
    return (
        <PageContext.Provider value={myFns as any}>
            {children}
        </PageContext.Provider>
    );
}

export function usePageContext() {
    const context = useContext(PageContext);
    if (context === undefined) {
        throw new Error('usePageContext must be used within a PageProvider');
    }
    return context;
}

export function withPageProvider(Component) {
    return (props) => {
        return (
            <PageProvider>
                <Component {...props} />
            </PageProvider>
        );
    };
}
