import React from 'react';

import { MuiThemeProvider as ThemeProvider } from '@material-ui/core';

import { theme } from '../../styles/muiTheme';

export function withMuiTheme(Component) {
    return (props) => {
        return (
            <ThemeProvider theme={theme}>
                <Component {...props} />
            </ThemeProvider>
        );
    };
}
