import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // this could break govern for example if you type text, go to word to copy text, come back to paste => all you had there => poof gone
            // it is a weird "magic" default behaviour, very disappointing
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchInterval: false,
        },
    },
});

export function withQueryClient(Component) {
    return (props) => {
        return (
            <QueryClientProvider client={queryClient}>
                <Component {...props} />
                {process.env.VITE_APP_SAIDOT_ENV === 'local' && (
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-right"
                    />
                )}
            </QueryClientProvider>
        );
    };
}
