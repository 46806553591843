import React from 'react';

import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/ErrorOutline';
import MuiAlert from '@material-ui/lab/Alert';
import { atom, useAtom, useAtomValue } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { Alert as FlowbiteAlert } from 'src/components/Alert';
import { useFlagValue } from 'src/tools/helpers/FlagResolver';

const snackBarDataAtom = atom({ show: false, message: '', variant: 'info' });
export const snackBarAtom = atom(
    (get) => get(snackBarDataAtom),
    (_get, set, data) => {
        data.time = data.time || 5000;
        data.variant = data.variant || 'info';

        setTimeout(() => {
            set(snackBarDataAtom, { ...data, message: '', show: false });
        }, data.time);

        return set(snackBarDataAtom, { show: true, ...data });
    },
);
const showSnackBarAtom = focusAtom(snackBarAtom, (optic) => optic.prop('show'));
const snackBarMessageAtom = focusAtom(snackBarAtom, (optic) =>
    optic.prop('message'),
);
const snackBarVariantAtom = focusAtom(snackBarAtom, (optic) =>
    optic.prop('variant'),
);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getIcon(variant) {
    switch (variant) {
        case 'success':
            return <CheckIcon fontSize="inherit" />;
        case 'info':
            return <InfoIcon fontSize="inherit" />;
        case 'error':
            return <ClearIcon fontSize="inherit" />;
        default:
            break;
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    snackbar: {
        zIndex: 9999, //Snackbar must be on top of everything, even the modal
    },
    success: {
        backgroundColor: '#22DEAB',
    },
    error: {
        backgroundColor: ' #FF5A38',
    },
    message: {
        color: 'black',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
    },
}));

export default function SnackBar() {
    const hasGovernSystemShell = useFlagValue('GovernSystemShell');
    const classes = useStyles();
    const [showSnackBar, setShowSnackBar] = useAtom(showSnackBarAtom);
    const snackBarVariant = useAtomValue(snackBarVariantAtom);
    const snackBarMessage = useAtomValue(snackBarMessageAtom);

    if (!showSnackBar) {
        return null;
    }

    return (
        <>
            {hasGovernSystemShell ? (
                <div className="absolute z-[999] bottom-[63px] right-[37px]">
                    <FlowbiteAlert
                        message={snackBarMessage}
                        variant={snackBarVariant}
                        onDismiss={() => setShowSnackBar(null)}
                    />
                </div>
            ) : (
                <Snackbar open={showSnackBar} className={classes.snackbar}>
                    <Alert
                        icon={getIcon(snackBarVariant)}
                        className={classes[snackBarVariant]}
                        severity={snackBarVariant}
                    >
                        <span className={classes.message}>
                            {snackBarMessage}
                        </span>
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}
