import { axiosCall } from 'tools/Api';
import { konsole } from 'tools/hooks/konsole';

export function fetcher<TData, TVariables>(
    query: string,
    variables?: TVariables,
) {
    return async (): Promise<TData> => {
        let operationName: string | undefined;
        try {
            operationName = query.match(
                /(query|mutation)\s*(\w*)[\s\{\(]+/,
            )?.[2];
        } catch (error) {
            konsole.error(error);
        }

        const json = await axiosCall(
            'POST',
            new URL(process.env.VITE_APP_API_URL + '/graphql').toString(),
            { query, variables, operationName },
            {
                'Content-type': 'application/json',
            },
        );

        if (json.errors) {
            const errors = Array.isArray(json.errors)
                ? json.errors
                : [json.errors];
            for (const error of errors) {
                konsole.error(operationName, error, query, variables);
            }

            const { message } = json.errors[0];
            throw new Error(message, json.errors);
        }

        return json.data;
    };
}
