import React from 'react';

export const ScrollContext = React.createContext<any>({
    pointers: [],
});

export const withScrollContext = (Component) => {
    return (props) => {
        return (
            <ScrollContext.Provider value={{ pointers: [] }}>
                <Component {...props} />
            </ScrollContext.Provider>
        );
    };
};
