import React, { useContext, useState } from 'react';

const ModalContext = React.createContext<TModalContext | undefined>(undefined);

function ModalProvider({ children }) {
    const [state, setState] = useState({});

    function toggle(type) {
        setState({
            ...state,
            [type]: !state[type],
        });
    }

    function open(type) {
        setState({
            ...state,
            [type]: true,
        });
    }

    function close(type) {
        setState({
            ...state,
            [type]: false,
        });
    }

    const value = { state, toggle, open, close };

    return (
        <ModalContext.Provider value={value}> {children}</ModalContext.Provider>
    );
}

function useModal() {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
}

function withModalProvider(Component) {
    return (props) => {
        return (
            <ModalProvider>
                <Component {...props} />
            </ModalProvider>
        );
    };
}

export { ModalProvider, useModal, withModalProvider };
