import { atom } from 'jotai';
import { atomWithHash } from 'jotai-location';
import type { LibraryCardType } from 'src/generated';
import type {
    EvaluationFilterKey,
    ModelFilterKey,
    PolicyFilterKey,
    RiskFilterKey,
    TemplateFilterKey,
} from 'src/pages/Library/@types';

const INITIAL_PAGE_INDEX = 1;
export type OrderByAtomValue = [string, 'ASC' | 'DESC' | 'asc' | 'desc'][];
export const orderByAtom = atomWithHash<OrderByAtomValue>('orderBy', [], {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    setHash: 'replaceState',
});
export const feedFilterAtom = atomWithHash<LibraryCardType[]>(
    'feedFilter',
    [],
    {
        serialize: JSON.stringify,
        deserialize: JSON.parse,
    },
);
export const filterByAtom = atomWithHash<
    [
        (
            | PolicyFilterKey
            | RiskFilterKey
            | ModelFilterKey
            | EvaluationFilterKey
            | TemplateFilterKey
            | 'followed'
        ),
        string[],
    ][]
>('filterBy', [], {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    setHash: 'replaceState',
});
export const searchQueryAtom = atomWithHash<string>('query', '', {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    setHash: 'replaceState',
});

export const pageIndexQueryAtom = atomWithHash<number>(
    'page',
    INITIAL_PAGE_INDEX,
    {
        serialize: JSON.stringify,
        deserialize: JSON.parse,
    },
);

export const resultsAtom = atom(0);

export const mobileFiltersAreOpenAtom = atom(false);

export const trendingTopicOffsetAtom = atom(0);

export const currentDocumentNameAtom = atom<string>('');
