import { atom, getDefaultStore } from 'jotai';
import { atomWithReducer } from 'jotai/utils';

/**
 * Which section of the menu is open & scrolled to / active
 */
export const menuSectionAtom = atomWithReducer<string, any>(
    'overview',
    (_prev, { value }) => {
        return value || 'overview';
    },
);

export const expandedMenuItemsAtom = atomWithReducer<string[], any>(
    [],
    (_prev, value) => {
        return value || [];
    },
);

export const defaultMenuItemAtom = atom('/home');

export const selectedMenuItemAtom = atomWithReducer<string[], any>(
    [],
    (_prev, value) => {
        return value || [getDefaultStore().get(defaultMenuItemAtom)];
    },
);

interface LocationDerivedState {
    isDashboard?: boolean;
    isAdmin?: boolean;
    isTemplates?: boolean;
    isFeedback?: boolean;

    isLibraryFeedPage?: boolean;
    isLibraryPoliciesPage?: boolean;
    isLibraryRisksPage?: boolean;
    isLibraryModelsPage?: boolean;
    isLibraryEvaluationsPage?: boolean;

    isAboutPage?: boolean;
    isDatasetsPage?: boolean;
}

export const locationStateAtom = atomWithReducer<LocationDerivedState, any>(
    {},
    (state, location) => {
        state.isDashboard = /^\/dashboard/.test(location.pathname);
        state.isAdmin = /^\/admin/.test(location.pathname);
        state.isTemplates = /^\/templates/.test(location.pathname);
        state.isFeedback = /^\/feedback/.test(location.pathname);

        state.isLibraryFeedPage = /^\/library\/feed\/*/.test(location.pathname);
        state.isLibraryPoliciesPage = /^\/library\/policies\/*/.test(
            location.pathname,
        );
        state.isLibraryRisksPage = /^\/library\/risks\/*/.test(
            location.pathname,
        );
        state.isLibraryModelsPage = /^\/library\/models\/*/.test(
            location.pathname,
        );
        state.isLibraryEvaluationsPage = /^\/library\/evaluations\/*/.test(
            location.pathname,
        );
        state.isAboutPage = /^\/about/.test(location.pathname);
        state.isDatasetsPage = /^\/datasets/.test(location.pathname);

        return state;
    },
);

export const sectionAtom = atom<string[]>([]);
export const menuItemRollupAtom = atom({});
