import React from 'react';
import { useNavigate } from 'react-router';

import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import { featureFlagsAtom } from 'src/tools/atoms';

import { legacyApi, refreshFetcher } from 'tools/Api';
import { api } from 'tools/Api/apiCall';
import { konsole } from 'tools/hooks/konsole';

import { PartiallyOptional } from 'src/@types';
import { Capabilities, MeInfo } from 'src/generated';
import * as Version from '../../version.json';
import { activeSpaceAtom } from '../Navigation/nav.atoms';
import { loginErrorAtom } from '../UserAtoms';
import { UserContext } from '../UserContext';
import { translationsAtom, translationsLoadedAtom } from '../usePageContext';

// slowly but surely we will set 'er right
export function UserStoreNeo({ children, ...props }) {
    const [loginError, setLoginError] = useAtom(loginErrorAtom);
    const setTranslations = useSetAtom(translationsAtom);
    const setFlags = useSetAtom(featureFlagsAtom);
    const setFlagsLoaded = useSetAtom(translationsLoadedAtom);
    const setActiveSpace = useSetAtom(activeSpaceAtom);
    const navigate = useNavigate();

    return (
        <UserStore
            {...props}
            {...{
                navigate,
                loginError,
                setLoginError,
                setTranslations,
                setActiveSpace,
                setFlags: (flags) => {
                    setFlags({
                        _loaded: true,
                        data: flags,
                        refreshed: new Date(),
                    });
                    setFlagsLoaded(true);
                },
            }}
        >
            {children}
        </UserStore>
    );
}

interface UserStoreProps {
    setLoginError: (error: string) => void;
    loginError?: string;
    setTranslations: (translations) => void;
    setFlags: (flags) => void;
    setActiveSpace: (space) => void;
    navigate: (path: string) => void;
}

export default class UserStore extends React.Component<
    React.PropsWithChildren<UserStoreProps>,
    PartiallyOptional<
        MeInfo,
        'activeSpace' | 'organization' | 'role' | 'user'
    > & {
        version: string;
        hasCheckedForSession?: boolean;
        hasSession?: boolean;
        manualLogout?: boolean;
        loading?: boolean;
        onboardingOpen?: boolean;
        firstTimeOnboard?: boolean;
        // biome-ignore lint/suspicious/noExplicitAny: todo
        dialogProperties?: any;
    }
> {
    state = {
        redirectPath: null,

        // MeInfo data
        capabilities: [],
        organizationCapabilities: [],
        spaces: [],

        // component state
        version: Version.version,

        hasSession: false,
        manualLogout: false,
        loading: false,

        onboardingOpen: false,
        firstTimeOnboard: false,

        dialogProperties: null,
    };

    logout = async (message?: string, manual?: boolean) => {
        if (manual) {
            try {
                await api('get', '/v2/logout');
            } catch (error) {
                konsole.error('logout', error);
                Sentry.captureException(error);
            }
        }

        this.setState({
            hasSession: false,
            user: undefined,
            role: undefined,
            organization: undefined,
            capabilities: [],
            organizationCapabilities: [],
            spaces: [],
            manualLogout: !!manual,
            loading: false,
        });

        if (message && !!manual) {
            this.props.setLoginError(message ?? '');
        }

        const scope = Sentry.getCurrentScope();
        scope.setUser(null);

        if (manual) {
            window.location.href = '/login';
        }
    };

    handleAccountCall = async (callPromise, callback) => {
        try {
            const data = await callPromise;
            if (data?.error) {
                konsole.error('handleAccountCall', data?.error);

                this.props.setLoginError(
                    data?.data?.message === 'Unauthorized'
                        ? data?.data?.message
                        : data?.error,
                );
                return false;
            }

            if (!data?.user) {
                return false;
            }

            this.storeLoginData(data);

            callback && typeof callback === 'function' && callback();

            // handle server redirect
            const redirectPath = data.redirectPath;
            if (redirectPath) {
                konsole.log('Redirecting to', redirectPath, ' from server');
                return this.props.navigate(redirectPath);
            }

            // handle session redirect
            const urlRedirect = Object.fromEntries(
                new URL(window.location.href).searchParams.entries() ?? [],
            )?.redirected;
            if (urlRedirect) {
                konsole.log('Redirecting to', urlRedirect, ' from session');
                return this.props.navigate(urlRedirect);
            }

            // let the app decide
            konsole.log('Redirecting to home [/]');
            return this.props.navigate('/');
        } catch (err) {
            if (typeof callback === 'function') {
                callback(err);
            }
            if (err instanceof Error) {
                this.props.setLoginError(err.message || 'Error');
            } else {
                this.props.setLoginError('An unknown error occurred');
            }

            return false;
        }
    };

    login = async (email, password, callback) => {
        this.handleAccountCall(
            api('post', '/v2/login', { email, password }),
            callback,
        );
    };

    register = async (password, emailToken, callback) => {
        this.handleAccountCall(
            api('post', '/v2/auth/register', { token: emailToken, password }),
            callback,
        );
    };

    resetPassword = async (password, emailToken, callback) => {
        this.handleAccountCall(
            api('post', '/v2/auth/reset-password', {
                token: emailToken,
                password,
            }),
            callback,
        );
    };

    vendorSignup = async (data, callback) => {
        this.handleAccountCall(
            api('post', '/v2/vendor/signup', data),
            callback,
        );
    };

    vendorLogin = async (data, callback) => {
        this.handleAccountCall(
            api('post', '/v2/vendor/signin', data),
            callback,
        );
    };

    storeLoginData(
        data: MeInfo & {
            // biome-ignore lint/suspicious/noExplicitAny: todo
            translations: Record<string, Record<string, any>>;
            flags: Record<string, boolean>;
        },
    ) {
        konsole.debug('Storing login data');
        this.setState({
            hasSession: !!data.user,
            ...data,
            loading: false,
            manualLogout: false,
            version: Version.version,
        });
        konsole.debug('userstore', data);

        const scope = Sentry.getCurrentScope();
        scope.setUser({
            id: data.user?.id ?? data.user?.externalId,
            email: data.user?.email,
            role: data.role,
            organization: data.organization?.externalId,
        });

        if (data.translations) {
            this.props.setTranslations(data.translations);
        }

        if (data.flags) {
            this.props.setFlags(data.flags);
        }

        if (data.activeSpace) {
            this.props.setActiveSpace(data.activeSpace);
        }
    }

    setUserData = (value) => {
        konsole.log('Setting user data', value);
        this.setState({ hasSession: !!value, user: value });
    };

    authenticatedApiCall = (method, url, payload, noToken, throwVersion) => {
        // still used in rest of the app as nonThrow version => gotta get fixed
        return legacyApi(method, url, payload, noToken, throwVersion);
    };

    refreshing = false;

    toggleOnboarding = async () => {
        const { firstTimeOnboard } = this.state;
        if (firstTimeOnboard) {
            try {
                await api('post', '/v2/users/onboarding/mark_read');
                this.setState({ firstTimeOnboard: false });
            } catch (error) {
                konsole.error('toggleOnboarding', error);
            }
        }
        this.setState({ onboardingOpen: false });
    };

    setDialog = (props) => {
        this.setState({ dialogProperties: props });
    };

    updateConsent = async () => {
        // noop
    };

    refresh = async () => {
        // TODO: We should rework user state management, from Context to atoms?
        try {
            // check if user is authenticated ( cookie )
            const data = await api('get', '/v2/auth/refresh?dm=true');
            if (data.error) {
                throw new Error('Token refresh failed');
            }

            this.storeLoginData(data);
        } catch (_error) {
            await this.logout();
        } finally {
            this.setState({ hasCheckedForSession: true });
        }
    };

    async componentDidMount() {
        const instance = refreshFetcher(axios);
        instance.interceptors.request.use(
            (req) => {
                req.headers = { ...(req.headers || {}) };
                return req;
            },
            (error) => {
                konsole.error('Request error', error);
                return Promise.reject(error);
            },
        );

        instance.interceptors.response.use(
            async (res) => {
                if (
                    res?.data?.errors?.[0]?.extensions?.originalError
                        ?.statusCode >= 500
                ) {
                    konsole.error('Response error', res?.data?.errors);
                    for (const error of res?.data?.errors || []) {
                        Sentry.captureException(error);
                    }
                }

                if (
                    res?.data?.errors?.[0]?.extensions?.originalError
                        ?.statusCode === 401
                ) {
                    konsole.error('Unauthorized', res?.data?.errors);
                    await this.logout('session_expired');
                }

                return res;
            },
            async (error) => {
                if (error?.response?.status >= 500) {
                    Sentry.captureException(error);
                }

                if (error?.response?.status === 401) {
                    await this.logout('session_expired');
                } else if (error?.response?.status === 403) {
                    window.location.assign('/forbidden');
                }

                return Promise.reject(error);
            },
        );

        await this.refresh();
    }

    render() {
        const { capabilities, organizationCapabilities } = this.state;
        const { loginError } = this.props;
        return (
            <UserContext.Provider
                value={{
                    loginError,
                    ...this.state,
                    logout: this.logout,
                    login: this.login,
                    vendorSignup: this.vendorSignup,
                    vendorLogin: this.vendorLogin,
                    apiCall: this.authenticatedApiCall,
                    register: this.register,
                    resetPassword: this.resetPassword,
                    toggleOnboarding: this.toggleOnboarding,
                    setDialog: this.setDialog,
                    setUserData: this.setUserData,
                    updateConsent: this.updateConsent,
                    hasCapability: (capability: string) => {
                        if (
                            (capabilities as MeInfo['capabilities'])?.includes(
                                capability as Capabilities,
                            )
                        ) {
                            return true;
                        }

                        // user level capabilities override any organization level capabilities
                        if (
                            !capabilities?.length &&
                            (
                                organizationCapabilities as MeInfo['organizationCapabilities']
                            )?.includes(capability as Capabilities)
                        ) {
                            return true;
                        }

                        return false;
                    },
                    impersonate: (data, _impersonator) => {
                        this.storeLoginData(data);
                        window.location.href = '/';
                    },
                    cancelImpersonate: () => {
                        this.logout('Impersonation ended', true);
                    },
                    refresh: this.refresh,
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
