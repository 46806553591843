// THIS FILE IS GENERATED, DO NOT EDIT!
import { z } from 'zod'
import { useQuery, useInfiniteQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions } from 'react-query';
import { fetcher } from './fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** `Date` type as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: any; output: any; }
};

/** The input for adding an organisation */
export type AddOrganisationInput = {
  /** Capabilities: The access level of the organisation */
  access: Capabilities;
  /** OrganisationAddressInput: The address of the organisation */
  address?: InputMaybe<UpdateMyOrganisationDetailsInput_Address>;
  consentToUserSwitching?: Scalars['Boolean']['input'];
  invitations: Array<AddOrganisationInput_Invitations>;
  /** Licenses: The license type of the organisation */
  license: Licenses;
  logo?: InputMaybe<UpdateMyOrganisationDetailsInput_Logo>;
  organisationName: Scalars['String']['input'];
  publicRegistryURL?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Boolean']['input'];
  systemLimit?: Scalars['Int']['input'];
};

/** The input for adding an organisation */
export type AddOrganisationInput_Invitations = {
  email: Scalars['String']['input'];
  /** Roles: The role of the user */
  role: Roles;
};

export type AddSystemDatasetInput = {
  datasetId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  purpose: Array<GovernDatasetPurpose>;
  systemId: Scalars['String']['input'];
};

/** The input for adding/modifying a system risk */
export type AddSystemRiskInput = {
  accept?: InputMaybe<Scalars['String']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  consequences?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  inherentRiskEvaluation?: InputMaybe<Array<Scalars['Int']['input']>>;
  libraryRiskId?: InputMaybe<Scalars['String']['input']>;
  marginalRiskLevel?: InputMaybe<Scalars['String']['input']>;
  /** GovernSystemRiskMitigationInput: The input for adding/modifying a system risk mitigation */
  mitigate?: InputMaybe<Array<AddSystemRiskInput_Mitigate>>;
  name: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
  residualRiskEvaluation?: InputMaybe<Array<Scalars['Int']['input']>>;
  sources?: InputMaybe<Array<Scalars['String']['input']>>;
  systemId: Scalars['String']['input'];
  /** RiskTreatment: The treatment plan for the risk */
  treatmentPlan?: InputMaybe<RiskTreatment>;
  types: Array<Scalars['String']['input']>;
};

/** The input for adding/modifying a system risk */
export type AddSystemRiskInput_Mitigate = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};

export type AddSystemSupplierInput = {
  documentIds: Array<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AddTeamMemberInput = {
  email: Scalars['String']['input'];
  role: TeamRole;
  spaceId: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
};

export type AddTeamResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<TeamMemberData>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Address = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  streetNameNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AggregatedByModelEvaluationResults = {
  model: LibraryModel;
  modelVersion?: Maybe<ModelVersionType>;
  scores: Array<EvaluationResultProvidersType>;
};

export type AggregatedByTaskEvaluationResults = {
  evaluationResults: Array<EvaluationWithScoresType>;
  task: LibraryTaskType;
};

export type AggregatedEvaluationResultsWithMetadata = {
  metricExplanations: Array<InputOptionType>;
  resultsAggregatedByModel: Array<AggregatedByModelEvaluationResults>;
  resultsAggregatedByTask: Array<AggregatedByTaskEvaluationResults>;
  shortenedDatasetParameters: Array<ShortenedDatasetParameter>;
};

export type AppCapabilitiesToOrganizations = {
  capability: AppCapability;
  capabilityId: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  organization: AppOrganization;
  organizationId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type AppCapabilitiesToUsers = {
  capability: AppCapability;
  capabilityId: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  cuid: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  user: AppUser;
  userId: Scalars['String']['output'];
};

export type AppCapability = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizations?: Maybe<Array<AppCapabilitiesToOrganizations>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  users?: Maybe<Array<AppCapabilitiesToUsers>>;
};

export type AppContactInformation = {
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  systems?: Maybe<Array<GovernSystem>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type AppEmailToken = {
  createdAt: Scalars['Timestamp']['output'];
  createdById?: Maybe<Scalars['Int']['output']>;
  createdByUser?: Maybe<AppUser>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  expiresAt: Scalars['Timestamp']['output'];
  id: Scalars['Int']['output'];
  idempotencyKey?: Maybe<Scalars['String']['output']>;
  jwt: Scalars['String']['output'];
  payload?: Maybe<Scalars['JSON']['output']>;
  targetId?: Maybe<Scalars['Int']['output']>;
  targetUser?: Maybe<AppUser>;
  token: Scalars['String']['output'];
  type?: Maybe<TokenType>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type AppInputOption = {
  attachments?: Maybe<Array<GovernAttachment>>;
  autoid: Scalars['Int']['output'];
  compoundId: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deleted_at?: Maybe<Scalars['Timestamp']['output']>;
  explainer?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  future_type?: Maybe<AppInputOptionsName>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  suppliers?: Maybe<Array<GovernSystemSupplier>>;
  targets?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export enum AppInputOptionsName {
  Block = 'Block',
  BusinessImpactLevel = 'BusinessImpactLevel',
  BusinessModels = 'BusinessModels',
  Country = 'Country',
  CoverageTag = 'CoverageTag',
  DatasetModality = 'DatasetModality',
  DatasetSubject = 'DatasetSubject',
  DocumentType = 'DocumentType',
  Function = 'Function',
  Industry = 'Industry',
  LifecycleStage = 'LifecycleStage',
  MarginalRiskLevel = 'MarginalRiskLevel',
  Metrics = 'Metrics',
  OrganisationRole = 'OrganisationRole',
  PersonalData = 'PersonalData',
  Region = 'Region',
  RiskImpact = 'RiskImpact',
  RiskLikelihood = 'RiskLikelihood',
  RiskSource = 'RiskSource',
  Role = 'Role',
  Sector = 'Sector',
  SpecialCategories = 'SpecialCategories',
  SupplierType = 'SupplierType',
  SystemRiskLevel = 'SystemRiskLevel',
  SystemType = 'SystemType',
  TreatmentStrategy = 'TreatmentStrategy',
  UsState = 'USState'
}

export type AppKey = {
  api_key?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  created_by?: Maybe<Scalars['Int']['output']>;
  cuid?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  organization_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updated_by?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type AppOrganization = {
  access?: Maybe<Capabilities>;
  accessCapabilities?: Maybe<Array<AppCapabilitiesToOrganizations>>;
  address?: Maybe<Scalars['JSON']['output']>;
  allowMemberInviteUsers?: Maybe<Scalars['Boolean']['output']>;
  allowOutsideOrgEmail?: Maybe<Scalars['Boolean']['output']>;
  allowSpaceManagerInviteUsers?: Maybe<Scalars['Boolean']['output']>;
  appCapabilities: Array<AppCapability>;
  appRequestCancellations?: Maybe<Array<AppRequestCancellation>>;
  attachments?: Maybe<Array<GovernAttachment>>;
  availableAccesses: Array<OptionsResponseType>;
  capabilities: Array<Capabilities>;
  consentToUserSwitching?: Maybe<Scalars['Boolean']['output']>;
  contacts?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  defaultBusinessModels?: Maybe<Array<Scalars['String']['output']>>;
  defaultIndustries?: Maybe<Array<Scalars['String']['output']>>;
  defaultRegions?: Maybe<Array<Scalars['String']['output']>>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  domain: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  governDatasets?: Maybe<Array<GovernDataset>>;
  governSystems?: Maybe<Array<GovernSystem>>;
  industryModelId?: Maybe<Scalars['Int']['output']>;
  license?: Maybe<Licenses>;
  licenseDeprecated?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<OrganizationLogoType>;
  maxGovernSystems: Scalars['Int']['output'];
  members: MembersPaginated;
  modelId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  pendingInvitations: MembersPaginated;
  publicRegisterUrl?: Maybe<Scalars['String']['output']>;
  publicRegistryURL?: Maybe<Scalars['String']['output']>;
  referenceAllowed?: Maybe<Scalars['Boolean']['output']>;
  spaces?: Maybe<Array<GovernSpace>>;
  status?: Maybe<Scalars['Boolean']['output']>;
  systemLimit: Scalars['Int']['output'];
  systemsCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  users: Array<AppUser>;
  usersCount: Scalars['Int']['output'];
  /** The name of vendor organization in case the parent system is not owned by caller */
  vendorOrganizationName?: Maybe<Scalars['String']['output']>;
  verifiedDate?: Maybe<Scalars['Timestamp']['output']>;
};


export type AppOrganizationMembersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type AppOrganizationPendingInvitationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AppOrganizationResultEdge = {
  cursor: Scalars['String']['output'];
  node: AppOrganization;
};

export type AppRequestCancellation = {
  additionalInfo?: Maybe<Scalars['String']['output']>;
  cancellationReason?: Maybe<RequestCancellationReason>;
  contactEmail: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  organization: AppOrganization;
  organizationId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  user: AppUser;
  userId: Scalars['Int']['output'];
};

export type AppUser = {
  access?: Maybe<Capabilities>;
  active: Scalars['Boolean']['output'];
  capabilities: Array<Capabilities>;
  color?: Maybe<Scalars['String']['output']>;
  consentedAt?: Maybe<Scalars['Timestamp']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  createdSpaces?: Maybe<Array<GovernSpace>>;
  createdTokens?: Maybe<Array<AppEmailToken>>;
  data?: Maybe<Scalars['JSON']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  failedLoginCount?: Maybe<Scalars['Int']['output']>;
  feedSeenAt?: Maybe<Scalars['Timestamp']['output']>;
  feedVisitedAt?: Maybe<Scalars['Timestamp']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  groups: Array<AppUserGroup>;
  hasNewFeedItems: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isFollowingItems: Scalars['Boolean']['output'];
  lastFailedLoginAt?: Maybe<Scalars['Timestamp']['output']>;
  lastLoginAt?: Maybe<Scalars['Timestamp']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  managedSpaces?: Maybe<Array<GovernSpaceToManager>>;
  onboardedAt?: Maybe<Scalars['Timestamp']['output']>;
  organizations: Array<AppOrganization>;
  passwordChangeRequired: Scalars['Boolean']['output'];
  pending: Scalars['Boolean']['output'];
  profileImageURL?: Maybe<Scalars['String']['output']>;
  role: Roles;
  seenReleaseAt?: Maybe<Scalars['String']['output']>;
  /** Spaces that user is a member/manager. */
  spaces: Array<GovernSpace>;
  systems: Array<GovernSystem>;
  targetTokens?: Maybe<Array<AppEmailToken>>;
  title?: Maybe<Scalars['String']['output']>;
  txtIdentifier: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Scalars['String']['output'];
};

export type AppUserContactInfo = {
  color?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  profileImageURL?: Maybe<Scalars['String']['output']>;
  txtIdentifier: Scalars['String']['output'];
};

export type AppUserEdge = {
  cursor: Scalars['String']['output'];
  node: AppUser;
};

export type AppUserGroup = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  explainerLong: Scalars['String']['output'];
  explainerShort: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type ApprovalStatus = {
  date?: Maybe<Scalars['Timestamp']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
};

export type ApprovalStatusInput = {
  date?: InputMaybe<Scalars['Timestamp']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AttachmentResponseType = {
  file: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum AttachmentTarget {
  Dataset = 'DATASET',
  Space = 'SPACE',
  System = 'SYSTEM'
}

export enum AuditLogEvent {
  Create = 'CREATE',
  Delete = 'DELETE',
  Default = 'Default',
  Link = 'LINK',
  Unlink = 'UNLINK',
  Update = 'UPDATE'
}

/** The status of a bookmark */
export enum BookmarkStatus {
  Bookmarked = 'bookmarked',
  Unbookmarked = 'unbookmarked'
}

export type BookmarkToggleResponse = {
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  status: BookmarkStatus;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum BookmarkType {
  Dataset = 'Dataset',
  System = 'System'
}

export enum Capabilities {
  Full = 'Full',
  Library = 'Library'
}

export type Contacts = {
  additionalContact?: InputMaybe<Scalars['String']['input']>;
  mainContact?: InputMaybe<Scalars['String']['input']>;
};

export type Country = {
  alpha2Code: Scalars['String']['output'];
  alpha3Code: Scalars['String']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  region: Region;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type CountryDataType = {
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateDatasetInput = {
  cardOwnerId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  modality: GovernDatasetModality;
  name: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  subject?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateGovernAttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  format: GovernAttachmentFormat;
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  target: AttachmentTarget;
  targetId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGovernSystemInput = {
  capabilities: Array<Scalars['String']['input']>;
  countries: Array<Scalars['String']['input']>;
  functions: Array<Scalars['String']['input']>;
  industries: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  regions: Array<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
  systemTypes: Array<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateGovernSystemResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<GovernSystem>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateOrganizationDto = {
  address?: InputMaybe<Address>;
  capabilities?: InputMaybe<Array<Capabilities>>;
  contacts?: InputMaybe<Contacts>;
  license?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['JSON']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  publicRegisterUrl?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The input for creating a space */
export type CreateSpaceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  functions?: InputMaybe<Array<Scalars['String']['input']>>;
  icon: Scalars['String']['input'];
  managers: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateUserArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  passwordInput?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};

export type CreateUsersInput = {
  organizationId: Scalars['ID']['input'];
  users: Array<SimpleUsersInput>;
};

export type DataResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Scalars['JSONObject']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Dataset = {
  autoid: Scalars['Int']['output'];
  copyrightedData?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  datasetId: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type ErgProvidersWithModelType = {
  datasetParameter?: Maybe<Scalars['String']['output']>;
  model: LibraryModel;
  modelVersion?: Maybe<ModelVersionType>;
  provider: Scalars['String']['output'];
  score: Scalars['String']['output'];
  scoreType: Scalars['String']['output'];
  scoreUnit: Scalars['String']['output'];
  taskParameters?: Maybe<Scalars['String']['output']>;
};

export type EvaluationResultInTask = {
  evaluation: LibraryEvaluation;
  scoreTypes?: Maybe<Scalars['JSONObject']['output']>;
};

export type EvaluationResultProvidersType = {
  datasetParameter?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  score: Scalars['String']['output'];
  scoreType: Scalars['String']['output'];
  scoreUnit: Scalars['String']['output'];
  taskParameters?: Maybe<Scalars['String']['output']>;
};

export type EvaluationResultsByTaskOrderByFieldGql = {
  columnName: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EvaluationResultsByTaskOrderByType = {
  direction: SortDirection;
  fields: Array<EvaluationResultsByTaskOrderByFieldGql>;
};

export type EvaluationResultsByTaskType = {
  tasks?: Maybe<Array<EvaluationResultsTaskType>>;
  versionNames?: Maybe<Scalars['JSONObject']['output']>;
};

export type EvaluationResultsTaskType = {
  evaluationResults?: Maybe<Array<EvaluationResultInTask>>;
  externalId: Scalars['ID']['output'];
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  taskParameters: Array<Scalars['String']['output']>;
};

export type EvaluationResultsType = {
  evaluation: LibraryEvaluation;
  modelName: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  score: Scalars['String']['output'];
  scoreType: Scalars['String']['output'];
  scoreUnit: Scalars['String']['output'];
  task: LibraryTaskType;
  taskId: Scalars['Int']['output'];
  versions?: Maybe<Scalars['JSONObject']['output']>;
};

export enum EvaluationType {
  Capability = 'CAPABILITY',
  Safety = 'SAFETY'
}

export type EvaluationWithScoresType = {
  evaluation: LibraryEvaluation;
  scores: Array<ErgProvidersWithModelType>;
};

export type EvidenceBlockResponseType = {
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FeatureResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FilterableDefinitionType = {
  counts: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  options: Array<Scalars['JSON']['output']>;
};

export enum FilterableTargetType {
  Evaluation = 'Evaluation',
  Model = 'Model',
  Policy = 'Policy',
  Risk = 'Risk',
  Template = 'Template'
}

export type GeographyType = {
  regions: Array<Region>;
};


export type GeographyTypeRegionsArgs = {
  alphabeticalOrderDirection?: InputMaybe<SortDirection>;
};

export type GovernAttachment = {
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  datasets?: Maybe<Array<GovernAttachmentToDataset>>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  format: GovernAttachmentFormat;
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organization: AppOrganization;
  organizationId: Scalars['Int']['output'];
  owner: AppUser;
  suppliers?: Maybe<Array<GovernAttachmentToSupplier>>;
  systems?: Maybe<Array<GovernAttachmentToSystem>>;
  type: AppInputOption;
  typeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GovernAttachmentEdge = {
  cursor: Scalars['String']['output'];
  node: GovernAttachment;
};

export enum GovernAttachmentFormat {
  AnythingCode = 'AnythingCode',
  Excel = 'Excel',
  Image = 'Image',
  Link = 'Link',
  Other = 'Other',
  Pdf = 'PDF',
  PowerPoint = 'PowerPoint',
  Text = 'Text'
}

export type GovernAttachmentPaginated = {
  edges?: Maybe<Array<GovernAttachmentEdge>>;
  nodes?: Maybe<Array<GovernAttachment>>;
  pageInfo: PageInfo;
};

export type GovernAttachmentToDataset = {
  attachment: GovernAttachment;
  attachmentId: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  dataset: GovernDataset;
  datasetId: Scalars['Int']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernAttachmentToSupplier = {
  attachment: GovernAttachment;
  attachmentId: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  supplier: GovernSystemSupplier;
  supplierId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernAttachmentToSystem = {
  attachment: GovernAttachment;
  attachmentId: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  system: GovernSystem;
  systemId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernBookmark = {
  createdAt: Scalars['Timestamp']['output'];
  datasetId?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  systemId?: Maybe<Scalars['Int']['output']>;
  type: BookmarkType;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  userId: Scalars['Int']['output'];
};

export type GovernControlsResponseDataType = {
  completedControlsCount: Scalars['Int']['output'];
  controls?: Maybe<Array<GovernSystemControl>>;
  controlsInProgressCount: Scalars['Int']['output'];
  sourceControlsCount: Scalars['Int']['output'];
};

export type GovernControlsResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<GovernControlsResponseDataType>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GovernDataset = {
  attachments?: Maybe<Array<GovernAttachmentToDataset>>;
  bookmark: Array<GovernBookmark>;
  cardOwner: AppUser;
  cardOwnerId: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  dataCollectionMethods?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isBookmarked: Scalars['Boolean']['output'];
  license?: Maybe<Scalars['String']['output']>;
  linkToDataset?: Maybe<Scalars['String']['output']>;
  modality?: Maybe<GovernDatasetModality>;
  name: Scalars['String']['output'];
  organization: AppOrganization;
  organizationId: Scalars['Int']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  personalData?: Maybe<Array<Scalars['String']['output']>>;
  preProcessingMethods?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  space?: Maybe<GovernSpace>;
  specialCategoriesPD?: Maybe<Array<Scalars['String']['output']>>;
  subject?: Maybe<Array<Scalars['String']['output']>>;
  systems: Array<GovernSystem>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type GovernDatasetEdge = {
  cursor: Scalars['String']['output'];
  node: GovernDataset;
};

export enum GovernDatasetModality {
  Audio = 'Audio',
  Documents = 'Documents',
  Geospatial = 'Geospatial',
  Graph = 'Graph',
  Language = 'Language',
  Multimodal = 'Multimodal',
  Tabular = 'Tabular',
  Text = 'Text',
  TimeSeries = 'TimeSeries',
  Unknown = 'Unknown',
  Video = 'Video',
  Vision = 'Vision'
}

export enum GovernDatasetPurpose {
  Operation = 'OPERATION',
  Testing = 'TESTING',
  Training = 'TRAINING',
  Validation = 'VALIDATION'
}

export type GovernDatasetsPaginated = {
  edges?: Maybe<Array<GovernDatasetEdge>>;
  nodes?: Maybe<Array<GovernDataset>>;
  pageInfo: PageInfo;
};

export type GovernLifecycle = {
  createdAt: Scalars['Timestamp']['output'];
  currentStage: GovernLifecycleStage;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId: Scalars['String']['output'];
  governSystem: GovernSystem;
  governSystemId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  stageTargets?: Maybe<Array<GovernLifecycleTarget>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  version: Scalars['Int']['output'];
};

export enum GovernLifecycleStage {
  ApprovedForDeployment = 'Approved_for_deployment',
  ApprovedForDesignAndDevelopment = 'Approved_for_design_and_development',
  ApprovedForReevaluationCompletion = 'Approved_for_reevaluation_completion',
  ApprovedForRetirement = 'Approved_for_retirement',
  Deployment = 'Deployment',
  DesignAndDevelopment = 'Design_and_development',
  Initiation = 'Initiation',
  OperationAndMonitoring = 'Operation_and_monitoring',
  ReEvaluation = 'Re_evaluation',
  Retirement = 'Retirement',
  VerificationAndValidation = 'Verification_and_validation'
}

export type GovernLifecycleTarget = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  lifecycle: GovernLifecycle;
  lifecycleId: Scalars['Int']['output'];
  stage: GovernLifecycleStage;
  targetDate?: Maybe<Scalars['Timestamp']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSpace = {
  address?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<Array<OptionsResponseType>>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  creator?: Maybe<AppUser>;
  datasets?: Maybe<Array<GovernDataset>>;
  datasetsCount: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  functions?: Maybe<Array<OptionsResponseType>>;
  icon?: Maybe<Scalars['String']['output']>;
  isDefault: Scalars['Boolean']['output'];
  /** Lifecycle statistics for a space. Since it is not possible to return a tuple in GraphQL, needed to return an array with all the keys. Recommend to transform this object into a map before usage. */
  lifecycleStatistics: Array<GovernSpacesLifecycleStatisticTuple>;
  managers?: Maybe<Array<AppUserContactInfo>>;
  members?: Maybe<Array<AppUserContactInfo>>;
  membersCount: Scalars['Int']['output'];
  monthlyDatasetsCountVariation?: Maybe<Scalars['Int']['output']>;
  monthlySystemsCountVariation?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<AppOrganization>;
  organizationId: Scalars['Int']['output'];
  regions?: Maybe<Array<OptionsResponseType>>;
  systems?: Maybe<Array<GovernSystem>>;
  systemsCount: Scalars['Int']['output'];
  topRisks: Array<GovernSpacesTopRisk>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};


export type GovernSpaceLifecycleStatisticsArgs = {
  riskLevel?: InputMaybe<Scalars['String']['input']>;
};


export type GovernSpaceMonthlySystemsCountVariationArgs = {
  riskLevel?: InputMaybe<Scalars['String']['input']>;
};


export type GovernSpaceSystemsCountArgs = {
  riskLevel?: InputMaybe<Scalars['String']['input']>;
};


export type GovernSpaceTopRisksArgs = {
  riskLevel?: InputMaybe<Scalars['String']['input']>;
};

export type GovernSpaceEdge = {
  cursor: Scalars['String']['output'];
  node: GovernSpace;
};

export type GovernSpaceMember = {
  color?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  platformRole: Roles;
  profileImageURL?: Maybe<Scalars['String']['output']>;
  role: GovernSpaceRole;
  txtIdentifier: Scalars['String']['output'];
};

export type GovernSpaceMemberEdge = {
  cursor: Scalars['String']['output'];
  node: GovernSpaceMember;
};

export type GovernSpaceResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<GovernSpace>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** The role of a user in a govern space */
export enum GovernSpaceRole {
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export type GovernSpaceToManager = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externaldId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  space: GovernSpace;
  spaceId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  user: AppUser;
  userId: Scalars['Int']['output'];
};

export type GovernSpacesLifecycleStatisticTuple = {
  averageDaysSpentOnStage?: Maybe<Scalars['Int']['output']>;
  /** This is the lifecycle stage for this statistic. It is named as key to more easily identify how to convert it to a map. */
  key: GovernLifecycleStage;
  numberOfSystemsCurrentlyInStage: Scalars['Int']['output'];
};

export type GovernSpacesMembersPaginated = {
  edges?: Maybe<Array<GovernSpaceMemberEdge>>;
  nodes?: Maybe<Array<GovernSpaceMember>>;
  pageInfo: PageInfo;
};

export type GovernSpacesPaginated = {
  edges?: Maybe<Array<GovernSpaceEdge>>;
  nodes?: Maybe<Array<GovernSpace>>;
  pageInfo: PageInfo;
};

export type GovernSpacesTopRisk = {
  libraryRisk: LibraryRisk;
  systemsCount: Scalars['Int']['output'];
};

export type GovernSystem = {
  attachments?: Maybe<Array<GovernAttachmentToSystem>>;
  bookmarks?: Maybe<Array<GovernBookmark>>;
  capabilities?: Maybe<Array<Scalars['String']['output']>>;
  contact?: Maybe<AppContactInformation>;
  createdAt: Scalars['Timestamp']['output'];
  createdByUser?: Maybe<AppUser>;
  datasets?: Maybe<Array<GovernSystemDataset>>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  functions?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  impact: Scalars['String']['output'];
  impactJustification: Scalars['String']['output'];
  industries?: Maybe<Array<Scalars['String']['output']>>;
  isBookmarked: Scalars['Boolean']['output'];
  isEditable: Scalars['Boolean']['output'];
  lifecycle?: Maybe<GovernLifecycle>;
  linkedBySystems?: Maybe<Array<GovernSystemToSystem>>;
  linkedGovernSystems?: Maybe<Array<GovernSystem>>;
  linkedLibraryModels?: Maybe<Array<LibraryModel>>;
  linkedModels?: Maybe<Array<GovernSystemToModel>>;
  linkedSystems?: Maybe<Array<GovernSystemToSystem>>;
  name: Scalars['String']['output'];
  organization?: Maybe<AppOrganization>;
  policies?: Maybe<Array<GovernSystemPolicy>>;
  regions?: Maybe<Array<Scalars['String']['output']>>;
  risk: Scalars['String']['output'];
  riskJustification?: Maybe<Scalars['String']['output']>;
  risks?: Maybe<Array<GovernSystemRisk>>;
  role?: Maybe<Array<Scalars['String']['output']>>;
  space: GovernSpace;
  spaceId: Scalars['Int']['output'];
  suppliers?: Maybe<Array<GovernSystemSupplier>>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  teams?: Maybe<Array<GovernSystemTeam>>;
  types?: Maybe<Array<Scalars['String']['output']>>;
  uid: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  version: Scalars['Int']['output'];
};

export type GovernSystemControl = {
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  completedBy?: Maybe<AppUser>;
  completedById?: Maybe<Scalars['Int']['output']>;
  contextualRequirementNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  evidenceBlocks: Array<EvidenceBlockResponseType>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  sourceControl?: Maybe<LibraryControl>;
  sourceControlId?: Maybe<Scalars['Int']['output']>;
  sourceRequirement: LibraryRequirement;
  sourceRequirementId: Scalars['Int']['output'];
  template: GovernSystemTemplate;
  templateId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSystemDataset = {
  createdAt: Scalars['Timestamp']['output'];
  dataset: GovernDataset;
  datasetId: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  purpose?: Maybe<Array<GovernDatasetPurpose>>;
  system: GovernSystem;
  systemId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSystemPaginated = {
  edges?: Maybe<Array<GovernSystemWithMoreDataEdge>>;
  nodes?: Maybe<Array<GovernSystemWithMoreData>>;
  pageInfo: PageInfo;
};

export type GovernSystemPolicy = {
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  sourcePolicy: LibraryPolicy;
  system: GovernSystem;
  systemTemplates?: Maybe<Array<GovernSystemTemplate>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GovernSystemPolicyWithMoreData = {
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  numberOfTemplatesInUse: Scalars['Int']['output'];
  sourcePolicy: LibraryPolicy;
  system: GovernSystem;
  systemTemplates?: Maybe<Array<GovernSystemTemplateWithControlsCounts>>;
  teams: Array<SystemTeamMembers>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GovernSystemPolicyWithTotal = {
  items: Array<GovernSystemPolicyWithMoreData>;
  total: Scalars['Int']['output'];
};

export type GovernSystemRisk = {
  accept?: Maybe<Scalars['String']['output']>;
  avoid?: Maybe<Scalars['String']['output']>;
  consequences?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inherentRiskEvaluation?: Maybe<Array<Scalars['Int']['output']>>;
  libraryRisk?: Maybe<LibraryRisk>;
  libraryRiskAutoid?: Maybe<Scalars['Int']['output']>;
  marginalRiskLevel?: Maybe<Scalars['String']['output']>;
  mitigations?: Maybe<Array<GovernSystemRiskMitigation>>;
  owner: AppUser;
  ownerId: Scalars['String']['output'];
  residualRiskEvaluation?: Maybe<Array<Scalars['Int']['output']>>;
  sources?: Maybe<Array<Scalars['String']['output']>>;
  system: GovernSystem;
  systemId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  treatmentPlan?: Maybe<RiskTreatment>;
  types?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GovernSystemRiskEdge = {
  cursor: Scalars['String']['output'];
  node: GovernSystemRisk;
};

export type GovernSystemRiskMitigation = {
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  governSystemRisk: GovernSystemRisk;
  governSystemRiskId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  libraryMitigation?: Maybe<LibraryMitigation>;
  libraryMitigationAutoid?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GovernSystemSupplier = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  documents?: Maybe<Array<GovernAttachmentToSupplier>>;
  externalId: Scalars['String']['output'];
  governSystemId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  provider: Scalars['String']['output'];
  system: GovernSystem;
  type: AppInputOption;
  typeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSystemTeam = {
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  member: AppUser;
  memberId: Scalars['Int']['output'];
  role: TeamRole;
  system: GovernSystem;
  systemId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSystemTemplate = {
  controls?: Maybe<Array<GovernSystemControl>>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  sourceTemplate: LibraryTemplate;
  systemPolicy: GovernSystemPolicy;
  updatedAt: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GovernSystemTemplateWithControlsCounts = {
  completedControlsCount: Scalars['Int']['output'];
  controls?: Maybe<Array<GovernSystemControl>>;
  controlsInProgressCount: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  sourceControlsCount: Scalars['Int']['output'];
  sourceTemplate: LibraryTemplate;
  systemPolicy: GovernSystemPolicy;
  updatedAt: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GovernSystemToModel = {
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  model: LibraryModel;
  modelId: Scalars['Int']['output'];
  system: GovernSystem;
  systemId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSystemToSystem = {
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  sourceSystem: GovernSystem;
  sourceSystemId: Scalars['String']['output'];
  targetSystem: GovernSystem;
  targetSystemId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type GovernSystemWithMoreData = {
  attachments?: Maybe<Array<GovernAttachmentToSystem>>;
  bookmarks?: Maybe<Array<GovernBookmark>>;
  canUserEditSystem?: Maybe<Scalars['Boolean']['output']>;
  capabilities?: Maybe<Array<Scalars['String']['output']>>;
  contact?: Maybe<AppContactInformation>;
  createdAt: Scalars['Timestamp']['output'];
  createdByUser?: Maybe<AppUser>;
  datasets?: Maybe<Array<GovernSystemDataset>>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  functions?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  impact: Scalars['String']['output'];
  impactJustification: Scalars['String']['output'];
  industries?: Maybe<Array<Scalars['String']['output']>>;
  isBookmarked: Scalars['Boolean']['output'];
  isEditable: Scalars['Boolean']['output'];
  lifecycle?: Maybe<GovernLifecycle>;
  linkedBySystems?: Maybe<Array<GovernSystemToSystem>>;
  linkedGovernSystems?: Maybe<Array<GovernSystem>>;
  linkedLibraryModels?: Maybe<Array<LibraryModel>>;
  linkedModels?: Maybe<Array<GovernSystemToModel>>;
  linkedSystems?: Maybe<Array<GovernSystemToSystem>>;
  name: Scalars['String']['output'];
  organization?: Maybe<AppOrganization>;
  policies?: Maybe<Array<GovernSystemPolicy>>;
  regions?: Maybe<Array<Scalars['String']['output']>>;
  risk: Scalars['String']['output'];
  riskJustification?: Maybe<Scalars['String']['output']>;
  risks?: Maybe<Array<GovernSystemRisk>>;
  role?: Maybe<Array<Scalars['String']['output']>>;
  space: GovernSpace;
  spaceId: Scalars['Int']['output'];
  suppliers?: Maybe<Array<GovernSystemSupplier>>;
  systemOwner?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  teams?: Maybe<Array<GovernSystemTeam>>;
  types?: Maybe<Array<Scalars['String']['output']>>;
  uid: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  version: Scalars['Int']['output'];
};

export type GovernSystemWithMoreDataEdge = {
  cursor: Scalars['String']['output'];
  node: GovernSystemWithMoreData;
};

export type IdResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InputOptionResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data: Array<InputOptionType>;
  error?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InputOptionType = {
  compoundId: Scalars['ID']['output'];
  explainer?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  targets?: Maybe<Array<Scalars['String']['output']>>;
  /** ExternalID */
  value: Scalars['ID']['output'];
};

/** The input for adding a member to an organisation */
export type InviteMemberInput = {
  access: Scalars['String']['input'];
  emails: Array<Scalars['String']['input']>;
  spaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LatestChangeType = {
  change?: Maybe<Array<Scalars['String']['output']>>;
  event: AuditLogEvent;
  externalId: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  time: Scalars['Timestamp']['output'];
};

export type LatestChangeTypeEdge = {
  cursor: Scalars['String']['output'];
  node: LatestChangeType;
};

export type LatestChangesPaginated = {
  edges?: Maybe<Array<LatestChangeTypeEdge>>;
  nodes?: Maybe<Array<LatestChangeType>>;
  pageInfo: PageInfo;
};

export enum LibraryCardType {
  Evaluation = 'EVALUATION',
  Model = 'MODEL',
  Policy = 'POLICY',
  Risk = 'RISK',
  Template = 'TEMPLATE'
}

export type LibraryControl = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  autoid: Scalars['Int']['output'];
  controlId: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  evidenceBlocks: Array<EvidenceBlockResponseType>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryEvaluation = {
  aggregatedResults: AggregatedEvaluationResultsWithMetadata;
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  dataset?: Maybe<Scalars['String']['output']>;
  datasetParameterLabel?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  evaluationId: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  follows?: Maybe<Array<LibraryFeedFollow>>;
  id: Scalars['BigInt']['output'];
  isFollowed: Scalars['Boolean']['output'];
  models?: Maybe<Array<LibraryModel>>;
  modelsCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['String']['output']>;
  /** The sources for the evaluation */
  references: Array<LibrarySource>;
  resultsCounts?: Maybe<Array<LibraryEvaluationModelResultCount>>;
  risks?: Maybe<Array<LibraryRisk>>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tasks?: Maybe<Array<LibraryTask>>;
  trendiness?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryEvaluationEdge = {
  cursor: Scalars['String']['output'];
  node: LibraryEvaluation;
};

export type LibraryEvaluationModelResultCount = {
  compoundkey: Scalars['String']['output'];
  evaluation?: Maybe<LibraryEvaluation>;
  evaluationId: Scalars['Int']['output'];
  model?: Maybe<LibraryModel>;
  modelId: Scalars['Int']['output'];
};

export type LibraryEvaluationToModel = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  evaluation?: Maybe<LibraryEvaluation>;
  evaluationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  model?: Maybe<LibraryModel>;
  modelId?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryEvaluationToTask = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  evaluation?: Maybe<LibraryEvaluation>;
  evaluationId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  task?: Maybe<LibraryTask>;
  taskId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryEvaluationsPaginated = {
  edges?: Maybe<Array<LibraryEvaluationEdge>>;
  nodes?: Maybe<Array<LibraryEvaluation>>;
  pageInfo: PageInfo;
};

export type LibraryFeedFollow = {
  createdAt: Scalars['Timestamp']['output'];
  cuid: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  stiType: Scalars['String']['output'];
  targetId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  userId: Scalars['Int']['output'];
};

export type LibraryFeedItem = {
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  date: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  eventType?: Maybe<NotificationSourceEventType>;
  id: Scalars['Int']['output'];
  isFollowable?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
  sourceAutoid: Scalars['Int']['output'];
  sourceName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  urlId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type LibraryMitigation = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  governSystemRiskMitigations?: Maybe<Array<GovernSystemRiskMitigation>>;
  id: Scalars['BigInt']['output'];
  mitigationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryModel = {
  aggregatedByTaskEvaluationResults: AggregatedEvaluationResultsWithMetadata;
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  architecture?: Maybe<Scalars['String']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  carbonEmitted?: Maybe<Scalars['Float']['output']>;
  contentPolicy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  credoVendorProfile?: Maybe<Scalars['Boolean']['output']>;
  cuid?: Maybe<Scalars['String']['output']>;
  dataLocation?: Maybe<Scalars['String']['output']>;
  datasets?: Maybe<Array<Dataset>>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  evaluationResults?: Maybe<Array<EvaluationResultsType>>;
  evaluationResultsByTaskFor?: Maybe<EvaluationResultsByTaskType>;
  evaluationsCount?: Maybe<Scalars['Int']['output']>;
  follows?: Maybe<Array<LibraryFeedFollow>>;
  generalPurpose?: Maybe<Scalars['Boolean']['output']>;
  hasEvaluationResultsByTaskFor: Scalars['Boolean']['output'];
  id: Scalars['BigInt']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  isFollowed: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  languages?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mitigations?: Maybe<Array<LibraryMitigation>>;
  modality?: Maybe<Scalars['String']['output']>;
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  overview?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<LibraryModelOwner>;
  parameters?: Maybe<Scalars['String']['output']>;
  privacyPolicy?: Maybe<Scalars['String']['output']>;
  productPage?: Maybe<Scalars['String']['output']>;
  providerRisks?: Maybe<Array<LibraryRisk>>;
  region?: Maybe<Scalars['String']['output']>;
  releaseNotes?: Maybe<Scalars['String']['output']>;
  restrictions?: Maybe<Array<RestrictionType>>;
  resultsCounts?: Maybe<Array<LibraryEvaluationModelResultCount>>;
  safetyFeatures?: Maybe<Array<SafetyFeatureType>>;
  saidotRisks?: Maybe<Array<LibraryRisk>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  sourceCode?: Maybe<Scalars['String']['output']>;
  sources?: Maybe<Array<LibrarySource>>;
  standardsAndCertificates?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tasks?: Maybe<Array<LibraryTask>>;
  termsOfService?: Maybe<Scalars['String']['output']>;
  toEvaluations?: Maybe<Array<LibraryEvaluationToModel>>;
  tokens?: Maybe<Scalars['String']['output']>;
  trainingCluster?: Maybe<Scalars['String']['output']>;
  trainingCost?: Maybe<Scalars['String']['output']>;
  trainingDataOverview?: Maybe<Scalars['String']['output']>;
  trainingTime?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  versions?: Maybe<Array<ModelVersionType>>;
};


export type LibraryModelEvaluationResultsByTaskForArgs = {
  evaluationType: EvaluationType;
  orderBy?: InputMaybe<Array<EvaluationResultsByTaskOrderByType>>;
};

export type LibraryModelEdge = {
  cursor: Scalars['String']['output'];
  node: LibraryModel;
};

export type LibraryModelOwner = {
  address?: Maybe<Scalars['String']['output']>;
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableId: Scalars['String']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  cuid: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  models?: Maybe<Array<LibraryModelOwnerToModel>>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryModelOwnerToModel = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['BigInt']['output'];
  modelId: Scalars['Int']['output'];
  modelOwnerId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryModelPaginated = {
  edges?: Maybe<Array<LibraryModelEdge>>;
  nodes?: Maybe<Array<LibraryModel>>;
  pageInfo: PageInfo;
};

export type LibraryModelToProviderRisk = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  model?: Maybe<LibraryModel>;
  modelId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  risk?: Maybe<LibraryRisk>;
  riskId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryModelToSaidotRisk = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  model?: Maybe<LibraryModel>;
  modelId: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  risk?: Maybe<LibraryRisk>;
  riskId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryPolicy = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  countries?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  documentDate?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDate?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDate2?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDate3?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDate4?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDate5?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDateScope?: Maybe<Scalars['String']['output']>;
  effectiveDateScope2?: Maybe<Scalars['String']['output']>;
  effectiveDateScope3?: Maybe<Scalars['String']['output']>;
  effectiveDateScope4?: Maybe<Scalars['String']['output']>;
  effectiveDateScope5?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['Timestamp']['output']>;
  follows?: Maybe<Array<LibraryFeedFollow>>;
  hasCoverage: Scalars['Boolean']['output'];
  hasRequirements: Scalars['Boolean']['output'];
  hasRoles: Scalars['Boolean']['output'];
  id: Scalars['BigInt']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  isFollowed: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  keyInformation?: Maybe<Scalars['String']['output']>;
  keyInformation2?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overview?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<LibraryPolicyOwner>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  penalties?: Maybe<Scalars['String']['output']>;
  policyGuidance?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regions?: Maybe<Scalars['String']['output']>;
  regulatedSystemCategories?: Maybe<Array<LibraryRegulatedSystemCategory>>;
  requirementSections?: Maybe<Array<LibraryRequirementSection>>;
  requirements?: Maybe<Array<LibraryRequirement>>;
  roles?: Maybe<Array<LibraryRole>>;
  scope?: Maybe<Scalars['String']['output']>;
  sector?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  templatesCount: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  usStates?: Maybe<Scalars['String']['output']>;
};


export type LibraryPolicyIsRecommendedArgs = {
  systemId?: InputMaybe<Scalars['String']['input']>;
};

export type LibraryPolicyEdge = {
  cursor: Scalars['String']['output'];
  node: LibraryPolicy;
};

export type LibraryPolicyOwner = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableId: Scalars['String']['output'];
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  policies?: Maybe<Array<LibraryPolicy>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryPolicyPaginated = {
  edges?: Maybe<Array<LibraryPolicyEdge>>;
  nodes?: Maybe<Array<LibraryPolicy>>;
  pageInfo: PageInfo;
};

export type LibraryPolicyToRole = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  policyId?: Maybe<Scalars['Int']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryRegulatedSystemCategory = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name?: Maybe<Scalars['String']['output']>;
  policyReference?: Maybe<Scalars['String']['output']>;
  regulatedSystemCategoryId: Scalars['String']['output'];
  regulatedSystems?: Maybe<Array<PolicyRegulatedSystemType>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryRequirement = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  contextualRequirement?: Maybe<Scalars['Boolean']['output']>;
  contextualRequirementExplanation?: Maybe<Scalars['String']['output']>;
  controlDescription?: Maybe<Scalars['String']['output']>;
  controls?: Maybe<Array<LibraryControl>>;
  controlsCount: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  cuid: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  policyReference?: Maybe<Scalars['String']['output']>;
  requirementId: Scalars['String']['output'];
  requirementSections?: Maybe<Array<LibraryRequirementSection>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryRequirementSection = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  cuid: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  includeInTemplate?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  platformSection?: Maybe<Scalars['String']['output']>;
  policyReference?: Maybe<Scalars['String']['output']>;
  requirementSectionId: Scalars['String']['output'];
  requirements?: Maybe<Array<LibraryRequirement>>;
  roles?: Maybe<Array<LibraryRole>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryRequirementSectionToRole = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  requirementSection?: Maybe<LibraryRequirementSection>;
  requirementSectionId?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<LibraryRole>;
  roleId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryRequirementToRole = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  requirementId?: Maybe<Scalars['Int']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryRisk = {
  autoid?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  follows?: Maybe<Array<LibraryFeedFollow>>;
  governSystemRisks?: Maybe<Array<GovernSystemRisk>>;
  id: Scalars['BigInt']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  isFollowed: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  mitigations?: Maybe<Array<LibraryMitigation>>;
  models?: Maybe<Array<LibraryModel>>;
  name: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  providerRisk?: Maybe<Array<LibraryModelToProviderRisk>>;
  riskFunction?: Maybe<Scalars['String']['output']>;
  riskId: Scalars['String']['output'];
  saidotRisks?: Maybe<Array<LibraryModelToSaidotRisk>>;
  sources?: Maybe<Array<LibrarySource>>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};


export type LibraryRiskIsRecommendedArgs = {
  systemId: Scalars['String']['input'];
};

export type LibraryRiskEdge = {
  cursor: Scalars['String']['output'];
  node: LibraryRisk;
};

export type LibraryRiskPaginated = {
  edges?: Maybe<Array<LibraryRiskEdge>>;
  nodes?: Maybe<Array<LibraryRisk>>;
  pageInfo: PageInfo;
};

export type LibraryRole = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['String']['output'];
  toPolicy?: Maybe<Array<LibraryPolicyToRole>>;
  toRequirement?: Maybe<Array<LibraryRequirementToRole>>;
  toRequirementSection?: Maybe<Array<LibraryRequirementSectionToRole>>;
  toTemplates?: Maybe<Array<LibraryTemplateToRole>>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibrarySource = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  sourceId: Scalars['String']['output'];
  sourceName?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LibraryTask = {
  LibraryEvaluationToTask?: Maybe<Array<LibraryEvaluationToTask>>;
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  taskId: Scalars['String']['output'];
  taskParameterLabel?: Maybe<Scalars['String']['output']>;
  taskTypes?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryTaskType = {
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  evaluationResults?: Maybe<Array<EvaluationResultsType>>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  taskId: Scalars['ID']['output'];
  taskParameterLabel?: Maybe<Scalars['String']['output']>;
  taskTypes: Array<EvaluationType>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryTemplate = {
  airtableCreatedAt: Scalars['Timestamp']['output'];
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid: Scalars['Int']['output'];
  controlsCount: Scalars['Int']['output'];
  coverageTags?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  isRecommended: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  requirements: Array<LibraryRequirement>;
  roles?: Maybe<Array<LibraryRole>>;
  templateId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type LibraryTemplateEdge = {
  cursor: Scalars['String']['output'];
  node: LibraryTemplate;
};

export type LibraryTemplatePaginated = {
  controls?: Maybe<Array<LibraryRequirement>>;
  controlsCount: Scalars['Int']['output'];
  edges?: Maybe<Array<LibraryTemplateEdge>>;
  externalId: Scalars['String']['output'];
  isRecommended: Scalars['Boolean']['output'];
  nodes?: Maybe<Array<LibraryTemplate>>;
  pageInfo: PageInfo;
};

export type LibraryTemplateToRole = {
  createdAt: Scalars['Timestamp']['output'];
  cuid?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  roleId: Scalars['Int']['output'];
  templateId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type LibraryTrendingTopicItem = {
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  cuid: Scalars['String']['output'];
  date: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  /** e.g [effectiveDate] */
  extra?: Maybe<Scalars['JSON']['output']>;
  /** 'Ai Act' */
  name: Scalars['String']['output'];
  /** Policy,Risk... */
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  urlId?: Maybe<Scalars['String']['output']>;
};

export type LibraryTrendingTopicItemEdge = {
  cursor: Scalars['String']['output'];
  node: LibraryTrendingTopicItem;
};

export type LibraryTrendingTopicPaginated = {
  edges?: Maybe<Array<LibraryTrendingTopicItemEdge>>;
  nodes?: Maybe<Array<LibraryTrendingTopicItem>>;
  pageInfo: PageInfo;
};

export type LibraryType = {
  evaluations: LibraryEvaluationsPaginated;
  filterables: Array<FilterableDefinitionType>;
  models: LibraryModelPaginated;
  policies: LibraryPolicyPaginated;
  risks: LibraryRiskPaginated;
  tasks: Array<LibraryTaskType>;
};


export type LibraryTypeEvaluationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryTypeFilterablesArgs = {
  for: FilterableTargetType;
  from: Array<Scalars['String']['input']>;
};


export type LibraryTypeModelsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryTypePoliciesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryTypeRisksArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
};


export type LibraryTypeTasksArgs = {
  taskType?: InputMaybe<EvaluationType>;
};

export enum Licenses {
  Enterprise = 'Enterprise',
  Free = 'Free'
}

export type Lifecycle = {
  Approved_for_deployment?: Maybe<ApprovalStatus>;
  Approved_for_design_and_development?: Maybe<ApprovalStatus>;
  Approved_for_retirement?: Maybe<ApprovalStatus>;
  Deployment?: Maybe<ApprovalStatus>;
  Design_and_development?: Maybe<ApprovalStatus>;
  Initiation?: Maybe<ApprovalStatus>;
  Operation_and_monitoring?: Maybe<ApprovalStatus>;
  Re_evaluation?: Maybe<ApprovalStatus>;
  Retirement?: Maybe<ApprovalStatus>;
  Verification_and_validation?: Maybe<ApprovalStatus>;
  currentLifecycleStage: GovernLifecycleStage;
  version: Scalars['Int']['output'];
};

export type LifecycleInputType = {
  Approved_for_deployment?: InputMaybe<ApprovalStatusInput>;
  Approved_for_design_and_development?: InputMaybe<ApprovalStatusInput>;
  Approved_for_retirement?: InputMaybe<ApprovalStatusInput>;
  Deployment?: InputMaybe<ApprovalStatusInput>;
  Design_and_development?: InputMaybe<ApprovalStatusInput>;
  Initiation?: InputMaybe<ApprovalStatusInput>;
  Operation_and_monitoring?: InputMaybe<ApprovalStatusInput>;
  Re_evaluation?: InputMaybe<ApprovalStatusInput>;
  Retirement?: InputMaybe<ApprovalStatusInput>;
  Verification_and_validation?: InputMaybe<ApprovalStatusInput>;
  currentLifecycleStage: GovernLifecycleStage;
};

export type MeInfo = {
  activeSpace: GovernSpace;
  capabilities: Array<Capabilities>;
  isEditor?: Maybe<Scalars['Boolean']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  isOrganizationAdmin?: Maybe<Scalars['Boolean']['output']>;
  isSaidotAdmin?: Maybe<Scalars['Boolean']['output']>;
  organization: AppOrganization;
  organizationCapabilities: Array<Capabilities>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  role: Roles;
  spaces: Array<GovernSpace>;
  user: AppUser;
};

export type MemberSlim = {
  email: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  id: Scalars['String']['output'];
  profileImageURL: Scalars['String']['output'];
  txtIdentifier: Scalars['String']['output'];
};

export type MembersPaginated = {
  edges?: Maybe<Array<AppUserEdge>>;
  nodes?: Maybe<Array<AppUser>>;
  pageInfo: PageInfo;
};

export type MessageResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ModelVersionType = {
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  descriotion?: Maybe<Scalars['String']['output']>;
  endpoints?: Maybe<Scalars['String']['output']>;
  environmentalImpact?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  latestStableVersion?: Maybe<Scalars['Timestamp']['output']>;
  latestVersion?: Maybe<Scalars['Timestamp']['output']>;
  maxImageSize?: Maybe<Scalars['Int']['output']>;
  maxInputTokens?: Maybe<Scalars['Int']['output']>;
  modality?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  versionId: Scalars['ID']['output'];
};

export type Mutation = {
  addLibraryRisksToSystem: ResponseType;
  addNewEditorUser: DataResponseType;
  addNewEvidenceBlock: DataResponseType;
  addNewUser: DataResponseType;
  addNewUsers: ResponseType;
  addOrganisation: MessageResponseType;
  addSpaceMembers: ResponseType;
  addSystemDataset: SystemDatasetResponseType;
  addSystemSupplier: SystemSupplierResponseType;
  addSystemTeamMember: AddTeamResponseType;
  addTemplateToPolicy: DataResponseType;
  assignDefaultSpaceToDatasets: ResponseType;
  assignDefaultSpaceToSystems: ResponseType;
  assignManagers: ResponseType;
  changeActiveSpace: GovernSpaceResponseType;
  changeMemberAccess: MessageResponseType;
  changeOrganisationAccess: MessageResponseType;
  changeOrganisationLicense: MessageResponseType;
  changeOrganisationStatus: MessageResponseType;
  changeSpaceMemberRole: ResponseType;
  changeUserRole: ResponseType;
  completeControl: ResponseType;
  createAttachment: DataResponseType;
  createDataset: ResponseType;
  createDefaultGovernSpaces: ResponseType;
  createGovernSystem: CreateGovernSystemResponseType;
  createOrganization: AppOrganization;
  createSpace: DataResponseType;
  createSystemControlsForTemplate: ResponseType;
  createSystemPolicies: ResponseType;
  deleteAttachment: DataResponseType;
  deleteAttachmentByKey: MessageResponseType;
  deleteDataset: DataResponseType;
  deleteEvidenceBlock: DataResponseType;
  deleteGovernSystem: DataResponseType;
  deleteManyRisks: SuccessResponseType;
  deleteMember: ResponseType;
  deleteMembers: ResponseType;
  deleteOrganisation: MessageResponseType;
  deletePendingMember: ResponseType;
  deletePendingMembers: ResponseType;
  deleteRisk: SuccessResponseType;
  deleteSpace: ResponseType;
  deleteSystemPolicy: ResponseType;
  deleteTemplateFromPolicy: ResponseType;
  featureFlagOverride: SuccessResponseType;
  featureFlagsRefresh: DataResponseType;
  followLibraryItem: IdResponseType;
  getGPTReferences: Scalars['String']['output'];
  inviteMembers: ResponseType;
  linkModel: DataResponseType;
  linkSystem: DataResponseType;
  login: MeInfo;
  markFeedAsSeen: ResponseType;
  markNotApplicable: ResponseType;
  migrateCapabilities: ResponseType;
  migrateRolesTypes: ResponseType;
  modifyRisk: SuccessResponseType;
  moveDatasetsToSpace: DataResponseType;
  moveSystemsToSpace: DataResponseType;
  organizationStatus: ResponseType;
  queueAirtableOptions: DataResponseType;
  queueLibrarySync: DataResponseType;
  removeMarkNotApplicable: ResponseType;
  removeMyOrganisationLogo: MessageResponseType;
  removeOrganisationLogo: MessageResponseType;
  removeProfilePicture: SuccessResponseType;
  removeSpaceMember: ResponseType;
  removeSystemDataset: SystemDatasetResponseType;
  removeSystemSupplier: SystemSupplierResponseType;
  removeSystemSupplierAttachment: SystemSupplierResponseType;
  removeSystemTeamMember: DataResponseType;
  renameDefaultSpaceToGeneral: ResponseType;
  requestCancellationOfMyOrganisationSubscription: MessageResponseType;
  requestUpgrade: ResponseType;
  resendInvitation: ResponseType;
  resetPassword: ResponseType;
  suggestPolicy: ResponseType;
  suggestRisk: ResponseType;
  suggestTemplate: ResponseType;
  toggleBookmark: BookmarkToggleResponse;
  toggleUserActivity: SuccessResponseType;
  uncompleteControl: ResponseType;
  unfollowLibraryItem: ResponseType;
  unlinkModel: DataResponseType;
  unlinkSystem: DataResponseType;
  updateAttachment: DataResponseType;
  updateDataset: GovernDataset;
  updateDatasetName: DataResponseType;
  updateGovernSystem: DataResponseType;
  updateLifecycleStage: DataResponseType;
  updateMyOrganisationDefaults: MessageResponseType;
  updateMyOrganisationDetails: MessageResponseType;
  updateMyOrganisationSecurity: MessageResponseType;
  updateOrganisationConfiguration: MessageResponseType;
  updateOrganisationDetails: MessageResponseType;
  updateOrganization: AppOrganization;
  updateSpace: ResponseType;
  updateSystemDataset: SystemDatasetResponseType;
  updateSystemSupplier: SystemSupplierResponseType;
  updateSystemTeamMemberRole: DataResponseType;
  /** Update user profile. Only admins can update other users if provided userId. */
  updateUser: AppUser;
  viewAsUser: MeInfo;
};


export type MutationAddLibraryRisksToSystemArgs = {
  libraryRisks: Array<Scalars['Int']['input']>;
  systemId: Scalars['String']['input'];
};


export type MutationAddNewEditorUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  systemPolicyId: Scalars['Int']['input'];
  values: CreateUserArgs;
};


export type MutationAddNewEvidenceBlockArgs = {
  controlId: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
};


export type MutationAddNewUserArgs = {
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
  tempPassword?: InputMaybe<Scalars['String']['input']>;
  values: CreateUserArgs;
};


export type MutationAddNewUsersArgs = {
  input: CreateUsersInput;
};


export type MutationAddOrganisationArgs = {
  input: AddOrganisationInput;
};


export type MutationAddSpaceMembersArgs = {
  inviteEmailsInput?: InputMaybe<InviteMemberInput>;
  memberIds: Array<Scalars['String']['input']>;
  spaceId: Scalars['String']['input'];
};


export type MutationAddSystemDatasetArgs = {
  addSystemDatasetInput: AddSystemDatasetInput;
};


export type MutationAddSystemSupplierArgs = {
  addSystemSupplierInput: AddSystemSupplierInput;
};


export type MutationAddSystemTeamMemberArgs = {
  addTeamMemberInput: AddTeamMemberInput;
};


export type MutationAddTemplateToPolicyArgs = {
  policyId: Scalars['String']['input'];
  sourceTemplateId: Scalars['Int']['input'];
};


export type MutationAssignManagersArgs = {
  managerIds: Array<Scalars['String']['input']>;
  spaceId: Scalars['String']['input'];
};


export type MutationChangeActiveSpaceArgs = {
  spaceId: Scalars['ID']['input'];
};


export type MutationChangeMemberAccessArgs = {
  access?: InputMaybe<Capabilities>;
  memberId: Scalars['ID']['input'];
};


export type MutationChangeOrganisationAccessArgs = {
  access: Capabilities;
  organisationId: Scalars['String']['input'];
};


export type MutationChangeOrganisationLicenseArgs = {
  license: Licenses;
  organisationId: Scalars['String']['input'];
};


export type MutationChangeOrganisationStatusArgs = {
  organisationId: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationChangeSpaceMemberRoleArgs = {
  memberId: Scalars['String']['input'];
  role: GovernSpaceRole;
  spaceId: Scalars['String']['input'];
};


export type MutationChangeUserRoleArgs = {
  id: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};


export type MutationCompleteControlArgs = {
  controlId: Scalars['String']['input'];
};


export type MutationCreateAttachmentArgs = {
  createAttachmentInput: CreateGovernAttachmentInput;
};


export type MutationCreateDatasetArgs = {
  createDatasetInput: CreateDatasetInput;
};


export type MutationCreateGovernSystemArgs = {
  createGovernSystemInput: CreateGovernSystemInput;
};


export type MutationCreateOrganizationArgs = {
  values: CreateOrganizationDto;
};


export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput;
};


export type MutationCreateSystemControlsForTemplateArgs = {
  templateId: Scalars['String']['input'];
};


export type MutationCreateSystemPoliciesArgs = {
  policies: Array<Scalars['Int']['input']>;
  systemId: Scalars['String']['input'];
};


export type MutationDeleteAttachmentArgs = {
  externalId: Scalars['String']['input'];
};


export type MutationDeleteAttachmentByKeyArgs = {
  key: Scalars['String']['input'];
};


export type MutationDeleteDatasetArgs = {
  externalId: Scalars['String']['input'];
};


export type MutationDeleteEvidenceBlockArgs = {
  blockId: Scalars['String']['input'];
  controlId: Scalars['String']['input'];
};


export type MutationDeleteGovernSystemArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteManyRisksArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteMemberArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMembersArgs = {
  externalIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteOrganisationArgs = {
  organisationId: Scalars['String']['input'];
};


export type MutationDeletePendingMemberArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePendingMembersArgs = {
  externalIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteRiskArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSpaceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSystemPolicyArgs = {
  policyId: Scalars['String']['input'];
};


export type MutationDeleteTemplateFromPolicyArgs = {
  templateId: Scalars['String']['input'];
};


export type MutationFeatureFlagOverrideArgs = {
  name: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationFollowLibraryItemArgs = {
  followableId: Scalars['Int']['input'];
  followableType: LibraryCardType;
};


export type MutationGetGptReferencesArgs = {
  description: Scalars['String']['input'];
};


export type MutationInviteMembersArgs = {
  input: InviteMemberInput;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationLinkModelArgs = {
  modelId: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
};


export type MutationLinkSystemArgs = {
  sourceSystemId: Scalars['String']['input'];
  targetSystemId: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMarkNotApplicableArgs = {
  controlId: Scalars['String']['input'];
};


export type MutationModifyRiskArgs = {
  addSystemRiskInput: AddSystemRiskInput;
};


export type MutationMoveDatasetsToSpaceArgs = {
  datasetIds: Array<Scalars['String']['input']>;
  targetSpaceId: Scalars['String']['input'];
};


export type MutationMoveSystemsToSpaceArgs = {
  systemIds: Array<Scalars['String']['input']>;
  targetSpaceId: Scalars['String']['input'];
};


export type MutationOrganizationStatusArgs = {
  organizationId: Scalars['Int']['input'];
  status: OrganizationStatus;
};


export type MutationRemoveMarkNotApplicableArgs = {
  controlId: Scalars['String']['input'];
};


export type MutationRemoveOrganisationLogoArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveSpaceMemberArgs = {
  memberId: Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
};


export type MutationRemoveSystemDatasetArgs = {
  systemDatasetId: Scalars['String']['input'];
};


export type MutationRemoveSystemSupplierArgs = {
  supplierId: Scalars['String']['input'];
};


export type MutationRemoveSystemSupplierAttachmentArgs = {
  attachmentId: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};


export type MutationRemoveSystemTeamMemberArgs = {
  email: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
};


export type MutationRequestCancellationOfMyOrganisationSubscriptionArgs = {
  input: RequestCancellationInput;
};


export type MutationRequestUpgradeArgs = {
  details?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResendInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationSuggestPolicyArgs = {
  kind?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  purpose?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSuggestRiskArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationSuggestTemplateArgs = {
  kind: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationToggleBookmarkArgs = {
  id: Scalars['String']['input'];
  type: BookmarkType;
};


export type MutationToggleUserActivityArgs = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUncompleteControlArgs = {
  controlId: Scalars['String']['input'];
};


export type MutationUnfollowLibraryItemArgs = {
  followableId: Scalars['Int']['input'];
  followableType: LibraryCardType;
};


export type MutationUnlinkModelArgs = {
  modelId: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
};


export type MutationUnlinkSystemArgs = {
  sourceSystemId: Scalars['String']['input'];
  targetSystemId: Scalars['String']['input'];
};


export type MutationUpdateAttachmentArgs = {
  externalId: Scalars['String']['input'];
  updateAttachmentInput: UpdateGovernAttachmentInput;
};


export type MutationUpdateDatasetArgs = {
  updateDatasetInput: UpdateDatasetInput;
};


export type MutationUpdateDatasetNameArgs = {
  externalId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateGovernSystemArgs = {
  id: Scalars['String']['input'];
  input: Scalars['JSONObject']['input'];
  version: Scalars['Int']['input'];
};


export type MutationUpdateLifecycleStageArgs = {
  governSystemId: Scalars['String']['input'];
  input: LifecycleInputType;
  version: Scalars['Int']['input'];
};


export type MutationUpdateMyOrganisationDefaultsArgs = {
  input: UpdateMyOrganisationDefaultsInput;
};


export type MutationUpdateMyOrganisationDetailsArgs = {
  input: UpdateMyOrganisationDetailsInput;
};


export type MutationUpdateMyOrganisationSecurityArgs = {
  input: UpdateMyOrganisationSecurityInput;
};


export type MutationUpdateOrganisationConfigurationArgs = {
  input: UpdateOrganisationConfigurationInput;
};


export type MutationUpdateOrganisationDetailsArgs = {
  input: UpdateOrganisationDetailsInput;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['Int']['input'];
  values: CreateOrganizationDto;
};


export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput;
  spaceId: Scalars['String']['input'];
};


export type MutationUpdateSystemDatasetArgs = {
  updateSystemDatasetInput: UpdateSystemDatasetInput;
};


export type MutationUpdateSystemSupplierArgs = {
  updateSystemSupplierInput: UpdateSystemSupplierInput;
};


export type MutationUpdateSystemTeamMemberRoleArgs = {
  updateTeamMemberRole: AddTeamMemberInput;
};


export type MutationUpdateUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
  values: UpdateUserArgs;
};


export type MutationViewAsUserArgs = {
  targetId: Scalars['ID']['input'];
};

export type OptionsResponseType = {
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrganisationPaginatedResult = {
  data: PaginatedType;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OrganizationLogoType = {
  file_description?: Maybe<Scalars['String']['output']>;
  file_name: Scalars['String']['output'];
  file_url: Scalars['String']['output'];
};

export enum OrganizationStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  recommendedCount?: Maybe<Scalars['Int']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type PaginatedType = {
  edges?: Maybe<Array<AppOrganizationResultEdge>>;
  nodes?: Maybe<Array<AppOrganization>>;
  pageInfo: PageInfo;
};

export type PolicyRegulatedSystemType = {
  createdAt: Scalars['Timestamp']['output'];
  deletedAt: Scalars['Timestamp']['output'];
  description: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  policyReference?: Maybe<Scalars['String']['output']>;
  regulatedSystemId: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
};

export type Query = {
  apiKeyLookup: AppKey;
  attachments: GovernAttachmentPaginated;
  availableAccess: Array<OptionsResponseType>;
  availableLicenses: Array<OptionsResponseType>;
  capabilities: Array<AppCapability>;
  countries: Array<CountryDataType>;
  dataset?: Maybe<GovernDataset>;
  datasets: GovernDatasetsPaginated;
  evaluation?: Maybe<LibraryEvaluation>;
  featureFlag: FeatureResponseType;
  featureFlagEnabled: Scalars['Boolean']['output'];
  featureFlags: Scalars['JSONObject']['output'];
  feed: Array<LibraryFeedItem>;
  geography: GeographyType;
  getGovernSpace?: Maybe<GovernSpace>;
  getGovernSpaceMembers: GovernSpacesMembersPaginated;
  getOrganizationAttachment: AttachmentResponseType;
  getPlatformUsers: MembersPaginated;
  getUploadURL: Scalars['JSONObject']['output'];
  governLatestChanges?: Maybe<LatestChangesPaginated>;
  governLifecycle?: Maybe<Lifecycle>;
  governSystem?: Maybe<GovernSystem>;
  governSystemRisks: SystemRisksPaginated;
  governSystemTemplate: GovernSystemTemplate;
  governSystems: GovernSystemPaginated;
  library: LibraryType;
  libraryMitigations?: Maybe<Array<LibraryMitigation>>;
  lifecycleStages?: Maybe<Array<GovernLifecycleStage>>;
  listUsers: Array<AppUser>;
  model?: Maybe<LibraryModel>;
  myOrganisationDetails: AppOrganization;
  myOrganization: AppOrganization;
  option?: Maybe<InputOptionResponseType>;
  options: Array<InputOptionResponseType>;
  organisationDetails: AppOrganization;
  organisations: OrganisationPaginatedResult;
  organization: AppOrganization;
  organizations: Array<AppOrganization>;
  policy?: Maybe<LibraryPolicy>;
  risk?: Maybe<LibraryRisk>;
  riskTypes: Scalars['JSON']['output'];
  risks: LibraryRiskPaginated;
  spaces: GovernSpacesPaginated;
  /** Spaces that belongs to the organization of the user. */
  spacesAvailable: Array<GovernSpace>;
  systemControls: GovernControlsResponseType;
  systemDataset: GovernSystemDataset;
  systemDatasets: Array<GovernSystemDataset>;
  systemPolicies: GovernSystemPolicyWithTotal;
  systemSupplier: GovernSystemSupplier;
  systemSuppliers: Array<GovernSystemSupplier>;
  systemUsers: Array<AppUser>;
  team: Array<GovernSystemTeam>;
  template: LibraryTemplate;
  templates: LibraryTemplatePaginated;
  translations: Scalars['JSON']['output'];
  trendingTopics: LibraryTrendingTopicPaginated;
  user: AppUser;
  userGroups: Array<AppUserGroup>;
};


export type QueryAttachmentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  target: AttachmentTarget;
  targetId: Scalars['String']['input'];
};


export type QueryDatasetArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryDatasetsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEvaluationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeatureFlagArgs = {
  name: Scalars['String']['input'];
};


export type QueryFeatureFlagEnabledArgs = {
  name: Scalars['String']['input'];
};


export type QueryFeedArgs = {
  filter?: InputMaybe<Array<LibraryCardType>>;
};


export type QueryGetGovernSpaceArgs = {
  organisationId?: InputMaybe<Scalars['ID']['input']>;
  spaceId: Scalars['String']['input'];
};


export type QueryGetGovernSpaceMembersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organisationId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  spaceId: Scalars['String']['input'];
};


export type QueryGetOrganizationAttachmentArgs = {
  name: Scalars['String']['input'];
  organization_id: Array<Scalars['Int']['input']>;
};


export type QueryGetPlatformUsersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUploadUrlArgs = {
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};


export type QueryGovernLatestChangesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  systemId: Scalars['String']['input'];
};


export type QueryGovernLifecycleArgs = {
  systemId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGovernSystemArgs = {
  id: Scalars['String']['input'];
};


export type QueryGovernSystemRisksArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId: Scalars['String']['input'];
};


export type QueryGovernSystemTemplateArgs = {
  templateId: Scalars['String']['input'];
};


export type QueryGovernSystemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLibraryMitigationsArgs = {
  riskId: Scalars['String']['input'];
};


export type QueryModelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptionArgs = {
  name?: InputMaybe<AppInputOptionsName>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOptionsArgs = {
  names?: InputMaybe<Array<AppInputOptionsName>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryOrganisationDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganisationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPolicyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRiskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRisksArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matches?: InputMaybe<Scalars['JSON']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpacesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySystemControlsArgs = {
  templateId: Scalars['String']['input'];
};


export type QuerySystemDatasetArgs = {
  externalId: Scalars['String']['input'];
};


export type QuerySystemDatasetsArgs = {
  systemId: Scalars['String']['input'];
};


export type QuerySystemPoliciesArgs = {
  filters?: InputMaybe<Array<SystemPoliciesFilter>>;
  includeTemplates?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<SortItemsBy>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId: Scalars['String']['input'];
};


export type QuerySystemSupplierArgs = {
  externalId: Scalars['String']['input'];
};


export type QuerySystemSuppliersArgs = {
  systemId: Scalars['String']['input'];
};


export type QuerySystemUsersArgs = {
  systemId: Scalars['ID']['input'];
};


export type QueryTeamArgs = {
  systemId: Scalars['String']['input'];
};


export type QueryTemplateArgs = {
  templateId: Scalars['Int']['input'];
};


export type QueryTemplatesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  policyId?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTranslationsArgs = {
  translationKeys: Array<Scalars['String']['input']>;
};


export type QueryTrendingTopicsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Region = {
  countries?: Maybe<Array<Country>>;
  createdAt: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  regionCode: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

/** Additional information */
export type RequestCancellationInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  /** RequestCancellationReason: Selected cancellation reason */
  cancellationReason: RequestCancellationReason;
  contactEmail: Scalars['String']['input'];
};

export enum RequestCancellationReason {
  Dissatisfied = 'dissatisfied',
  NoLongerNeeded = 'noLongerNeeded',
  Other = 'other',
  SwitchingService = 'switchingService',
  TooExpensive = 'tooExpensive'
}

export type ResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RestrictionType = {
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  restrictionId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export enum RiskTreatment {
  Accept = 'ACCEPT',
  Avoid = 'AVOID',
  Mitigate = 'MITIGATE'
}

export enum Roles {
  Admin = 'Admin',
  Editor = 'Editor',
  IdentityAuthor = 'IdentityAuthor',
  Member = 'Member',
  SaidotAdmin = 'SaidotAdmin',
  Viewer = 'Viewer'
}

export type SafetyFeatureType = {
  airtableUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  featureDetails?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  safetyFeatureId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type ShortenedDatasetParameter = {
  autoid: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  shortenedVersion: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export type SimpleUsersInput = {
  email: Scalars['String']['input'];
  role: Roles;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortItemsBy {
  AddedBy = 'ADDED_BY',
  Alphabetical = 'ALPHABETICAL',
  BookmarkedFirst = 'BOOKMARKED_FIRST',
  DocumentType = 'DOCUMENT_TYPE',
  FileName = 'FILE_NAME',
  FileType = 'FILE_TYPE',
  FollowedFirst = 'FOLLOWED_FIRST',
  LeastRecent = 'LEAST_RECENT',
  MostRecent = 'MOST_RECENT',
  NumberOfEvaluations = 'NUMBER_OF_EVALUATIONS',
  NumberOfModels = 'NUMBER_OF_MODELS'
}

export type SuccessResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SystemDatasetResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<GovernSystemDataset>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum SystemPoliciesFilter {
  TemplatesCompleted = 'TEMPLATES_COMPLETED',
  TemplatesInProgress = 'TEMPLATES_IN_PROGRESS',
  WithoutTemplates = 'WITHOUT_TEMPLATES',
  WithTemplates = 'WITH_TEMPLATES'
}

export type SystemRisksPaginated = {
  edges?: Maybe<Array<GovernSystemRiskEdge>>;
  nodes?: Maybe<Array<GovernSystemRisk>>;
  pageInfo: PageInfo;
};

export type SystemSupplierResponseType = {
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<GovernSystemSupplier>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SystemTeamMembers = {
  members?: Maybe<Array<MemberSlim>>;
};

export type TeamMemberData = {
  role: TeamRole;
  systemId: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export enum TeamRole {
  BusinessOwner = 'BusinessOwner',
  ComplianceSpecialist = 'ComplianceSpecialist',
  DataScientist = 'DataScientist',
  DataSteward = 'DataSteward',
  Other = 'Other',
  OversightManager = 'OversightManager',
  Reviewer = 'Reviewer',
  SystemOwner = 'SystemOwner',
  TechnicalSpecialist = 'TechnicalSpecialist',
  User = 'User'
}

export enum TokenType {
  Default = 'DEFAULT',
  Invitation = 'INVITATION',
  PasswordReset = 'PASSWORD_RESET',
  Registration = 'REGISTRATION',
  SystemInvitation = 'SYSTEM_INVITATION',
  TransparencyRequest = 'TRANSPARENCY_REQUEST'
}

export type UpdateDatasetInput = {
  cardOwnerId: Scalars['String']['input'];
  dataCollectionMethods?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  license?: InputMaybe<Scalars['String']['input']>;
  linkToDataset?: InputMaybe<Scalars['String']['input']>;
  modality: GovernDatasetModality;
  name: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  personalData?: InputMaybe<Array<Scalars['String']['input']>>;
  preProcessingMethods?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  specialCategoriesPD?: InputMaybe<Array<Scalars['String']['input']>>;
  subject?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGovernAttachmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

/** The input for updating organisation defaults */
export type UpdateMyOrganisationDefaultsInput = {
  businessModels?: InputMaybe<Array<Scalars['String']['input']>>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The input for updating my organisation details */
export type UpdateMyOrganisationDetailsInput = {
  /** OrganisationAddressInput: The address of the organisation */
  address?: InputMaybe<UpdateMyOrganisationDetailsInput_Address>;
  logo?: InputMaybe<UpdateMyOrganisationDetailsInput_Logo>;
  organisationName: Scalars['String']['input'];
};

/** The input for updating my organisation details */
export type UpdateMyOrganisationDetailsInput_Address = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

/** The input for updating my organisation details */
export type UpdateMyOrganisationDetailsInput_Logo = {
  file_description?: InputMaybe<Scalars['String']['input']>;
  file_name: Scalars['String']['input'];
  file_url: Scalars['String']['input'];
};

/** The input for updating organisation security */
export type UpdateMyOrganisationSecurityInput = {
  memberInvite: Scalars['Boolean']['input'];
  spaceManagerInvite: Scalars['Boolean']['input'];
};

/** The input for updating organisation configuration */
export type UpdateOrganisationConfigurationInput = {
  /** Capabilities: The access level of the organisation */
  access: Capabilities;
  consentToUserSwitching?: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  /** Licenses: The license type of the organisation */
  license: Licenses;
  publicRegistryURL?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['Boolean']['input'];
  systemLimit?: Scalars['Int']['input'];
};

/** The input for updating organisation details */
export type UpdateOrganisationDetailsInput = {
  /** OrganisationAddressInput: The address of the organisation */
  address?: InputMaybe<UpdateMyOrganisationDetailsInput_Address>;
  id: Scalars['String']['input'];
  logo?: InputMaybe<UpdateMyOrganisationDetailsInput_Logo>;
  organisationName: Scalars['String']['input'];
};

/** The input for updating a space */
export type UpdateSpaceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  functions?: InputMaybe<Array<Scalars['String']['input']>>;
  icon?: InputMaybe<Scalars['String']['input']>;
  managers?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateSystemDatasetInput = {
  datasetId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  purpose: Array<GovernDatasetPurpose>;
};

export type UpdateSystemSupplierInput = {
  documentIds: Array<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type UpdateUserArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  passwordInput?: InputMaybe<Scalars['String']['input']>;
  profileImageURL?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserEmailType = {
  email: Scalars['String']['output'];
};

export enum NotificationSourceEventType {
  Creation = 'CREATION',
  Update = 'UPDATE'
}

export type GetUploadUrlQueryVariables = Exact<{
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
}>;


export type GetUploadUrlQuery = Pick<Query, 'getUploadURL'>;

export type ModifyRiskMutationVariables = Exact<{
  addSystemRiskInput: AddSystemRiskInput;
}>;


export type ModifyRiskMutation = { modifyRisk: Pick<SuccessResponseType, 'success' | 'error'> };

export type RisksTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type RisksTypesQuery = Pick<Query, 'riskTypes'>;

export type SystemUsersQueryVariables = Exact<{
  systemId: Scalars['ID']['input'];
}>;


export type SystemUsersQuery = { systemUsers: Array<(
    Pick<AppUser, 'firstName' | 'lastName' | 'email' | 'externalId' | 'fullName' | 'txtIdentifier'>
    & { id: AppUser['externalId'] }
  )> };

export type GetSystemSuppliersQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type GetSystemSuppliersQuery = { systemSuppliers: Array<(
    Pick<GovernSystemSupplier, 'externalId' | 'governSystemId' | 'provider'>
    & { type: Pick<AppInputOption, 'externalId' | 'label'>, documents?: Maybe<Array<{ attachment: (
        Pick<GovernAttachment, 'externalId' | 'name' | 'format' | 'url' | 'location' | 'createdAt'>
        & { owner: Pick<AppUser, 'firstName' | 'lastName'>, type: Pick<AppInputOption, 'externalId' | 'label'> }
      ) }>> }
  )> };

export type GetSystemSupplierQueryVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type GetSystemSupplierQuery = { systemSupplier: (
    Pick<GovernSystemSupplier, 'externalId' | 'provider' | 'governSystemId'>
    & { type: Pick<AppInputOption, 'externalId' | 'label'>, documents?: Maybe<Array<{ attachment: (
        Pick<GovernAttachment, 'externalId' | 'name' | 'format'>
        & { type: Pick<AppInputOption, 'externalId' | 'label'> }
      ) }>> }
  ) };

export type AddGovernSystemSupplierMutationVariables = Exact<{
  addSystemSupplierInput: AddSystemSupplierInput;
}>;


export type AddGovernSystemSupplierMutation = { addSystemSupplier: (
    Pick<SystemSupplierResponseType, 'success' | 'error'>
    & { data?: Maybe<Pick<GovernSystemSupplier, 'externalId'>> }
  ) };

export type UpdateGovernSystemSupplierMutationVariables = Exact<{
  updateSystemSupplierInput: UpdateSystemSupplierInput;
}>;


export type UpdateGovernSystemSupplierMutation = { updateSystemSupplier: (
    Pick<SystemSupplierResponseType, 'success' | 'error'>
    & { data?: Maybe<Pick<GovernSystemSupplier, 'externalId'>> }
  ) };

export type DeleteGovernSystemSupplierMutationVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type DeleteGovernSystemSupplierMutation = { removeSystemSupplier: (
    Pick<SystemSupplierResponseType, 'success' | 'error'>
    & { data?: Maybe<Pick<GovernSystemSupplier, 'externalId'>> }
  ) };

export type RemoveSystemSupplierAttachmentMutationVariables = Exact<{
  supplierId: Scalars['String']['input'];
  attachmentId: Scalars['String']['input'];
}>;


export type RemoveSystemSupplierAttachmentMutation = { removeSystemSupplierAttachment: Pick<SystemSupplierResponseType, 'success' | 'error'> };

export type LinkModelToSystemMutationVariables = Exact<{
  systemId: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
}>;


export type LinkModelToSystemMutation = { linkModel: Pick<DataResponseType, 'success' | 'error'> };

export type UnlinkModelToSystemMutationVariables = Exact<{
  systemId: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
}>;


export type UnlinkModelToSystemMutation = { unlinkModel: Pick<DataResponseType, 'success' | 'error'> };

export type LinkSystemToSystemMutationVariables = Exact<{
  sourceSystemId: Scalars['String']['input'];
  targetSystemId: Scalars['String']['input'];
}>;


export type LinkSystemToSystemMutation = { linkSystem: Pick<DataResponseType, 'success' | 'error'> };

export type UnlinkSystemToSystemMutationVariables = Exact<{
  sourceSystemId: Scalars['String']['input'];
  targetSystemId: Scalars['String']['input'];
}>;


export type UnlinkSystemToSystemMutation = { unlinkSystem: Pick<DataResponseType, 'success' | 'error'> };

export type LatestChangesWidgetQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type LatestChangesWidgetQuery = { governLatestChanges?: Maybe<{ edges?: Maybe<Array<{ node: Pick<LatestChangeType, 'externalId' | 'change' | 'message' | 'event' | 'fullname' | 'time'> }>> }> };

export type LifecycleWidgetQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type LifecycleWidgetQuery = (
  Pick<Query, 'lifecycleStages'>
  & { governLifecycle?: Maybe<(
    Pick<Lifecycle, 'version' | 'currentLifecycleStage'>
    & { Initiation?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Approved_for_design_and_development?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Design_and_development?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Verification_and_validation?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Approved_for_deployment?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Deployment?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Operation_and_monitoring?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Re_evaluation?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Approved_for_retirement?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>>, Retirement?: Maybe<Pick<ApprovalStatus, 'date' | 'isApproved'>> }
  )> }
);

export type UpdateLifecycleStageMutationVariables = Exact<{
  governSystemId: Scalars['String']['input'];
  version: Scalars['Int']['input'];
  input: LifecycleInputType;
}>;


export type UpdateLifecycleStageMutation = { updateLifecycleStage: Pick<DataResponseType, 'data' | 'error' | 'success'> };

export type RiskLibraryWidgetQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filters?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matches?: InputMaybe<Scalars['JSON']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RiskLibraryWidgetQuery = { risks: { pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage' | 'recommendedCount'>, nodes?: Maybe<Array<(
      Pick<LibraryRisk, 'riskId' | 'externalId' | 'name' | 'description' | 'industry' | 'category' | 'riskFunction' | 'type'>
      & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'cuid'>>>, saidotRisks?: Maybe<Array<{ model?: Maybe<Pick<LibraryModel, 'name'>> }>>, providerRisk?: Maybe<Array<{ model?: Maybe<Pick<LibraryModel, 'name'>> }>> }
    )>> } };

export type AddSystemTeamMemberMutationVariables = Exact<{
  addTeamMemberInput: AddTeamMemberInput;
}>;


export type AddSystemTeamMemberMutation = { addSystemTeamMember: (
    Pick<AddTeamResponseType, 'success' | 'error'>
    & { data?: Maybe<Pick<TeamMemberData, 'systemId'>> }
  ) };

export type RemoveTeamMemberMutationVariables = Exact<{
  systemId: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type RemoveTeamMemberMutation = { removeSystemTeamMember: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type UpdateTeamMemberMutationVariables = Exact<{
  updateSystemTeamMemberRoleInput: AddTeamMemberInput;
}>;


export type UpdateTeamMemberMutation = { updateSystemTeamMemberRole: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type SystemTeamQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type SystemTeamQuery = { team: Array<(
    Pick<GovernSystemTeam, 'role'>
    & { member: Pick<AppUser, 'email' | 'firstName' | 'lastName'> }
  )> };

export type LegacyLibraryModelEvaluationsSectionQueryVariables = Exact<{
  modelId: Scalars['ID']['input'];
  evaluationType: EvaluationType;
  orderBy?: InputMaybe<Array<EvaluationResultsByTaskOrderByType> | EvaluationResultsByTaskOrderByType>;
}>;


export type LegacyLibraryModelEvaluationsSectionQuery = { model?: Maybe<{ evaluationResultsByTaskFor?: Maybe<(
      Pick<EvaluationResultsByTaskType, 'versionNames'>
      & { tasks?: Maybe<Array<(
        Pick<EvaluationResultsTaskType, 'externalId' | 'name' | 'taskParameters'>
        & { evaluationResults?: Maybe<Array<(
          Pick<EvaluationResultInTask, 'scoreTypes'>
          & { evaluation: Pick<LibraryEvaluation, 'id' | 'externalId' | 'autoid' | 'name' | 'type' | 'isFollowed'> }
        )>> }
      )>> }
    )> }> };

export type LibraryModelEvaluationsSectionQueryVariables = Exact<{
  modelId: Scalars['ID']['input'];
  translationKeys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type LibraryModelEvaluationsSectionQuery = (
  Pick<Query, 'translations'>
  & { model?: Maybe<{ aggregatedByTaskEvaluationResults: { shortenedDatasetParameters: Array<Pick<ShortenedDatasetParameter, 'name' | 'shortenedVersion'>>, metricExplanations: Array<Pick<InputOptionType, 'label' | 'explainer'>>, resultsAggregatedByTask: Array<{ task: Pick<LibraryTaskType, 'name' | 'taskTypes' | 'taskParameterLabel'>, evaluationResults: Array<{ evaluation: Pick<LibraryEvaluation, 'id' | 'autoid' | 'name' | 'isFollowed' | 'datasetParameterLabel'>, scores: Array<(
            Pick<ErgProvidersWithModelType, 'score' | 'scoreUnit' | 'scoreType' | 'taskParameters' | 'datasetParameter'>
            & { model: Pick<LibraryModel, 'name'>, modelVersion?: Maybe<Pick<ModelVersionType, 'name'>> }
          )> }> }> } }> }
);

export type LibraryPolicyRequirementSectionsQueryVariables = Exact<{
  policyId: Scalars['ID']['input'];
}>;


export type LibraryPolicyRequirementSectionsQuery = { policy?: Maybe<{ requirementSections?: Maybe<Array<(
      Pick<LibraryRequirementSection, 'requirementSectionId' | 'name' | 'description' | 'policyReference'>
      & { roles?: Maybe<Array<Pick<LibraryRole, 'autoid' | 'description' | 'name' | 'reference'>>>, requirements?: Maybe<Array<Pick<LibraryRequirement, 'autoid' | 'requirementId' | 'name' | 'description' | 'policyReference'>>> }
    )>> }> };

export type LibraryRequirementSectionTabFragment = (
  Pick<LibraryRequirementSection, 'requirementSectionId' | 'name' | 'description' | 'policyReference'>
  & { roles?: Maybe<Array<Pick<LibraryRole, 'autoid' | 'description' | 'name' | 'reference'>>>, requirements?: Maybe<Array<Pick<LibraryRequirement, 'autoid' | 'requirementId' | 'name' | 'description' | 'policyReference'>>> }
);

export type LibraryPolicyRegulatedSystemsQueryVariables = Exact<{
  policyId: Scalars['ID']['input'];
}>;


export type LibraryPolicyRegulatedSystemsQuery = { policy?: Maybe<{ regulatedSystemCategories?: Maybe<Array<(
      Pick<LibraryRegulatedSystemCategory, 'id' | 'name' | 'description' | 'policyReference'>
      & { regulatedSystems?: Maybe<Array<Pick<PolicyRegulatedSystemType, 'id' | 'regulatedSystemId' | 'name' | 'description' | 'policyReference'>>> }
    )>> }> };

export type LibraryPolicyCoverageTabFragment = (
  Pick<LibraryRegulatedSystemCategory, 'id' | 'name' | 'description' | 'policyReference'>
  & { regulatedSystems?: Maybe<Array<Pick<PolicyRegulatedSystemType, 'id' | 'regulatedSystemId' | 'name' | 'description' | 'policyReference'>>> }
);

export type ChangeActiveSpaceMutationVariables = Exact<{
  spaceId: Scalars['ID']['input'];
}>;


export type ChangeActiveSpaceMutation = { changeActiveSpace: (
    Pick<GovernSpaceResponseType, 'success'>
    & { data?: Maybe<(
      Pick<GovernSpace, 'description' | 'externalId' | 'icon' | 'isDefault' | 'name'>
      & { managers?: Maybe<Array<Pick<AppUserContactInfo, 'email'>>> }
    )> }
  ) };

export type RequestUpgradeMutationVariables = Exact<{
  details?: InputMaybe<Scalars['String']['input']>;
}>;


export type RequestUpgradeMutation = { requestUpgrade: Pick<ResponseType, 'success' | 'error'> };

export type GetMyOrganizationDomainQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOrganizationDomainQuery = { myOrganization: Pick<AppOrganization, 'domain'> };

export type AddSpaceMembersMutationVariables = Exact<{
  inviteEmailsInput?: InputMaybe<InviteMemberInput>;
  memberIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
}>;


export type AddSpaceMembersMutation = { addSpaceMembers: Pick<ResponseType, 'success' | 'error'> };

export type ChangeSpaceMemberRoleMutationVariables = Exact<{
  spaceId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  role: GovernSpaceRole;
}>;


export type ChangeSpaceMemberRoleMutation = { changeSpaceMemberRole: Pick<ResponseType, 'success' | 'error'> };

export type GetSpaceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSpaceQuery = { getGovernSpace?: Maybe<(
    Pick<GovernSpace, 'externalId'>
    & { members?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'color' | 'profileImageURL' | 'txtIdentifier'>>>, managers?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'color' | 'profileImageURL' | 'txtIdentifier'>>> }
  )> };

export type GetSpaceMembersQueryVariables = Exact<{
  id: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetSpaceMembersQuery = { getGovernSpaceMembers: { pageInfo: Pick<PageInfo, 'totalCount' | 'perPage' | 'page'>, nodes?: Maybe<Array<Pick<GovernSpaceMember, 'externalId' | 'email' | 'firstName' | 'lastName' | 'role' | 'profileImageURL' | 'txtIdentifier' | 'platformRole'>>> } };

export type RemoveSpaceMemberMutationVariables = Exact<{
  spaceId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
}>;


export type RemoveSpaceMemberMutation = { removeSpaceMember: Pick<ResponseType, 'success' | 'error'> };

export type SuggestPolicyMutationVariables = Exact<{
  name: Scalars['String']['input'];
  kind?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
}>;


export type SuggestPolicyMutation = { suggestPolicy: Pick<ResponseType, 'success' | 'error'> };

export type SuggestRiskMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type SuggestRiskMutation = { suggestRisk: Pick<ResponseType, 'success'> };

export type SuggestTemplateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  kind: Scalars['String']['input'];
}>;


export type SuggestTemplateMutation = { suggestTemplate: Pick<ResponseType, 'success' | 'error'> };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { countries: Array<Pick<CountryDataType, 'code' | 'name'>> };

export type UpdateUserMutationVariables = Exact<{
  values: UpdateUserArgs;
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateUserMutation = { updateUser: Pick<AppUser, 'id' | 'firstName' | 'lastName'> };

export type AddNewUserMutationVariables = Exact<{
  values: CreateUserArgs;
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
  tempPassword?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddNewUserMutation = { addNewUser: Pick<DataResponseType, 'data' | 'error' | 'success'> };

export type InviteMembersMutationVariables = Exact<{
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  input: InviteMemberInput;
}>;


export type InviteMembersMutation = { inviteMembers: Pick<ResponseType, 'success' | 'error'> };

export type ChangeMemberAccessMutationVariables = Exact<{
  access?: InputMaybe<Capabilities>;
  memberId: Scalars['ID']['input'];
}>;


export type ChangeMemberAccessMutation = { changeMemberAccess: Pick<MessageResponseType, 'success' | 'message'> };

export type ChangeUserRoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  role: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ChangeUserRoleMutation = { changeUserRole: Pick<ResponseType, 'success' | 'error'> };

export type DeleteMemberMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteMemberMutation = { deleteMember: Pick<ResponseType, 'success' | 'error'> };

export type DeleteMembersMutationVariables = Exact<{
  externalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteMembersMutation = { deleteMembers: Pick<ResponseType, 'success' | 'error'> };

export type DeletePendingMemberMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeletePendingMemberMutation = { deletePendingMember: Pick<ResponseType, 'success' | 'error'> };

export type DeletePendingMembersMutationVariables = Exact<{
  externalIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeletePendingMembersMutation = { deletePendingMembers: Pick<ResponseType, 'success' | 'error'> };

export type GetCapabilitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapabilitiesQuery = { myOrganization: { appCapabilities: Array<Pick<AppCapability, 'externalId' | 'name'>> } };

export type GetMembersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organisationId: Scalars['ID']['input'];
}>;


export type GetMembersQuery = { organization: { availableAccesses: Array<Pick<OptionsResponseType, 'value' | 'label'>>, members: { nodes?: Maybe<Array<(
        Pick<AppUser, 'externalId' | 'firstName' | 'lastName' | 'email' | 'access' | 'lastLoginAt' | 'profileImageURL' | 'title' | 'role'>
        & { systems: Array<Pick<GovernSystem, 'externalId'>>, groups: Array<Pick<AppUserGroup, 'name'>>, organizations: Array<Pick<AppOrganization, 'name' | 'externalId'>>, spaces: Array<(
          Pick<GovernSpace, 'externalId' | 'name'>
          & { systems?: Maybe<Array<Pick<GovernSystem, 'externalId'>>>, managers?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'txtIdentifier'>>> }
        )> }
      )>>, pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage'> } } };

export type GetMembersCountsQueryVariables = Exact<{
  organisationId: Scalars['ID']['input'];
}>;


export type GetMembersCountsQuery = { organization: { members: { pageInfo: Pick<PageInfo, 'totalCount'> }, pendingInvitations: { pageInfo: Pick<PageInfo, 'totalCount'> } } };

export type GetMyOrganisationMembersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMyOrganisationMembersQuery = { myOrganization: { availableAccesses: Array<Pick<OptionsResponseType, 'value' | 'label'>>, members: { nodes?: Maybe<Array<(
        Pick<AppUser, 'externalId' | 'firstName' | 'lastName' | 'email' | 'access' | 'lastLoginAt' | 'profileImageURL' | 'title' | 'role'>
        & { systems: Array<Pick<GovernSystem, 'externalId'>>, groups: Array<Pick<AppUserGroup, 'name'>>, organizations: Array<Pick<AppOrganization, 'name' | 'externalId'>>, spaces: Array<(
          Pick<GovernSpace, 'externalId' | 'name'>
          & { systems?: Maybe<Array<Pick<GovernSystem, 'externalId'>>>, managers?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'txtIdentifier'>>> }
        )> }
      )>>, pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage'> } } };

export type GetMyOrganisationMembersCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOrganisationMembersCountsQuery = { myOrganization: { members: { pageInfo: Pick<PageInfo, 'totalCount'> }, pendingInvitations: { pageInfo: Pick<PageInfo, 'totalCount'> } } };

export type GetMyOrganisationPendingMembersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMyOrganisationPendingMembersQuery = { myOrganization: { pendingInvitations: { nodes?: Maybe<Array<(
        Pick<AppUser, 'externalId' | 'email'>
        & { spaces: Array<Pick<GovernSpace, 'name'>>, targetTokens?: Maybe<Array<(
          Pick<AppEmailToken, 'updatedAt' | 'expiresAt'>
          & { createdByUser?: Maybe<Pick<AppUser, 'firstName' | 'lastName' | 'email'>> }
        )>> }
      )>>, pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage'> } } };

export type GetPendingMembersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organisationId: Scalars['ID']['input'];
}>;


export type GetPendingMembersQuery = { organization: { pendingInvitations: { nodes?: Maybe<Array<(
        Pick<AppUser, 'externalId' | 'email'>
        & { spaces: Array<Pick<GovernSpace, 'name'>>, targetTokens?: Maybe<Array<(
          Pick<AppEmailToken, 'updatedAt' | 'expiresAt'>
          & { createdByUser?: Maybe<Pick<AppUser, 'firstName' | 'lastName' | 'email'>> }
        )>> }
      )>>, pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage'> } } };

export type PlatformViewAsUserMutationVariables = Exact<{
  targetId: Scalars['ID']['input'];
}>;


export type PlatformViewAsUserMutation = { viewAsUser: { user: Pick<AppUser, 'externalId' | 'email' | 'firstName' | 'lastName' | 'color'> } };

export type ResendInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ResendInvitationMutation = { resendInvitation: Pick<ResponseType, 'success' | 'error'> };

export type GetGeographyQueryVariables = Exact<{
  regionAlphabeticalOrder?: InputMaybe<SortDirection>;
}>;


export type GetGeographyQuery = { geography: { regions: Array<(
      Pick<Region, 'name' | 'externalId'>
      & { countries?: Maybe<Array<Pick<Country, 'name' | 'externalId'>>> }
    )> } };

export type MyOrganisationDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrganisationDetailsQuery = { myOrganisationDetails: (
    Pick<AppOrganization, 'address' | 'defaultRegions' | 'defaultIndustries' | 'defaultBusinessModels' | 'allowSpaceManagerInviteUsers' | 'allowMemberInviteUsers'>
    & { id: AppOrganization['externalId'], organisationName: AppOrganization['name'] }
    & { logo?: Maybe<Pick<OrganizationLogoType, 'file_name' | 'file_url'>> }
  ), countries: Array<{ label: CountryDataType['name'], value: CountryDataType['code'] }> };

export type RemoveMyOrganisationLogoMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveMyOrganisationLogoMutation = { removeMyOrganisationLogo: Pick<MessageResponseType, 'success' | 'message'> };

export type RequestCancellationMutationVariables = Exact<{
  input: RequestCancellationInput;
}>;


export type RequestCancellationMutation = { requestCancellationOfMyOrganisationSubscription: Pick<MessageResponseType, 'success' | 'message'> };

export type UpdateMyOrganisationDefaultsMutationVariables = Exact<{
  input: UpdateMyOrganisationDefaultsInput;
}>;


export type UpdateMyOrganisationDefaultsMutation = { updateMyOrganisationDefaults: Pick<MessageResponseType, 'success' | 'message'> };

export type UpdateMyOrganisationDetailsMutationVariables = Exact<{
  input: UpdateMyOrganisationDetailsInput;
}>;


export type UpdateMyOrganisationDetailsMutation = { updateMyOrganisationDetails: Pick<MessageResponseType, 'success' | 'message'> };

export type UpdateMyOrganisationSecurityMutationVariables = Exact<{
  input: UpdateMyOrganisationSecurityInput;
}>;


export type UpdateMyOrganisationSecurityMutation = { updateMyOrganisationSecurity: Pick<MessageResponseType, 'success' | 'message'> };

export type AssignSpaceManagersMutationVariables = Exact<{
  spaceId: Scalars['String']['input'];
  managerIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AssignSpaceManagersMutation = { assignManagers: Pick<ResponseType, 'success' | 'error'> };

export type CreateSpaceMutationVariables = Exact<{
  input: CreateSpaceInput;
}>;


export type CreateSpaceMutation = { createSpace: Pick<DataResponseType, 'error' | 'success' | 'data'> };

export type DeleteSpaceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSpaceMutation = { deleteSpace: Pick<ResponseType, 'success' | 'error'> };

export type GetSpacesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSpacesQuery = { spaces: { pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage'>, nodes?: Maybe<Array<(
      Pick<GovernSpace, 'name' | 'isDefault' | 'description' | 'externalId' | 'icon' | 'membersCount' | 'systemsCount' | 'address' | 'updatedAt' | 'createdAt'>
      & { functions?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, regions?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, countries?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, managers?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'color' | 'profileImageURL' | 'txtIdentifier'>>>, creator?: Maybe<Pick<AppUser, 'fullName' | 'txtIdentifier'>> }
    )>> } };

export type GovernSpaceNodeFragment = (
  Pick<GovernSpace, 'name' | 'isDefault' | 'description' | 'externalId' | 'icon' | 'membersCount' | 'systemsCount' | 'address' | 'updatedAt' | 'createdAt'>
  & { functions?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, regions?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, countries?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, managers?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'color' | 'profileImageURL' | 'txtIdentifier'>>>, creator?: Maybe<Pick<AppUser, 'fullName' | 'txtIdentifier'>> }
);

export type UpdateSpaceMutationVariables = Exact<{
  input: UpdateSpaceInput;
  spaceId: Scalars['String']['input'];
}>;


export type UpdateSpaceMutation = { updateSpace: Pick<ResponseType, 'success' | 'error'> };

export type AddLibraryTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type AddLibraryTaskMutation = { queueLibrarySync: Pick<DataResponseType, 'success' | 'data' | 'error'> };

export type AddOptionsSyncTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type AddOptionsSyncTaskMutation = { queueAirtableOptions: Pick<DataResponseType, 'success' | 'data' | 'error'> };

export type RefreshFeatureFlagsMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshFeatureFlagsMutation = { featureFlagsRefresh: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type OrganisationDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrganisationDetailsQuery = { organisationDetails: (
    Pick<AppOrganization, 'address' | 'status' | 'consentToUserSwitching' | 'systemLimit' | 'access' | 'license' | 'publicRegistryURL'>
    & { organisationName: AppOrganization['name'] }
    & { logo?: Maybe<Pick<OrganizationLogoType, 'file_name' | 'file_url'>> }
  ), countries: Array<{ label: CountryDataType['name'], value: CountryDataType['code'] }> };

export type RemoveOrganisationLogoMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemoveOrganisationLogoMutation = { removeOrganisationLogo: Pick<MessageResponseType, 'success' | 'message'> };

export type UpdateOrganisationConfigurationMutationVariables = Exact<{
  input: UpdateOrganisationConfigurationInput;
}>;


export type UpdateOrganisationConfigurationMutation = { updateOrganisationConfiguration: Pick<MessageResponseType, 'success' | 'message'> };

export type UpdateOrganisationDetailsMutationVariables = Exact<{
  input: UpdateOrganisationDetailsInput;
}>;


export type UpdateOrganisationDetailsMutation = { updateOrganisationDetails: Pick<MessageResponseType, 'success' | 'message'> };

export type AddOrganisationMutationVariables = Exact<{
  input: AddOrganisationInput;
}>;


export type AddOrganisationMutation = { addOrganisation: Pick<MessageResponseType, 'success' | 'message'> };

export type ChangeOrganisationAccessMutationVariables = Exact<{
  access: Capabilities;
  organisationId: Scalars['String']['input'];
}>;


export type ChangeOrganisationAccessMutation = { changeOrganisationAccess: Pick<MessageResponseType, 'success' | 'message'> };

export type ChangeOrganisationLicenseMutationVariables = Exact<{
  license: Licenses;
  organisationId: Scalars['String']['input'];
}>;


export type ChangeOrganisationLicenseMutation = { changeOrganisationLicense: Pick<MessageResponseType, 'success' | 'message'> };

export type ChangeOrganisationStatusMutationVariables = Exact<{
  status: Scalars['Boolean']['input'];
  organisationId: Scalars['String']['input'];
}>;


export type ChangeOrganisationStatusMutation = { changeOrganisationStatus: Pick<MessageResponseType, 'success' | 'message'> };

export type DeleteOrganisationMutationVariables = Exact<{
  organisationId: Scalars['String']['input'];
}>;


export type DeleteOrganisationMutation = { deleteOrganisation: Pick<MessageResponseType, 'success' | 'message'> };

export type GetOrganisationCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganisationCountriesQuery = { countries: Array<{ label: CountryDataType['name'], value: CountryDataType['code'] }> };

export type OrganisationsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type OrganisationsQuery = { organisations: (
    Pick<OrganisationPaginatedResult, 'success'>
    & { data: { nodes?: Maybe<Array<(
        Pick<AppOrganization, 'externalId' | 'access' | 'license' | 'status'>
        & { title: AppOrganization['name'], users: AppOrganization['usersCount'], systems: AppOrganization['systemsCount'], registeredDate: AppOrganization['createdAt'] }
        & { logo?: Maybe<Pick<OrganizationLogoType, 'file_url'>> }
      )>>, pageInfo: Pick<PageInfo, 'totalCount' | 'page' | 'perPage'> } }
  ), availableAccess: Array<Pick<OptionsResponseType, 'value' | 'label'>>, availableLicenses: Array<Pick<OptionsResponseType, 'value' | 'label'>> };

export type OrganisationTableItemFragment = (
  Pick<AppOrganization, 'externalId' | 'access' | 'license' | 'status'>
  & { title: AppOrganization['name'], users: AppOrganization['usersCount'], systems: AppOrganization['systemsCount'], registeredDate: AppOrganization['createdAt'] }
  & { logo?: Maybe<Pick<OrganizationLogoType, 'file_url'>> }
);

export type AddNewUsersMutationVariables = Exact<{
  input: CreateUsersInput;
}>;


export type AddNewUsersMutation = { addNewUsers: Pick<ResponseType, 'success' | 'error'> };

export type GetPlatformUsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetPlatformUsersQuery = { getPlatformUsers: { pageInfo: Pick<PageInfo, 'perPage' | 'page' | 'totalCount'>, nodes?: Maybe<Array<(
      Pick<AppUser, 'externalId' | 'firstName' | 'lastName' | 'email' | 'txtIdentifier' | 'profileImageURL' | 'color' | 'lastLoginAt' | 'access' | 'active' | 'pending' | 'role'>
      & { organizations: Array<(
        Pick<AppOrganization, 'externalId' | 'name'>
        & { availableAccesses: Array<Pick<OptionsResponseType, 'label' | 'value'>> }
      )> }
    )>> } };

export type GovernDatasetQueryVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type GovernDatasetQuery = { dataset?: Maybe<(
    Pick<GovernDataset, 'externalId' | 'name' | 'subject' | 'owner' | 'modality' | 'cardOwnerId' | 'linkToDataset' | 'personalData' | 'specialCategoriesPD' | 'source' | 'version' | 'license' | 'tags' | 'dataCollectionMethods' | 'preProcessingMethods' | 'description' | 'isBookmarked'>
    & { systems: Array<Pick<GovernSystem, 'externalId' | 'name'>>, cardOwner: Pick<AppUser, 'id' | 'externalId' | 'firstName' | 'lastName'> }
  )> };

export type UpdateDatasetMutationVariables = Exact<{
  updateDatasetInput: UpdateDatasetInput;
}>;


export type UpdateDatasetMutation = { updateDataset: (
    Pick<GovernDataset, 'externalId' | 'name' | 'subject' | 'owner' | 'modality' | 'cardOwnerId' | 'linkToDataset' | 'personalData' | 'specialCategoriesPD' | 'source' | 'version' | 'license' | 'tags' | 'dataCollectionMethods' | 'preProcessingMethods' | 'description'>
    & { cardOwner: Pick<AppUser, 'id' | 'firstName' | 'lastName'> }
  ) };

export type UpdateDatasetNameMutationVariables = Exact<{
  externalId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateDatasetNameMutation = { updateDatasetName: Pick<DataResponseType, 'success' | 'error'> };

export type InputOptionsQueryVariables = Exact<{
  datasetSubjects?: InputMaybe<Scalars['Boolean']['input']>;
  personalData?: InputMaybe<Scalars['Boolean']['input']>;
  specialCategories?: InputMaybe<Scalars['Boolean']['input']>;
  supplierTypes?: InputMaybe<Scalars['Boolean']['input']>;
  functions?: InputMaybe<Scalars['Boolean']['input']>;
  systemTypes?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Scalars['Boolean']['input']>;
  capabilities?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Scalars['Boolean']['input']>;
  regions?: InputMaybe<Scalars['Boolean']['input']>;
  documentTypes?: InputMaybe<Scalars['Boolean']['input']>;
  riskSources?: InputMaybe<Scalars['Boolean']['input']>;
  businessImpactLevels?: InputMaybe<Scalars['Boolean']['input']>;
  systemRiskLevels?: InputMaybe<Scalars['Boolean']['input']>;
  businessModels?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type InputOptionsQuery = { industries?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, functions?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, systemTypes?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, capabilities?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, roles?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, tags?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, regions?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, datasetSubjects?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, personalData?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, specialCategories?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, supplierTypes?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, documentTypes?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, riskSources?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, businessImpactLevels?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, systemRiskLevels?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )>, businessModels?: Maybe<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<(
      Pick<InputOptionType, 'explainer' | 'targets'>
      & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
    )> }
  )> };

export type TemporaryInputTypeFragment = (
  Pick<InputOptionResponseType, 'name'>
  & { data: Array<(
    Pick<InputOptionType, 'explainer' | 'targets'>
    & { text: InputOptionType['label'], value: InputOptionType['value'], id: InputOptionType['compoundId'] }
  )> }
);

export type CreateDatasetMutationVariables = Exact<{
  createDatasetInput: CreateDatasetInput;
}>;


export type CreateDatasetMutation = { createDataset: Pick<ResponseType, 'success'> };

export type ToggleBookmarkMutationVariables = Exact<{
  type: BookmarkType;
  id: Scalars['String']['input'];
}>;


export type ToggleBookmarkMutation = { toggleBookmark: Pick<BookmarkToggleResponse, 'status' | 'success' | 'error'> };

export type DatasetsPageQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type DatasetsPageQuery = { datasets: { nodes?: Maybe<Array<(
      Pick<GovernDataset, 'externalId' | 'name' | 'owner' | 'subject' | 'modality' | 'isBookmarked'>
      & { systems: Array<Pick<GovernSystem, 'externalId'>> }
    )>>, pageInfo: Pick<PageInfo, 'totalCount'> } };

export type DeleteDatasetMutationVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type DeleteDatasetMutation = { deleteDataset: Pick<DataResponseType, 'success' | 'error'> };

export type MoveDatasetsToSpaceMutationVariables = Exact<{
  targetSpaceId: Scalars['String']['input'];
  datasetIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type MoveDatasetsToSpaceMutation = { moveDatasetsToSpace: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type OrgUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgUsersQuery = { listUsers: Array<Pick<AppUser, 'firstName' | 'lastName' | 'email' | 'externalId' | 'fullName' | 'txtIdentifier'>> };

export type CreateSystemPoliciesMutationVariables = Exact<{
  systemId: Scalars['String']['input'];
  policies: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type CreateSystemPoliciesMutation = { createSystemPolicies: Pick<ResponseType, 'success' | 'error'> };

export type CreateSystemTemplateMutationVariables = Exact<{
  systemPolicyId: Scalars['String']['input'];
  sourceTemplateId: Scalars['Int']['input'];
}>;


export type CreateSystemTemplateMutation = { addTemplateToPolicy: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type DeleteSystemPolicyMutationVariables = Exact<{
  policyId: Scalars['String']['input'];
}>;


export type DeleteSystemPolicyMutation = { deleteSystemPolicy: Pick<ResponseType, 'success' | 'error'> };

export type DeleteTemplateFromPolicyMutationVariables = Exact<{
  templateExternalId: Scalars['String']['input'];
}>;


export type DeleteTemplateFromPolicyMutation = { deleteTemplateFromPolicy: Pick<ResponseType, 'success' | 'error'> };

export type GetLibraryTemplateQueryVariables = Exact<{
  templateId: Scalars['Int']['input'];
}>;


export type GetLibraryTemplateQuery = { template: (
    Pick<LibraryTemplate, 'externalId' | 'name'>
    & { requirements: Array<(
      Pick<LibraryRequirement, 'name' | 'description' | 'controlsCount'>
      & { externalId: LibraryRequirement['cuid'] }
      & { requirementSections?: Maybe<Array<Pick<LibraryRequirementSection, 'cuid' | 'autoid' | 'name' | 'description'>>>, controls?: Maybe<Array<(
        Pick<LibraryControl, 'externalId' | 'name'>
        & { evidenceBlocks: Array<Pick<EvidenceBlockResponseType, 'id' | 'name' | 'description'>> }
      )>> }
    )> }
  ) };

export type GetPolicyTemplatesQueryVariables = Exact<{
  policyId: Scalars['Int']['input'];
  systemId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetPolicyTemplatesQuery = { templates: { pageInfo: Pick<PageInfo, 'totalCount'>, nodes?: Maybe<Array<(
      Pick<LibraryTemplate, 'autoid' | 'externalId' | 'name' | 'description' | 'coverageTags' | 'isRecommended' | 'updatedAt' | 'controlsCount'>
      & { roles?: Maybe<Array<Pick<LibraryRole, 'name'>>> }
    )>> } };

export type GetSystemPoliciesQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
  orderBy?: InputMaybe<SortItemsBy>;
  query?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<SystemPoliciesFilter> | SystemPoliciesFilter>;
}>;


export type GetSystemPoliciesQuery = { systemPolicies: (
    Pick<GovernSystemPolicyWithTotal, 'total'>
    & { items: Array<(
      Pick<GovernSystemPolicyWithMoreData, 'externalId' | 'numberOfTemplatesInUse'>
      & { sourcePolicy: (
        Pick<LibraryPolicy, 'id' | 'autoid' | 'name' | 'templatesCount'>
        & { owner?: Maybe<Pick<LibraryPolicyOwner, 'name' | 'logo'>> }
      ), systemTemplates?: Maybe<Array<(
        Pick<GovernSystemTemplateWithControlsCounts, 'externalId' | 'updatedAt' | 'sourceControlsCount' | 'controlsInProgressCount' | 'completedControlsCount'>
        & { sourceTemplate: Pick<LibraryTemplate, 'name'> }
      )>> }
    )> }
  ) };

export type GetGovernSystemTemplateQueryVariables = Exact<{
  templateId: Scalars['String']['input'];
}>;


export type GetGovernSystemTemplateQuery = { governSystemTemplate: (
    Pick<GovernSystemTemplate, 'id'>
    & { sourceTemplate: Pick<LibraryTemplate, 'autoid' | 'name'> }
  ) };

export type AddLibraryRisksToSystemMutationVariables = Exact<{
  systemId: Scalars['String']['input'];
  libraryRisks: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type AddLibraryRisksToSystemMutation = { addLibraryRisksToSystem: Pick<ResponseType, 'success'> };

export type GetAvailableLibraryRisksForSystemQueryVariables = Exact<{
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId: Scalars['String']['input'];
}>;


export type GetAvailableLibraryRisksForSystemQuery = { library: { risks: { pageInfo: Pick<PageInfo, 'totalCount'>, edges?: Maybe<Array<{ node: (
          Pick<LibraryRisk, 'id' | 'autoid' | 'riskId' | 'isFollowed' | 'name' | 'description' | 'updatedAt' | 'type' | 'industry' | 'isRecommended'>
          & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'id'>>> }
        ) }>> } } };

export type AddGovernAttachmentMutationVariables = Exact<{
  input: CreateGovernAttachmentInput;
}>;


export type AddGovernAttachmentMutation = { createAttachment: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type AddGovernSystemMutationVariables = Exact<{
  input: CreateGovernSystemInput;
}>;


export type AddGovernSystemMutation = { createGovernSystem: (
    Pick<CreateGovernSystemResponseType, 'success' | 'error'>
    & { data?: Maybe<Pick<GovernSystem, 'externalId'>> }
  ) };

export type AddSystemDatasetMutationVariables = Exact<{
  addSystemDatasetInput: AddSystemDatasetInput;
}>;


export type AddSystemDatasetMutation = { addSystemDataset: Pick<SystemDatasetResponseType, 'success' | 'error'> };

export type DeleteGovernAttachmentMutationVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type DeleteGovernAttachmentMutation = { deleteAttachment: Pick<DataResponseType, 'success' | 'error'> };

export type DeleteGovernSystemMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteGovernSystemMutation = { deleteGovernSystem: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type DeleteGovernSystemRiskMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteGovernSystemRiskMutation = { deleteRisk: Pick<SuccessResponseType, 'success' | 'error'> };

export type DeleteManyGovernSystemRisksMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type DeleteManyGovernSystemRisksMutation = { deleteManyRisks: Pick<SuccessResponseType, 'success' | 'count' | 'error'> };

export type EditGovernSystemMutationVariables = Exact<{
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
  input: Scalars['JSONObject']['input'];
}>;


export type EditGovernSystemMutation = { updateGovernSystem: Pick<DataResponseType, 'data' | 'error' | 'success'> };

export type GetLibraryMitigationsByRiskQueryVariables = Exact<{
  riskId: Scalars['String']['input'];
}>;


export type GetLibraryMitigationsByRiskQuery = { libraryMitigations?: Maybe<Array<Pick<LibraryMitigation, 'cuid' | 'name' | 'description'>>> };

export type GetSortedLibraryPoliciesQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSortedLibraryPoliciesQuery = { library: { policies: { pageInfo: Pick<PageInfo, 'totalCount'>, edges?: Maybe<Array<{ node: (
          Pick<LibraryPolicy, 'id' | 'autoid' | 'policyId' | 'name' | 'overview' | 'updatedAt' | 'type' | 'regions' | 'countries' | 'usStates' | 'sector' | 'isFollowed' | 'isRecommended'>
          & { owner?: Maybe<Pick<LibraryPolicyOwner, 'name' | 'logo'>>, requirements?: Maybe<Array<Pick<LibraryRequirement, 'id' | 'requirementId' | 'createdAt'>>>, follows?: Maybe<Array<Pick<LibraryFeedFollow, 'cuid' | 'userId'>>> }
        ) }>> } } };

export type GetGovernSystemQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetGovernSystemQuery = { governSystem?: Maybe<(
    Pick<GovernSystem, 'version' | 'externalId' | 'name' | 'description' | 'uid' | 'risk' | 'riskJustification' | 'impact' | 'impactJustification' | 'functions' | 'role' | 'tags' | 'regions' | 'industries' | 'types' | 'capabilities' | 'id' | 'isBookmarked' | 'isEditable' | 'createdAt' | 'updatedAt'>
    & { createdByUser?: Maybe<Pick<AppUser, 'firstName' | 'lastName' | 'email' | 'color'>>, contact?: Maybe<Pick<AppContactInformation, 'address' | 'phone' | 'email'>>, linkedLibraryModels?: Maybe<Array<(
      Pick<LibraryModel, 'cuid' | 'name'>
      & { owner?: Maybe<Pick<LibraryModelOwner, 'name' | 'logo'>> }
    )>>, risks?: Maybe<Array<(
      Pick<GovernSystemRisk, 'externalId' | 'title' | 'description' | 'sources' | 'marginalRiskLevel'>
      & { mitigations?: Maybe<Array<Pick<GovernSystemRiskMitigation, 'externalId' | 'name' | 'description' | 'status'>>>, owner: Pick<AppUser, 'email'> }
    )>> }
  )> };

export type GovernAttachmentsQueryVariables = Exact<{
  targetId: Scalars['String']['input'];
  target: AttachmentTarget;
  query?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GovernAttachmentsQuery = { attachments: { pageInfo: Pick<PageInfo, 'totalCount'>, nodes?: Maybe<Array<(
      Pick<GovernAttachment, 'externalId' | 'name' | 'description' | 'url' | 'location' | 'format' | 'updatedAt'>
      & { type: Pick<AppInputOption, 'externalId' | 'label'>, owner: Pick<AppUser, 'firstName' | 'lastName' | 'email'> }
    )>> } };

export type GovernAttachmentNodeFragment = (
  Pick<GovernAttachment, 'externalId' | 'name' | 'description' | 'url' | 'location' | 'format' | 'updatedAt'>
  & { type: Pick<AppInputOption, 'externalId' | 'label'>, owner: Pick<AppUser, 'firstName' | 'lastName' | 'email'> }
);

export type GovernSystemDatasetQueryVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type GovernSystemDatasetQuery = { systemDataset: (
    Pick<GovernSystemDataset, 'externalId' | 'purpose' | 'description'>
    & { dataset: Pick<GovernDataset, 'name' | 'externalId'> }
  ) };

export type GovernSystemDatasetsQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type GovernSystemDatasetsQuery = { systemDatasets: Array<(
    Pick<GovernSystemDataset, 'externalId' | 'purpose' | 'description'>
    & { dataset: Pick<GovernDataset, 'name' | 'externalId' | 'isBookmarked'> }
  )> };

export type GetGovernSystemLinksQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type GetGovernSystemLinksQuery = { governSystem?: Maybe<(
    Pick<GovernSystem, 'id'>
    & { linkedLibraryModels?: Maybe<Array<(
      Pick<LibraryModel, 'id' | 'cuid' | 'autoid' | 'name' | 'isFollowed'>
      & { owner?: Maybe<Pick<LibraryModelOwner, 'logo'>> }
    )>>, linkedGovernSystems?: Maybe<Array<Pick<GovernSystem, 'externalId' | 'name' | 'isBookmarked'>>> }
  )> };

export type GovernSystemRiskSourcesDataQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
}>;


export type GovernSystemRiskSourcesDataQuery = { systemDatasets: Array<{ dataset: Pick<GovernDataset, 'name' | 'externalId'> }>, governSystem?: Maybe<(
    Pick<GovernSystem, 'externalId'>
    & { linkedLibraryModels?: Maybe<Array<(
      Pick<LibraryModel, 'autoid' | 'name'>
      & { owner?: Maybe<Pick<LibraryModelOwner, 'name' | 'logo'>> }
    )>> }
  )> };

export type GetGovernSystemRisksQueryVariables = Exact<{
  systemId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetGovernSystemRisksQuery = { governSystemRisks: { pageInfo: Pick<PageInfo, 'totalCount'>, edges?: Maybe<Array<{ node: (
        Pick<GovernSystemRisk, 'externalId' | 'title' | 'description' | 'consequences' | 'systemId' | 'residualRiskEvaluation' | 'inherentRiskEvaluation' | 'marginalRiskLevel' | 'treatmentPlan' | 'accept' | 'avoid' | 'updatedAt' | 'types' | 'sources'>
        & { mitigations?: Maybe<Array<Pick<GovernSystemRiskMitigation, 'externalId' | 'name' | 'description' | 'status'>>>, owner: Pick<AppUser, 'externalId' | 'firstName' | 'lastName' | 'email' | 'active'>, libraryRisk?: Maybe<Pick<LibraryRisk, 'externalId'>> }
      ) }>> } };

export type GovernSystemRiskTableRowFragment = (
  Pick<GovernSystemRisk, 'externalId' | 'title' | 'description' | 'consequences' | 'systemId' | 'residualRiskEvaluation' | 'inherentRiskEvaluation' | 'marginalRiskLevel' | 'treatmentPlan' | 'accept' | 'avoid' | 'updatedAt' | 'types' | 'sources'>
  & { mitigations?: Maybe<Array<Pick<GovernSystemRiskMitigation, 'externalId' | 'name' | 'description' | 'status'>>>, owner: Pick<AppUser, 'externalId' | 'firstName' | 'lastName' | 'email' | 'active'>, libraryRisk?: Maybe<Pick<LibraryRisk, 'externalId'>> }
);

export type GetGovernSystemSlimQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetGovernSystemSlimQuery = { governSystem?: Maybe<Pick<GovernSystem, 'externalId' | 'name' | 'isEditable'>> };

export type GetGovernSystemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGovernSystemsQuery = { governSystems: { nodes?: Maybe<Array<Pick<GovernSystemWithMoreData, 'uid' | 'version' | 'externalId' | 'name' | 'description' | 'role' | 'functions' | 'risk' | 'riskJustification' | 'impact' | 'impactJustification' | 'tags' | 'regions' | 'industries' | 'types' | 'capabilities' | 'id'>>> } };

export type RemoveSystemDatasetMutationVariables = Exact<{
  systemDatasetId: Scalars['String']['input'];
}>;


export type RemoveSystemDatasetMutation = { removeSystemDataset: Pick<SystemDatasetResponseType, 'success' | 'error'> };

export type UpdateGovernAttachmentMutationVariables = Exact<{
  externalId: Scalars['String']['input'];
  input: UpdateGovernAttachmentInput;
}>;


export type UpdateGovernAttachmentMutation = { updateAttachment: Pick<DataResponseType, 'success' | 'error'> };

export type UpdateSystemDatasetMutationVariables = Exact<{
  updateSystemDatasetInput: UpdateSystemDatasetInput;
}>;


export type UpdateSystemDatasetMutation = { updateSystemDataset: Pick<SystemDatasetResponseType, 'success' | 'error'> };

export type AddNewEvidenceBlockMutationVariables = Exact<{
  controlId: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
}>;


export type AddNewEvidenceBlockMutation = { addNewEvidenceBlock: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type CompleteControlMutationVariables = Exact<{
  controlId: Scalars['String']['input'];
}>;


export type CompleteControlMutation = { completeControl: Pick<ResponseType, 'success' | 'error'> };

export type CreateControlsForTemplateMutationVariables = Exact<{
  templateId: Scalars['String']['input'];
}>;


export type CreateControlsForTemplateMutation = { createSystemControlsForTemplate: Pick<ResponseType, 'success' | 'error'> };

export type DeleteEvidenceBlockMutationVariables = Exact<{
  controlId: Scalars['String']['input'];
  blockId: Scalars['String']['input'];
}>;


export type DeleteEvidenceBlockMutation = { deleteEvidenceBlock: Pick<DataResponseType, 'success' | 'error'> };

export type GetTemplateControlsQueryVariables = Exact<{
  templateId: Scalars['String']['input'];
}>;


export type GetTemplateControlsQuery = { systemControls: (
    Pick<GovernControlsResponseType, 'success' | 'error'>
    & { data?: Maybe<(
      Pick<GovernControlsResponseDataType, 'completedControlsCount' | 'controlsInProgressCount' | 'sourceControlsCount'>
      & { controls?: Maybe<Array<(
        Pick<GovernSystemControl, 'externalId' | 'contextualRequirementNotApplicable' | 'completedAt'>
        & { completedBy?: Maybe<Pick<AppUser, 'firstName' | 'lastName'>>, evidenceBlocks: Array<Pick<EvidenceBlockResponseType, 'id' | 'name' | 'description'>>, sourceRequirement: (
          Pick<LibraryRequirement, 'cuid' | 'name' | 'description' | 'contextualRequirement' | 'contextualRequirementExplanation'>
          & { requirementSections?: Maybe<Array<Pick<LibraryRequirementSection, 'cuid' | 'name' | 'description'>>> }
        ), sourceControl?: Maybe<(
          Pick<LibraryControl, 'externalId' | 'name'>
          & { evidenceBlocks: Array<Pick<EvidenceBlockResponseType, 'id' | 'name' | 'description'>> }
        )> }
      )>> }
    )> }
  ) };

export type MarkControlNotApplicableMutationVariables = Exact<{
  controlId: Scalars['String']['input'];
}>;


export type MarkControlNotApplicableMutation = { markNotApplicable: Pick<ResponseType, 'success' | 'error'> };

export type RemoveMarkControlNotApplicableMutationVariables = Exact<{
  controlId: Scalars['String']['input'];
}>;


export type RemoveMarkControlNotApplicableMutation = { removeMarkNotApplicable: Pick<ResponseType, 'success' | 'error'> };

export type UnCompleteControlMutationVariables = Exact<{
  controlId: Scalars['String']['input'];
}>;


export type UnCompleteControlMutation = { uncompleteControl: Pick<ResponseType, 'success' | 'error'> };

export type GetGovernSpaceQueryVariables = Exact<{
  spaceId: Scalars['String']['input'];
}>;


export type GetGovernSpaceQuery = { getGovernSpace?: Maybe<(
    Pick<GovernSpace, 'monthlySystemsCountVariation' | 'monthlyDatasetsCountVariation' | 'systemsCount' | 'datasetsCount' | 'name' | 'isDefault' | 'description' | 'externalId' | 'icon' | 'membersCount' | 'address' | 'updatedAt' | 'createdAt'>
    & { functions?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, regions?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, countries?: Maybe<Array<Pick<OptionsResponseType, 'label' | 'value'>>>, managers?: Maybe<Array<Pick<AppUserContactInfo, 'externalId' | 'firstName' | 'lastName' | 'email' | 'color' | 'profileImageURL' | 'txtIdentifier'>>>, creator?: Maybe<Pick<AppUser, 'fullName' | 'txtIdentifier'>> }
  )> };

export type MoveSystemsToSpaceMutationVariables = Exact<{
  targetSpaceId: Scalars['String']['input'];
  systemIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type MoveSystemsToSpaceMutation = { moveSystemsToSpace: Pick<DataResponseType, 'success' | 'error' | 'data'> };

export type OrganizationGovernSystemLimitQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationGovernSystemLimitQuery = { myOrganization: Pick<AppOrganization, 'maxGovernSystems'> };

export type SystemsHomePage2QueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type SystemsHomePage2Query = { governSystems: { nodes?: Maybe<Array<(
      Pick<GovernSystemWithMoreData, 'externalId' | 'name' | 'systemOwner' | 'risk' | 'impact' | 'createdAt' | 'updatedAt' | 'canUserEditSystem'>
      & { teams?: Maybe<Array<{ member: Pick<AppUser, 'externalId' | 'email'> }>> }
    )>>, pageInfo: Pick<PageInfo, 'totalCount'> }, options: Array<(
    Pick<InputOptionResponseType, 'name'>
    & { data: Array<Pick<InputOptionType, 'compoundId' | 'label'>> }
  )> };

export type SystemRegionAndCapabilityQueryVariables = Exact<{
  regionAlphabeticalOrder?: InputMaybe<SortDirection>;
}>;


export type SystemRegionAndCapabilityQuery = { library: { tasks: Array<Pick<LibraryTaskType, 'externalId' | 'name'>> }, geography: { regions: Array<(
      Pick<Region, 'name' | 'externalId'>
      & { countries?: Maybe<Array<Pick<Country, 'name' | 'externalId'>>> }
    )> } };

export type LibraryEvaluationDetailQueryVariables = Exact<{
  evaluationId: Scalars['ID']['input'];
  translationKeys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type LibraryEvaluationDetailQuery = (
  Pick<Query, 'translations'>
  & { evaluation?: Maybe<(
    Pick<LibraryEvaluation, 'name' | 'type' | 'description' | 'autoid' | 'dataset' | 'airtableUpdatedAt' | 'isFollowed' | 'datasetParameterLabel'>
    & { tasks?: Maybe<Array<Pick<LibraryTask, 'name'>>>, references: Array<Pick<LibrarySource, 'url'>>, aggregatedResults: { shortenedDatasetParameters: Array<Pick<ShortenedDatasetParameter, 'name' | 'shortenedVersion'>>, metricExplanations: Array<Pick<InputOptionType, 'label' | 'explainer'>>, resultsAggregatedByModel: Array<{ model: Pick<LibraryModel, 'id' | 'autoid' | 'name' | 'logoUrl' | 'isFollowed'>, modelVersion?: Maybe<Pick<ModelVersionType, 'name' | 'endpoints'>>, scores: Array<Pick<EvaluationResultProvidersType, 'provider' | 'scoreType' | 'score' | 'scoreUnit' | 'taskParameters' | 'datasetParameter'>> }> } }
  )> }
);

export type LibraryEvaluationsHomepageQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type LibraryEvaluationsHomepageQuery = { library: { evaluations: { edges?: Maybe<Array<{ node: (
          Pick<LibraryEvaluation, 'id' | 'autoid' | 'evaluationId' | 'name' | 'description' | 'type' | 'modelsCount' | 'airtableUpdatedAt'>
          & { tasks?: Maybe<Array<Pick<LibraryTask, 'name'>>> }
        ) }>> } } };

export type LibraryFeedHomepageQueryVariables = Exact<{
  filter?: InputMaybe<Array<LibraryCardType> | LibraryCardType>;
}>;


export type LibraryFeedHomepageQuery = { feed: Array<Pick<LibraryFeedItem, 'source' | 'sourceName' | 'sourceAutoid' | 'autoid' | 'name' | 'date' | 'type' | 'isFollowable' | 'urlId'>>, user: Pick<AppUser, 'isFollowingItems' | 'hasNewFeedItems' | 'feedSeenAt'> };

export type FeedItemFragment = Pick<LibraryFeedItem, 'source' | 'sourceName' | 'sourceAutoid' | 'autoid' | 'name' | 'date' | 'type' | 'isFollowable' | 'urlId'>;

export type LibraryFeedStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type LibraryFeedStatusQuery = { user: Pick<AppUser, 'hasNewFeedItems' | 'feedSeenAt' | 'color'> };

export type LibraryTrendingTopicsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type LibraryTrendingTopicsQuery = { trendingTopics: { edges?: Maybe<Array<{ node: Pick<LibraryTrendingTopicItem, 'date' | 'type' | 'name' | 'extra' | 'autoid' | 'urlId'> }>>, pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'totalCount'> } };

export type TrendingTopicFragment = Pick<LibraryTrendingTopicItem, 'date' | 'type' | 'name' | 'extra' | 'autoid' | 'urlId'>;

export type MarkFeedAsSeenMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkFeedAsSeenMutation = { markFeedAsSeen: Pick<ResponseType, 'success'> };

export type LibraryModelDetailQueryVariables = Exact<{
  modelId: Scalars['ID']['input'];
}>;


export type LibraryModelDetailQuery = { model?: Maybe<(
    Pick<LibraryModel, 'id' | 'autoid' | 'name' | 'productPage' | 'updatedAt' | 'logoUrl' | 'isFollowed' | 'airtableUpdatedAt' | 'type' | 'modality' | 'region' | 'industry' | 'sourceCode' | 'languages' | 'architecture' | 'parameters' | 'overview' | 'tokens' | 'trainingCluster' | 'trainingTime' | 'trainingCost' | 'carbonEmitted' | 'trainingDataOverview' | 'termsOfService' | 'contentPolicy' | 'privacyPolicy' | 'standardsAndCertificates' | 'releaseNotes' | 'hasEvaluationResultsByTaskFor'>
    & { owner?: Maybe<Pick<LibraryModelOwner, 'name' | 'address' | 'email'>>, versions?: Maybe<Array<Pick<ModelVersionType, 'name' | 'endpoints'>>>, restrictions?: Maybe<Array<Pick<RestrictionType, 'restrictionId' | 'name' | 'notes'>>>, safetyFeatures?: Maybe<Array<Pick<SafetyFeatureType, 'name' | 'notes' | 'featureDetails'>>>, saidotRisks?: Maybe<Array<(
      Pick<LibraryRisk, 'id' | 'autoid' | 'name' | 'description' | 'updatedAt' | 'isFollowed'>
      & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'name'>>> }
    )>>, providerRisks?: Maybe<Array<(
      Pick<LibraryRisk, 'id' | 'autoid' | 'name' | 'description' | 'updatedAt' | 'isFollowed'>
      & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'name'>>> }
    )>>, sources?: Maybe<Array<Pick<LibrarySource, 'sourceName' | 'url'>>>, datasets?: Maybe<Array<Pick<Dataset, 'datasetId' | 'name' | 'description'>>>, tasks?: Maybe<Array<Pick<LibraryTask, 'autoid' | 'name'>>> }
  )> };

export type MmmmFragment = (
  Pick<LibraryModel, 'id' | 'autoid' | 'name' | 'productPage' | 'updatedAt' | 'logoUrl' | 'isFollowed' | 'airtableUpdatedAt' | 'type' | 'modality' | 'region' | 'industry' | 'sourceCode' | 'languages' | 'architecture' | 'parameters' | 'overview' | 'tokens' | 'trainingCluster' | 'trainingTime' | 'trainingCost' | 'carbonEmitted' | 'trainingDataOverview' | 'termsOfService' | 'contentPolicy' | 'privacyPolicy' | 'standardsAndCertificates' | 'releaseNotes' | 'hasEvaluationResultsByTaskFor'>
  & { owner?: Maybe<Pick<LibraryModelOwner, 'name' | 'address' | 'email'>>, versions?: Maybe<Array<Pick<ModelVersionType, 'name' | 'endpoints'>>>, restrictions?: Maybe<Array<Pick<RestrictionType, 'restrictionId' | 'name' | 'notes'>>>, safetyFeatures?: Maybe<Array<Pick<SafetyFeatureType, 'name' | 'notes' | 'featureDetails'>>>, saidotRisks?: Maybe<Array<(
    Pick<LibraryRisk, 'id' | 'autoid' | 'name' | 'description' | 'updatedAt' | 'isFollowed'>
    & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'name'>>> }
  )>>, providerRisks?: Maybe<Array<(
    Pick<LibraryRisk, 'id' | 'autoid' | 'name' | 'description' | 'updatedAt' | 'isFollowed'>
    & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'name'>>> }
  )>>, sources?: Maybe<Array<Pick<LibrarySource, 'sourceName' | 'url'>>>, datasets?: Maybe<Array<Pick<Dataset, 'datasetId' | 'name' | 'description'>>>, tasks?: Maybe<Array<Pick<LibraryTask, 'autoid' | 'name'>>> }
);

export type LibraryModelsHomepageQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type LibraryModelsHomepageQuery = { library: { models: { edges?: Maybe<Array<{ node: (
          Pick<LibraryModel, 'id' | 'autoid' | 'modelId' | 'isFollowed' | 'isRecommended' | 'name' | 'shortDescription' | 'updatedAt' | 'region' | 'type' | 'evaluationsCount'>
          & { owner?: Maybe<Pick<LibraryModelOwner, 'address' | 'email' | 'name'>>, tasks?: Maybe<Array<Pick<LibraryTask, 'name'>>>, providerRisks?: Maybe<Array<Pick<LibraryRisk, 'id'>>>, follows?: Maybe<Array<Pick<LibraryFeedFollow, 'cuid' | 'userId'>>> }
        ) }>> } } };

export type GetCategorisedLibraryModelsQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCategorisedLibraryModelsQuery = { library: { models: { edges?: Maybe<Array<{ node: (
          Pick<LibraryModel, 'id' | 'cuid' | 'isFollowed' | 'isRecommended' | 'name' | 'logoUrl'>
          & { owner?: Maybe<Pick<LibraryModelOwner, 'name' | 'logo'>> }
        ) }>> } } };

export type LibraryPolicyRolesQueryVariables = Exact<{
  policyId: Scalars['ID']['input'];
}>;


export type LibraryPolicyRolesQuery = { policy?: Maybe<{ roles?: Maybe<Array<Pick<LibraryRole, 'description' | 'name' | 'reference'>>> }> };

export type LibraryPolicyRoleFragment = Pick<LibraryRole, 'description' | 'name' | 'reference'>;

export type LibraryPolicyDetailQueryVariables = Exact<{
  policyId: Scalars['ID']['input'];
}>;


export type LibraryPolicyDetailQuery = { policy?: Maybe<(
    Pick<LibraryPolicy, 'policyId' | 'autoid' | 'airtableCreatedAt' | 'createdAt' | 'documentDate' | 'effectiveDate' | 'effectiveDate2' | 'effectiveDate3' | 'effectiveDate4' | 'effectiveDate5' | 'effectiveDateScope' | 'effectiveDateScope2' | 'effectiveDateScope3' | 'effectiveDateScope4' | 'effectiveDateScope5' | 'expiryDate' | 'id' | 'industry' | 'keyInformation' | 'keyInformation2' | 'name' | 'overview' | 'penalties' | 'region' | 'reference' | 'sector' | 'scope' | 'type' | 'airtableUpdatedAt' | 'isFollowed' | 'hasRoles' | 'hasCoverage' | 'hasRequirements'>
    & { owner?: Maybe<Pick<LibraryPolicyOwner, 'name'>> }
  )> };

export type LibraryPolicyHomepageQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type LibraryPolicyHomepageQuery = { library: { policies: { pageInfo: Pick<PageInfo, 'totalCount'>, edges?: Maybe<Array<{ node: (
          Pick<LibraryPolicy, 'id' | 'autoid' | 'policyId' | 'name' | 'overview' | 'updatedAt' | 'type' | 'regions' | 'countries' | 'usStates' | 'sector' | 'isFollowed'>
          & { owner?: Maybe<Pick<LibraryPolicyOwner, 'name' | 'logo'>>, requirements?: Maybe<Array<Pick<LibraryRequirement, 'id' | 'requirementId' | 'createdAt'>>>, follows?: Maybe<Array<Pick<LibraryFeedFollow, 'cuid' | 'userId'>>> }
        ) }>> } } };

export type LibraryRiskDetailQueryVariables = Exact<{
  riskId: Scalars['ID']['input'];
}>;


export type LibraryRiskDetailQuery = { risk?: Maybe<(
    Pick<LibraryRisk, 'id' | 'autoid' | 'name' | 'isFollowed' | 'updatedAt' | 'type' | 'industry' | 'description'>
    & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'name' | 'description'>>>, sources?: Maybe<Array<Pick<LibrarySource, 'sourceName' | 'url'>>> }
  )> };

export type LibraryRisksHomepageQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['JSON']['input']>;
  filterBy?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type LibraryRisksHomepageQuery = { library: { risks: { edges?: Maybe<Array<{ node: (
          Pick<LibraryRisk, 'id' | 'autoid' | 'riskId' | 'isFollowed' | 'name' | 'description' | 'updatedAt' | 'type' | 'industry'>
          & { mitigations?: Maybe<Array<Pick<LibraryMitigation, 'id'>>> }
        ) }>> } } };

export type LibraryFilterOptionsQueryVariables = Exact<{
  type?: InputMaybe<FilterableTargetType>;
  filters: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type LibraryFilterOptionsQuery = { library: { filterables: Array<Pick<FilterableDefinitionType, 'name' | 'counts' | 'options'>> } };

export type LibraryFollowMutationVariables = Exact<{
  followableId: Scalars['Int']['input'];
  followableType: LibraryCardType;
}>;


export type LibraryFollowMutation = { followLibraryItem: Pick<IdResponseType, 'success'> };

export type LibraryUnfollowMutationVariables = Exact<{
  followableId: Scalars['Int']['input'];
  followableType: LibraryCardType;
}>;


export type LibraryUnfollowMutation = { unfollowLibraryItem: Pick<ResponseType, 'success'> };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { resetPassword: Pick<ResponseType, 'success' | 'error'> };

export type GetUserGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserGroupsQuery = { userGroups: Array<Pick<AppUserGroup, 'externalId' | 'name' | 'explainerShort' | 'explainerLong'>> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { user: (
    Pick<AppUser, 'firstName' | 'lastName' | 'title' | 'profileImageURL'>
    & { groups: Array<Pick<AppUserGroup, 'externalId' | 'name'>> }
  ) };

export type RemoveProfilePictureMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveProfilePictureMutation = { removeProfilePicture: Pick<SuccessResponseType, 'success' | 'error'> };

export type FeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureFlagsQuery = Pick<Query, 'featureFlags'>;

export const LibraryRequirementSectionTabFragmentDoc = `
    fragment LibraryRequirementSectionTab on LibraryRequirementSection {
  requirementSectionId
  name
  description
  policyReference
  roles {
    autoid
    description
    name
    reference
  }
  requirements {
    autoid
    requirementId
    name
    description
    policyReference
  }
}
    `;
export const LibraryPolicyCoverageTabFragmentDoc = `
    fragment LibraryPolicyCoverageTab on LibraryRegulatedSystemCategory {
  id
  name
  description
  policyReference
  regulatedSystems {
    id
    regulatedSystemId
    name
    description
    policyReference
  }
}
    `;
export const GovernSpaceNodeFragmentDoc = `
    fragment GovernSpaceNode on GovernSpace {
  name
  isDefault
  description
  externalId
  icon
  membersCount
  systemsCount
  address
  functions {
    label
    value
  }
  regions {
    label
    value
  }
  countries {
    label
    value
  }
  managers {
    externalId
    firstName
    lastName
    email
    color
    profileImageURL
    txtIdentifier
  }
  updatedAt
  createdAt
  creator {
    fullName
    txtIdentifier
  }
}
    `;
export const OrganisationTableItemFragmentDoc = `
    fragment OrganisationTableItem on AppOrganization {
  externalId
  title: name
  users: usersCount
  systems: systemsCount
  access
  license
  registeredDate: createdAt
  status
  logo {
    file_url
  }
}
    `;
export const TemporaryInputTypeFragmentDoc = `
    fragment temporaryInputType on InputOptionResponseType {
  name
  data {
    text: label
    value: value
    id: compoundId
    explainer
    targets
  }
}
    `;
export const GovernAttachmentNodeFragmentDoc = `
    fragment GovernAttachmentNode on GovernAttachment {
  externalId
  name
  description
  url
  location
  type {
    externalId
    label
  }
  format
  owner {
    firstName
    lastName
    email
  }
  updatedAt
}
    `;
export const GovernSystemRiskTableRowFragmentDoc = `
    fragment GovernSystemRiskTableRow on GovernSystemRisk {
  externalId
  title
  description
  consequences
  systemId
  residualRiskEvaluation
  inherentRiskEvaluation
  marginalRiskLevel
  treatmentPlan
  accept
  avoid
  updatedAt
  mitigations {
    externalId
    name
    description
    status
  }
  owner {
    externalId
    firstName
    lastName
    email
    active
  }
  types
  sources
  libraryRisk {
    externalId
  }
}
    `;
export const FeedItemFragmentDoc = `
    fragment FeedItem on LibraryFeedItem {
  source
  sourceName
  sourceAutoid
  autoid
  name
  date
  type
  isFollowable
  urlId
}
    `;
export const TrendingTopicFragmentDoc = `
    fragment TrendingTopic on LibraryTrendingTopicItem {
  date
  type
  name
  extra
  autoid
  urlId
}
    `;
export const MmmmFragmentDoc = `
    fragment Mmmm on LibraryModel {
  id
  autoid
  name
  productPage
  updatedAt
  logoUrl
  isFollowed
  airtableUpdatedAt
  type
  modality
  region
  industry
  sourceCode
  languages
  architecture
  parameters
  owner {
    name
    address
    email
  }
  versions {
    name
    endpoints
  }
  overview
  tokens
  trainingCluster
  trainingTime
  trainingCost
  carbonEmitted
  trainingDataOverview
  restrictions {
    restrictionId
    name
    notes
  }
  safetyFeatures {
    name
    notes
    featureDetails
  }
  saidotRisks {
    id
    autoid
    name
    description
    updatedAt
    isFollowed
    mitigations {
      name
    }
  }
  providerRisks {
    id
    autoid
    name
    description
    updatedAt
    isFollowed
    mitigations {
      name
    }
  }
  termsOfService
  contentPolicy
  privacyPolicy
  standardsAndCertificates
  releaseNotes
  sources {
    sourceName
    url
  }
  datasets {
    datasetId
    name
    description
  }
  tasks {
    autoid
    name
  }
  hasEvaluationResultsByTaskFor
}
    `;
export const LibraryPolicyRoleFragmentDoc = `
    fragment LibraryPolicyRole on LibraryRole {
  description
  name
  reference
}
    `;
export const GetUploadUrlDocument = `
    query GetUploadURL($name: String!, $organizationId: ID, $systemId: ID, $type: String!) {
  getUploadURL(
    name: $name
    systemId: $systemId
    organizationId: $organizationId
    type: $type
  )
}
    `;
export const useGetUploadUrlQuery = <
      TData = GetUploadUrlQuery,
      TError = unknown
    >(
      variables: GetUploadUrlQueryVariables,
      options?: UseQueryOptions<GetUploadUrlQuery, TError, TData>
    ) =>
    useQuery<GetUploadUrlQuery, TError, TData>(
      ['GetUploadURL', variables],
      fetcher<GetUploadUrlQuery, GetUploadUrlQueryVariables>(GetUploadUrlDocument, variables),
      options
    );
useGetUploadUrlQuery.document = GetUploadUrlDocument;


useGetUploadUrlQuery.getKey = (variables: GetUploadUrlQueryVariables) => ['GetUploadURL', variables];
;

export const useInfiniteGetUploadUrlQuery = <
      TData = GetUploadUrlQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetUploadUrlQueryVariables,
      variables: GetUploadUrlQueryVariables,
      options?: UseInfiniteQueryOptions<GetUploadUrlQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetUploadUrlQuery, TError, TData>(
      ['GetUploadURL.infinite', variables],
      (metaData) => fetcher<GetUploadUrlQuery, GetUploadUrlQueryVariables>(GetUploadUrlDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetUploadUrlQuery.getKey = (variables: GetUploadUrlQueryVariables) => ['GetUploadURL.infinite', variables];
;

export const ModifyRiskDocument = `
    mutation ModifyRisk($addSystemRiskInput: AddSystemRiskInput!) {
  modifyRisk(addSystemRiskInput: $addSystemRiskInput) {
    success
    error
  }
}
    `;
export const useModifyRiskMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ModifyRiskMutation, TError, ModifyRiskMutationVariables, TContext>) =>
    useMutation<ModifyRiskMutation, TError, ModifyRiskMutationVariables, TContext>(
      ['ModifyRisk'],
      (variables?: ModifyRiskMutationVariables) => fetcher<ModifyRiskMutation, ModifyRiskMutationVariables>(ModifyRiskDocument, variables)(),
      options
    );
useModifyRiskMutation.getKey = () => ['ModifyRisk'];

export const RisksTypesDocument = `
    query RisksTypes {
  riskTypes
}
    `;
export const useRisksTypesQuery = <
      TData = RisksTypesQuery,
      TError = unknown
    >(
      variables?: RisksTypesQueryVariables,
      options?: UseQueryOptions<RisksTypesQuery, TError, TData>
    ) =>
    useQuery<RisksTypesQuery, TError, TData>(
      variables === undefined ? ['RisksTypes'] : ['RisksTypes', variables],
      fetcher<RisksTypesQuery, RisksTypesQueryVariables>(RisksTypesDocument, variables),
      options
    );
useRisksTypesQuery.document = RisksTypesDocument;


useRisksTypesQuery.getKey = (variables?: RisksTypesQueryVariables) => variables === undefined ? ['RisksTypes'] : ['RisksTypes', variables];
;

export const useInfiniteRisksTypesQuery = <
      TData = RisksTypesQuery,
      TError = unknown
    >(
      pageParamKey: keyof RisksTypesQueryVariables,
      variables?: RisksTypesQueryVariables,
      options?: UseInfiniteQueryOptions<RisksTypesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<RisksTypesQuery, TError, TData>(
      variables === undefined ? ['RisksTypes.infinite'] : ['RisksTypes.infinite', variables],
      (metaData) => fetcher<RisksTypesQuery, RisksTypesQueryVariables>(RisksTypesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteRisksTypesQuery.getKey = (variables?: RisksTypesQueryVariables) => variables === undefined ? ['RisksTypes.infinite'] : ['RisksTypes.infinite', variables];
;

export const SystemUsersDocument = `
    query SystemUsers($systemId: ID!) {
  systemUsers(systemId: $systemId) {
    id: externalId
    firstName
    lastName
    email
    externalId
    fullName
    txtIdentifier
  }
}
    `;
export const useSystemUsersQuery = <
      TData = SystemUsersQuery,
      TError = unknown
    >(
      variables: SystemUsersQueryVariables,
      options?: UseQueryOptions<SystemUsersQuery, TError, TData>
    ) =>
    useQuery<SystemUsersQuery, TError, TData>(
      ['SystemUsers', variables],
      fetcher<SystemUsersQuery, SystemUsersQueryVariables>(SystemUsersDocument, variables),
      options
    );
useSystemUsersQuery.document = SystemUsersDocument;


useSystemUsersQuery.getKey = (variables: SystemUsersQueryVariables) => ['SystemUsers', variables];
;

export const useInfiniteSystemUsersQuery = <
      TData = SystemUsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof SystemUsersQueryVariables,
      variables: SystemUsersQueryVariables,
      options?: UseInfiniteQueryOptions<SystemUsersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SystemUsersQuery, TError, TData>(
      ['SystemUsers.infinite', variables],
      (metaData) => fetcher<SystemUsersQuery, SystemUsersQueryVariables>(SystemUsersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSystemUsersQuery.getKey = (variables: SystemUsersQueryVariables) => ['SystemUsers.infinite', variables];
;

export const GetSystemSuppliersDocument = `
    query GetSystemSuppliers($systemId: String!) {
  systemSuppliers(systemId: $systemId) {
    externalId
    governSystemId
    provider
    type {
      externalId
      label
    }
    documents {
      attachment {
        externalId
        name
        format
        url
        location
        owner {
          firstName
          lastName
        }
        createdAt
        type {
          externalId
          label
        }
      }
    }
  }
}
    `;
export const useGetSystemSuppliersQuery = <
      TData = GetSystemSuppliersQuery,
      TError = unknown
    >(
      variables: GetSystemSuppliersQueryVariables,
      options?: UseQueryOptions<GetSystemSuppliersQuery, TError, TData>
    ) =>
    useQuery<GetSystemSuppliersQuery, TError, TData>(
      ['GetSystemSuppliers', variables],
      fetcher<GetSystemSuppliersQuery, GetSystemSuppliersQueryVariables>(GetSystemSuppliersDocument, variables),
      options
    );
useGetSystemSuppliersQuery.document = GetSystemSuppliersDocument;


useGetSystemSuppliersQuery.getKey = (variables: GetSystemSuppliersQueryVariables) => ['GetSystemSuppliers', variables];
;

export const useInfiniteGetSystemSuppliersQuery = <
      TData = GetSystemSuppliersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSystemSuppliersQueryVariables,
      variables: GetSystemSuppliersQueryVariables,
      options?: UseInfiniteQueryOptions<GetSystemSuppliersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSystemSuppliersQuery, TError, TData>(
      ['GetSystemSuppliers.infinite', variables],
      (metaData) => fetcher<GetSystemSuppliersQuery, GetSystemSuppliersQueryVariables>(GetSystemSuppliersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSystemSuppliersQuery.getKey = (variables: GetSystemSuppliersQueryVariables) => ['GetSystemSuppliers.infinite', variables];
;

export const GetSystemSupplierDocument = `
    query GetSystemSupplier($externalId: String!) {
  systemSupplier(externalId: $externalId) {
    externalId
    provider
    governSystemId
    type {
      externalId
      label
    }
    documents {
      attachment {
        externalId
        name
        format
        type {
          externalId
          label
        }
      }
    }
  }
}
    `;
export const useGetSystemSupplierQuery = <
      TData = GetSystemSupplierQuery,
      TError = unknown
    >(
      variables: GetSystemSupplierQueryVariables,
      options?: UseQueryOptions<GetSystemSupplierQuery, TError, TData>
    ) =>
    useQuery<GetSystemSupplierQuery, TError, TData>(
      ['GetSystemSupplier', variables],
      fetcher<GetSystemSupplierQuery, GetSystemSupplierQueryVariables>(GetSystemSupplierDocument, variables),
      options
    );
useGetSystemSupplierQuery.document = GetSystemSupplierDocument;


useGetSystemSupplierQuery.getKey = (variables: GetSystemSupplierQueryVariables) => ['GetSystemSupplier', variables];
;

export const useInfiniteGetSystemSupplierQuery = <
      TData = GetSystemSupplierQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSystemSupplierQueryVariables,
      variables: GetSystemSupplierQueryVariables,
      options?: UseInfiniteQueryOptions<GetSystemSupplierQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSystemSupplierQuery, TError, TData>(
      ['GetSystemSupplier.infinite', variables],
      (metaData) => fetcher<GetSystemSupplierQuery, GetSystemSupplierQueryVariables>(GetSystemSupplierDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSystemSupplierQuery.getKey = (variables: GetSystemSupplierQueryVariables) => ['GetSystemSupplier.infinite', variables];
;

export const AddGovernSystemSupplierDocument = `
    mutation AddGovernSystemSupplier($addSystemSupplierInput: AddSystemSupplierInput!) {
  addSystemSupplier(addSystemSupplierInput: $addSystemSupplierInput) {
    success
    error
    data {
      externalId
    }
  }
}
    `;
export const useAddGovernSystemSupplierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddGovernSystemSupplierMutation, TError, AddGovernSystemSupplierMutationVariables, TContext>) =>
    useMutation<AddGovernSystemSupplierMutation, TError, AddGovernSystemSupplierMutationVariables, TContext>(
      ['AddGovernSystemSupplier'],
      (variables?: AddGovernSystemSupplierMutationVariables) => fetcher<AddGovernSystemSupplierMutation, AddGovernSystemSupplierMutationVariables>(AddGovernSystemSupplierDocument, variables)(),
      options
    );
useAddGovernSystemSupplierMutation.getKey = () => ['AddGovernSystemSupplier'];

export const UpdateGovernSystemSupplierDocument = `
    mutation UpdateGovernSystemSupplier($updateSystemSupplierInput: UpdateSystemSupplierInput!) {
  updateSystemSupplier(updateSystemSupplierInput: $updateSystemSupplierInput) {
    success
    error
    data {
      externalId
    }
  }
}
    `;
export const useUpdateGovernSystemSupplierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateGovernSystemSupplierMutation, TError, UpdateGovernSystemSupplierMutationVariables, TContext>) =>
    useMutation<UpdateGovernSystemSupplierMutation, TError, UpdateGovernSystemSupplierMutationVariables, TContext>(
      ['UpdateGovernSystemSupplier'],
      (variables?: UpdateGovernSystemSupplierMutationVariables) => fetcher<UpdateGovernSystemSupplierMutation, UpdateGovernSystemSupplierMutationVariables>(UpdateGovernSystemSupplierDocument, variables)(),
      options
    );
useUpdateGovernSystemSupplierMutation.getKey = () => ['UpdateGovernSystemSupplier'];

export const DeleteGovernSystemSupplierDocument = `
    mutation DeleteGovernSystemSupplier($externalId: String!) {
  removeSystemSupplier(supplierId: $externalId) {
    success
    error
    data {
      externalId
    }
  }
}
    `;
export const useDeleteGovernSystemSupplierMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGovernSystemSupplierMutation, TError, DeleteGovernSystemSupplierMutationVariables, TContext>) =>
    useMutation<DeleteGovernSystemSupplierMutation, TError, DeleteGovernSystemSupplierMutationVariables, TContext>(
      ['DeleteGovernSystemSupplier'],
      (variables?: DeleteGovernSystemSupplierMutationVariables) => fetcher<DeleteGovernSystemSupplierMutation, DeleteGovernSystemSupplierMutationVariables>(DeleteGovernSystemSupplierDocument, variables)(),
      options
    );
useDeleteGovernSystemSupplierMutation.getKey = () => ['DeleteGovernSystemSupplier'];

export const RemoveSystemSupplierAttachmentDocument = `
    mutation RemoveSystemSupplierAttachment($supplierId: String!, $attachmentId: String!) {
  removeSystemSupplierAttachment(
    supplierId: $supplierId
    attachmentId: $attachmentId
  ) {
    success
    error
  }
}
    `;
export const useRemoveSystemSupplierAttachmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveSystemSupplierAttachmentMutation, TError, RemoveSystemSupplierAttachmentMutationVariables, TContext>) =>
    useMutation<RemoveSystemSupplierAttachmentMutation, TError, RemoveSystemSupplierAttachmentMutationVariables, TContext>(
      ['RemoveSystemSupplierAttachment'],
      (variables?: RemoveSystemSupplierAttachmentMutationVariables) => fetcher<RemoveSystemSupplierAttachmentMutation, RemoveSystemSupplierAttachmentMutationVariables>(RemoveSystemSupplierAttachmentDocument, variables)(),
      options
    );
useRemoveSystemSupplierAttachmentMutation.getKey = () => ['RemoveSystemSupplierAttachment'];

export const LinkModelToSystemDocument = `
    mutation LinkModelToSystem($systemId: String!, $modelId: String!) {
  linkModel(systemId: $systemId, modelId: $modelId) {
    success
    error
  }
}
    `;
export const useLinkModelToSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LinkModelToSystemMutation, TError, LinkModelToSystemMutationVariables, TContext>) =>
    useMutation<LinkModelToSystemMutation, TError, LinkModelToSystemMutationVariables, TContext>(
      ['LinkModelToSystem'],
      (variables?: LinkModelToSystemMutationVariables) => fetcher<LinkModelToSystemMutation, LinkModelToSystemMutationVariables>(LinkModelToSystemDocument, variables)(),
      options
    );
useLinkModelToSystemMutation.getKey = () => ['LinkModelToSystem'];

export const UnlinkModelToSystemDocument = `
    mutation UnlinkModelToSystem($systemId: String!, $modelId: String!) {
  unlinkModel(systemId: $systemId, modelId: $modelId) {
    success
    error
  }
}
    `;
export const useUnlinkModelToSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UnlinkModelToSystemMutation, TError, UnlinkModelToSystemMutationVariables, TContext>) =>
    useMutation<UnlinkModelToSystemMutation, TError, UnlinkModelToSystemMutationVariables, TContext>(
      ['UnlinkModelToSystem'],
      (variables?: UnlinkModelToSystemMutationVariables) => fetcher<UnlinkModelToSystemMutation, UnlinkModelToSystemMutationVariables>(UnlinkModelToSystemDocument, variables)(),
      options
    );
useUnlinkModelToSystemMutation.getKey = () => ['UnlinkModelToSystem'];

export const LinkSystemToSystemDocument = `
    mutation LinkSystemToSystem($sourceSystemId: String!, $targetSystemId: String!) {
  linkSystem(sourceSystemId: $sourceSystemId, targetSystemId: $targetSystemId) {
    success
    error
  }
}
    `;
export const useLinkSystemToSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LinkSystemToSystemMutation, TError, LinkSystemToSystemMutationVariables, TContext>) =>
    useMutation<LinkSystemToSystemMutation, TError, LinkSystemToSystemMutationVariables, TContext>(
      ['LinkSystemToSystem'],
      (variables?: LinkSystemToSystemMutationVariables) => fetcher<LinkSystemToSystemMutation, LinkSystemToSystemMutationVariables>(LinkSystemToSystemDocument, variables)(),
      options
    );
useLinkSystemToSystemMutation.getKey = () => ['LinkSystemToSystem'];

export const UnlinkSystemToSystemDocument = `
    mutation UnlinkSystemToSystem($sourceSystemId: String!, $targetSystemId: String!) {
  unlinkSystem(sourceSystemId: $sourceSystemId, targetSystemId: $targetSystemId) {
    success
    error
  }
}
    `;
export const useUnlinkSystemToSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UnlinkSystemToSystemMutation, TError, UnlinkSystemToSystemMutationVariables, TContext>) =>
    useMutation<UnlinkSystemToSystemMutation, TError, UnlinkSystemToSystemMutationVariables, TContext>(
      ['UnlinkSystemToSystem'],
      (variables?: UnlinkSystemToSystemMutationVariables) => fetcher<UnlinkSystemToSystemMutation, UnlinkSystemToSystemMutationVariables>(UnlinkSystemToSystemDocument, variables)(),
      options
    );
useUnlinkSystemToSystemMutation.getKey = () => ['UnlinkSystemToSystem'];

export const LatestChangesWidgetDocument = `
    query LatestChangesWidget($systemId: String!) {
  governLatestChanges(systemId: $systemId, limit: 5) {
    edges {
      node {
        externalId
        change
        message
        event
        fullname
        time
        message
      }
    }
  }
}
    `;
export const useLatestChangesWidgetQuery = <
      TData = LatestChangesWidgetQuery,
      TError = unknown
    >(
      variables: LatestChangesWidgetQueryVariables,
      options?: UseQueryOptions<LatestChangesWidgetQuery, TError, TData>
    ) =>
    useQuery<LatestChangesWidgetQuery, TError, TData>(
      ['LatestChangesWidget', variables],
      fetcher<LatestChangesWidgetQuery, LatestChangesWidgetQueryVariables>(LatestChangesWidgetDocument, variables),
      options
    );
useLatestChangesWidgetQuery.document = LatestChangesWidgetDocument;


useLatestChangesWidgetQuery.getKey = (variables: LatestChangesWidgetQueryVariables) => ['LatestChangesWidget', variables];
;

export const useInfiniteLatestChangesWidgetQuery = <
      TData = LatestChangesWidgetQuery,
      TError = unknown
    >(
      pageParamKey: keyof LatestChangesWidgetQueryVariables,
      variables: LatestChangesWidgetQueryVariables,
      options?: UseInfiniteQueryOptions<LatestChangesWidgetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LatestChangesWidgetQuery, TError, TData>(
      ['LatestChangesWidget.infinite', variables],
      (metaData) => fetcher<LatestChangesWidgetQuery, LatestChangesWidgetQueryVariables>(LatestChangesWidgetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLatestChangesWidgetQuery.getKey = (variables: LatestChangesWidgetQueryVariables) => ['LatestChangesWidget.infinite', variables];
;

export const LifecycleWidgetDocument = `
    query LifecycleWidget($systemId: String!) {
  lifecycleStages
  governLifecycle(systemId: $systemId) {
    version
    currentLifecycleStage
    Initiation {
      date
      isApproved
    }
    Approved_for_design_and_development {
      date
      isApproved
    }
    Design_and_development {
      date
      isApproved
    }
    Verification_and_validation {
      date
      isApproved
    }
    Approved_for_deployment {
      date
      isApproved
    }
    Deployment {
      date
      isApproved
    }
    Operation_and_monitoring {
      date
      isApproved
    }
    Re_evaluation {
      date
      isApproved
    }
    Approved_for_retirement {
      date
      isApproved
    }
    Retirement {
      date
      isApproved
    }
  }
}
    `;
export const useLifecycleWidgetQuery = <
      TData = LifecycleWidgetQuery,
      TError = unknown
    >(
      variables: LifecycleWidgetQueryVariables,
      options?: UseQueryOptions<LifecycleWidgetQuery, TError, TData>
    ) =>
    useQuery<LifecycleWidgetQuery, TError, TData>(
      ['LifecycleWidget', variables],
      fetcher<LifecycleWidgetQuery, LifecycleWidgetQueryVariables>(LifecycleWidgetDocument, variables),
      options
    );
useLifecycleWidgetQuery.document = LifecycleWidgetDocument;


useLifecycleWidgetQuery.getKey = (variables: LifecycleWidgetQueryVariables) => ['LifecycleWidget', variables];
;

export const useInfiniteLifecycleWidgetQuery = <
      TData = LifecycleWidgetQuery,
      TError = unknown
    >(
      pageParamKey: keyof LifecycleWidgetQueryVariables,
      variables: LifecycleWidgetQueryVariables,
      options?: UseInfiniteQueryOptions<LifecycleWidgetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LifecycleWidgetQuery, TError, TData>(
      ['LifecycleWidget.infinite', variables],
      (metaData) => fetcher<LifecycleWidgetQuery, LifecycleWidgetQueryVariables>(LifecycleWidgetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLifecycleWidgetQuery.getKey = (variables: LifecycleWidgetQueryVariables) => ['LifecycleWidget.infinite', variables];
;

export const UpdateLifecycleStageDocument = `
    mutation UpdateLifecycleStage($governSystemId: String!, $version: Int!, $input: LifecycleInputType!) {
  updateLifecycleStage(
    governSystemId: $governSystemId
    version: $version
    input: $input
  ) {
    data
    error
    success
  }
}
    `;
export const useUpdateLifecycleStageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateLifecycleStageMutation, TError, UpdateLifecycleStageMutationVariables, TContext>) =>
    useMutation<UpdateLifecycleStageMutation, TError, UpdateLifecycleStageMutationVariables, TContext>(
      ['UpdateLifecycleStage'],
      (variables?: UpdateLifecycleStageMutationVariables) => fetcher<UpdateLifecycleStageMutation, UpdateLifecycleStageMutationVariables>(UpdateLifecycleStageDocument, variables)(),
      options
    );
useUpdateLifecycleStageMutation.getKey = () => ['UpdateLifecycleStage'];

export const RiskLibraryWidgetDocument = `
    query RiskLibraryWidget($orderBy: JSON, $filters: JSON, $query: String, $limit: Int, $matches: JSON, $offset: Int) {
  risks(
    orderBy: $orderBy
    filters: $filters
    query: $query
    limit: $limit
    matches: $matches
    offset: $offset
  ) {
    pageInfo {
      totalCount
      page
      perPage
      recommendedCount
    }
    nodes {
      riskId
      externalId
      name
      description
      industry
      category
      riskFunction
      type
      mitigations {
        cuid
      }
      saidotRisks {
        model {
          name
        }
      }
      providerRisk {
        model {
          name
        }
      }
    }
  }
}
    `;
export const useRiskLibraryWidgetQuery = <
      TData = RiskLibraryWidgetQuery,
      TError = unknown
    >(
      variables?: RiskLibraryWidgetQueryVariables,
      options?: UseQueryOptions<RiskLibraryWidgetQuery, TError, TData>
    ) =>
    useQuery<RiskLibraryWidgetQuery, TError, TData>(
      variables === undefined ? ['RiskLibraryWidget'] : ['RiskLibraryWidget', variables],
      fetcher<RiskLibraryWidgetQuery, RiskLibraryWidgetQueryVariables>(RiskLibraryWidgetDocument, variables),
      options
    );
useRiskLibraryWidgetQuery.document = RiskLibraryWidgetDocument;


useRiskLibraryWidgetQuery.getKey = (variables?: RiskLibraryWidgetQueryVariables) => variables === undefined ? ['RiskLibraryWidget'] : ['RiskLibraryWidget', variables];
;

export const useInfiniteRiskLibraryWidgetQuery = <
      TData = RiskLibraryWidgetQuery,
      TError = unknown
    >(
      pageParamKey: keyof RiskLibraryWidgetQueryVariables,
      variables?: RiskLibraryWidgetQueryVariables,
      options?: UseInfiniteQueryOptions<RiskLibraryWidgetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<RiskLibraryWidgetQuery, TError, TData>(
      variables === undefined ? ['RiskLibraryWidget.infinite'] : ['RiskLibraryWidget.infinite', variables],
      (metaData) => fetcher<RiskLibraryWidgetQuery, RiskLibraryWidgetQueryVariables>(RiskLibraryWidgetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteRiskLibraryWidgetQuery.getKey = (variables?: RiskLibraryWidgetQueryVariables) => variables === undefined ? ['RiskLibraryWidget.infinite'] : ['RiskLibraryWidget.infinite', variables];
;

export const AddSystemTeamMemberDocument = `
    mutation AddSystemTeamMember($addTeamMemberInput: AddTeamMemberInput!) {
  addSystemTeamMember(addTeamMemberInput: $addTeamMemberInput) {
    success
    error
    data {
      systemId
    }
  }
}
    `;
export const useAddSystemTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddSystemTeamMemberMutation, TError, AddSystemTeamMemberMutationVariables, TContext>) =>
    useMutation<AddSystemTeamMemberMutation, TError, AddSystemTeamMemberMutationVariables, TContext>(
      ['AddSystemTeamMember'],
      (variables?: AddSystemTeamMemberMutationVariables) => fetcher<AddSystemTeamMemberMutation, AddSystemTeamMemberMutationVariables>(AddSystemTeamMemberDocument, variables)(),
      options
    );
useAddSystemTeamMemberMutation.getKey = () => ['AddSystemTeamMember'];

export const RemoveTeamMemberDocument = `
    mutation RemoveTeamMember($systemId: String!, $email: String!) {
  removeSystemTeamMember(systemId: $systemId, email: $email) {
    success
    error
    data
  }
}
    `;
export const useRemoveTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>) =>
    useMutation<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>(
      ['RemoveTeamMember'],
      (variables?: RemoveTeamMemberMutationVariables) => fetcher<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, variables)(),
      options
    );
useRemoveTeamMemberMutation.getKey = () => ['RemoveTeamMember'];

export const UpdateTeamMemberDocument = `
    mutation UpdateTeamMember($updateSystemTeamMemberRoleInput: AddTeamMemberInput!) {
  updateSystemTeamMemberRole(
    updateTeamMemberRole: $updateSystemTeamMemberRoleInput
  ) {
    success
    error
    data
  }
}
    `;
export const useUpdateTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTeamMemberMutation, TError, UpdateTeamMemberMutationVariables, TContext>) =>
    useMutation<UpdateTeamMemberMutation, TError, UpdateTeamMemberMutationVariables, TContext>(
      ['UpdateTeamMember'],
      (variables?: UpdateTeamMemberMutationVariables) => fetcher<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, variables)(),
      options
    );
useUpdateTeamMemberMutation.getKey = () => ['UpdateTeamMember'];

export const SystemTeamDocument = `
    query SystemTeam($systemId: String!) {
  team(systemId: $systemId) {
    member {
      email
      firstName
      lastName
    }
    role
  }
}
    `;
export const useSystemTeamQuery = <
      TData = SystemTeamQuery,
      TError = unknown
    >(
      variables: SystemTeamQueryVariables,
      options?: UseQueryOptions<SystemTeamQuery, TError, TData>
    ) =>
    useQuery<SystemTeamQuery, TError, TData>(
      ['SystemTeam', variables],
      fetcher<SystemTeamQuery, SystemTeamQueryVariables>(SystemTeamDocument, variables),
      options
    );
useSystemTeamQuery.document = SystemTeamDocument;


useSystemTeamQuery.getKey = (variables: SystemTeamQueryVariables) => ['SystemTeam', variables];
;

export const useInfiniteSystemTeamQuery = <
      TData = SystemTeamQuery,
      TError = unknown
    >(
      pageParamKey: keyof SystemTeamQueryVariables,
      variables: SystemTeamQueryVariables,
      options?: UseInfiniteQueryOptions<SystemTeamQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SystemTeamQuery, TError, TData>(
      ['SystemTeam.infinite', variables],
      (metaData) => fetcher<SystemTeamQuery, SystemTeamQueryVariables>(SystemTeamDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSystemTeamQuery.getKey = (variables: SystemTeamQueryVariables) => ['SystemTeam.infinite', variables];
;

export const LegacyLibraryModelEvaluationsSectionDocument = `
    query LegacyLibraryModelEvaluationsSection($modelId: ID!, $evaluationType: EvaluationType!, $orderBy: [EvaluationResultsByTaskOrderByType!]) {
  model(id: $modelId) {
    evaluationResultsByTaskFor(evaluationType: $evaluationType, orderBy: $orderBy) {
      versionNames
      tasks {
        externalId
        name
        taskParameters
        evaluationResults {
          evaluation {
            id
            externalId
            autoid
            name
            type
            isFollowed
          }
          scoreTypes
        }
      }
    }
  }
}
    `;
export const useLegacyLibraryModelEvaluationsSectionQuery = <
      TData = LegacyLibraryModelEvaluationsSectionQuery,
      TError = unknown
    >(
      variables: LegacyLibraryModelEvaluationsSectionQueryVariables,
      options?: UseQueryOptions<LegacyLibraryModelEvaluationsSectionQuery, TError, TData>
    ) =>
    useQuery<LegacyLibraryModelEvaluationsSectionQuery, TError, TData>(
      ['LegacyLibraryModelEvaluationsSection', variables],
      fetcher<LegacyLibraryModelEvaluationsSectionQuery, LegacyLibraryModelEvaluationsSectionQueryVariables>(LegacyLibraryModelEvaluationsSectionDocument, variables),
      options
    );
useLegacyLibraryModelEvaluationsSectionQuery.document = LegacyLibraryModelEvaluationsSectionDocument;


useLegacyLibraryModelEvaluationsSectionQuery.getKey = (variables: LegacyLibraryModelEvaluationsSectionQueryVariables) => ['LegacyLibraryModelEvaluationsSection', variables];
;

export const useInfiniteLegacyLibraryModelEvaluationsSectionQuery = <
      TData = LegacyLibraryModelEvaluationsSectionQuery,
      TError = unknown
    >(
      pageParamKey: keyof LegacyLibraryModelEvaluationsSectionQueryVariables,
      variables: LegacyLibraryModelEvaluationsSectionQueryVariables,
      options?: UseInfiniteQueryOptions<LegacyLibraryModelEvaluationsSectionQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LegacyLibraryModelEvaluationsSectionQuery, TError, TData>(
      ['LegacyLibraryModelEvaluationsSection.infinite', variables],
      (metaData) => fetcher<LegacyLibraryModelEvaluationsSectionQuery, LegacyLibraryModelEvaluationsSectionQueryVariables>(LegacyLibraryModelEvaluationsSectionDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLegacyLibraryModelEvaluationsSectionQuery.getKey = (variables: LegacyLibraryModelEvaluationsSectionQueryVariables) => ['LegacyLibraryModelEvaluationsSection.infinite', variables];
;

export const LibraryModelEvaluationsSectionDocument = `
    query LibraryModelEvaluationsSection($modelId: ID!, $translationKeys: [String!]!) {
  model(id: $modelId) {
    aggregatedByTaskEvaluationResults {
      shortenedDatasetParameters {
        name
        shortenedVersion
      }
      metricExplanations {
        label
        explainer
      }
      resultsAggregatedByTask {
        task {
          name
          taskTypes
          taskParameterLabel
        }
        evaluationResults {
          evaluation {
            id
            autoid
            name
            isFollowed
            datasetParameterLabel
          }
          scores {
            model {
              name
            }
            modelVersion {
              name
            }
            score
            scoreUnit
            scoreType
            taskParameters
            datasetParameter
          }
        }
      }
    }
  }
  translations(translationKeys: $translationKeys)
}
    `;
export const useLibraryModelEvaluationsSectionQuery = <
      TData = LibraryModelEvaluationsSectionQuery,
      TError = unknown
    >(
      variables: LibraryModelEvaluationsSectionQueryVariables,
      options?: UseQueryOptions<LibraryModelEvaluationsSectionQuery, TError, TData>
    ) =>
    useQuery<LibraryModelEvaluationsSectionQuery, TError, TData>(
      ['LibraryModelEvaluationsSection', variables],
      fetcher<LibraryModelEvaluationsSectionQuery, LibraryModelEvaluationsSectionQueryVariables>(LibraryModelEvaluationsSectionDocument, variables),
      options
    );
useLibraryModelEvaluationsSectionQuery.document = LibraryModelEvaluationsSectionDocument;


useLibraryModelEvaluationsSectionQuery.getKey = (variables: LibraryModelEvaluationsSectionQueryVariables) => ['LibraryModelEvaluationsSection', variables];
;

export const useInfiniteLibraryModelEvaluationsSectionQuery = <
      TData = LibraryModelEvaluationsSectionQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryModelEvaluationsSectionQueryVariables,
      variables: LibraryModelEvaluationsSectionQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryModelEvaluationsSectionQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryModelEvaluationsSectionQuery, TError, TData>(
      ['LibraryModelEvaluationsSection.infinite', variables],
      (metaData) => fetcher<LibraryModelEvaluationsSectionQuery, LibraryModelEvaluationsSectionQueryVariables>(LibraryModelEvaluationsSectionDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryModelEvaluationsSectionQuery.getKey = (variables: LibraryModelEvaluationsSectionQueryVariables) => ['LibraryModelEvaluationsSection.infinite', variables];
;

export const LibraryPolicyRequirementSectionsDocument = `
    query LibraryPolicyRequirementSections($policyId: ID!) {
  policy(id: $policyId) {
    requirementSections {
      ...LibraryRequirementSectionTab
    }
  }
}
    ${LibraryRequirementSectionTabFragmentDoc}`;
export const useLibraryPolicyRequirementSectionsQuery = <
      TData = LibraryPolicyRequirementSectionsQuery,
      TError = unknown
    >(
      variables: LibraryPolicyRequirementSectionsQueryVariables,
      options?: UseQueryOptions<LibraryPolicyRequirementSectionsQuery, TError, TData>
    ) =>
    useQuery<LibraryPolicyRequirementSectionsQuery, TError, TData>(
      ['LibraryPolicyRequirementSections', variables],
      fetcher<LibraryPolicyRequirementSectionsQuery, LibraryPolicyRequirementSectionsQueryVariables>(LibraryPolicyRequirementSectionsDocument, variables),
      options
    );
useLibraryPolicyRequirementSectionsQuery.document = LibraryPolicyRequirementSectionsDocument;


useLibraryPolicyRequirementSectionsQuery.getKey = (variables: LibraryPolicyRequirementSectionsQueryVariables) => ['LibraryPolicyRequirementSections', variables];
;

export const useInfiniteLibraryPolicyRequirementSectionsQuery = <
      TData = LibraryPolicyRequirementSectionsQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryPolicyRequirementSectionsQueryVariables,
      variables: LibraryPolicyRequirementSectionsQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryPolicyRequirementSectionsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryPolicyRequirementSectionsQuery, TError, TData>(
      ['LibraryPolicyRequirementSections.infinite', variables],
      (metaData) => fetcher<LibraryPolicyRequirementSectionsQuery, LibraryPolicyRequirementSectionsQueryVariables>(LibraryPolicyRequirementSectionsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryPolicyRequirementSectionsQuery.getKey = (variables: LibraryPolicyRequirementSectionsQueryVariables) => ['LibraryPolicyRequirementSections.infinite', variables];
;

export const LibraryPolicyRegulatedSystemsDocument = `
    query LibraryPolicyRegulatedSystems($policyId: ID!) {
  policy(id: $policyId) {
    regulatedSystemCategories {
      ...LibraryPolicyCoverageTab
    }
  }
}
    ${LibraryPolicyCoverageTabFragmentDoc}`;
export const useLibraryPolicyRegulatedSystemsQuery = <
      TData = LibraryPolicyRegulatedSystemsQuery,
      TError = unknown
    >(
      variables: LibraryPolicyRegulatedSystemsQueryVariables,
      options?: UseQueryOptions<LibraryPolicyRegulatedSystemsQuery, TError, TData>
    ) =>
    useQuery<LibraryPolicyRegulatedSystemsQuery, TError, TData>(
      ['LibraryPolicyRegulatedSystems', variables],
      fetcher<LibraryPolicyRegulatedSystemsQuery, LibraryPolicyRegulatedSystemsQueryVariables>(LibraryPolicyRegulatedSystemsDocument, variables),
      options
    );
useLibraryPolicyRegulatedSystemsQuery.document = LibraryPolicyRegulatedSystemsDocument;


useLibraryPolicyRegulatedSystemsQuery.getKey = (variables: LibraryPolicyRegulatedSystemsQueryVariables) => ['LibraryPolicyRegulatedSystems', variables];
;

export const useInfiniteLibraryPolicyRegulatedSystemsQuery = <
      TData = LibraryPolicyRegulatedSystemsQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryPolicyRegulatedSystemsQueryVariables,
      variables: LibraryPolicyRegulatedSystemsQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryPolicyRegulatedSystemsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryPolicyRegulatedSystemsQuery, TError, TData>(
      ['LibraryPolicyRegulatedSystems.infinite', variables],
      (metaData) => fetcher<LibraryPolicyRegulatedSystemsQuery, LibraryPolicyRegulatedSystemsQueryVariables>(LibraryPolicyRegulatedSystemsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryPolicyRegulatedSystemsQuery.getKey = (variables: LibraryPolicyRegulatedSystemsQueryVariables) => ['LibraryPolicyRegulatedSystems.infinite', variables];
;

export const ChangeActiveSpaceDocument = `
    mutation ChangeActiveSpace($spaceId: ID!) {
  changeActiveSpace(spaceId: $spaceId) {
    success
    data {
      description
      externalId
      icon
      isDefault
      name
      managers {
        email
      }
    }
  }
}
    `;
export const useChangeActiveSpaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeActiveSpaceMutation, TError, ChangeActiveSpaceMutationVariables, TContext>) =>
    useMutation<ChangeActiveSpaceMutation, TError, ChangeActiveSpaceMutationVariables, TContext>(
      ['ChangeActiveSpace'],
      (variables?: ChangeActiveSpaceMutationVariables) => fetcher<ChangeActiveSpaceMutation, ChangeActiveSpaceMutationVariables>(ChangeActiveSpaceDocument, variables)(),
      options
    );
useChangeActiveSpaceMutation.getKey = () => ['ChangeActiveSpace'];

export const RequestUpgradeDocument = `
    mutation RequestUpgrade($details: String) {
  requestUpgrade(details: $details) {
    success
    error
  }
}
    `;
export const useRequestUpgradeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestUpgradeMutation, TError, RequestUpgradeMutationVariables, TContext>) =>
    useMutation<RequestUpgradeMutation, TError, RequestUpgradeMutationVariables, TContext>(
      ['RequestUpgrade'],
      (variables?: RequestUpgradeMutationVariables) => fetcher<RequestUpgradeMutation, RequestUpgradeMutationVariables>(RequestUpgradeDocument, variables)(),
      options
    );
useRequestUpgradeMutation.getKey = () => ['RequestUpgrade'];

export const GetMyOrganizationDomainDocument = `
    query GetMyOrganizationDomain {
  myOrganization {
    domain
  }
}
    `;
export const useGetMyOrganizationDomainQuery = <
      TData = GetMyOrganizationDomainQuery,
      TError = unknown
    >(
      variables?: GetMyOrganizationDomainQueryVariables,
      options?: UseQueryOptions<GetMyOrganizationDomainQuery, TError, TData>
    ) =>
    useQuery<GetMyOrganizationDomainQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganizationDomain'] : ['GetMyOrganizationDomain', variables],
      fetcher<GetMyOrganizationDomainQuery, GetMyOrganizationDomainQueryVariables>(GetMyOrganizationDomainDocument, variables),
      options
    );
useGetMyOrganizationDomainQuery.document = GetMyOrganizationDomainDocument;


useGetMyOrganizationDomainQuery.getKey = (variables?: GetMyOrganizationDomainQueryVariables) => variables === undefined ? ['GetMyOrganizationDomain'] : ['GetMyOrganizationDomain', variables];
;

export const useInfiniteGetMyOrganizationDomainQuery = <
      TData = GetMyOrganizationDomainQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMyOrganizationDomainQueryVariables,
      variables?: GetMyOrganizationDomainQueryVariables,
      options?: UseInfiniteQueryOptions<GetMyOrganizationDomainQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMyOrganizationDomainQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganizationDomain.infinite'] : ['GetMyOrganizationDomain.infinite', variables],
      (metaData) => fetcher<GetMyOrganizationDomainQuery, GetMyOrganizationDomainQueryVariables>(GetMyOrganizationDomainDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMyOrganizationDomainQuery.getKey = (variables?: GetMyOrganizationDomainQueryVariables) => variables === undefined ? ['GetMyOrganizationDomain.infinite'] : ['GetMyOrganizationDomain.infinite', variables];
;

export const AddSpaceMembersDocument = `
    mutation AddSpaceMembers($inviteEmailsInput: InviteMemberInput, $memberIds: [String!]!, $spaceId: String!) {
  addSpaceMembers(
    inviteEmailsInput: $inviteEmailsInput
    spaceId: $spaceId
    memberIds: $memberIds
  ) {
    success
    error
  }
}
    `;
export const useAddSpaceMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddSpaceMembersMutation, TError, AddSpaceMembersMutationVariables, TContext>) =>
    useMutation<AddSpaceMembersMutation, TError, AddSpaceMembersMutationVariables, TContext>(
      ['AddSpaceMembers'],
      (variables?: AddSpaceMembersMutationVariables) => fetcher<AddSpaceMembersMutation, AddSpaceMembersMutationVariables>(AddSpaceMembersDocument, variables)(),
      options
    );
useAddSpaceMembersMutation.getKey = () => ['AddSpaceMembers'];

export const ChangeSpaceMemberRoleDocument = `
    mutation ChangeSpaceMemberRole($spaceId: String!, $memberId: String!, $role: GovernSpaceRole!) {
  changeSpaceMemberRole(spaceId: $spaceId, memberId: $memberId, role: $role) {
    success
    error
  }
}
    `;
export const useChangeSpaceMemberRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeSpaceMemberRoleMutation, TError, ChangeSpaceMemberRoleMutationVariables, TContext>) =>
    useMutation<ChangeSpaceMemberRoleMutation, TError, ChangeSpaceMemberRoleMutationVariables, TContext>(
      ['ChangeSpaceMemberRole'],
      (variables?: ChangeSpaceMemberRoleMutationVariables) => fetcher<ChangeSpaceMemberRoleMutation, ChangeSpaceMemberRoleMutationVariables>(ChangeSpaceMemberRoleDocument, variables)(),
      options
    );
useChangeSpaceMemberRoleMutation.getKey = () => ['ChangeSpaceMemberRole'];

export const GetSpaceDocument = `
    query GetSpace($id: String!) {
  getGovernSpace(spaceId: $id) {
    externalId
    members {
      externalId
      firstName
      lastName
      email
      color
      profileImageURL
      txtIdentifier
    }
    managers {
      externalId
      firstName
      lastName
      email
      color
      profileImageURL
      txtIdentifier
    }
  }
}
    `;
export const useGetSpaceQuery = <
      TData = GetSpaceQuery,
      TError = unknown
    >(
      variables: GetSpaceQueryVariables,
      options?: UseQueryOptions<GetSpaceQuery, TError, TData>
    ) =>
    useQuery<GetSpaceQuery, TError, TData>(
      ['GetSpace', variables],
      fetcher<GetSpaceQuery, GetSpaceQueryVariables>(GetSpaceDocument, variables),
      options
    );
useGetSpaceQuery.document = GetSpaceDocument;


useGetSpaceQuery.getKey = (variables: GetSpaceQueryVariables) => ['GetSpace', variables];
;

export const useInfiniteGetSpaceQuery = <
      TData = GetSpaceQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSpaceQueryVariables,
      variables: GetSpaceQueryVariables,
      options?: UseInfiniteQueryOptions<GetSpaceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSpaceQuery, TError, TData>(
      ['GetSpace.infinite', variables],
      (metaData) => fetcher<GetSpaceQuery, GetSpaceQueryVariables>(GetSpaceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSpaceQuery.getKey = (variables: GetSpaceQueryVariables) => ['GetSpace.infinite', variables];
;

export const GetSpaceMembersDocument = `
    query GetSpaceMembers($id: String!, $query: String, $limit: Int, $offset: Int) {
  getGovernSpaceMembers(
    spaceId: $id
    query: $query
    limit: $limit
    offset: $offset
  ) {
    pageInfo {
      totalCount
      perPage
      page
    }
    nodes {
      externalId
      email
      firstName
      lastName
      role
      profileImageURL
      txtIdentifier
      platformRole
    }
  }
}
    `;
export const useGetSpaceMembersQuery = <
      TData = GetSpaceMembersQuery,
      TError = unknown
    >(
      variables: GetSpaceMembersQueryVariables,
      options?: UseQueryOptions<GetSpaceMembersQuery, TError, TData>
    ) =>
    useQuery<GetSpaceMembersQuery, TError, TData>(
      ['GetSpaceMembers', variables],
      fetcher<GetSpaceMembersQuery, GetSpaceMembersQueryVariables>(GetSpaceMembersDocument, variables),
      options
    );
useGetSpaceMembersQuery.document = GetSpaceMembersDocument;


useGetSpaceMembersQuery.getKey = (variables: GetSpaceMembersQueryVariables) => ['GetSpaceMembers', variables];
;

export const useInfiniteGetSpaceMembersQuery = <
      TData = GetSpaceMembersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSpaceMembersQueryVariables,
      variables: GetSpaceMembersQueryVariables,
      options?: UseInfiniteQueryOptions<GetSpaceMembersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSpaceMembersQuery, TError, TData>(
      ['GetSpaceMembers.infinite', variables],
      (metaData) => fetcher<GetSpaceMembersQuery, GetSpaceMembersQueryVariables>(GetSpaceMembersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSpaceMembersQuery.getKey = (variables: GetSpaceMembersQueryVariables) => ['GetSpaceMembers.infinite', variables];
;

export const RemoveSpaceMemberDocument = `
    mutation RemoveSpaceMember($spaceId: String!, $memberId: String!) {
  removeSpaceMember(spaceId: $spaceId, memberId: $memberId) {
    success
    error
  }
}
    `;
export const useRemoveSpaceMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveSpaceMemberMutation, TError, RemoveSpaceMemberMutationVariables, TContext>) =>
    useMutation<RemoveSpaceMemberMutation, TError, RemoveSpaceMemberMutationVariables, TContext>(
      ['RemoveSpaceMember'],
      (variables?: RemoveSpaceMemberMutationVariables) => fetcher<RemoveSpaceMemberMutation, RemoveSpaceMemberMutationVariables>(RemoveSpaceMemberDocument, variables)(),
      options
    );
useRemoveSpaceMemberMutation.getKey = () => ['RemoveSpaceMember'];

export const SuggestPolicyDocument = `
    mutation SuggestPolicy($name: String!, $kind: String, $purpose: String) {
  suggestPolicy(name: $name, kind: $kind, purpose: $purpose) {
    success
    error
  }
}
    `;
export const useSuggestPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SuggestPolicyMutation, TError, SuggestPolicyMutationVariables, TContext>) =>
    useMutation<SuggestPolicyMutation, TError, SuggestPolicyMutationVariables, TContext>(
      ['SuggestPolicy'],
      (variables?: SuggestPolicyMutationVariables) => fetcher<SuggestPolicyMutation, SuggestPolicyMutationVariables>(SuggestPolicyDocument, variables)(),
      options
    );
useSuggestPolicyMutation.getKey = () => ['SuggestPolicy'];

export const SuggestRiskDocument = `
    mutation SuggestRisk($name: String!, $description: String) {
  suggestRisk(name: $name, description: $description) {
    success
  }
}
    `;
export const useSuggestRiskMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SuggestRiskMutation, TError, SuggestRiskMutationVariables, TContext>) =>
    useMutation<SuggestRiskMutation, TError, SuggestRiskMutationVariables, TContext>(
      ['SuggestRisk'],
      (variables?: SuggestRiskMutationVariables) => fetcher<SuggestRiskMutation, SuggestRiskMutationVariables>(SuggestRiskDocument, variables)(),
      options
    );
useSuggestRiskMutation.getKey = () => ['SuggestRisk'];

export const SuggestTemplateDocument = `
    mutation SuggestTemplate($name: String!, $kind: String!) {
  suggestTemplate(name: $name, kind: $kind) {
    success
    error
  }
}
    `;
export const useSuggestTemplateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SuggestTemplateMutation, TError, SuggestTemplateMutationVariables, TContext>) =>
    useMutation<SuggestTemplateMutation, TError, SuggestTemplateMutationVariables, TContext>(
      ['SuggestTemplate'],
      (variables?: SuggestTemplateMutationVariables) => fetcher<SuggestTemplateMutation, SuggestTemplateMutationVariables>(SuggestTemplateDocument, variables)(),
      options
    );
useSuggestTemplateMutation.getKey = () => ['SuggestTemplate'];

export const CountriesDocument = `
    query Countries {
  countries {
    code
    name
  }
}
    `;
export const useCountriesQuery = <
      TData = CountriesQuery,
      TError = unknown
    >(
      variables?: CountriesQueryVariables,
      options?: UseQueryOptions<CountriesQuery, TError, TData>
    ) =>
    useQuery<CountriesQuery, TError, TData>(
      variables === undefined ? ['Countries'] : ['Countries', variables],
      fetcher<CountriesQuery, CountriesQueryVariables>(CountriesDocument, variables),
      options
    );
useCountriesQuery.document = CountriesDocument;


useCountriesQuery.getKey = (variables?: CountriesQueryVariables) => variables === undefined ? ['Countries'] : ['Countries', variables];
;

export const useInfiniteCountriesQuery = <
      TData = CountriesQuery,
      TError = unknown
    >(
      pageParamKey: keyof CountriesQueryVariables,
      variables?: CountriesQueryVariables,
      options?: UseInfiniteQueryOptions<CountriesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CountriesQuery, TError, TData>(
      variables === undefined ? ['Countries.infinite'] : ['Countries.infinite', variables],
      (metaData) => fetcher<CountriesQuery, CountriesQueryVariables>(CountriesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteCountriesQuery.getKey = (variables?: CountriesQueryVariables) => variables === undefined ? ['Countries.infinite'] : ['Countries.infinite', variables];
;

export const UpdateUserDocument = `
    mutation updateUser($values: UpdateUserArgs!, $sendMail: Boolean) {
  updateUser(values: $values, sendMail: $sendMail) {
    id
    firstName
    lastName
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['updateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
      options
    );
useUpdateUserMutation.getKey = () => ['updateUser'];

export const AddNewUserDocument = `
    mutation addNewUser($values: CreateUserArgs!, $sendMail: Boolean, $tempPassword: String) {
  addNewUser(values: $values, sendMail: $sendMail, tempPassword: $tempPassword) {
    data
    error
    success
  }
}
    `;
export const useAddNewUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddNewUserMutation, TError, AddNewUserMutationVariables, TContext>) =>
    useMutation<AddNewUserMutation, TError, AddNewUserMutationVariables, TContext>(
      ['addNewUser'],
      (variables?: AddNewUserMutationVariables) => fetcher<AddNewUserMutation, AddNewUserMutationVariables>(AddNewUserDocument, variables)(),
      options
    );
useAddNewUserMutation.getKey = () => ['addNewUser'];

export const InviteMembersDocument = `
    mutation InviteMembers($organizationId: ID, $input: InviteMemberInput!) {
  inviteMembers(organizationId: $organizationId, input: $input) {
    success
    error
  }
}
    `;
export const useInviteMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<InviteMembersMutation, TError, InviteMembersMutationVariables, TContext>) =>
    useMutation<InviteMembersMutation, TError, InviteMembersMutationVariables, TContext>(
      ['InviteMembers'],
      (variables?: InviteMembersMutationVariables) => fetcher<InviteMembersMutation, InviteMembersMutationVariables>(InviteMembersDocument, variables)(),
      options
    );
useInviteMembersMutation.getKey = () => ['InviteMembers'];

export const ChangeMemberAccessDocument = `
    mutation ChangeMemberAccess($access: Capabilities, $memberId: ID!) {
  changeMemberAccess(access: $access, memberId: $memberId) {
    success
    message
  }
}
    `;
export const useChangeMemberAccessMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeMemberAccessMutation, TError, ChangeMemberAccessMutationVariables, TContext>) =>
    useMutation<ChangeMemberAccessMutation, TError, ChangeMemberAccessMutationVariables, TContext>(
      ['ChangeMemberAccess'],
      (variables?: ChangeMemberAccessMutationVariables) => fetcher<ChangeMemberAccessMutation, ChangeMemberAccessMutationVariables>(ChangeMemberAccessDocument, variables)(),
      options
    );
useChangeMemberAccessMutation.getKey = () => ['ChangeMemberAccess'];

export const ChangeUserRoleDocument = `
    mutation ChangeUserRole($id: String!, $role: String!, $organizationId: String) {
  changeUserRole(id: $id, role: $role, organizationId: $organizationId) {
    success
    error
  }
}
    `;
export const useChangeUserRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeUserRoleMutation, TError, ChangeUserRoleMutationVariables, TContext>) =>
    useMutation<ChangeUserRoleMutation, TError, ChangeUserRoleMutationVariables, TContext>(
      ['ChangeUserRole'],
      (variables?: ChangeUserRoleMutationVariables) => fetcher<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>(ChangeUserRoleDocument, variables)(),
      options
    );
useChangeUserRoleMutation.getKey = () => ['ChangeUserRole'];

export const DeleteMemberDocument = `
    mutation DeleteMember($id: String!) {
  deleteMember(id: $id) {
    success
    error
  }
}
    `;
export const useDeleteMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMemberMutation, TError, DeleteMemberMutationVariables, TContext>) =>
    useMutation<DeleteMemberMutation, TError, DeleteMemberMutationVariables, TContext>(
      ['DeleteMember'],
      (variables?: DeleteMemberMutationVariables) => fetcher<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, variables)(),
      options
    );
useDeleteMemberMutation.getKey = () => ['DeleteMember'];

export const DeleteMembersDocument = `
    mutation DeleteMembers($externalIds: [String!]!) {
  deleteMembers(externalIds: $externalIds) {
    success
    error
  }
}
    `;
export const useDeleteMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteMembersMutation, TError, DeleteMembersMutationVariables, TContext>) =>
    useMutation<DeleteMembersMutation, TError, DeleteMembersMutationVariables, TContext>(
      ['DeleteMembers'],
      (variables?: DeleteMembersMutationVariables) => fetcher<DeleteMembersMutation, DeleteMembersMutationVariables>(DeleteMembersDocument, variables)(),
      options
    );
useDeleteMembersMutation.getKey = () => ['DeleteMembers'];

export const DeletePendingMemberDocument = `
    mutation DeletePendingMember($id: String!) {
  deletePendingMember(id: $id) {
    success
    error
  }
}
    `;
export const useDeletePendingMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePendingMemberMutation, TError, DeletePendingMemberMutationVariables, TContext>) =>
    useMutation<DeletePendingMemberMutation, TError, DeletePendingMemberMutationVariables, TContext>(
      ['DeletePendingMember'],
      (variables?: DeletePendingMemberMutationVariables) => fetcher<DeletePendingMemberMutation, DeletePendingMemberMutationVariables>(DeletePendingMemberDocument, variables)(),
      options
    );
useDeletePendingMemberMutation.getKey = () => ['DeletePendingMember'];

export const DeletePendingMembersDocument = `
    mutation DeletePendingMembers($externalIds: [String!]!) {
  deletePendingMembers(externalIds: $externalIds) {
    success
    error
  }
}
    `;
export const useDeletePendingMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePendingMembersMutation, TError, DeletePendingMembersMutationVariables, TContext>) =>
    useMutation<DeletePendingMembersMutation, TError, DeletePendingMembersMutationVariables, TContext>(
      ['DeletePendingMembers'],
      (variables?: DeletePendingMembersMutationVariables) => fetcher<DeletePendingMembersMutation, DeletePendingMembersMutationVariables>(DeletePendingMembersDocument, variables)(),
      options
    );
useDeletePendingMembersMutation.getKey = () => ['DeletePendingMembers'];

export const GetCapabilitiesDocument = `
    query GetCapabilities {
  myOrganization {
    appCapabilities {
      externalId
      name
    }
  }
}
    `;
export const useGetCapabilitiesQuery = <
      TData = GetCapabilitiesQuery,
      TError = unknown
    >(
      variables?: GetCapabilitiesQueryVariables,
      options?: UseQueryOptions<GetCapabilitiesQuery, TError, TData>
    ) =>
    useQuery<GetCapabilitiesQuery, TError, TData>(
      variables === undefined ? ['GetCapabilities'] : ['GetCapabilities', variables],
      fetcher<GetCapabilitiesQuery, GetCapabilitiesQueryVariables>(GetCapabilitiesDocument, variables),
      options
    );
useGetCapabilitiesQuery.document = GetCapabilitiesDocument;


useGetCapabilitiesQuery.getKey = (variables?: GetCapabilitiesQueryVariables) => variables === undefined ? ['GetCapabilities'] : ['GetCapabilities', variables];
;

export const useInfiniteGetCapabilitiesQuery = <
      TData = GetCapabilitiesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCapabilitiesQueryVariables,
      variables?: GetCapabilitiesQueryVariables,
      options?: UseInfiniteQueryOptions<GetCapabilitiesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetCapabilitiesQuery, TError, TData>(
      variables === undefined ? ['GetCapabilities.infinite'] : ['GetCapabilities.infinite', variables],
      (metaData) => fetcher<GetCapabilitiesQuery, GetCapabilitiesQueryVariables>(GetCapabilitiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCapabilitiesQuery.getKey = (variables?: GetCapabilitiesQueryVariables) => variables === undefined ? ['GetCapabilities.infinite'] : ['GetCapabilities.infinite', variables];
;

export const GetMembersDocument = `
    query GetMembers($query: String, $limit: Int, $offset: Int, $organisationId: ID!) {
  organization(id: $organisationId) {
    availableAccesses {
      value
      label
    }
    members(query: $query, limit: $limit, offset: $offset) {
      nodes {
        externalId
        firstName
        lastName
        email
        access
        lastLoginAt
        profileImageURL
        title
        systems {
          externalId
        }
        groups {
          name
        }
        organizations {
          name
          externalId
        }
        spaces {
          externalId
          name
          systems {
            externalId
          }
          managers {
            externalId
            firstName
            lastName
            email
            txtIdentifier
          }
        }
        role
      }
      pageInfo {
        totalCount
        page
        perPage
      }
    }
  }
}
    `;
export const useGetMembersQuery = <
      TData = GetMembersQuery,
      TError = unknown
    >(
      variables: GetMembersQueryVariables,
      options?: UseQueryOptions<GetMembersQuery, TError, TData>
    ) =>
    useQuery<GetMembersQuery, TError, TData>(
      ['GetMembers', variables],
      fetcher<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, variables),
      options
    );
useGetMembersQuery.document = GetMembersDocument;


useGetMembersQuery.getKey = (variables: GetMembersQueryVariables) => ['GetMembers', variables];
;

export const useInfiniteGetMembersQuery = <
      TData = GetMembersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMembersQueryVariables,
      variables: GetMembersQueryVariables,
      options?: UseInfiniteQueryOptions<GetMembersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMembersQuery, TError, TData>(
      ['GetMembers.infinite', variables],
      (metaData) => fetcher<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMembersQuery.getKey = (variables: GetMembersQueryVariables) => ['GetMembers.infinite', variables];
;

export const GetMembersCountsDocument = `
    query GetMembersCounts($organisationId: ID!) {
  organization(id: $organisationId) {
    members {
      pageInfo {
        totalCount
      }
    }
    pendingInvitations {
      pageInfo {
        totalCount
      }
    }
  }
}
    `;
export const useGetMembersCountsQuery = <
      TData = GetMembersCountsQuery,
      TError = unknown
    >(
      variables: GetMembersCountsQueryVariables,
      options?: UseQueryOptions<GetMembersCountsQuery, TError, TData>
    ) =>
    useQuery<GetMembersCountsQuery, TError, TData>(
      ['GetMembersCounts', variables],
      fetcher<GetMembersCountsQuery, GetMembersCountsQueryVariables>(GetMembersCountsDocument, variables),
      options
    );
useGetMembersCountsQuery.document = GetMembersCountsDocument;


useGetMembersCountsQuery.getKey = (variables: GetMembersCountsQueryVariables) => ['GetMembersCounts', variables];
;

export const useInfiniteGetMembersCountsQuery = <
      TData = GetMembersCountsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMembersCountsQueryVariables,
      variables: GetMembersCountsQueryVariables,
      options?: UseInfiniteQueryOptions<GetMembersCountsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMembersCountsQuery, TError, TData>(
      ['GetMembersCounts.infinite', variables],
      (metaData) => fetcher<GetMembersCountsQuery, GetMembersCountsQueryVariables>(GetMembersCountsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMembersCountsQuery.getKey = (variables: GetMembersCountsQueryVariables) => ['GetMembersCounts.infinite', variables];
;

export const GetMyOrganisationMembersDocument = `
    query GetMyOrganisationMembers($query: String, $limit: Int, $offset: Int) {
  myOrganization {
    availableAccesses {
      value
      label
    }
    members(query: $query, limit: $limit, offset: $offset) {
      nodes {
        externalId
        firstName
        lastName
        email
        access
        lastLoginAt
        profileImageURL
        title
        systems {
          externalId
        }
        groups {
          name
        }
        organizations {
          name
          externalId
        }
        spaces {
          externalId
          name
          systems {
            externalId
          }
          managers {
            externalId
            firstName
            lastName
            email
            txtIdentifier
          }
        }
        role
      }
      pageInfo {
        totalCount
        page
        perPage
      }
    }
  }
}
    `;
export const useGetMyOrganisationMembersQuery = <
      TData = GetMyOrganisationMembersQuery,
      TError = unknown
    >(
      variables?: GetMyOrganisationMembersQueryVariables,
      options?: UseQueryOptions<GetMyOrganisationMembersQuery, TError, TData>
    ) =>
    useQuery<GetMyOrganisationMembersQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganisationMembers'] : ['GetMyOrganisationMembers', variables],
      fetcher<GetMyOrganisationMembersQuery, GetMyOrganisationMembersQueryVariables>(GetMyOrganisationMembersDocument, variables),
      options
    );
useGetMyOrganisationMembersQuery.document = GetMyOrganisationMembersDocument;


useGetMyOrganisationMembersQuery.getKey = (variables?: GetMyOrganisationMembersQueryVariables) => variables === undefined ? ['GetMyOrganisationMembers'] : ['GetMyOrganisationMembers', variables];
;

export const useInfiniteGetMyOrganisationMembersQuery = <
      TData = GetMyOrganisationMembersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMyOrganisationMembersQueryVariables,
      variables?: GetMyOrganisationMembersQueryVariables,
      options?: UseInfiniteQueryOptions<GetMyOrganisationMembersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMyOrganisationMembersQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganisationMembers.infinite'] : ['GetMyOrganisationMembers.infinite', variables],
      (metaData) => fetcher<GetMyOrganisationMembersQuery, GetMyOrganisationMembersQueryVariables>(GetMyOrganisationMembersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMyOrganisationMembersQuery.getKey = (variables?: GetMyOrganisationMembersQueryVariables) => variables === undefined ? ['GetMyOrganisationMembers.infinite'] : ['GetMyOrganisationMembers.infinite', variables];
;

export const GetMyOrganisationMembersCountsDocument = `
    query GetMyOrganisationMembersCounts {
  myOrganization {
    members {
      pageInfo {
        totalCount
      }
    }
    pendingInvitations {
      pageInfo {
        totalCount
      }
    }
  }
}
    `;
export const useGetMyOrganisationMembersCountsQuery = <
      TData = GetMyOrganisationMembersCountsQuery,
      TError = unknown
    >(
      variables?: GetMyOrganisationMembersCountsQueryVariables,
      options?: UseQueryOptions<GetMyOrganisationMembersCountsQuery, TError, TData>
    ) =>
    useQuery<GetMyOrganisationMembersCountsQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganisationMembersCounts'] : ['GetMyOrganisationMembersCounts', variables],
      fetcher<GetMyOrganisationMembersCountsQuery, GetMyOrganisationMembersCountsQueryVariables>(GetMyOrganisationMembersCountsDocument, variables),
      options
    );
useGetMyOrganisationMembersCountsQuery.document = GetMyOrganisationMembersCountsDocument;


useGetMyOrganisationMembersCountsQuery.getKey = (variables?: GetMyOrganisationMembersCountsQueryVariables) => variables === undefined ? ['GetMyOrganisationMembersCounts'] : ['GetMyOrganisationMembersCounts', variables];
;

export const useInfiniteGetMyOrganisationMembersCountsQuery = <
      TData = GetMyOrganisationMembersCountsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMyOrganisationMembersCountsQueryVariables,
      variables?: GetMyOrganisationMembersCountsQueryVariables,
      options?: UseInfiniteQueryOptions<GetMyOrganisationMembersCountsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMyOrganisationMembersCountsQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganisationMembersCounts.infinite'] : ['GetMyOrganisationMembersCounts.infinite', variables],
      (metaData) => fetcher<GetMyOrganisationMembersCountsQuery, GetMyOrganisationMembersCountsQueryVariables>(GetMyOrganisationMembersCountsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMyOrganisationMembersCountsQuery.getKey = (variables?: GetMyOrganisationMembersCountsQueryVariables) => variables === undefined ? ['GetMyOrganisationMembersCounts.infinite'] : ['GetMyOrganisationMembersCounts.infinite', variables];
;

export const GetMyOrganisationPendingMembersDocument = `
    query GetMyOrganisationPendingMembers($query: String, $limit: Int, $offset: Int) {
  myOrganization {
    pendingInvitations(query: $query, limit: $limit, offset: $offset) {
      nodes {
        externalId
        email
        spaces {
          name
        }
        targetTokens {
          updatedAt
          expiresAt
          createdByUser {
            firstName
            lastName
            email
          }
        }
      }
      pageInfo {
        totalCount
        page
        perPage
      }
    }
  }
}
    `;
export const useGetMyOrganisationPendingMembersQuery = <
      TData = GetMyOrganisationPendingMembersQuery,
      TError = unknown
    >(
      variables?: GetMyOrganisationPendingMembersQueryVariables,
      options?: UseQueryOptions<GetMyOrganisationPendingMembersQuery, TError, TData>
    ) =>
    useQuery<GetMyOrganisationPendingMembersQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganisationPendingMembers'] : ['GetMyOrganisationPendingMembers', variables],
      fetcher<GetMyOrganisationPendingMembersQuery, GetMyOrganisationPendingMembersQueryVariables>(GetMyOrganisationPendingMembersDocument, variables),
      options
    );
useGetMyOrganisationPendingMembersQuery.document = GetMyOrganisationPendingMembersDocument;


useGetMyOrganisationPendingMembersQuery.getKey = (variables?: GetMyOrganisationPendingMembersQueryVariables) => variables === undefined ? ['GetMyOrganisationPendingMembers'] : ['GetMyOrganisationPendingMembers', variables];
;

export const useInfiniteGetMyOrganisationPendingMembersQuery = <
      TData = GetMyOrganisationPendingMembersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMyOrganisationPendingMembersQueryVariables,
      variables?: GetMyOrganisationPendingMembersQueryVariables,
      options?: UseInfiniteQueryOptions<GetMyOrganisationPendingMembersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMyOrganisationPendingMembersQuery, TError, TData>(
      variables === undefined ? ['GetMyOrganisationPendingMembers.infinite'] : ['GetMyOrganisationPendingMembers.infinite', variables],
      (metaData) => fetcher<GetMyOrganisationPendingMembersQuery, GetMyOrganisationPendingMembersQueryVariables>(GetMyOrganisationPendingMembersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetMyOrganisationPendingMembersQuery.getKey = (variables?: GetMyOrganisationPendingMembersQueryVariables) => variables === undefined ? ['GetMyOrganisationPendingMembers.infinite'] : ['GetMyOrganisationPendingMembers.infinite', variables];
;

export const GetPendingMembersDocument = `
    query GetPendingMembers($query: String, $limit: Int, $offset: Int, $organisationId: ID!) {
  organization(id: $organisationId) {
    pendingInvitations(query: $query, limit: $limit, offset: $offset) {
      nodes {
        externalId
        email
        spaces {
          name
        }
        targetTokens {
          updatedAt
          expiresAt
          createdByUser {
            firstName
            lastName
            email
          }
        }
      }
      pageInfo {
        totalCount
        page
        perPage
      }
    }
  }
}
    `;
export const useGetPendingMembersQuery = <
      TData = GetPendingMembersQuery,
      TError = unknown
    >(
      variables: GetPendingMembersQueryVariables,
      options?: UseQueryOptions<GetPendingMembersQuery, TError, TData>
    ) =>
    useQuery<GetPendingMembersQuery, TError, TData>(
      ['GetPendingMembers', variables],
      fetcher<GetPendingMembersQuery, GetPendingMembersQueryVariables>(GetPendingMembersDocument, variables),
      options
    );
useGetPendingMembersQuery.document = GetPendingMembersDocument;


useGetPendingMembersQuery.getKey = (variables: GetPendingMembersQueryVariables) => ['GetPendingMembers', variables];
;

export const useInfiniteGetPendingMembersQuery = <
      TData = GetPendingMembersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetPendingMembersQueryVariables,
      variables: GetPendingMembersQueryVariables,
      options?: UseInfiniteQueryOptions<GetPendingMembersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetPendingMembersQuery, TError, TData>(
      ['GetPendingMembers.infinite', variables],
      (metaData) => fetcher<GetPendingMembersQuery, GetPendingMembersQueryVariables>(GetPendingMembersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPendingMembersQuery.getKey = (variables: GetPendingMembersQueryVariables) => ['GetPendingMembers.infinite', variables];
;

export const PlatformViewAsUserDocument = `
    mutation PlatformViewAsUser($targetId: ID!) {
  viewAsUser(targetId: $targetId) {
    user {
      externalId
      email
      firstName
      lastName
      color
    }
  }
}
    `;
export const usePlatformViewAsUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PlatformViewAsUserMutation, TError, PlatformViewAsUserMutationVariables, TContext>) =>
    useMutation<PlatformViewAsUserMutation, TError, PlatformViewAsUserMutationVariables, TContext>(
      ['PlatformViewAsUser'],
      (variables?: PlatformViewAsUserMutationVariables) => fetcher<PlatformViewAsUserMutation, PlatformViewAsUserMutationVariables>(PlatformViewAsUserDocument, variables)(),
      options
    );
usePlatformViewAsUserMutation.getKey = () => ['PlatformViewAsUser'];

export const ResendInvitationDocument = `
    mutation ResendInvitation($id: String!) {
  resendInvitation(id: $id) {
    success
    error
  }
}
    `;
export const useResendInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResendInvitationMutation, TError, ResendInvitationMutationVariables, TContext>) =>
    useMutation<ResendInvitationMutation, TError, ResendInvitationMutationVariables, TContext>(
      ['ResendInvitation'],
      (variables?: ResendInvitationMutationVariables) => fetcher<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, variables)(),
      options
    );
useResendInvitationMutation.getKey = () => ['ResendInvitation'];

export const GetGeographyDocument = `
    query GetGeography($regionAlphabeticalOrder: SortDirection) {
  geography {
    regions(alphabeticalOrderDirection: $regionAlphabeticalOrder) {
      name
      externalId
      countries {
        name
        externalId
      }
    }
  }
}
    `;
export const useGetGeographyQuery = <
      TData = GetGeographyQuery,
      TError = unknown
    >(
      variables?: GetGeographyQueryVariables,
      options?: UseQueryOptions<GetGeographyQuery, TError, TData>
    ) =>
    useQuery<GetGeographyQuery, TError, TData>(
      variables === undefined ? ['GetGeography'] : ['GetGeography', variables],
      fetcher<GetGeographyQuery, GetGeographyQueryVariables>(GetGeographyDocument, variables),
      options
    );
useGetGeographyQuery.document = GetGeographyDocument;


useGetGeographyQuery.getKey = (variables?: GetGeographyQueryVariables) => variables === undefined ? ['GetGeography'] : ['GetGeography', variables];
;

export const useInfiniteGetGeographyQuery = <
      TData = GetGeographyQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGeographyQueryVariables,
      variables?: GetGeographyQueryVariables,
      options?: UseInfiniteQueryOptions<GetGeographyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGeographyQuery, TError, TData>(
      variables === undefined ? ['GetGeography.infinite'] : ['GetGeography.infinite', variables],
      (metaData) => fetcher<GetGeographyQuery, GetGeographyQueryVariables>(GetGeographyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGeographyQuery.getKey = (variables?: GetGeographyQueryVariables) => variables === undefined ? ['GetGeography.infinite'] : ['GetGeography.infinite', variables];
;

export const MyOrganisationDetailsDocument = `
    query MyOrganisationDetails {
  myOrganisationDetails {
    id: externalId
    organisationName: name
    address
    logo {
      file_name
      file_url
    }
    defaultRegions
    defaultIndustries
    defaultBusinessModels
    allowSpaceManagerInviteUsers
    allowMemberInviteUsers
  }
  countries {
    label: name
    value: code
  }
}
    `;
export const useMyOrganisationDetailsQuery = <
      TData = MyOrganisationDetailsQuery,
      TError = unknown
    >(
      variables?: MyOrganisationDetailsQueryVariables,
      options?: UseQueryOptions<MyOrganisationDetailsQuery, TError, TData>
    ) =>
    useQuery<MyOrganisationDetailsQuery, TError, TData>(
      variables === undefined ? ['MyOrganisationDetails'] : ['MyOrganisationDetails', variables],
      fetcher<MyOrganisationDetailsQuery, MyOrganisationDetailsQueryVariables>(MyOrganisationDetailsDocument, variables),
      options
    );
useMyOrganisationDetailsQuery.document = MyOrganisationDetailsDocument;


useMyOrganisationDetailsQuery.getKey = (variables?: MyOrganisationDetailsQueryVariables) => variables === undefined ? ['MyOrganisationDetails'] : ['MyOrganisationDetails', variables];
;

export const useInfiniteMyOrganisationDetailsQuery = <
      TData = MyOrganisationDetailsQuery,
      TError = unknown
    >(
      pageParamKey: keyof MyOrganisationDetailsQueryVariables,
      variables?: MyOrganisationDetailsQueryVariables,
      options?: UseInfiniteQueryOptions<MyOrganisationDetailsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MyOrganisationDetailsQuery, TError, TData>(
      variables === undefined ? ['MyOrganisationDetails.infinite'] : ['MyOrganisationDetails.infinite', variables],
      (metaData) => fetcher<MyOrganisationDetailsQuery, MyOrganisationDetailsQueryVariables>(MyOrganisationDetailsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteMyOrganisationDetailsQuery.getKey = (variables?: MyOrganisationDetailsQueryVariables) => variables === undefined ? ['MyOrganisationDetails.infinite'] : ['MyOrganisationDetails.infinite', variables];
;

export const RemoveMyOrganisationLogoDocument = `
    mutation RemoveMyOrganisationLogo {
  removeMyOrganisationLogo {
    success
    message
  }
}
    `;
export const useRemoveMyOrganisationLogoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveMyOrganisationLogoMutation, TError, RemoveMyOrganisationLogoMutationVariables, TContext>) =>
    useMutation<RemoveMyOrganisationLogoMutation, TError, RemoveMyOrganisationLogoMutationVariables, TContext>(
      ['RemoveMyOrganisationLogo'],
      (variables?: RemoveMyOrganisationLogoMutationVariables) => fetcher<RemoveMyOrganisationLogoMutation, RemoveMyOrganisationLogoMutationVariables>(RemoveMyOrganisationLogoDocument, variables)(),
      options
    );
useRemoveMyOrganisationLogoMutation.getKey = () => ['RemoveMyOrganisationLogo'];

export const RequestCancellationDocument = `
    mutation RequestCancellation($input: RequestCancellationInput!) {
  requestCancellationOfMyOrganisationSubscription(input: $input) {
    success
    message
  }
}
    `;
export const useRequestCancellationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestCancellationMutation, TError, RequestCancellationMutationVariables, TContext>) =>
    useMutation<RequestCancellationMutation, TError, RequestCancellationMutationVariables, TContext>(
      ['RequestCancellation'],
      (variables?: RequestCancellationMutationVariables) => fetcher<RequestCancellationMutation, RequestCancellationMutationVariables>(RequestCancellationDocument, variables)(),
      options
    );
useRequestCancellationMutation.getKey = () => ['RequestCancellation'];

export const UpdateMyOrganisationDefaultsDocument = `
    mutation UpdateMyOrganisationDefaults($input: UpdateMyOrganisationDefaultsInput!) {
  updateMyOrganisationDefaults(input: $input) {
    success
    message
  }
}
    `;
export const useUpdateMyOrganisationDefaultsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMyOrganisationDefaultsMutation, TError, UpdateMyOrganisationDefaultsMutationVariables, TContext>) =>
    useMutation<UpdateMyOrganisationDefaultsMutation, TError, UpdateMyOrganisationDefaultsMutationVariables, TContext>(
      ['UpdateMyOrganisationDefaults'],
      (variables?: UpdateMyOrganisationDefaultsMutationVariables) => fetcher<UpdateMyOrganisationDefaultsMutation, UpdateMyOrganisationDefaultsMutationVariables>(UpdateMyOrganisationDefaultsDocument, variables)(),
      options
    );
useUpdateMyOrganisationDefaultsMutation.getKey = () => ['UpdateMyOrganisationDefaults'];

export const UpdateMyOrganisationDetailsDocument = `
    mutation UpdateMyOrganisationDetails($input: UpdateMyOrganisationDetailsInput!) {
  updateMyOrganisationDetails(input: $input) {
    success
    message
  }
}
    `;
export const useUpdateMyOrganisationDetailsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMyOrganisationDetailsMutation, TError, UpdateMyOrganisationDetailsMutationVariables, TContext>) =>
    useMutation<UpdateMyOrganisationDetailsMutation, TError, UpdateMyOrganisationDetailsMutationVariables, TContext>(
      ['UpdateMyOrganisationDetails'],
      (variables?: UpdateMyOrganisationDetailsMutationVariables) => fetcher<UpdateMyOrganisationDetailsMutation, UpdateMyOrganisationDetailsMutationVariables>(UpdateMyOrganisationDetailsDocument, variables)(),
      options
    );
useUpdateMyOrganisationDetailsMutation.getKey = () => ['UpdateMyOrganisationDetails'];

export const UpdateMyOrganisationSecurityDocument = `
    mutation UpdateMyOrganisationSecurity($input: UpdateMyOrganisationSecurityInput!) {
  updateMyOrganisationSecurity(input: $input) {
    success
    message
  }
}
    `;
export const useUpdateMyOrganisationSecurityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateMyOrganisationSecurityMutation, TError, UpdateMyOrganisationSecurityMutationVariables, TContext>) =>
    useMutation<UpdateMyOrganisationSecurityMutation, TError, UpdateMyOrganisationSecurityMutationVariables, TContext>(
      ['UpdateMyOrganisationSecurity'],
      (variables?: UpdateMyOrganisationSecurityMutationVariables) => fetcher<UpdateMyOrganisationSecurityMutation, UpdateMyOrganisationSecurityMutationVariables>(UpdateMyOrganisationSecurityDocument, variables)(),
      options
    );
useUpdateMyOrganisationSecurityMutation.getKey = () => ['UpdateMyOrganisationSecurity'];

export const AssignSpaceManagersDocument = `
    mutation AssignSpaceManagers($spaceId: String!, $managerIds: [String!]!) {
  assignManagers(spaceId: $spaceId, managerIds: $managerIds) {
    success
    error
  }
}
    `;
export const useAssignSpaceManagersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AssignSpaceManagersMutation, TError, AssignSpaceManagersMutationVariables, TContext>) =>
    useMutation<AssignSpaceManagersMutation, TError, AssignSpaceManagersMutationVariables, TContext>(
      ['AssignSpaceManagers'],
      (variables?: AssignSpaceManagersMutationVariables) => fetcher<AssignSpaceManagersMutation, AssignSpaceManagersMutationVariables>(AssignSpaceManagersDocument, variables)(),
      options
    );
useAssignSpaceManagersMutation.getKey = () => ['AssignSpaceManagers'];

export const CreateSpaceDocument = `
    mutation CreateSpace($input: CreateSpaceInput!) {
  createSpace(input: $input) {
    error
    success
    data
  }
}
    `;
export const useCreateSpaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSpaceMutation, TError, CreateSpaceMutationVariables, TContext>) =>
    useMutation<CreateSpaceMutation, TError, CreateSpaceMutationVariables, TContext>(
      ['CreateSpace'],
      (variables?: CreateSpaceMutationVariables) => fetcher<CreateSpaceMutation, CreateSpaceMutationVariables>(CreateSpaceDocument, variables)(),
      options
    );
useCreateSpaceMutation.getKey = () => ['CreateSpace'];

export const DeleteSpaceDocument = `
    mutation DeleteSpace($id: String!) {
  deleteSpace(id: $id) {
    success
    error
  }
}
    `;
export const useDeleteSpaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSpaceMutation, TError, DeleteSpaceMutationVariables, TContext>) =>
    useMutation<DeleteSpaceMutation, TError, DeleteSpaceMutationVariables, TContext>(
      ['DeleteSpace'],
      (variables?: DeleteSpaceMutationVariables) => fetcher<DeleteSpaceMutation, DeleteSpaceMutationVariables>(DeleteSpaceDocument, variables)(),
      options
    );
useDeleteSpaceMutation.getKey = () => ['DeleteSpace'];

export const GetSpacesDocument = `
    query GetSpaces($query: String, $limit: Int, $offset: Int, $organizationId: String) {
  spaces(
    query: $query
    limit: $limit
    offset: $offset
    organizationId: $organizationId
  ) {
    pageInfo {
      totalCount
      page
      perPage
    }
    nodes {
      ...GovernSpaceNode
    }
  }
}
    ${GovernSpaceNodeFragmentDoc}`;
export const useGetSpacesQuery = <
      TData = GetSpacesQuery,
      TError = unknown
    >(
      variables?: GetSpacesQueryVariables,
      options?: UseQueryOptions<GetSpacesQuery, TError, TData>
    ) =>
    useQuery<GetSpacesQuery, TError, TData>(
      variables === undefined ? ['GetSpaces'] : ['GetSpaces', variables],
      fetcher<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, variables),
      options
    );
useGetSpacesQuery.document = GetSpacesDocument;


useGetSpacesQuery.getKey = (variables?: GetSpacesQueryVariables) => variables === undefined ? ['GetSpaces'] : ['GetSpaces', variables];
;

export const useInfiniteGetSpacesQuery = <
      TData = GetSpacesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSpacesQueryVariables,
      variables?: GetSpacesQueryVariables,
      options?: UseInfiniteQueryOptions<GetSpacesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSpacesQuery, TError, TData>(
      variables === undefined ? ['GetSpaces.infinite'] : ['GetSpaces.infinite', variables],
      (metaData) => fetcher<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSpacesQuery.getKey = (variables?: GetSpacesQueryVariables) => variables === undefined ? ['GetSpaces.infinite'] : ['GetSpaces.infinite', variables];
;

export const UpdateSpaceDocument = `
    mutation UpdateSpace($input: UpdateSpaceInput!, $spaceId: String!) {
  updateSpace(input: $input, spaceId: $spaceId) {
    success
    error
  }
}
    `;
export const useUpdateSpaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSpaceMutation, TError, UpdateSpaceMutationVariables, TContext>) =>
    useMutation<UpdateSpaceMutation, TError, UpdateSpaceMutationVariables, TContext>(
      ['UpdateSpace'],
      (variables?: UpdateSpaceMutationVariables) => fetcher<UpdateSpaceMutation, UpdateSpaceMutationVariables>(UpdateSpaceDocument, variables)(),
      options
    );
useUpdateSpaceMutation.getKey = () => ['UpdateSpace'];

export const AddLibraryTaskDocument = `
    mutation AddLibraryTask {
  queueLibrarySync {
    success
    data
    error
  }
}
    `;
export const useAddLibraryTaskMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddLibraryTaskMutation, TError, AddLibraryTaskMutationVariables, TContext>) =>
    useMutation<AddLibraryTaskMutation, TError, AddLibraryTaskMutationVariables, TContext>(
      ['AddLibraryTask'],
      (variables?: AddLibraryTaskMutationVariables) => fetcher<AddLibraryTaskMutation, AddLibraryTaskMutationVariables>(AddLibraryTaskDocument, variables)(),
      options
    );
useAddLibraryTaskMutation.getKey = () => ['AddLibraryTask'];

export const AddOptionsSyncTaskDocument = `
    mutation AddOptionsSyncTask {
  queueAirtableOptions {
    success
    data
    error
  }
}
    `;
export const useAddOptionsSyncTaskMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddOptionsSyncTaskMutation, TError, AddOptionsSyncTaskMutationVariables, TContext>) =>
    useMutation<AddOptionsSyncTaskMutation, TError, AddOptionsSyncTaskMutationVariables, TContext>(
      ['AddOptionsSyncTask'],
      (variables?: AddOptionsSyncTaskMutationVariables) => fetcher<AddOptionsSyncTaskMutation, AddOptionsSyncTaskMutationVariables>(AddOptionsSyncTaskDocument, variables)(),
      options
    );
useAddOptionsSyncTaskMutation.getKey = () => ['AddOptionsSyncTask'];

export const RefreshFeatureFlagsDocument = `
    mutation RefreshFeatureFlags {
  featureFlagsRefresh {
    success
    error
    data
  }
}
    `;
export const useRefreshFeatureFlagsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RefreshFeatureFlagsMutation, TError, RefreshFeatureFlagsMutationVariables, TContext>) =>
    useMutation<RefreshFeatureFlagsMutation, TError, RefreshFeatureFlagsMutationVariables, TContext>(
      ['RefreshFeatureFlags'],
      (variables?: RefreshFeatureFlagsMutationVariables) => fetcher<RefreshFeatureFlagsMutation, RefreshFeatureFlagsMutationVariables>(RefreshFeatureFlagsDocument, variables)(),
      options
    );
useRefreshFeatureFlagsMutation.getKey = () => ['RefreshFeatureFlags'];

export const OrganisationDetailsDocument = `
    query OrganisationDetails($id: ID!) {
  organisationDetails(id: $id) {
    organisationName: name
    address
    logo {
      file_name
      file_url
    }
    status
    consentToUserSwitching
    systemLimit
    access
    license
    publicRegistryURL
  }
  countries {
    label: name
    value: code
  }
}
    `;
export const useOrganisationDetailsQuery = <
      TData = OrganisationDetailsQuery,
      TError = unknown
    >(
      variables: OrganisationDetailsQueryVariables,
      options?: UseQueryOptions<OrganisationDetailsQuery, TError, TData>
    ) =>
    useQuery<OrganisationDetailsQuery, TError, TData>(
      ['OrganisationDetails', variables],
      fetcher<OrganisationDetailsQuery, OrganisationDetailsQueryVariables>(OrganisationDetailsDocument, variables),
      options
    );
useOrganisationDetailsQuery.document = OrganisationDetailsDocument;


useOrganisationDetailsQuery.getKey = (variables: OrganisationDetailsQueryVariables) => ['OrganisationDetails', variables];
;

export const useInfiniteOrganisationDetailsQuery = <
      TData = OrganisationDetailsQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganisationDetailsQueryVariables,
      variables: OrganisationDetailsQueryVariables,
      options?: UseInfiniteQueryOptions<OrganisationDetailsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganisationDetailsQuery, TError, TData>(
      ['OrganisationDetails.infinite', variables],
      (metaData) => fetcher<OrganisationDetailsQuery, OrganisationDetailsQueryVariables>(OrganisationDetailsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganisationDetailsQuery.getKey = (variables: OrganisationDetailsQueryVariables) => ['OrganisationDetails.infinite', variables];
;

export const RemoveOrganisationLogoDocument = `
    mutation RemoveOrganisationLogo($id: String!) {
  removeOrganisationLogo(id: $id) {
    success
    message
  }
}
    `;
export const useRemoveOrganisationLogoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveOrganisationLogoMutation, TError, RemoveOrganisationLogoMutationVariables, TContext>) =>
    useMutation<RemoveOrganisationLogoMutation, TError, RemoveOrganisationLogoMutationVariables, TContext>(
      ['RemoveOrganisationLogo'],
      (variables?: RemoveOrganisationLogoMutationVariables) => fetcher<RemoveOrganisationLogoMutation, RemoveOrganisationLogoMutationVariables>(RemoveOrganisationLogoDocument, variables)(),
      options
    );
useRemoveOrganisationLogoMutation.getKey = () => ['RemoveOrganisationLogo'];

export const UpdateOrganisationConfigurationDocument = `
    mutation UpdateOrganisationConfiguration($input: UpdateOrganisationConfigurationInput!) {
  updateOrganisationConfiguration(input: $input) {
    success
    message
  }
}
    `;
export const useUpdateOrganisationConfigurationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganisationConfigurationMutation, TError, UpdateOrganisationConfigurationMutationVariables, TContext>) =>
    useMutation<UpdateOrganisationConfigurationMutation, TError, UpdateOrganisationConfigurationMutationVariables, TContext>(
      ['UpdateOrganisationConfiguration'],
      (variables?: UpdateOrganisationConfigurationMutationVariables) => fetcher<UpdateOrganisationConfigurationMutation, UpdateOrganisationConfigurationMutationVariables>(UpdateOrganisationConfigurationDocument, variables)(),
      options
    );
useUpdateOrganisationConfigurationMutation.getKey = () => ['UpdateOrganisationConfiguration'];

export const UpdateOrganisationDetailsDocument = `
    mutation UpdateOrganisationDetails($input: UpdateOrganisationDetailsInput!) {
  updateOrganisationDetails(input: $input) {
    success
    message
  }
}
    `;
export const useUpdateOrganisationDetailsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOrganisationDetailsMutation, TError, UpdateOrganisationDetailsMutationVariables, TContext>) =>
    useMutation<UpdateOrganisationDetailsMutation, TError, UpdateOrganisationDetailsMutationVariables, TContext>(
      ['UpdateOrganisationDetails'],
      (variables?: UpdateOrganisationDetailsMutationVariables) => fetcher<UpdateOrganisationDetailsMutation, UpdateOrganisationDetailsMutationVariables>(UpdateOrganisationDetailsDocument, variables)(),
      options
    );
useUpdateOrganisationDetailsMutation.getKey = () => ['UpdateOrganisationDetails'];

export const AddOrganisationDocument = `
    mutation AddOrganisation($input: AddOrganisationInput!) {
  addOrganisation(input: $input) {
    success
    message
  }
}
    `;
export const useAddOrganisationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddOrganisationMutation, TError, AddOrganisationMutationVariables, TContext>) =>
    useMutation<AddOrganisationMutation, TError, AddOrganisationMutationVariables, TContext>(
      ['AddOrganisation'],
      (variables?: AddOrganisationMutationVariables) => fetcher<AddOrganisationMutation, AddOrganisationMutationVariables>(AddOrganisationDocument, variables)(),
      options
    );
useAddOrganisationMutation.getKey = () => ['AddOrganisation'];

export const ChangeOrganisationAccessDocument = `
    mutation ChangeOrganisationAccess($access: Capabilities!, $organisationId: String!) {
  changeOrganisationAccess(access: $access, organisationId: $organisationId) {
    success
    message
  }
}
    `;
export const useChangeOrganisationAccessMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeOrganisationAccessMutation, TError, ChangeOrganisationAccessMutationVariables, TContext>) =>
    useMutation<ChangeOrganisationAccessMutation, TError, ChangeOrganisationAccessMutationVariables, TContext>(
      ['ChangeOrganisationAccess'],
      (variables?: ChangeOrganisationAccessMutationVariables) => fetcher<ChangeOrganisationAccessMutation, ChangeOrganisationAccessMutationVariables>(ChangeOrganisationAccessDocument, variables)(),
      options
    );
useChangeOrganisationAccessMutation.getKey = () => ['ChangeOrganisationAccess'];

export const ChangeOrganisationLicenseDocument = `
    mutation ChangeOrganisationLicense($license: Licenses!, $organisationId: String!) {
  changeOrganisationLicense(license: $license, organisationId: $organisationId) {
    success
    message
  }
}
    `;
export const useChangeOrganisationLicenseMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeOrganisationLicenseMutation, TError, ChangeOrganisationLicenseMutationVariables, TContext>) =>
    useMutation<ChangeOrganisationLicenseMutation, TError, ChangeOrganisationLicenseMutationVariables, TContext>(
      ['ChangeOrganisationLicense'],
      (variables?: ChangeOrganisationLicenseMutationVariables) => fetcher<ChangeOrganisationLicenseMutation, ChangeOrganisationLicenseMutationVariables>(ChangeOrganisationLicenseDocument, variables)(),
      options
    );
useChangeOrganisationLicenseMutation.getKey = () => ['ChangeOrganisationLicense'];

export const ChangeOrganisationStatusDocument = `
    mutation ChangeOrganisationStatus($status: Boolean!, $organisationId: String!) {
  changeOrganisationStatus(status: $status, organisationId: $organisationId) {
    success
    message
  }
}
    `;
export const useChangeOrganisationStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeOrganisationStatusMutation, TError, ChangeOrganisationStatusMutationVariables, TContext>) =>
    useMutation<ChangeOrganisationStatusMutation, TError, ChangeOrganisationStatusMutationVariables, TContext>(
      ['ChangeOrganisationStatus'],
      (variables?: ChangeOrganisationStatusMutationVariables) => fetcher<ChangeOrganisationStatusMutation, ChangeOrganisationStatusMutationVariables>(ChangeOrganisationStatusDocument, variables)(),
      options
    );
useChangeOrganisationStatusMutation.getKey = () => ['ChangeOrganisationStatus'];

export const DeleteOrganisationDocument = `
    mutation DeleteOrganisation($organisationId: String!) {
  deleteOrganisation(organisationId: $organisationId) {
    success
    message
  }
}
    `;
export const useDeleteOrganisationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteOrganisationMutation, TError, DeleteOrganisationMutationVariables, TContext>) =>
    useMutation<DeleteOrganisationMutation, TError, DeleteOrganisationMutationVariables, TContext>(
      ['DeleteOrganisation'],
      (variables?: DeleteOrganisationMutationVariables) => fetcher<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>(DeleteOrganisationDocument, variables)(),
      options
    );
useDeleteOrganisationMutation.getKey = () => ['DeleteOrganisation'];

export const GetOrganisationCountriesDocument = `
    query GetOrganisationCountries {
  countries {
    label: name
    value: code
  }
}
    `;
export const useGetOrganisationCountriesQuery = <
      TData = GetOrganisationCountriesQuery,
      TError = unknown
    >(
      variables?: GetOrganisationCountriesQueryVariables,
      options?: UseQueryOptions<GetOrganisationCountriesQuery, TError, TData>
    ) =>
    useQuery<GetOrganisationCountriesQuery, TError, TData>(
      variables === undefined ? ['GetOrganisationCountries'] : ['GetOrganisationCountries', variables],
      fetcher<GetOrganisationCountriesQuery, GetOrganisationCountriesQueryVariables>(GetOrganisationCountriesDocument, variables),
      options
    );
useGetOrganisationCountriesQuery.document = GetOrganisationCountriesDocument;


useGetOrganisationCountriesQuery.getKey = (variables?: GetOrganisationCountriesQueryVariables) => variables === undefined ? ['GetOrganisationCountries'] : ['GetOrganisationCountries', variables];
;

export const useInfiniteGetOrganisationCountriesQuery = <
      TData = GetOrganisationCountriesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetOrganisationCountriesQueryVariables,
      variables?: GetOrganisationCountriesQueryVariables,
      options?: UseInfiniteQueryOptions<GetOrganisationCountriesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetOrganisationCountriesQuery, TError, TData>(
      variables === undefined ? ['GetOrganisationCountries.infinite'] : ['GetOrganisationCountries.infinite', variables],
      (metaData) => fetcher<GetOrganisationCountriesQuery, GetOrganisationCountriesQueryVariables>(GetOrganisationCountriesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetOrganisationCountriesQuery.getKey = (variables?: GetOrganisationCountriesQueryVariables) => variables === undefined ? ['GetOrganisationCountries.infinite'] : ['GetOrganisationCountries.infinite', variables];
;

export const OrganisationsDocument = `
    query Organisations($query: String, $limit: Int, $offset: Int) {
  organisations(query: $query, limit: $limit, offset: $offset) {
    success
    data {
      nodes {
        ...OrganisationTableItem
      }
      pageInfo {
        totalCount
        page
        perPage
      }
    }
  }
  availableAccess {
    value
    label
  }
  availableLicenses {
    value
    label
  }
}
    ${OrganisationTableItemFragmentDoc}`;
export const useOrganisationsQuery = <
      TData = OrganisationsQuery,
      TError = unknown
    >(
      variables?: OrganisationsQueryVariables,
      options?: UseQueryOptions<OrganisationsQuery, TError, TData>
    ) =>
    useQuery<OrganisationsQuery, TError, TData>(
      variables === undefined ? ['Organisations'] : ['Organisations', variables],
      fetcher<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, variables),
      options
    );
useOrganisationsQuery.document = OrganisationsDocument;


useOrganisationsQuery.getKey = (variables?: OrganisationsQueryVariables) => variables === undefined ? ['Organisations'] : ['Organisations', variables];
;

export const useInfiniteOrganisationsQuery = <
      TData = OrganisationsQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganisationsQueryVariables,
      variables?: OrganisationsQueryVariables,
      options?: UseInfiniteQueryOptions<OrganisationsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganisationsQuery, TError, TData>(
      variables === undefined ? ['Organisations.infinite'] : ['Organisations.infinite', variables],
      (metaData) => fetcher<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganisationsQuery.getKey = (variables?: OrganisationsQueryVariables) => variables === undefined ? ['Organisations.infinite'] : ['Organisations.infinite', variables];
;

export const AddNewUsersDocument = `
    mutation AddNewUsers($input: CreateUsersInput!) {
  addNewUsers(input: $input) {
    success
    error
  }
}
    `;
export const useAddNewUsersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddNewUsersMutation, TError, AddNewUsersMutationVariables, TContext>) =>
    useMutation<AddNewUsersMutation, TError, AddNewUsersMutationVariables, TContext>(
      ['AddNewUsers'],
      (variables?: AddNewUsersMutationVariables) => fetcher<AddNewUsersMutation, AddNewUsersMutationVariables>(AddNewUsersDocument, variables)(),
      options
    );
useAddNewUsersMutation.getKey = () => ['AddNewUsers'];

export const GetPlatformUsersDocument = `
    query GetPlatformUsers($query: String, $limit: Int, $offset: Int, $organizations: [String]) {
  getPlatformUsers(
    query: $query
    limit: $limit
    offset: $offset
    organizations: $organizations
  ) {
    pageInfo {
      perPage
      page
      totalCount
    }
    nodes {
      externalId
      firstName
      lastName
      email
      txtIdentifier
      profileImageURL
      color
      organizations {
        externalId
        name
        availableAccesses {
          label
          value
        }
      }
      lastLoginAt
      access
      active
      pending
      role
    }
  }
}
    `;
export const useGetPlatformUsersQuery = <
      TData = GetPlatformUsersQuery,
      TError = unknown
    >(
      variables?: GetPlatformUsersQueryVariables,
      options?: UseQueryOptions<GetPlatformUsersQuery, TError, TData>
    ) =>
    useQuery<GetPlatformUsersQuery, TError, TData>(
      variables === undefined ? ['GetPlatformUsers'] : ['GetPlatformUsers', variables],
      fetcher<GetPlatformUsersQuery, GetPlatformUsersQueryVariables>(GetPlatformUsersDocument, variables),
      options
    );
useGetPlatformUsersQuery.document = GetPlatformUsersDocument;


useGetPlatformUsersQuery.getKey = (variables?: GetPlatformUsersQueryVariables) => variables === undefined ? ['GetPlatformUsers'] : ['GetPlatformUsers', variables];
;

export const useInfiniteGetPlatformUsersQuery = <
      TData = GetPlatformUsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetPlatformUsersQueryVariables,
      variables?: GetPlatformUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetPlatformUsersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetPlatformUsersQuery, TError, TData>(
      variables === undefined ? ['GetPlatformUsers.infinite'] : ['GetPlatformUsers.infinite', variables],
      (metaData) => fetcher<GetPlatformUsersQuery, GetPlatformUsersQueryVariables>(GetPlatformUsersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPlatformUsersQuery.getKey = (variables?: GetPlatformUsersQueryVariables) => variables === undefined ? ['GetPlatformUsers.infinite'] : ['GetPlatformUsers.infinite', variables];
;

export const GovernDatasetDocument = `
    query GovernDataset($externalId: String!) {
  dataset(externalId: $externalId) {
    externalId
    name
    subject
    owner
    modality
    cardOwnerId
    linkToDataset
    personalData
    specialCategoriesPD
    source
    version
    license
    tags
    dataCollectionMethods
    preProcessingMethods
    description
    isBookmarked
    systems {
      externalId
      name
    }
    cardOwner {
      id
      externalId
      firstName
      lastName
    }
  }
}
    `;
export const useGovernDatasetQuery = <
      TData = GovernDatasetQuery,
      TError = unknown
    >(
      variables: GovernDatasetQueryVariables,
      options?: UseQueryOptions<GovernDatasetQuery, TError, TData>
    ) =>
    useQuery<GovernDatasetQuery, TError, TData>(
      ['GovernDataset', variables],
      fetcher<GovernDatasetQuery, GovernDatasetQueryVariables>(GovernDatasetDocument, variables),
      options
    );
useGovernDatasetQuery.document = GovernDatasetDocument;


useGovernDatasetQuery.getKey = (variables: GovernDatasetQueryVariables) => ['GovernDataset', variables];
;

export const useInfiniteGovernDatasetQuery = <
      TData = GovernDatasetQuery,
      TError = unknown
    >(
      pageParamKey: keyof GovernDatasetQueryVariables,
      variables: GovernDatasetQueryVariables,
      options?: UseInfiniteQueryOptions<GovernDatasetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GovernDatasetQuery, TError, TData>(
      ['GovernDataset.infinite', variables],
      (metaData) => fetcher<GovernDatasetQuery, GovernDatasetQueryVariables>(GovernDatasetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGovernDatasetQuery.getKey = (variables: GovernDatasetQueryVariables) => ['GovernDataset.infinite', variables];
;

export const UpdateDatasetDocument = `
    mutation UpdateDataset($updateDatasetInput: UpdateDatasetInput!) {
  updateDataset(updateDatasetInput: $updateDatasetInput) {
    externalId
    name
    subject
    owner
    modality
    cardOwnerId
    linkToDataset
    personalData
    specialCategoriesPD
    source
    version
    license
    tags
    dataCollectionMethods
    preProcessingMethods
    description
    cardOwner {
      id
      firstName
      lastName
    }
  }
}
    `;
export const useUpdateDatasetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDatasetMutation, TError, UpdateDatasetMutationVariables, TContext>) =>
    useMutation<UpdateDatasetMutation, TError, UpdateDatasetMutationVariables, TContext>(
      ['UpdateDataset'],
      (variables?: UpdateDatasetMutationVariables) => fetcher<UpdateDatasetMutation, UpdateDatasetMutationVariables>(UpdateDatasetDocument, variables)(),
      options
    );
useUpdateDatasetMutation.getKey = () => ['UpdateDataset'];

export const UpdateDatasetNameDocument = `
    mutation UpdateDatasetName($externalId: String!, $name: String!) {
  updateDatasetName(externalId: $externalId, name: $name) {
    success
    error
  }
}
    `;
export const useUpdateDatasetNameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDatasetNameMutation, TError, UpdateDatasetNameMutationVariables, TContext>) =>
    useMutation<UpdateDatasetNameMutation, TError, UpdateDatasetNameMutationVariables, TContext>(
      ['UpdateDatasetName'],
      (variables?: UpdateDatasetNameMutationVariables) => fetcher<UpdateDatasetNameMutation, UpdateDatasetNameMutationVariables>(UpdateDatasetNameDocument, variables)(),
      options
    );
useUpdateDatasetNameMutation.getKey = () => ['UpdateDatasetName'];

export const InputOptionsDocument = `
    query InputOptions($datasetSubjects: Boolean = false, $personalData: Boolean = false, $specialCategories: Boolean = false, $supplierTypes: Boolean = false, $functions: Boolean = false, $systemTypes: Boolean = false, $industries: Boolean = false, $capabilities: Boolean = false, $roles: Boolean = false, $tags: Boolean = false, $regions: Boolean = false, $documentTypes: Boolean = false, $riskSources: Boolean = false, $businessImpactLevels: Boolean = false, $systemRiskLevels: Boolean = false, $businessModels: Boolean = false) {
  industries: option(name: Industry) @include(if: $industries) {
    ...temporaryInputType
  }
  functions: option(name: Function) @include(if: $functions) {
    ...temporaryInputType
  }
  systemTypes: option(name: SystemType) @include(if: $systemTypes) {
    ...temporaryInputType
  }
  capabilities: option(type: "Tasks") @include(if: $capabilities) {
    ...temporaryInputType
  }
  roles: option(name: OrganisationRole) @include(if: $roles) {
    ...temporaryInputType
  }
  tags: option(name: CoverageTag) @include(if: $tags) {
    ...temporaryInputType
  }
  regions: option(type: "Region") @include(if: $regions) {
    ...temporaryInputType
  }
  datasetSubjects: option(name: DatasetSubject) @include(if: $datasetSubjects) {
    ...temporaryInputType
  }
  personalData: option(name: PersonalData) @include(if: $personalData) {
    ...temporaryInputType
  }
  specialCategories: option(name: SpecialCategories) @include(if: $specialCategories) {
    ...temporaryInputType
  }
  supplierTypes: option(name: SupplierType) @include(if: $supplierTypes) {
    ...temporaryInputType
  }
  documentTypes: option(name: DocumentType) @include(if: $documentTypes) {
    ...temporaryInputType
  }
  riskSources: option(name: RiskSource) @include(if: $riskSources) {
    ...temporaryInputType
  }
  businessImpactLevels: option(name: BusinessImpactLevel) @include(if: $businessImpactLevels) {
    ...temporaryInputType
  }
  systemRiskLevels: option(name: SystemRiskLevel) @include(if: $systemRiskLevels) {
    ...temporaryInputType
  }
  businessModels: option(name: BusinessModels) @include(if: $businessModels) {
    ...temporaryInputType
  }
}
    ${TemporaryInputTypeFragmentDoc}`;
export const useInputOptionsQuery = <
      TData = InputOptionsQuery,
      TError = unknown
    >(
      variables?: InputOptionsQueryVariables,
      options?: UseQueryOptions<InputOptionsQuery, TError, TData>
    ) =>
    useQuery<InputOptionsQuery, TError, TData>(
      variables === undefined ? ['InputOptions'] : ['InputOptions', variables],
      fetcher<InputOptionsQuery, InputOptionsQueryVariables>(InputOptionsDocument, variables),
      options
    );
useInputOptionsQuery.document = InputOptionsDocument;


useInputOptionsQuery.getKey = (variables?: InputOptionsQueryVariables) => variables === undefined ? ['InputOptions'] : ['InputOptions', variables];
;

export const useInfiniteInputOptionsQuery = <
      TData = InputOptionsQuery,
      TError = unknown
    >(
      pageParamKey: keyof InputOptionsQueryVariables,
      variables?: InputOptionsQueryVariables,
      options?: UseInfiniteQueryOptions<InputOptionsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<InputOptionsQuery, TError, TData>(
      variables === undefined ? ['InputOptions.infinite'] : ['InputOptions.infinite', variables],
      (metaData) => fetcher<InputOptionsQuery, InputOptionsQueryVariables>(InputOptionsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteInputOptionsQuery.getKey = (variables?: InputOptionsQueryVariables) => variables === undefined ? ['InputOptions.infinite'] : ['InputOptions.infinite', variables];
;

export const CreateDatasetDocument = `
    mutation CreateDataset($createDatasetInput: CreateDatasetInput!) {
  createDataset(createDatasetInput: $createDatasetInput) {
    success
  }
}
    `;
export const useCreateDatasetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDatasetMutation, TError, CreateDatasetMutationVariables, TContext>) =>
    useMutation<CreateDatasetMutation, TError, CreateDatasetMutationVariables, TContext>(
      ['CreateDataset'],
      (variables?: CreateDatasetMutationVariables) => fetcher<CreateDatasetMutation, CreateDatasetMutationVariables>(CreateDatasetDocument, variables)(),
      options
    );
useCreateDatasetMutation.getKey = () => ['CreateDataset'];

export const ToggleBookmarkDocument = `
    mutation ToggleBookmark($type: BookmarkType!, $id: String!) {
  toggleBookmark(type: $type, id: $id) {
    status
    success
    error
  }
}
    `;
export const useToggleBookmarkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ToggleBookmarkMutation, TError, ToggleBookmarkMutationVariables, TContext>) =>
    useMutation<ToggleBookmarkMutation, TError, ToggleBookmarkMutationVariables, TContext>(
      ['ToggleBookmark'],
      (variables?: ToggleBookmarkMutationVariables) => fetcher<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>(ToggleBookmarkDocument, variables)(),
      options
    );
useToggleBookmarkMutation.getKey = () => ['ToggleBookmark'];

export const DatasetsPageDocument = `
    query DatasetsPage($query: String, $limit: Int, $offset: Int, $orderBy: JSON) {
  datasets(query: $query, limit: $limit, offset: $offset, orderBy: $orderBy) {
    nodes {
      externalId
      name
      owner
      subject
      modality
      isBookmarked
      systems {
        externalId
      }
    }
    pageInfo {
      totalCount
    }
  }
}
    `;
export const useDatasetsPageQuery = <
      TData = DatasetsPageQuery,
      TError = unknown
    >(
      variables?: DatasetsPageQueryVariables,
      options?: UseQueryOptions<DatasetsPageQuery, TError, TData>
    ) =>
    useQuery<DatasetsPageQuery, TError, TData>(
      variables === undefined ? ['DatasetsPage'] : ['DatasetsPage', variables],
      fetcher<DatasetsPageQuery, DatasetsPageQueryVariables>(DatasetsPageDocument, variables),
      options
    );
useDatasetsPageQuery.document = DatasetsPageDocument;


useDatasetsPageQuery.getKey = (variables?: DatasetsPageQueryVariables) => variables === undefined ? ['DatasetsPage'] : ['DatasetsPage', variables];
;

export const useInfiniteDatasetsPageQuery = <
      TData = DatasetsPageQuery,
      TError = unknown
    >(
      pageParamKey: keyof DatasetsPageQueryVariables,
      variables?: DatasetsPageQueryVariables,
      options?: UseInfiniteQueryOptions<DatasetsPageQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<DatasetsPageQuery, TError, TData>(
      variables === undefined ? ['DatasetsPage.infinite'] : ['DatasetsPage.infinite', variables],
      (metaData) => fetcher<DatasetsPageQuery, DatasetsPageQueryVariables>(DatasetsPageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteDatasetsPageQuery.getKey = (variables?: DatasetsPageQueryVariables) => variables === undefined ? ['DatasetsPage.infinite'] : ['DatasetsPage.infinite', variables];
;

export const DeleteDatasetDocument = `
    mutation DeleteDataset($externalId: String!) {
  deleteDataset(externalId: $externalId) {
    success
    error
  }
}
    `;
export const useDeleteDatasetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteDatasetMutation, TError, DeleteDatasetMutationVariables, TContext>) =>
    useMutation<DeleteDatasetMutation, TError, DeleteDatasetMutationVariables, TContext>(
      ['DeleteDataset'],
      (variables?: DeleteDatasetMutationVariables) => fetcher<DeleteDatasetMutation, DeleteDatasetMutationVariables>(DeleteDatasetDocument, variables)(),
      options
    );
useDeleteDatasetMutation.getKey = () => ['DeleteDataset'];

export const MoveDatasetsToSpaceDocument = `
    mutation MoveDatasetsToSpace($targetSpaceId: String!, $datasetIds: [String!]!) {
  moveDatasetsToSpace(targetSpaceId: $targetSpaceId, datasetIds: $datasetIds) {
    success
    error
    data
  }
}
    `;
export const useMoveDatasetsToSpaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MoveDatasetsToSpaceMutation, TError, MoveDatasetsToSpaceMutationVariables, TContext>) =>
    useMutation<MoveDatasetsToSpaceMutation, TError, MoveDatasetsToSpaceMutationVariables, TContext>(
      ['MoveDatasetsToSpace'],
      (variables?: MoveDatasetsToSpaceMutationVariables) => fetcher<MoveDatasetsToSpaceMutation, MoveDatasetsToSpaceMutationVariables>(MoveDatasetsToSpaceDocument, variables)(),
      options
    );
useMoveDatasetsToSpaceMutation.getKey = () => ['MoveDatasetsToSpace'];

export const OrgUsersDocument = `
    query OrgUsers {
  listUsers {
    firstName
    lastName
    email
    externalId
    fullName
    txtIdentifier
  }
}
    `;
export const useOrgUsersQuery = <
      TData = OrgUsersQuery,
      TError = unknown
    >(
      variables?: OrgUsersQueryVariables,
      options?: UseQueryOptions<OrgUsersQuery, TError, TData>
    ) =>
    useQuery<OrgUsersQuery, TError, TData>(
      variables === undefined ? ['OrgUsers'] : ['OrgUsers', variables],
      fetcher<OrgUsersQuery, OrgUsersQueryVariables>(OrgUsersDocument, variables),
      options
    );
useOrgUsersQuery.document = OrgUsersDocument;


useOrgUsersQuery.getKey = (variables?: OrgUsersQueryVariables) => variables === undefined ? ['OrgUsers'] : ['OrgUsers', variables];
;

export const useInfiniteOrgUsersQuery = <
      TData = OrgUsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrgUsersQueryVariables,
      variables?: OrgUsersQueryVariables,
      options?: UseInfiniteQueryOptions<OrgUsersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrgUsersQuery, TError, TData>(
      variables === undefined ? ['OrgUsers.infinite'] : ['OrgUsers.infinite', variables],
      (metaData) => fetcher<OrgUsersQuery, OrgUsersQueryVariables>(OrgUsersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrgUsersQuery.getKey = (variables?: OrgUsersQueryVariables) => variables === undefined ? ['OrgUsers.infinite'] : ['OrgUsers.infinite', variables];
;

export const CreateSystemPoliciesDocument = `
    mutation CreateSystemPolicies($systemId: String!, $policies: [Int!]!) {
  createSystemPolicies(systemId: $systemId, policies: $policies) {
    success
    error
  }
}
    `;
export const useCreateSystemPoliciesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSystemPoliciesMutation, TError, CreateSystemPoliciesMutationVariables, TContext>) =>
    useMutation<CreateSystemPoliciesMutation, TError, CreateSystemPoliciesMutationVariables, TContext>(
      ['CreateSystemPolicies'],
      (variables?: CreateSystemPoliciesMutationVariables) => fetcher<CreateSystemPoliciesMutation, CreateSystemPoliciesMutationVariables>(CreateSystemPoliciesDocument, variables)(),
      options
    );
useCreateSystemPoliciesMutation.getKey = () => ['CreateSystemPolicies'];

export const CreateSystemTemplateDocument = `
    mutation CreateSystemTemplate($systemPolicyId: String!, $sourceTemplateId: Int!) {
  addTemplateToPolicy(
    policyId: $systemPolicyId
    sourceTemplateId: $sourceTemplateId
  ) {
    success
    error
    data
  }
}
    `;
export const useCreateSystemTemplateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSystemTemplateMutation, TError, CreateSystemTemplateMutationVariables, TContext>) =>
    useMutation<CreateSystemTemplateMutation, TError, CreateSystemTemplateMutationVariables, TContext>(
      ['CreateSystemTemplate'],
      (variables?: CreateSystemTemplateMutationVariables) => fetcher<CreateSystemTemplateMutation, CreateSystemTemplateMutationVariables>(CreateSystemTemplateDocument, variables)(),
      options
    );
useCreateSystemTemplateMutation.getKey = () => ['CreateSystemTemplate'];

export const DeleteSystemPolicyDocument = `
    mutation DeleteSystemPolicy($policyId: String!) {
  deleteSystemPolicy(policyId: $policyId) {
    success
    error
  }
}
    `;
export const useDeleteSystemPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSystemPolicyMutation, TError, DeleteSystemPolicyMutationVariables, TContext>) =>
    useMutation<DeleteSystemPolicyMutation, TError, DeleteSystemPolicyMutationVariables, TContext>(
      ['DeleteSystemPolicy'],
      (variables?: DeleteSystemPolicyMutationVariables) => fetcher<DeleteSystemPolicyMutation, DeleteSystemPolicyMutationVariables>(DeleteSystemPolicyDocument, variables)(),
      options
    );
useDeleteSystemPolicyMutation.getKey = () => ['DeleteSystemPolicy'];

export const DeleteTemplateFromPolicyDocument = `
    mutation DeleteTemplateFromPolicy($templateExternalId: String!) {
  deleteTemplateFromPolicy(templateId: $templateExternalId) {
    success
    error
  }
}
    `;
export const useDeleteTemplateFromPolicyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteTemplateFromPolicyMutation, TError, DeleteTemplateFromPolicyMutationVariables, TContext>) =>
    useMutation<DeleteTemplateFromPolicyMutation, TError, DeleteTemplateFromPolicyMutationVariables, TContext>(
      ['DeleteTemplateFromPolicy'],
      (variables?: DeleteTemplateFromPolicyMutationVariables) => fetcher<DeleteTemplateFromPolicyMutation, DeleteTemplateFromPolicyMutationVariables>(DeleteTemplateFromPolicyDocument, variables)(),
      options
    );
useDeleteTemplateFromPolicyMutation.getKey = () => ['DeleteTemplateFromPolicy'];

export const GetLibraryTemplateDocument = `
    query GetLibraryTemplate($templateId: Int!) {
  template(templateId: $templateId) {
    externalId
    name
    requirements {
      externalId: cuid
      name
      description
      requirementSections {
        cuid
        autoid
        name
        description
      }
      controlsCount
      controls {
        externalId
        name
        evidenceBlocks {
          id
          name
          description
        }
      }
    }
  }
}
    `;
export const useGetLibraryTemplateQuery = <
      TData = GetLibraryTemplateQuery,
      TError = unknown
    >(
      variables: GetLibraryTemplateQueryVariables,
      options?: UseQueryOptions<GetLibraryTemplateQuery, TError, TData>
    ) =>
    useQuery<GetLibraryTemplateQuery, TError, TData>(
      ['GetLibraryTemplate', variables],
      fetcher<GetLibraryTemplateQuery, GetLibraryTemplateQueryVariables>(GetLibraryTemplateDocument, variables),
      options
    );
useGetLibraryTemplateQuery.document = GetLibraryTemplateDocument;


useGetLibraryTemplateQuery.getKey = (variables: GetLibraryTemplateQueryVariables) => ['GetLibraryTemplate', variables];
;

export const useInfiniteGetLibraryTemplateQuery = <
      TData = GetLibraryTemplateQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetLibraryTemplateQueryVariables,
      variables: GetLibraryTemplateQueryVariables,
      options?: UseInfiniteQueryOptions<GetLibraryTemplateQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetLibraryTemplateQuery, TError, TData>(
      ['GetLibraryTemplate.infinite', variables],
      (metaData) => fetcher<GetLibraryTemplateQuery, GetLibraryTemplateQueryVariables>(GetLibraryTemplateDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLibraryTemplateQuery.getKey = (variables: GetLibraryTemplateQueryVariables) => ['GetLibraryTemplate.infinite', variables];
;

export const GetPolicyTemplatesDocument = `
    query GetPolicyTemplates($policyId: Int!, $systemId: String, $query: String, $filterBy: JSON) {
  templates(
    policyId: $policyId
    query: $query
    systemId: $systemId
    filterBy: $filterBy
  ) {
    pageInfo {
      totalCount
    }
    nodes {
      autoid
      externalId
      name
      description
      roles {
        name
      }
      coverageTags
      isRecommended
      updatedAt
      controlsCount
    }
  }
}
    `;
export const useGetPolicyTemplatesQuery = <
      TData = GetPolicyTemplatesQuery,
      TError = unknown
    >(
      variables: GetPolicyTemplatesQueryVariables,
      options?: UseQueryOptions<GetPolicyTemplatesQuery, TError, TData>
    ) =>
    useQuery<GetPolicyTemplatesQuery, TError, TData>(
      ['GetPolicyTemplates', variables],
      fetcher<GetPolicyTemplatesQuery, GetPolicyTemplatesQueryVariables>(GetPolicyTemplatesDocument, variables),
      options
    );
useGetPolicyTemplatesQuery.document = GetPolicyTemplatesDocument;


useGetPolicyTemplatesQuery.getKey = (variables: GetPolicyTemplatesQueryVariables) => ['GetPolicyTemplates', variables];
;

export const useInfiniteGetPolicyTemplatesQuery = <
      TData = GetPolicyTemplatesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetPolicyTemplatesQueryVariables,
      variables: GetPolicyTemplatesQueryVariables,
      options?: UseInfiniteQueryOptions<GetPolicyTemplatesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetPolicyTemplatesQuery, TError, TData>(
      ['GetPolicyTemplates.infinite', variables],
      (metaData) => fetcher<GetPolicyTemplatesQuery, GetPolicyTemplatesQueryVariables>(GetPolicyTemplatesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetPolicyTemplatesQuery.getKey = (variables: GetPolicyTemplatesQueryVariables) => ['GetPolicyTemplates.infinite', variables];
;

export const GetSystemPoliciesDocument = `
    query GetSystemPolicies($systemId: String!, $orderBy: SortItemsBy, $query: String, $filters: [SystemPoliciesFilter!]) {
  systemPolicies(
    systemId: $systemId
    includeTemplates: true
    orderBy: $orderBy
    query: $query
    filters: $filters
  ) {
    total
    items {
      externalId
      numberOfTemplatesInUse
      sourcePolicy {
        id
        autoid
        name
        owner {
          name
          logo
        }
        templatesCount
      }
      systemTemplates {
        externalId
        updatedAt
        sourceTemplate {
          name
        }
        sourceControlsCount
        controlsInProgressCount
        completedControlsCount
      }
    }
  }
}
    `;
export const useGetSystemPoliciesQuery = <
      TData = GetSystemPoliciesQuery,
      TError = unknown
    >(
      variables: GetSystemPoliciesQueryVariables,
      options?: UseQueryOptions<GetSystemPoliciesQuery, TError, TData>
    ) =>
    useQuery<GetSystemPoliciesQuery, TError, TData>(
      ['GetSystemPolicies', variables],
      fetcher<GetSystemPoliciesQuery, GetSystemPoliciesQueryVariables>(GetSystemPoliciesDocument, variables),
      options
    );
useGetSystemPoliciesQuery.document = GetSystemPoliciesDocument;


useGetSystemPoliciesQuery.getKey = (variables: GetSystemPoliciesQueryVariables) => ['GetSystemPolicies', variables];
;

export const useInfiniteGetSystemPoliciesQuery = <
      TData = GetSystemPoliciesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSystemPoliciesQueryVariables,
      variables: GetSystemPoliciesQueryVariables,
      options?: UseInfiniteQueryOptions<GetSystemPoliciesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSystemPoliciesQuery, TError, TData>(
      ['GetSystemPolicies.infinite', variables],
      (metaData) => fetcher<GetSystemPoliciesQuery, GetSystemPoliciesQueryVariables>(GetSystemPoliciesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSystemPoliciesQuery.getKey = (variables: GetSystemPoliciesQueryVariables) => ['GetSystemPolicies.infinite', variables];
;

export const GetGovernSystemTemplateDocument = `
    query GetGovernSystemTemplate($templateId: String!) {
  governSystemTemplate(templateId: $templateId) {
    id
    sourceTemplate {
      autoid
      name
    }
  }
}
    `;
export const useGetGovernSystemTemplateQuery = <
      TData = GetGovernSystemTemplateQuery,
      TError = unknown
    >(
      variables: GetGovernSystemTemplateQueryVariables,
      options?: UseQueryOptions<GetGovernSystemTemplateQuery, TError, TData>
    ) =>
    useQuery<GetGovernSystemTemplateQuery, TError, TData>(
      ['GetGovernSystemTemplate', variables],
      fetcher<GetGovernSystemTemplateQuery, GetGovernSystemTemplateQueryVariables>(GetGovernSystemTemplateDocument, variables),
      options
    );
useGetGovernSystemTemplateQuery.document = GetGovernSystemTemplateDocument;


useGetGovernSystemTemplateQuery.getKey = (variables: GetGovernSystemTemplateQueryVariables) => ['GetGovernSystemTemplate', variables];
;

export const useInfiniteGetGovernSystemTemplateQuery = <
      TData = GetGovernSystemTemplateQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSystemTemplateQueryVariables,
      variables: GetGovernSystemTemplateQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSystemTemplateQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSystemTemplateQuery, TError, TData>(
      ['GetGovernSystemTemplate.infinite', variables],
      (metaData) => fetcher<GetGovernSystemTemplateQuery, GetGovernSystemTemplateQueryVariables>(GetGovernSystemTemplateDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSystemTemplateQuery.getKey = (variables: GetGovernSystemTemplateQueryVariables) => ['GetGovernSystemTemplate.infinite', variables];
;

export const AddLibraryRisksToSystemDocument = `
    mutation AddLibraryRisksToSystem($systemId: String!, $libraryRisks: [Int!]!) {
  addLibraryRisksToSystem(systemId: $systemId, libraryRisks: $libraryRisks) {
    success
  }
}
    `;
export const useAddLibraryRisksToSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddLibraryRisksToSystemMutation, TError, AddLibraryRisksToSystemMutationVariables, TContext>) =>
    useMutation<AddLibraryRisksToSystemMutation, TError, AddLibraryRisksToSystemMutationVariables, TContext>(
      ['AddLibraryRisksToSystem'],
      (variables?: AddLibraryRisksToSystemMutationVariables) => fetcher<AddLibraryRisksToSystemMutation, AddLibraryRisksToSystemMutationVariables>(AddLibraryRisksToSystemDocument, variables)(),
      options
    );
useAddLibraryRisksToSystemMutation.getKey = () => ['AddLibraryRisksToSystem'];

export const GetAvailableLibraryRisksForSystemDocument = `
    query GetAvailableLibraryRisksForSystem($filterBy: JSON, $query: String, $systemId: String!) {
  library {
    risks(filterBy: $filterBy, query: $query, systemId: $systemId) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          id
          autoid
          riskId
          isFollowed
          name
          description
          updatedAt
          type
          industry
          mitigations {
            id
          }
          isRecommended(systemId: $systemId)
        }
      }
    }
  }
}
    `;
export const useGetAvailableLibraryRisksForSystemQuery = <
      TData = GetAvailableLibraryRisksForSystemQuery,
      TError = unknown
    >(
      variables: GetAvailableLibraryRisksForSystemQueryVariables,
      options?: UseQueryOptions<GetAvailableLibraryRisksForSystemQuery, TError, TData>
    ) =>
    useQuery<GetAvailableLibraryRisksForSystemQuery, TError, TData>(
      ['GetAvailableLibraryRisksForSystem', variables],
      fetcher<GetAvailableLibraryRisksForSystemQuery, GetAvailableLibraryRisksForSystemQueryVariables>(GetAvailableLibraryRisksForSystemDocument, variables),
      options
    );
useGetAvailableLibraryRisksForSystemQuery.document = GetAvailableLibraryRisksForSystemDocument;


useGetAvailableLibraryRisksForSystemQuery.getKey = (variables: GetAvailableLibraryRisksForSystemQueryVariables) => ['GetAvailableLibraryRisksForSystem', variables];
;

export const useInfiniteGetAvailableLibraryRisksForSystemQuery = <
      TData = GetAvailableLibraryRisksForSystemQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetAvailableLibraryRisksForSystemQueryVariables,
      variables: GetAvailableLibraryRisksForSystemQueryVariables,
      options?: UseInfiniteQueryOptions<GetAvailableLibraryRisksForSystemQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetAvailableLibraryRisksForSystemQuery, TError, TData>(
      ['GetAvailableLibraryRisksForSystem.infinite', variables],
      (metaData) => fetcher<GetAvailableLibraryRisksForSystemQuery, GetAvailableLibraryRisksForSystemQueryVariables>(GetAvailableLibraryRisksForSystemDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetAvailableLibraryRisksForSystemQuery.getKey = (variables: GetAvailableLibraryRisksForSystemQueryVariables) => ['GetAvailableLibraryRisksForSystem.infinite', variables];
;

export const AddGovernAttachmentDocument = `
    mutation AddGovernAttachment($input: CreateGovernAttachmentInput!) {
  createAttachment(createAttachmentInput: $input) {
    success
    error
    data
  }
}
    `;
export const useAddGovernAttachmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddGovernAttachmentMutation, TError, AddGovernAttachmentMutationVariables, TContext>) =>
    useMutation<AddGovernAttachmentMutation, TError, AddGovernAttachmentMutationVariables, TContext>(
      ['AddGovernAttachment'],
      (variables?: AddGovernAttachmentMutationVariables) => fetcher<AddGovernAttachmentMutation, AddGovernAttachmentMutationVariables>(AddGovernAttachmentDocument, variables)(),
      options
    );
useAddGovernAttachmentMutation.getKey = () => ['AddGovernAttachment'];

export const AddGovernSystemDocument = `
    mutation AddGovernSystem($input: CreateGovernSystemInput!) {
  createGovernSystem(createGovernSystemInput: $input) {
    success
    error
    data {
      externalId
    }
  }
}
    `;
export const useAddGovernSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddGovernSystemMutation, TError, AddGovernSystemMutationVariables, TContext>) =>
    useMutation<AddGovernSystemMutation, TError, AddGovernSystemMutationVariables, TContext>(
      ['AddGovernSystem'],
      (variables?: AddGovernSystemMutationVariables) => fetcher<AddGovernSystemMutation, AddGovernSystemMutationVariables>(AddGovernSystemDocument, variables)(),
      options
    );
useAddGovernSystemMutation.getKey = () => ['AddGovernSystem'];

export const AddSystemDatasetDocument = `
    mutation AddSystemDataset($addSystemDatasetInput: AddSystemDatasetInput!) {
  addSystemDataset(addSystemDatasetInput: $addSystemDatasetInput) {
    success
    error
  }
}
    `;
export const useAddSystemDatasetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddSystemDatasetMutation, TError, AddSystemDatasetMutationVariables, TContext>) =>
    useMutation<AddSystemDatasetMutation, TError, AddSystemDatasetMutationVariables, TContext>(
      ['AddSystemDataset'],
      (variables?: AddSystemDatasetMutationVariables) => fetcher<AddSystemDatasetMutation, AddSystemDatasetMutationVariables>(AddSystemDatasetDocument, variables)(),
      options
    );
useAddSystemDatasetMutation.getKey = () => ['AddSystemDataset'];

export const DeleteGovernAttachmentDocument = `
    mutation DeleteGovernAttachment($externalId: String!) {
  deleteAttachment(externalId: $externalId) {
    success
    error
  }
}
    `;
export const useDeleteGovernAttachmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGovernAttachmentMutation, TError, DeleteGovernAttachmentMutationVariables, TContext>) =>
    useMutation<DeleteGovernAttachmentMutation, TError, DeleteGovernAttachmentMutationVariables, TContext>(
      ['DeleteGovernAttachment'],
      (variables?: DeleteGovernAttachmentMutationVariables) => fetcher<DeleteGovernAttachmentMutation, DeleteGovernAttachmentMutationVariables>(DeleteGovernAttachmentDocument, variables)(),
      options
    );
useDeleteGovernAttachmentMutation.getKey = () => ['DeleteGovernAttachment'];

export const DeleteGovernSystemDocument = `
    mutation DeleteGovernSystem($id: String!) {
  deleteGovernSystem(id: $id) {
    success
    error
    data
  }
}
    `;
export const useDeleteGovernSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGovernSystemMutation, TError, DeleteGovernSystemMutationVariables, TContext>) =>
    useMutation<DeleteGovernSystemMutation, TError, DeleteGovernSystemMutationVariables, TContext>(
      ['DeleteGovernSystem'],
      (variables?: DeleteGovernSystemMutationVariables) => fetcher<DeleteGovernSystemMutation, DeleteGovernSystemMutationVariables>(DeleteGovernSystemDocument, variables)(),
      options
    );
useDeleteGovernSystemMutation.getKey = () => ['DeleteGovernSystem'];

export const DeleteGovernSystemRiskDocument = `
    mutation DeleteGovernSystemRisk($id: String!) {
  deleteRisk(id: $id) {
    success
    error
  }
}
    `;
export const useDeleteGovernSystemRiskMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteGovernSystemRiskMutation, TError, DeleteGovernSystemRiskMutationVariables, TContext>) =>
    useMutation<DeleteGovernSystemRiskMutation, TError, DeleteGovernSystemRiskMutationVariables, TContext>(
      ['DeleteGovernSystemRisk'],
      (variables?: DeleteGovernSystemRiskMutationVariables) => fetcher<DeleteGovernSystemRiskMutation, DeleteGovernSystemRiskMutationVariables>(DeleteGovernSystemRiskDocument, variables)(),
      options
    );
useDeleteGovernSystemRiskMutation.getKey = () => ['DeleteGovernSystemRisk'];

export const DeleteManyGovernSystemRisksDocument = `
    mutation DeleteManyGovernSystemRisks($ids: [ID!]!) {
  deleteManyRisks(ids: $ids) {
    success
    count
    error
  }
}
    `;
export const useDeleteManyGovernSystemRisksMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteManyGovernSystemRisksMutation, TError, DeleteManyGovernSystemRisksMutationVariables, TContext>) =>
    useMutation<DeleteManyGovernSystemRisksMutation, TError, DeleteManyGovernSystemRisksMutationVariables, TContext>(
      ['DeleteManyGovernSystemRisks'],
      (variables?: DeleteManyGovernSystemRisksMutationVariables) => fetcher<DeleteManyGovernSystemRisksMutation, DeleteManyGovernSystemRisksMutationVariables>(DeleteManyGovernSystemRisksDocument, variables)(),
      options
    );
useDeleteManyGovernSystemRisksMutation.getKey = () => ['DeleteManyGovernSystemRisks'];

export const EditGovernSystemDocument = `
    mutation EditGovernSystem($id: String!, $version: Int!, $input: JSONObject!) {
  updateGovernSystem(id: $id, version: $version, input: $input) {
    data
    error
    success
  }
}
    `;
export const useEditGovernSystemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EditGovernSystemMutation, TError, EditGovernSystemMutationVariables, TContext>) =>
    useMutation<EditGovernSystemMutation, TError, EditGovernSystemMutationVariables, TContext>(
      ['EditGovernSystem'],
      (variables?: EditGovernSystemMutationVariables) => fetcher<EditGovernSystemMutation, EditGovernSystemMutationVariables>(EditGovernSystemDocument, variables)(),
      options
    );
useEditGovernSystemMutation.getKey = () => ['EditGovernSystem'];

export const GetLibraryMitigationsByRiskDocument = `
    query GetLibraryMitigationsByRisk($riskId: String!) {
  libraryMitigations(riskId: $riskId) {
    cuid
    name
    description
  }
}
    `;
export const useGetLibraryMitigationsByRiskQuery = <
      TData = GetLibraryMitigationsByRiskQuery,
      TError = unknown
    >(
      variables: GetLibraryMitigationsByRiskQueryVariables,
      options?: UseQueryOptions<GetLibraryMitigationsByRiskQuery, TError, TData>
    ) =>
    useQuery<GetLibraryMitigationsByRiskQuery, TError, TData>(
      ['GetLibraryMitigationsByRisk', variables],
      fetcher<GetLibraryMitigationsByRiskQuery, GetLibraryMitigationsByRiskQueryVariables>(GetLibraryMitigationsByRiskDocument, variables),
      options
    );
useGetLibraryMitigationsByRiskQuery.document = GetLibraryMitigationsByRiskDocument;


useGetLibraryMitigationsByRiskQuery.getKey = (variables: GetLibraryMitigationsByRiskQueryVariables) => ['GetLibraryMitigationsByRisk', variables];
;

export const useInfiniteGetLibraryMitigationsByRiskQuery = <
      TData = GetLibraryMitigationsByRiskQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetLibraryMitigationsByRiskQueryVariables,
      variables: GetLibraryMitigationsByRiskQueryVariables,
      options?: UseInfiniteQueryOptions<GetLibraryMitigationsByRiskQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetLibraryMitigationsByRiskQuery, TError, TData>(
      ['GetLibraryMitigationsByRisk.infinite', variables],
      (metaData) => fetcher<GetLibraryMitigationsByRiskQuery, GetLibraryMitigationsByRiskQueryVariables>(GetLibraryMitigationsByRiskDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetLibraryMitigationsByRiskQuery.getKey = (variables: GetLibraryMitigationsByRiskQueryVariables) => ['GetLibraryMitigationsByRisk.infinite', variables];
;

export const GetSortedLibraryPoliciesDocument = `
    query GetSortedLibraryPolicies($orderBy: JSON, $filterBy: JSON, $query: String, $systemId: String) {
  library {
    policies(
      orderBy: $orderBy
      filterBy: $filterBy
      query: $query
      systemId: $systemId
    ) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          id
          autoid
          policyId
          name
          overview
          updatedAt
          type
          regions
          countries
          usStates
          owner {
            name
            logo
          }
          sector
          requirements {
            id
            requirementId
            createdAt
          }
          isFollowed
          follows {
            cuid
            userId
          }
          isRecommended(systemId: $systemId)
        }
      }
    }
  }
}
    `;
export const useGetSortedLibraryPoliciesQuery = <
      TData = GetSortedLibraryPoliciesQuery,
      TError = unknown
    >(
      variables?: GetSortedLibraryPoliciesQueryVariables,
      options?: UseQueryOptions<GetSortedLibraryPoliciesQuery, TError, TData>
    ) =>
    useQuery<GetSortedLibraryPoliciesQuery, TError, TData>(
      variables === undefined ? ['GetSortedLibraryPolicies'] : ['GetSortedLibraryPolicies', variables],
      fetcher<GetSortedLibraryPoliciesQuery, GetSortedLibraryPoliciesQueryVariables>(GetSortedLibraryPoliciesDocument, variables),
      options
    );
useGetSortedLibraryPoliciesQuery.document = GetSortedLibraryPoliciesDocument;


useGetSortedLibraryPoliciesQuery.getKey = (variables?: GetSortedLibraryPoliciesQueryVariables) => variables === undefined ? ['GetSortedLibraryPolicies'] : ['GetSortedLibraryPolicies', variables];
;

export const useInfiniteGetSortedLibraryPoliciesQuery = <
      TData = GetSortedLibraryPoliciesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSortedLibraryPoliciesQueryVariables,
      variables?: GetSortedLibraryPoliciesQueryVariables,
      options?: UseInfiniteQueryOptions<GetSortedLibraryPoliciesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetSortedLibraryPoliciesQuery, TError, TData>(
      variables === undefined ? ['GetSortedLibraryPolicies.infinite'] : ['GetSortedLibraryPolicies.infinite', variables],
      (metaData) => fetcher<GetSortedLibraryPoliciesQuery, GetSortedLibraryPoliciesQueryVariables>(GetSortedLibraryPoliciesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetSortedLibraryPoliciesQuery.getKey = (variables?: GetSortedLibraryPoliciesQueryVariables) => variables === undefined ? ['GetSortedLibraryPolicies.infinite'] : ['GetSortedLibraryPolicies.infinite', variables];
;

export const GetGovernSystemDocument = `
    query GetGovernSystem($id: String!) {
  governSystem(id: $id) {
    version
    externalId
    name
    description
    uid
    risk
    riskJustification
    impact
    impactJustification
    functions
    role
    tags
    regions
    industries
    types
    capabilities
    id
    isBookmarked
    isEditable
    createdAt
    updatedAt
    createdByUser {
      firstName
      lastName
      email
      color
    }
    contact {
      address
      phone
      email
    }
    linkedLibraryModels {
      cuid
      name
      owner {
        name
        logo
      }
    }
    risks {
      externalId
      title
      description
      sources
      marginalRiskLevel
      mitigations {
        externalId
        name
        description
        status
      }
      owner {
        email
      }
    }
  }
}
    `;
export const useGetGovernSystemQuery = <
      TData = GetGovernSystemQuery,
      TError = unknown
    >(
      variables: GetGovernSystemQueryVariables,
      options?: UseQueryOptions<GetGovernSystemQuery, TError, TData>
    ) =>
    useQuery<GetGovernSystemQuery, TError, TData>(
      ['GetGovernSystem', variables],
      fetcher<GetGovernSystemQuery, GetGovernSystemQueryVariables>(GetGovernSystemDocument, variables),
      options
    );
useGetGovernSystemQuery.document = GetGovernSystemDocument;


useGetGovernSystemQuery.getKey = (variables: GetGovernSystemQueryVariables) => ['GetGovernSystem', variables];
;

export const useInfiniteGetGovernSystemQuery = <
      TData = GetGovernSystemQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSystemQueryVariables,
      variables: GetGovernSystemQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSystemQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSystemQuery, TError, TData>(
      ['GetGovernSystem.infinite', variables],
      (metaData) => fetcher<GetGovernSystemQuery, GetGovernSystemQueryVariables>(GetGovernSystemDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSystemQuery.getKey = (variables: GetGovernSystemQueryVariables) => ['GetGovernSystem.infinite', variables];
;

export const GovernAttachmentsDocument = `
    query GovernAttachments($targetId: String!, $target: AttachmentTarget!, $query: String, $limit: Int, $offset: Int, $orderBy: JSON) {
  attachments(
    targetId: $targetId
    target: $target
    query: $query
    limit: $limit
    offset: $offset
    orderBy: $orderBy
  ) {
    pageInfo {
      totalCount
    }
    nodes {
      ...GovernAttachmentNode
    }
  }
}
    ${GovernAttachmentNodeFragmentDoc}`;
export const useGovernAttachmentsQuery = <
      TData = GovernAttachmentsQuery,
      TError = unknown
    >(
      variables: GovernAttachmentsQueryVariables,
      options?: UseQueryOptions<GovernAttachmentsQuery, TError, TData>
    ) =>
    useQuery<GovernAttachmentsQuery, TError, TData>(
      ['GovernAttachments', variables],
      fetcher<GovernAttachmentsQuery, GovernAttachmentsQueryVariables>(GovernAttachmentsDocument, variables),
      options
    );
useGovernAttachmentsQuery.document = GovernAttachmentsDocument;


useGovernAttachmentsQuery.getKey = (variables: GovernAttachmentsQueryVariables) => ['GovernAttachments', variables];
;

export const useInfiniteGovernAttachmentsQuery = <
      TData = GovernAttachmentsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GovernAttachmentsQueryVariables,
      variables: GovernAttachmentsQueryVariables,
      options?: UseInfiniteQueryOptions<GovernAttachmentsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GovernAttachmentsQuery, TError, TData>(
      ['GovernAttachments.infinite', variables],
      (metaData) => fetcher<GovernAttachmentsQuery, GovernAttachmentsQueryVariables>(GovernAttachmentsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGovernAttachmentsQuery.getKey = (variables: GovernAttachmentsQueryVariables) => ['GovernAttachments.infinite', variables];
;

export const GovernSystemDatasetDocument = `
    query GovernSystemDataset($externalId: String!) {
  systemDataset(externalId: $externalId) {
    externalId
    purpose
    description
    dataset {
      name
      externalId
    }
  }
}
    `;
export const useGovernSystemDatasetQuery = <
      TData = GovernSystemDatasetQuery,
      TError = unknown
    >(
      variables: GovernSystemDatasetQueryVariables,
      options?: UseQueryOptions<GovernSystemDatasetQuery, TError, TData>
    ) =>
    useQuery<GovernSystemDatasetQuery, TError, TData>(
      ['GovernSystemDataset', variables],
      fetcher<GovernSystemDatasetQuery, GovernSystemDatasetQueryVariables>(GovernSystemDatasetDocument, variables),
      options
    );
useGovernSystemDatasetQuery.document = GovernSystemDatasetDocument;


useGovernSystemDatasetQuery.getKey = (variables: GovernSystemDatasetQueryVariables) => ['GovernSystemDataset', variables];
;

export const useInfiniteGovernSystemDatasetQuery = <
      TData = GovernSystemDatasetQuery,
      TError = unknown
    >(
      pageParamKey: keyof GovernSystemDatasetQueryVariables,
      variables: GovernSystemDatasetQueryVariables,
      options?: UseInfiniteQueryOptions<GovernSystemDatasetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GovernSystemDatasetQuery, TError, TData>(
      ['GovernSystemDataset.infinite', variables],
      (metaData) => fetcher<GovernSystemDatasetQuery, GovernSystemDatasetQueryVariables>(GovernSystemDatasetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGovernSystemDatasetQuery.getKey = (variables: GovernSystemDatasetQueryVariables) => ['GovernSystemDataset.infinite', variables];
;

export const GovernSystemDatasetsDocument = `
    query GovernSystemDatasets($systemId: String!) {
  systemDatasets(systemId: $systemId) {
    externalId
    purpose
    description
    dataset {
      name
      externalId
      isBookmarked
    }
  }
}
    `;
export const useGovernSystemDatasetsQuery = <
      TData = GovernSystemDatasetsQuery,
      TError = unknown
    >(
      variables: GovernSystemDatasetsQueryVariables,
      options?: UseQueryOptions<GovernSystemDatasetsQuery, TError, TData>
    ) =>
    useQuery<GovernSystemDatasetsQuery, TError, TData>(
      ['GovernSystemDatasets', variables],
      fetcher<GovernSystemDatasetsQuery, GovernSystemDatasetsQueryVariables>(GovernSystemDatasetsDocument, variables),
      options
    );
useGovernSystemDatasetsQuery.document = GovernSystemDatasetsDocument;


useGovernSystemDatasetsQuery.getKey = (variables: GovernSystemDatasetsQueryVariables) => ['GovernSystemDatasets', variables];
;

export const useInfiniteGovernSystemDatasetsQuery = <
      TData = GovernSystemDatasetsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GovernSystemDatasetsQueryVariables,
      variables: GovernSystemDatasetsQueryVariables,
      options?: UseInfiniteQueryOptions<GovernSystemDatasetsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GovernSystemDatasetsQuery, TError, TData>(
      ['GovernSystemDatasets.infinite', variables],
      (metaData) => fetcher<GovernSystemDatasetsQuery, GovernSystemDatasetsQueryVariables>(GovernSystemDatasetsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGovernSystemDatasetsQuery.getKey = (variables: GovernSystemDatasetsQueryVariables) => ['GovernSystemDatasets.infinite', variables];
;

export const GetGovernSystemLinksDocument = `
    query GetGovernSystemLinks($systemId: String!) {
  governSystem(id: $systemId) {
    id
    linkedLibraryModels {
      id
      cuid
      autoid
      name
      owner {
        logo
      }
      isFollowed
    }
    linkedGovernSystems {
      externalId
      name
      isBookmarked
    }
  }
}
    `;
export const useGetGovernSystemLinksQuery = <
      TData = GetGovernSystemLinksQuery,
      TError = unknown
    >(
      variables: GetGovernSystemLinksQueryVariables,
      options?: UseQueryOptions<GetGovernSystemLinksQuery, TError, TData>
    ) =>
    useQuery<GetGovernSystemLinksQuery, TError, TData>(
      ['GetGovernSystemLinks', variables],
      fetcher<GetGovernSystemLinksQuery, GetGovernSystemLinksQueryVariables>(GetGovernSystemLinksDocument, variables),
      options
    );
useGetGovernSystemLinksQuery.document = GetGovernSystemLinksDocument;


useGetGovernSystemLinksQuery.getKey = (variables: GetGovernSystemLinksQueryVariables) => ['GetGovernSystemLinks', variables];
;

export const useInfiniteGetGovernSystemLinksQuery = <
      TData = GetGovernSystemLinksQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSystemLinksQueryVariables,
      variables: GetGovernSystemLinksQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSystemLinksQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSystemLinksQuery, TError, TData>(
      ['GetGovernSystemLinks.infinite', variables],
      (metaData) => fetcher<GetGovernSystemLinksQuery, GetGovernSystemLinksQueryVariables>(GetGovernSystemLinksDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSystemLinksQuery.getKey = (variables: GetGovernSystemLinksQueryVariables) => ['GetGovernSystemLinks.infinite', variables];
;

export const GovernSystemRiskSourcesDataDocument = `
    query GovernSystemRiskSourcesData($systemId: String!) {
  systemDatasets(systemId: $systemId) {
    dataset {
      name
      externalId
    }
  }
  governSystem(id: $systemId) {
    externalId
    linkedLibraryModels {
      autoid
      name
      owner {
        name
        logo
      }
    }
  }
}
    `;
export const useGovernSystemRiskSourcesDataQuery = <
      TData = GovernSystemRiskSourcesDataQuery,
      TError = unknown
    >(
      variables: GovernSystemRiskSourcesDataQueryVariables,
      options?: UseQueryOptions<GovernSystemRiskSourcesDataQuery, TError, TData>
    ) =>
    useQuery<GovernSystemRiskSourcesDataQuery, TError, TData>(
      ['GovernSystemRiskSourcesData', variables],
      fetcher<GovernSystemRiskSourcesDataQuery, GovernSystemRiskSourcesDataQueryVariables>(GovernSystemRiskSourcesDataDocument, variables),
      options
    );
useGovernSystemRiskSourcesDataQuery.document = GovernSystemRiskSourcesDataDocument;


useGovernSystemRiskSourcesDataQuery.getKey = (variables: GovernSystemRiskSourcesDataQueryVariables) => ['GovernSystemRiskSourcesData', variables];
;

export const useInfiniteGovernSystemRiskSourcesDataQuery = <
      TData = GovernSystemRiskSourcesDataQuery,
      TError = unknown
    >(
      pageParamKey: keyof GovernSystemRiskSourcesDataQueryVariables,
      variables: GovernSystemRiskSourcesDataQueryVariables,
      options?: UseInfiniteQueryOptions<GovernSystemRiskSourcesDataQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GovernSystemRiskSourcesDataQuery, TError, TData>(
      ['GovernSystemRiskSourcesData.infinite', variables],
      (metaData) => fetcher<GovernSystemRiskSourcesDataQuery, GovernSystemRiskSourcesDataQueryVariables>(GovernSystemRiskSourcesDataDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGovernSystemRiskSourcesDataQuery.getKey = (variables: GovernSystemRiskSourcesDataQueryVariables) => ['GovernSystemRiskSourcesData.infinite', variables];
;

export const GetGovernSystemRisksDocument = `
    query GetGovernSystemRisks($systemId: String!, $query: String) {
  governSystemRisks(systemId: $systemId, query: $query) {
    pageInfo {
      totalCount
    }
    edges {
      node {
        ...GovernSystemRiskTableRow
      }
    }
  }
}
    ${GovernSystemRiskTableRowFragmentDoc}`;
export const useGetGovernSystemRisksQuery = <
      TData = GetGovernSystemRisksQuery,
      TError = unknown
    >(
      variables: GetGovernSystemRisksQueryVariables,
      options?: UseQueryOptions<GetGovernSystemRisksQuery, TError, TData>
    ) =>
    useQuery<GetGovernSystemRisksQuery, TError, TData>(
      ['GetGovernSystemRisks', variables],
      fetcher<GetGovernSystemRisksQuery, GetGovernSystemRisksQueryVariables>(GetGovernSystemRisksDocument, variables),
      options
    );
useGetGovernSystemRisksQuery.document = GetGovernSystemRisksDocument;


useGetGovernSystemRisksQuery.getKey = (variables: GetGovernSystemRisksQueryVariables) => ['GetGovernSystemRisks', variables];
;

export const useInfiniteGetGovernSystemRisksQuery = <
      TData = GetGovernSystemRisksQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSystemRisksQueryVariables,
      variables: GetGovernSystemRisksQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSystemRisksQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSystemRisksQuery, TError, TData>(
      ['GetGovernSystemRisks.infinite', variables],
      (metaData) => fetcher<GetGovernSystemRisksQuery, GetGovernSystemRisksQueryVariables>(GetGovernSystemRisksDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSystemRisksQuery.getKey = (variables: GetGovernSystemRisksQueryVariables) => ['GetGovernSystemRisks.infinite', variables];
;

export const GetGovernSystemSlimDocument = `
    query GetGovernSystemSlim($id: String!) {
  governSystem(id: $id) {
    externalId
    name
    isEditable
  }
}
    `;
export const useGetGovernSystemSlimQuery = <
      TData = GetGovernSystemSlimQuery,
      TError = unknown
    >(
      variables: GetGovernSystemSlimQueryVariables,
      options?: UseQueryOptions<GetGovernSystemSlimQuery, TError, TData>
    ) =>
    useQuery<GetGovernSystemSlimQuery, TError, TData>(
      ['GetGovernSystemSlim', variables],
      fetcher<GetGovernSystemSlimQuery, GetGovernSystemSlimQueryVariables>(GetGovernSystemSlimDocument, variables),
      options
    );
useGetGovernSystemSlimQuery.document = GetGovernSystemSlimDocument;


useGetGovernSystemSlimQuery.getKey = (variables: GetGovernSystemSlimQueryVariables) => ['GetGovernSystemSlim', variables];
;

export const useInfiniteGetGovernSystemSlimQuery = <
      TData = GetGovernSystemSlimQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSystemSlimQueryVariables,
      variables: GetGovernSystemSlimQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSystemSlimQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSystemSlimQuery, TError, TData>(
      ['GetGovernSystemSlim.infinite', variables],
      (metaData) => fetcher<GetGovernSystemSlimQuery, GetGovernSystemSlimQueryVariables>(GetGovernSystemSlimDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSystemSlimQuery.getKey = (variables: GetGovernSystemSlimQueryVariables) => ['GetGovernSystemSlim.infinite', variables];
;

export const GetGovernSystemsDocument = `
    query GetGovernSystems {
  governSystems {
    nodes {
      uid
      version
      externalId
      name
      description
      role
      functions
      risk
      riskJustification
      impact
      impactJustification
      tags
      regions
      industries
      types
      capabilities
      id
    }
  }
}
    `;
export const useGetGovernSystemsQuery = <
      TData = GetGovernSystemsQuery,
      TError = unknown
    >(
      variables?: GetGovernSystemsQueryVariables,
      options?: UseQueryOptions<GetGovernSystemsQuery, TError, TData>
    ) =>
    useQuery<GetGovernSystemsQuery, TError, TData>(
      variables === undefined ? ['GetGovernSystems'] : ['GetGovernSystems', variables],
      fetcher<GetGovernSystemsQuery, GetGovernSystemsQueryVariables>(GetGovernSystemsDocument, variables),
      options
    );
useGetGovernSystemsQuery.document = GetGovernSystemsDocument;


useGetGovernSystemsQuery.getKey = (variables?: GetGovernSystemsQueryVariables) => variables === undefined ? ['GetGovernSystems'] : ['GetGovernSystems', variables];
;

export const useInfiniteGetGovernSystemsQuery = <
      TData = GetGovernSystemsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSystemsQueryVariables,
      variables?: GetGovernSystemsQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSystemsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSystemsQuery, TError, TData>(
      variables === undefined ? ['GetGovernSystems.infinite'] : ['GetGovernSystems.infinite', variables],
      (metaData) => fetcher<GetGovernSystemsQuery, GetGovernSystemsQueryVariables>(GetGovernSystemsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSystemsQuery.getKey = (variables?: GetGovernSystemsQueryVariables) => variables === undefined ? ['GetGovernSystems.infinite'] : ['GetGovernSystems.infinite', variables];
;

export const RemoveSystemDatasetDocument = `
    mutation RemoveSystemDataset($systemDatasetId: String!) {
  removeSystemDataset(systemDatasetId: $systemDatasetId) {
    success
    error
  }
}
    `;
export const useRemoveSystemDatasetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveSystemDatasetMutation, TError, RemoveSystemDatasetMutationVariables, TContext>) =>
    useMutation<RemoveSystemDatasetMutation, TError, RemoveSystemDatasetMutationVariables, TContext>(
      ['RemoveSystemDataset'],
      (variables?: RemoveSystemDatasetMutationVariables) => fetcher<RemoveSystemDatasetMutation, RemoveSystemDatasetMutationVariables>(RemoveSystemDatasetDocument, variables)(),
      options
    );
useRemoveSystemDatasetMutation.getKey = () => ['RemoveSystemDataset'];

export const UpdateGovernAttachmentDocument = `
    mutation UpdateGovernAttachment($externalId: String!, $input: UpdateGovernAttachmentInput!) {
  updateAttachment(externalId: $externalId, updateAttachmentInput: $input) {
    success
    error
  }
}
    `;
export const useUpdateGovernAttachmentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateGovernAttachmentMutation, TError, UpdateGovernAttachmentMutationVariables, TContext>) =>
    useMutation<UpdateGovernAttachmentMutation, TError, UpdateGovernAttachmentMutationVariables, TContext>(
      ['UpdateGovernAttachment'],
      (variables?: UpdateGovernAttachmentMutationVariables) => fetcher<UpdateGovernAttachmentMutation, UpdateGovernAttachmentMutationVariables>(UpdateGovernAttachmentDocument, variables)(),
      options
    );
useUpdateGovernAttachmentMutation.getKey = () => ['UpdateGovernAttachment'];

export const UpdateSystemDatasetDocument = `
    mutation UpdateSystemDataset($updateSystemDatasetInput: UpdateSystemDatasetInput!) {
  updateSystemDataset(updateSystemDatasetInput: $updateSystemDatasetInput) {
    success
    error
  }
}
    `;
export const useUpdateSystemDatasetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSystemDatasetMutation, TError, UpdateSystemDatasetMutationVariables, TContext>) =>
    useMutation<UpdateSystemDatasetMutation, TError, UpdateSystemDatasetMutationVariables, TContext>(
      ['UpdateSystemDataset'],
      (variables?: UpdateSystemDatasetMutationVariables) => fetcher<UpdateSystemDatasetMutation, UpdateSystemDatasetMutationVariables>(UpdateSystemDatasetDocument, variables)(),
      options
    );
useUpdateSystemDatasetMutation.getKey = () => ['UpdateSystemDataset'];

export const AddNewEvidenceBlockDocument = `
    mutation AddNewEvidenceBlock($controlId: String!, $systemId: String!) {
  addNewEvidenceBlock(controlId: $controlId, systemId: $systemId) {
    success
    error
    data
  }
}
    `;
export const useAddNewEvidenceBlockMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddNewEvidenceBlockMutation, TError, AddNewEvidenceBlockMutationVariables, TContext>) =>
    useMutation<AddNewEvidenceBlockMutation, TError, AddNewEvidenceBlockMutationVariables, TContext>(
      ['AddNewEvidenceBlock'],
      (variables?: AddNewEvidenceBlockMutationVariables) => fetcher<AddNewEvidenceBlockMutation, AddNewEvidenceBlockMutationVariables>(AddNewEvidenceBlockDocument, variables)(),
      options
    );
useAddNewEvidenceBlockMutation.getKey = () => ['AddNewEvidenceBlock'];

export const CompleteControlDocument = `
    mutation CompleteControl($controlId: String!) {
  completeControl(controlId: $controlId) {
    success
    error
  }
}
    `;
export const useCompleteControlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompleteControlMutation, TError, CompleteControlMutationVariables, TContext>) =>
    useMutation<CompleteControlMutation, TError, CompleteControlMutationVariables, TContext>(
      ['CompleteControl'],
      (variables?: CompleteControlMutationVariables) => fetcher<CompleteControlMutation, CompleteControlMutationVariables>(CompleteControlDocument, variables)(),
      options
    );
useCompleteControlMutation.getKey = () => ['CompleteControl'];

export const CreateControlsForTemplateDocument = `
    mutation CreateControlsForTemplate($templateId: String!) {
  createSystemControlsForTemplate(templateId: $templateId) {
    success
    error
  }
}
    `;
export const useCreateControlsForTemplateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateControlsForTemplateMutation, TError, CreateControlsForTemplateMutationVariables, TContext>) =>
    useMutation<CreateControlsForTemplateMutation, TError, CreateControlsForTemplateMutationVariables, TContext>(
      ['CreateControlsForTemplate'],
      (variables?: CreateControlsForTemplateMutationVariables) => fetcher<CreateControlsForTemplateMutation, CreateControlsForTemplateMutationVariables>(CreateControlsForTemplateDocument, variables)(),
      options
    );
useCreateControlsForTemplateMutation.getKey = () => ['CreateControlsForTemplate'];

export const DeleteEvidenceBlockDocument = `
    mutation DeleteEvidenceBlock($controlId: String!, $blockId: String!) {
  deleteEvidenceBlock(blockId: $blockId, controlId: $controlId) {
    success
    error
  }
}
    `;
export const useDeleteEvidenceBlockMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteEvidenceBlockMutation, TError, DeleteEvidenceBlockMutationVariables, TContext>) =>
    useMutation<DeleteEvidenceBlockMutation, TError, DeleteEvidenceBlockMutationVariables, TContext>(
      ['DeleteEvidenceBlock'],
      (variables?: DeleteEvidenceBlockMutationVariables) => fetcher<DeleteEvidenceBlockMutation, DeleteEvidenceBlockMutationVariables>(DeleteEvidenceBlockDocument, variables)(),
      options
    );
useDeleteEvidenceBlockMutation.getKey = () => ['DeleteEvidenceBlock'];

export const GetTemplateControlsDocument = `
    query GetTemplateControls($templateId: String!) {
  systemControls(templateId: $templateId) {
    success
    error
    data {
      completedControlsCount
      controlsInProgressCount
      sourceControlsCount
      controls {
        externalId
        contextualRequirementNotApplicable
        completedAt
        completedBy {
          firstName
          lastName
        }
        evidenceBlocks {
          id
          name
          description
        }
        sourceRequirement {
          cuid
          name
          description
          contextualRequirement
          contextualRequirementExplanation
          requirementSections {
            cuid
            name
            description
          }
        }
        sourceControl {
          externalId
          name
          evidenceBlocks {
            id
            name
            description
          }
        }
      }
    }
  }
}
    `;
export const useGetTemplateControlsQuery = <
      TData = GetTemplateControlsQuery,
      TError = unknown
    >(
      variables: GetTemplateControlsQueryVariables,
      options?: UseQueryOptions<GetTemplateControlsQuery, TError, TData>
    ) =>
    useQuery<GetTemplateControlsQuery, TError, TData>(
      ['GetTemplateControls', variables],
      fetcher<GetTemplateControlsQuery, GetTemplateControlsQueryVariables>(GetTemplateControlsDocument, variables),
      options
    );
useGetTemplateControlsQuery.document = GetTemplateControlsDocument;


useGetTemplateControlsQuery.getKey = (variables: GetTemplateControlsQueryVariables) => ['GetTemplateControls', variables];
;

export const useInfiniteGetTemplateControlsQuery = <
      TData = GetTemplateControlsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetTemplateControlsQueryVariables,
      variables: GetTemplateControlsQueryVariables,
      options?: UseInfiniteQueryOptions<GetTemplateControlsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetTemplateControlsQuery, TError, TData>(
      ['GetTemplateControls.infinite', variables],
      (metaData) => fetcher<GetTemplateControlsQuery, GetTemplateControlsQueryVariables>(GetTemplateControlsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetTemplateControlsQuery.getKey = (variables: GetTemplateControlsQueryVariables) => ['GetTemplateControls.infinite', variables];
;

export const MarkControlNotApplicableDocument = `
    mutation MarkControlNotApplicable($controlId: String!) {
  markNotApplicable(controlId: $controlId) {
    success
    error
  }
}
    `;
export const useMarkControlNotApplicableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MarkControlNotApplicableMutation, TError, MarkControlNotApplicableMutationVariables, TContext>) =>
    useMutation<MarkControlNotApplicableMutation, TError, MarkControlNotApplicableMutationVariables, TContext>(
      ['MarkControlNotApplicable'],
      (variables?: MarkControlNotApplicableMutationVariables) => fetcher<MarkControlNotApplicableMutation, MarkControlNotApplicableMutationVariables>(MarkControlNotApplicableDocument, variables)(),
      options
    );
useMarkControlNotApplicableMutation.getKey = () => ['MarkControlNotApplicable'];

export const RemoveMarkControlNotApplicableDocument = `
    mutation RemoveMarkControlNotApplicable($controlId: String!) {
  removeMarkNotApplicable(controlId: $controlId) {
    success
    error
  }
}
    `;
export const useRemoveMarkControlNotApplicableMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveMarkControlNotApplicableMutation, TError, RemoveMarkControlNotApplicableMutationVariables, TContext>) =>
    useMutation<RemoveMarkControlNotApplicableMutation, TError, RemoveMarkControlNotApplicableMutationVariables, TContext>(
      ['RemoveMarkControlNotApplicable'],
      (variables?: RemoveMarkControlNotApplicableMutationVariables) => fetcher<RemoveMarkControlNotApplicableMutation, RemoveMarkControlNotApplicableMutationVariables>(RemoveMarkControlNotApplicableDocument, variables)(),
      options
    );
useRemoveMarkControlNotApplicableMutation.getKey = () => ['RemoveMarkControlNotApplicable'];

export const UnCompleteControlDocument = `
    mutation UnCompleteControl($controlId: String!) {
  uncompleteControl(controlId: $controlId) {
    success
    error
  }
}
    `;
export const useUnCompleteControlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UnCompleteControlMutation, TError, UnCompleteControlMutationVariables, TContext>) =>
    useMutation<UnCompleteControlMutation, TError, UnCompleteControlMutationVariables, TContext>(
      ['UnCompleteControl'],
      (variables?: UnCompleteControlMutationVariables) => fetcher<UnCompleteControlMutation, UnCompleteControlMutationVariables>(UnCompleteControlDocument, variables)(),
      options
    );
useUnCompleteControlMutation.getKey = () => ['UnCompleteControl'];

export const GetGovernSpaceDocument = `
    query GetGovernSpace($spaceId: String!) {
  getGovernSpace(spaceId: $spaceId) {
    ...GovernSpaceNode
    monthlySystemsCountVariation
    monthlyDatasetsCountVariation
    systemsCount
    datasetsCount
  }
}
    ${GovernSpaceNodeFragmentDoc}`;
export const useGetGovernSpaceQuery = <
      TData = GetGovernSpaceQuery,
      TError = unknown
    >(
      variables: GetGovernSpaceQueryVariables,
      options?: UseQueryOptions<GetGovernSpaceQuery, TError, TData>
    ) =>
    useQuery<GetGovernSpaceQuery, TError, TData>(
      ['GetGovernSpace', variables],
      fetcher<GetGovernSpaceQuery, GetGovernSpaceQueryVariables>(GetGovernSpaceDocument, variables),
      options
    );
useGetGovernSpaceQuery.document = GetGovernSpaceDocument;


useGetGovernSpaceQuery.getKey = (variables: GetGovernSpaceQueryVariables) => ['GetGovernSpace', variables];
;

export const useInfiniteGetGovernSpaceQuery = <
      TData = GetGovernSpaceQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGovernSpaceQueryVariables,
      variables: GetGovernSpaceQueryVariables,
      options?: UseInfiniteQueryOptions<GetGovernSpaceQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetGovernSpaceQuery, TError, TData>(
      ['GetGovernSpace.infinite', variables],
      (metaData) => fetcher<GetGovernSpaceQuery, GetGovernSpaceQueryVariables>(GetGovernSpaceDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetGovernSpaceQuery.getKey = (variables: GetGovernSpaceQueryVariables) => ['GetGovernSpace.infinite', variables];
;

export const MoveSystemsToSpaceDocument = `
    mutation MoveSystemsToSpace($targetSpaceId: String!, $systemIds: [String!]!) {
  moveSystemsToSpace(targetSpaceId: $targetSpaceId, systemIds: $systemIds) {
    success
    error
    data
  }
}
    `;
export const useMoveSystemsToSpaceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MoveSystemsToSpaceMutation, TError, MoveSystemsToSpaceMutationVariables, TContext>) =>
    useMutation<MoveSystemsToSpaceMutation, TError, MoveSystemsToSpaceMutationVariables, TContext>(
      ['MoveSystemsToSpace'],
      (variables?: MoveSystemsToSpaceMutationVariables) => fetcher<MoveSystemsToSpaceMutation, MoveSystemsToSpaceMutationVariables>(MoveSystemsToSpaceDocument, variables)(),
      options
    );
useMoveSystemsToSpaceMutation.getKey = () => ['MoveSystemsToSpace'];

export const OrganizationGovernSystemLimitDocument = `
    query OrganizationGovernSystemLimit {
  myOrganization {
    maxGovernSystems
  }
}
    `;
export const useOrganizationGovernSystemLimitQuery = <
      TData = OrganizationGovernSystemLimitQuery,
      TError = unknown
    >(
      variables?: OrganizationGovernSystemLimitQueryVariables,
      options?: UseQueryOptions<OrganizationGovernSystemLimitQuery, TError, TData>
    ) =>
    useQuery<OrganizationGovernSystemLimitQuery, TError, TData>(
      variables === undefined ? ['OrganizationGovernSystemLimit'] : ['OrganizationGovernSystemLimit', variables],
      fetcher<OrganizationGovernSystemLimitQuery, OrganizationGovernSystemLimitQueryVariables>(OrganizationGovernSystemLimitDocument, variables),
      options
    );
useOrganizationGovernSystemLimitQuery.document = OrganizationGovernSystemLimitDocument;


useOrganizationGovernSystemLimitQuery.getKey = (variables?: OrganizationGovernSystemLimitQueryVariables) => variables === undefined ? ['OrganizationGovernSystemLimit'] : ['OrganizationGovernSystemLimit', variables];
;

export const useInfiniteOrganizationGovernSystemLimitQuery = <
      TData = OrganizationGovernSystemLimitQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganizationGovernSystemLimitQueryVariables,
      variables?: OrganizationGovernSystemLimitQueryVariables,
      options?: UseInfiniteQueryOptions<OrganizationGovernSystemLimitQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganizationGovernSystemLimitQuery, TError, TData>(
      variables === undefined ? ['OrganizationGovernSystemLimit.infinite'] : ['OrganizationGovernSystemLimit.infinite', variables],
      (metaData) => fetcher<OrganizationGovernSystemLimitQuery, OrganizationGovernSystemLimitQueryVariables>(OrganizationGovernSystemLimitDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganizationGovernSystemLimitQuery.getKey = (variables?: OrganizationGovernSystemLimitQueryVariables) => variables === undefined ? ['OrganizationGovernSystemLimit.infinite'] : ['OrganizationGovernSystemLimit.infinite', variables];
;

export const SystemsHomePage2Document = `
    query SystemsHomePage2($query: String, $orderBy: JSON) {
  governSystems(query: $query, orderBy: $orderBy) {
    nodes {
      externalId
      name
      systemOwner
      risk
      impact
      createdAt
      updatedAt
      canUserEditSystem
      teams {
        member {
          externalId
          email
        }
      }
    }
    pageInfo {
      totalCount
    }
  }
  options(names: [SystemRiskLevel, BusinessImpactLevel]) {
    name
    data {
      compoundId
      label
    }
  }
}
    `;
export const useSystemsHomePage2Query = <
      TData = SystemsHomePage2Query,
      TError = unknown
    >(
      variables?: SystemsHomePage2QueryVariables,
      options?: UseQueryOptions<SystemsHomePage2Query, TError, TData>
    ) =>
    useQuery<SystemsHomePage2Query, TError, TData>(
      variables === undefined ? ['SystemsHomePage2'] : ['SystemsHomePage2', variables],
      fetcher<SystemsHomePage2Query, SystemsHomePage2QueryVariables>(SystemsHomePage2Document, variables),
      options
    );
useSystemsHomePage2Query.document = SystemsHomePage2Document;


useSystemsHomePage2Query.getKey = (variables?: SystemsHomePage2QueryVariables) => variables === undefined ? ['SystemsHomePage2'] : ['SystemsHomePage2', variables];
;

export const useInfiniteSystemsHomePage2Query = <
      TData = SystemsHomePage2Query,
      TError = unknown
    >(
      pageParamKey: keyof SystemsHomePage2QueryVariables,
      variables?: SystemsHomePage2QueryVariables,
      options?: UseInfiniteQueryOptions<SystemsHomePage2Query, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SystemsHomePage2Query, TError, TData>(
      variables === undefined ? ['SystemsHomePage2.infinite'] : ['SystemsHomePage2.infinite', variables],
      (metaData) => fetcher<SystemsHomePage2Query, SystemsHomePage2QueryVariables>(SystemsHomePage2Document, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSystemsHomePage2Query.getKey = (variables?: SystemsHomePage2QueryVariables) => variables === undefined ? ['SystemsHomePage2.infinite'] : ['SystemsHomePage2.infinite', variables];
;

export const SystemRegionAndCapabilityDocument = `
    query SystemRegionAndCapability($regionAlphabeticalOrder: SortDirection) {
  library {
    tasks(taskType: CAPABILITY) {
      externalId
      name
    }
  }
  geography {
    regions(alphabeticalOrderDirection: $regionAlphabeticalOrder) {
      name
      externalId
      countries {
        name
        externalId
      }
    }
  }
}
    `;
export const useSystemRegionAndCapabilityQuery = <
      TData = SystemRegionAndCapabilityQuery,
      TError = unknown
    >(
      variables?: SystemRegionAndCapabilityQueryVariables,
      options?: UseQueryOptions<SystemRegionAndCapabilityQuery, TError, TData>
    ) =>
    useQuery<SystemRegionAndCapabilityQuery, TError, TData>(
      variables === undefined ? ['SystemRegionAndCapability'] : ['SystemRegionAndCapability', variables],
      fetcher<SystemRegionAndCapabilityQuery, SystemRegionAndCapabilityQueryVariables>(SystemRegionAndCapabilityDocument, variables),
      options
    );
useSystemRegionAndCapabilityQuery.document = SystemRegionAndCapabilityDocument;


useSystemRegionAndCapabilityQuery.getKey = (variables?: SystemRegionAndCapabilityQueryVariables) => variables === undefined ? ['SystemRegionAndCapability'] : ['SystemRegionAndCapability', variables];
;

export const useInfiniteSystemRegionAndCapabilityQuery = <
      TData = SystemRegionAndCapabilityQuery,
      TError = unknown
    >(
      pageParamKey: keyof SystemRegionAndCapabilityQueryVariables,
      variables?: SystemRegionAndCapabilityQueryVariables,
      options?: UseInfiniteQueryOptions<SystemRegionAndCapabilityQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SystemRegionAndCapabilityQuery, TError, TData>(
      variables === undefined ? ['SystemRegionAndCapability.infinite'] : ['SystemRegionAndCapability.infinite', variables],
      (metaData) => fetcher<SystemRegionAndCapabilityQuery, SystemRegionAndCapabilityQueryVariables>(SystemRegionAndCapabilityDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSystemRegionAndCapabilityQuery.getKey = (variables?: SystemRegionAndCapabilityQueryVariables) => variables === undefined ? ['SystemRegionAndCapability.infinite'] : ['SystemRegionAndCapability.infinite', variables];
;

export const LibraryEvaluationDetailDocument = `
    query LibraryEvaluationDetail($evaluationId: ID!, $translationKeys: [String!]!) {
  evaluation(id: $evaluationId) {
    name
    type
    description
    autoid
    dataset
    airtableUpdatedAt
    isFollowed
    datasetParameterLabel
    tasks {
      name
    }
    references {
      url
    }
    aggregatedResults {
      shortenedDatasetParameters {
        name
        shortenedVersion
      }
      metricExplanations {
        label
        explainer
      }
      resultsAggregatedByModel {
        model {
          id
          autoid
          name
          logoUrl
          isFollowed
        }
        modelVersion {
          name
          endpoints
        }
        scores {
          provider
          scoreType
          score
          scoreUnit
          taskParameters
          datasetParameter
        }
      }
    }
  }
  translations(translationKeys: $translationKeys)
}
    `;
export const useLibraryEvaluationDetailQuery = <
      TData = LibraryEvaluationDetailQuery,
      TError = unknown
    >(
      variables: LibraryEvaluationDetailQueryVariables,
      options?: UseQueryOptions<LibraryEvaluationDetailQuery, TError, TData>
    ) =>
    useQuery<LibraryEvaluationDetailQuery, TError, TData>(
      ['LibraryEvaluationDetail', variables],
      fetcher<LibraryEvaluationDetailQuery, LibraryEvaluationDetailQueryVariables>(LibraryEvaluationDetailDocument, variables),
      options
    );
useLibraryEvaluationDetailQuery.document = LibraryEvaluationDetailDocument;


useLibraryEvaluationDetailQuery.getKey = (variables: LibraryEvaluationDetailQueryVariables) => ['LibraryEvaluationDetail', variables];
;

export const useInfiniteLibraryEvaluationDetailQuery = <
      TData = LibraryEvaluationDetailQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryEvaluationDetailQueryVariables,
      variables: LibraryEvaluationDetailQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryEvaluationDetailQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryEvaluationDetailQuery, TError, TData>(
      ['LibraryEvaluationDetail.infinite', variables],
      (metaData) => fetcher<LibraryEvaluationDetailQuery, LibraryEvaluationDetailQueryVariables>(LibraryEvaluationDetailDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryEvaluationDetailQuery.getKey = (variables: LibraryEvaluationDetailQueryVariables) => ['LibraryEvaluationDetail.infinite', variables];
;

export const LibraryEvaluationsHomepageDocument = `
    query LibraryEvaluationsHomepage($orderBy: JSON, $filterBy: JSON, $query: String) {
  library {
    evaluations(orderBy: $orderBy, filterBy: $filterBy, query: $query) {
      edges {
        node {
          id
          autoid
          evaluationId
          name
          description
          type
          tasks {
            name
          }
          modelsCount
          airtableUpdatedAt
        }
      }
    }
  }
}
    `;
export const useLibraryEvaluationsHomepageQuery = <
      TData = LibraryEvaluationsHomepageQuery,
      TError = unknown
    >(
      variables?: LibraryEvaluationsHomepageQueryVariables,
      options?: UseQueryOptions<LibraryEvaluationsHomepageQuery, TError, TData>
    ) =>
    useQuery<LibraryEvaluationsHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryEvaluationsHomepage'] : ['LibraryEvaluationsHomepage', variables],
      fetcher<LibraryEvaluationsHomepageQuery, LibraryEvaluationsHomepageQueryVariables>(LibraryEvaluationsHomepageDocument, variables),
      options
    );
useLibraryEvaluationsHomepageQuery.document = LibraryEvaluationsHomepageDocument;


useLibraryEvaluationsHomepageQuery.getKey = (variables?: LibraryEvaluationsHomepageQueryVariables) => variables === undefined ? ['LibraryEvaluationsHomepage'] : ['LibraryEvaluationsHomepage', variables];
;

export const useInfiniteLibraryEvaluationsHomepageQuery = <
      TData = LibraryEvaluationsHomepageQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryEvaluationsHomepageQueryVariables,
      variables?: LibraryEvaluationsHomepageQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryEvaluationsHomepageQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryEvaluationsHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryEvaluationsHomepage.infinite'] : ['LibraryEvaluationsHomepage.infinite', variables],
      (metaData) => fetcher<LibraryEvaluationsHomepageQuery, LibraryEvaluationsHomepageQueryVariables>(LibraryEvaluationsHomepageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryEvaluationsHomepageQuery.getKey = (variables?: LibraryEvaluationsHomepageQueryVariables) => variables === undefined ? ['LibraryEvaluationsHomepage.infinite'] : ['LibraryEvaluationsHomepage.infinite', variables];
;

export const LibraryFeedHomepageDocument = `
    query LibraryFeedHomepage($filter: [LibraryCardType!]) {
  feed(filter: $filter) {
    ...FeedItem
  }
  user {
    isFollowingItems
    hasNewFeedItems
    feedSeenAt
  }
}
    ${FeedItemFragmentDoc}`;
export const useLibraryFeedHomepageQuery = <
      TData = LibraryFeedHomepageQuery,
      TError = unknown
    >(
      variables?: LibraryFeedHomepageQueryVariables,
      options?: UseQueryOptions<LibraryFeedHomepageQuery, TError, TData>
    ) =>
    useQuery<LibraryFeedHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryFeedHomepage'] : ['LibraryFeedHomepage', variables],
      fetcher<LibraryFeedHomepageQuery, LibraryFeedHomepageQueryVariables>(LibraryFeedHomepageDocument, variables),
      options
    );
useLibraryFeedHomepageQuery.document = LibraryFeedHomepageDocument;


useLibraryFeedHomepageQuery.getKey = (variables?: LibraryFeedHomepageQueryVariables) => variables === undefined ? ['LibraryFeedHomepage'] : ['LibraryFeedHomepage', variables];
;

export const useInfiniteLibraryFeedHomepageQuery = <
      TData = LibraryFeedHomepageQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryFeedHomepageQueryVariables,
      variables?: LibraryFeedHomepageQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryFeedHomepageQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryFeedHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryFeedHomepage.infinite'] : ['LibraryFeedHomepage.infinite', variables],
      (metaData) => fetcher<LibraryFeedHomepageQuery, LibraryFeedHomepageQueryVariables>(LibraryFeedHomepageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryFeedHomepageQuery.getKey = (variables?: LibraryFeedHomepageQueryVariables) => variables === undefined ? ['LibraryFeedHomepage.infinite'] : ['LibraryFeedHomepage.infinite', variables];
;

export const LibraryFeedStatusDocument = `
    query LibraryFeedStatus {
  user {
    hasNewFeedItems
    feedSeenAt
    color
  }
}
    `;
export const useLibraryFeedStatusQuery = <
      TData = LibraryFeedStatusQuery,
      TError = unknown
    >(
      variables?: LibraryFeedStatusQueryVariables,
      options?: UseQueryOptions<LibraryFeedStatusQuery, TError, TData>
    ) =>
    useQuery<LibraryFeedStatusQuery, TError, TData>(
      variables === undefined ? ['LibraryFeedStatus'] : ['LibraryFeedStatus', variables],
      fetcher<LibraryFeedStatusQuery, LibraryFeedStatusQueryVariables>(LibraryFeedStatusDocument, variables),
      options
    );
useLibraryFeedStatusQuery.document = LibraryFeedStatusDocument;


useLibraryFeedStatusQuery.getKey = (variables?: LibraryFeedStatusQueryVariables) => variables === undefined ? ['LibraryFeedStatus'] : ['LibraryFeedStatus', variables];
;

export const useInfiniteLibraryFeedStatusQuery = <
      TData = LibraryFeedStatusQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryFeedStatusQueryVariables,
      variables?: LibraryFeedStatusQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryFeedStatusQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryFeedStatusQuery, TError, TData>(
      variables === undefined ? ['LibraryFeedStatus.infinite'] : ['LibraryFeedStatus.infinite', variables],
      (metaData) => fetcher<LibraryFeedStatusQuery, LibraryFeedStatusQueryVariables>(LibraryFeedStatusDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryFeedStatusQuery.getKey = (variables?: LibraryFeedStatusQueryVariables) => variables === undefined ? ['LibraryFeedStatus.infinite'] : ['LibraryFeedStatus.infinite', variables];
;

export const LibraryTrendingTopicsDocument = `
    query LibraryTrendingTopics($offset: Int) {
  trendingTopics(offset: $offset) {
    edges {
      node {
        ...TrendingTopic
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      totalCount
    }
  }
}
    ${TrendingTopicFragmentDoc}`;
export const useLibraryTrendingTopicsQuery = <
      TData = LibraryTrendingTopicsQuery,
      TError = unknown
    >(
      variables?: LibraryTrendingTopicsQueryVariables,
      options?: UseQueryOptions<LibraryTrendingTopicsQuery, TError, TData>
    ) =>
    useQuery<LibraryTrendingTopicsQuery, TError, TData>(
      variables === undefined ? ['LibraryTrendingTopics'] : ['LibraryTrendingTopics', variables],
      fetcher<LibraryTrendingTopicsQuery, LibraryTrendingTopicsQueryVariables>(LibraryTrendingTopicsDocument, variables),
      options
    );
useLibraryTrendingTopicsQuery.document = LibraryTrendingTopicsDocument;


useLibraryTrendingTopicsQuery.getKey = (variables?: LibraryTrendingTopicsQueryVariables) => variables === undefined ? ['LibraryTrendingTopics'] : ['LibraryTrendingTopics', variables];
;

export const useInfiniteLibraryTrendingTopicsQuery = <
      TData = LibraryTrendingTopicsQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryTrendingTopicsQueryVariables,
      variables?: LibraryTrendingTopicsQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryTrendingTopicsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryTrendingTopicsQuery, TError, TData>(
      variables === undefined ? ['LibraryTrendingTopics.infinite'] : ['LibraryTrendingTopics.infinite', variables],
      (metaData) => fetcher<LibraryTrendingTopicsQuery, LibraryTrendingTopicsQueryVariables>(LibraryTrendingTopicsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryTrendingTopicsQuery.getKey = (variables?: LibraryTrendingTopicsQueryVariables) => variables === undefined ? ['LibraryTrendingTopics.infinite'] : ['LibraryTrendingTopics.infinite', variables];
;

export const MarkFeedAsSeenDocument = `
    mutation MarkFeedAsSeen {
  markFeedAsSeen {
    success
  }
}
    `;
export const useMarkFeedAsSeenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MarkFeedAsSeenMutation, TError, MarkFeedAsSeenMutationVariables, TContext>) =>
    useMutation<MarkFeedAsSeenMutation, TError, MarkFeedAsSeenMutationVariables, TContext>(
      ['MarkFeedAsSeen'],
      (variables?: MarkFeedAsSeenMutationVariables) => fetcher<MarkFeedAsSeenMutation, MarkFeedAsSeenMutationVariables>(MarkFeedAsSeenDocument, variables)(),
      options
    );
useMarkFeedAsSeenMutation.getKey = () => ['MarkFeedAsSeen'];

export const LibraryModelDetailDocument = `
    query LibraryModelDetail($modelId: ID!) {
  model(id: $modelId) {
    ...Mmmm
  }
}
    ${MmmmFragmentDoc}`;
export const useLibraryModelDetailQuery = <
      TData = LibraryModelDetailQuery,
      TError = unknown
    >(
      variables: LibraryModelDetailQueryVariables,
      options?: UseQueryOptions<LibraryModelDetailQuery, TError, TData>
    ) =>
    useQuery<LibraryModelDetailQuery, TError, TData>(
      ['LibraryModelDetail', variables],
      fetcher<LibraryModelDetailQuery, LibraryModelDetailQueryVariables>(LibraryModelDetailDocument, variables),
      options
    );
useLibraryModelDetailQuery.document = LibraryModelDetailDocument;


useLibraryModelDetailQuery.getKey = (variables: LibraryModelDetailQueryVariables) => ['LibraryModelDetail', variables];
;

export const useInfiniteLibraryModelDetailQuery = <
      TData = LibraryModelDetailQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryModelDetailQueryVariables,
      variables: LibraryModelDetailQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryModelDetailQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryModelDetailQuery, TError, TData>(
      ['LibraryModelDetail.infinite', variables],
      (metaData) => fetcher<LibraryModelDetailQuery, LibraryModelDetailQueryVariables>(LibraryModelDetailDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryModelDetailQuery.getKey = (variables: LibraryModelDetailQueryVariables) => ['LibraryModelDetail.infinite', variables];
;

export const LibraryModelsHomepageDocument = `
    query LibraryModelsHomepage($orderBy: JSON, $filterBy: JSON, $query: String) {
  library {
    models(orderBy: $orderBy, filterBy: $filterBy, query: $query) {
      edges {
        node {
          id
          autoid
          modelId
          isFollowed
          isRecommended
          name
          owner {
            address
            email
            name
          }
          shortDescription
          updatedAt
          region
          type
          tasks {
            name
          }
          providerRisks {
            id
          }
          follows {
            cuid
            userId
          }
          evaluationsCount
        }
      }
    }
  }
}
    `;
export const useLibraryModelsHomepageQuery = <
      TData = LibraryModelsHomepageQuery,
      TError = unknown
    >(
      variables?: LibraryModelsHomepageQueryVariables,
      options?: UseQueryOptions<LibraryModelsHomepageQuery, TError, TData>
    ) =>
    useQuery<LibraryModelsHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryModelsHomepage'] : ['LibraryModelsHomepage', variables],
      fetcher<LibraryModelsHomepageQuery, LibraryModelsHomepageQueryVariables>(LibraryModelsHomepageDocument, variables),
      options
    );
useLibraryModelsHomepageQuery.document = LibraryModelsHomepageDocument;


useLibraryModelsHomepageQuery.getKey = (variables?: LibraryModelsHomepageQueryVariables) => variables === undefined ? ['LibraryModelsHomepage'] : ['LibraryModelsHomepage', variables];
;

export const useInfiniteLibraryModelsHomepageQuery = <
      TData = LibraryModelsHomepageQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryModelsHomepageQueryVariables,
      variables?: LibraryModelsHomepageQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryModelsHomepageQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryModelsHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryModelsHomepage.infinite'] : ['LibraryModelsHomepage.infinite', variables],
      (metaData) => fetcher<LibraryModelsHomepageQuery, LibraryModelsHomepageQueryVariables>(LibraryModelsHomepageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryModelsHomepageQuery.getKey = (variables?: LibraryModelsHomepageQueryVariables) => variables === undefined ? ['LibraryModelsHomepage.infinite'] : ['LibraryModelsHomepage.infinite', variables];
;

export const GetCategorisedLibraryModelsDocument = `
    query GetCategorisedLibraryModels($orderBy: JSON, $filterBy: JSON, $query: String) {
  library {
    models(orderBy: $orderBy, filterBy: $filterBy, query: $query) {
      edges {
        node {
          id
          cuid
          isFollowed
          isRecommended
          name
          logoUrl
          owner {
            name
            logo
          }
        }
      }
    }
  }
}
    `;
export const useGetCategorisedLibraryModelsQuery = <
      TData = GetCategorisedLibraryModelsQuery,
      TError = unknown
    >(
      variables?: GetCategorisedLibraryModelsQueryVariables,
      options?: UseQueryOptions<GetCategorisedLibraryModelsQuery, TError, TData>
    ) =>
    useQuery<GetCategorisedLibraryModelsQuery, TError, TData>(
      variables === undefined ? ['GetCategorisedLibraryModels'] : ['GetCategorisedLibraryModels', variables],
      fetcher<GetCategorisedLibraryModelsQuery, GetCategorisedLibraryModelsQueryVariables>(GetCategorisedLibraryModelsDocument, variables),
      options
    );
useGetCategorisedLibraryModelsQuery.document = GetCategorisedLibraryModelsDocument;


useGetCategorisedLibraryModelsQuery.getKey = (variables?: GetCategorisedLibraryModelsQueryVariables) => variables === undefined ? ['GetCategorisedLibraryModels'] : ['GetCategorisedLibraryModels', variables];
;

export const useInfiniteGetCategorisedLibraryModelsQuery = <
      TData = GetCategorisedLibraryModelsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCategorisedLibraryModelsQueryVariables,
      variables?: GetCategorisedLibraryModelsQueryVariables,
      options?: UseInfiniteQueryOptions<GetCategorisedLibraryModelsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetCategorisedLibraryModelsQuery, TError, TData>(
      variables === undefined ? ['GetCategorisedLibraryModels.infinite'] : ['GetCategorisedLibraryModels.infinite', variables],
      (metaData) => fetcher<GetCategorisedLibraryModelsQuery, GetCategorisedLibraryModelsQueryVariables>(GetCategorisedLibraryModelsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetCategorisedLibraryModelsQuery.getKey = (variables?: GetCategorisedLibraryModelsQueryVariables) => variables === undefined ? ['GetCategorisedLibraryModels.infinite'] : ['GetCategorisedLibraryModels.infinite', variables];
;

export const LibraryPolicyRolesDocument = `
    query LibraryPolicyRoles($policyId: ID!) {
  policy(id: $policyId) {
    roles {
      ...LibraryPolicyRole
    }
  }
}
    ${LibraryPolicyRoleFragmentDoc}`;
export const useLibraryPolicyRolesQuery = <
      TData = LibraryPolicyRolesQuery,
      TError = unknown
    >(
      variables: LibraryPolicyRolesQueryVariables,
      options?: UseQueryOptions<LibraryPolicyRolesQuery, TError, TData>
    ) =>
    useQuery<LibraryPolicyRolesQuery, TError, TData>(
      ['LibraryPolicyRoles', variables],
      fetcher<LibraryPolicyRolesQuery, LibraryPolicyRolesQueryVariables>(LibraryPolicyRolesDocument, variables),
      options
    );
useLibraryPolicyRolesQuery.document = LibraryPolicyRolesDocument;


useLibraryPolicyRolesQuery.getKey = (variables: LibraryPolicyRolesQueryVariables) => ['LibraryPolicyRoles', variables];
;

export const useInfiniteLibraryPolicyRolesQuery = <
      TData = LibraryPolicyRolesQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryPolicyRolesQueryVariables,
      variables: LibraryPolicyRolesQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryPolicyRolesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryPolicyRolesQuery, TError, TData>(
      ['LibraryPolicyRoles.infinite', variables],
      (metaData) => fetcher<LibraryPolicyRolesQuery, LibraryPolicyRolesQueryVariables>(LibraryPolicyRolesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryPolicyRolesQuery.getKey = (variables: LibraryPolicyRolesQueryVariables) => ['LibraryPolicyRoles.infinite', variables];
;

export const LibraryPolicyDetailDocument = `
    query LibraryPolicyDetail($policyId: ID!) {
  policy(id: $policyId) {
    policyId
    autoid
    airtableCreatedAt
    createdAt
    documentDate
    effectiveDate
    effectiveDate2
    effectiveDate3
    effectiveDate4
    effectiveDate5
    effectiveDateScope
    effectiveDateScope2
    effectiveDateScope3
    effectiveDateScope4
    effectiveDateScope5
    expiryDate
    id
    industry
    keyInformation
    keyInformation2
    name
    overview
    owner {
      name
    }
    penalties
    region
    reference
    sector
    scope
    type
    airtableUpdatedAt
    isFollowed
    hasRoles
    hasCoverage
    hasRequirements
  }
}
    `;
export const useLibraryPolicyDetailQuery = <
      TData = LibraryPolicyDetailQuery,
      TError = unknown
    >(
      variables: LibraryPolicyDetailQueryVariables,
      options?: UseQueryOptions<LibraryPolicyDetailQuery, TError, TData>
    ) =>
    useQuery<LibraryPolicyDetailQuery, TError, TData>(
      ['LibraryPolicyDetail', variables],
      fetcher<LibraryPolicyDetailQuery, LibraryPolicyDetailQueryVariables>(LibraryPolicyDetailDocument, variables),
      options
    );
useLibraryPolicyDetailQuery.document = LibraryPolicyDetailDocument;


useLibraryPolicyDetailQuery.getKey = (variables: LibraryPolicyDetailQueryVariables) => ['LibraryPolicyDetail', variables];
;

export const useInfiniteLibraryPolicyDetailQuery = <
      TData = LibraryPolicyDetailQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryPolicyDetailQueryVariables,
      variables: LibraryPolicyDetailQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryPolicyDetailQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryPolicyDetailQuery, TError, TData>(
      ['LibraryPolicyDetail.infinite', variables],
      (metaData) => fetcher<LibraryPolicyDetailQuery, LibraryPolicyDetailQueryVariables>(LibraryPolicyDetailDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryPolicyDetailQuery.getKey = (variables: LibraryPolicyDetailQueryVariables) => ['LibraryPolicyDetail.infinite', variables];
;

export const LibraryPolicyHomepageDocument = `
    query LibraryPolicyHomepage($orderBy: JSON, $filterBy: JSON, $query: String) {
  library {
    policies(orderBy: $orderBy, filterBy: $filterBy, query: $query) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          id
          autoid
          policyId
          name
          overview
          updatedAt
          type
          regions
          countries
          usStates
          owner {
            name
            logo
          }
          sector
          requirements {
            id
            requirementId
            createdAt
          }
          isFollowed
          follows {
            cuid
            userId
          }
        }
      }
    }
  }
}
    `;
export const useLibraryPolicyHomepageQuery = <
      TData = LibraryPolicyHomepageQuery,
      TError = unknown
    >(
      variables?: LibraryPolicyHomepageQueryVariables,
      options?: UseQueryOptions<LibraryPolicyHomepageQuery, TError, TData>
    ) =>
    useQuery<LibraryPolicyHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryPolicyHomepage'] : ['LibraryPolicyHomepage', variables],
      fetcher<LibraryPolicyHomepageQuery, LibraryPolicyHomepageQueryVariables>(LibraryPolicyHomepageDocument, variables),
      options
    );
useLibraryPolicyHomepageQuery.document = LibraryPolicyHomepageDocument;


useLibraryPolicyHomepageQuery.getKey = (variables?: LibraryPolicyHomepageQueryVariables) => variables === undefined ? ['LibraryPolicyHomepage'] : ['LibraryPolicyHomepage', variables];
;

export const useInfiniteLibraryPolicyHomepageQuery = <
      TData = LibraryPolicyHomepageQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryPolicyHomepageQueryVariables,
      variables?: LibraryPolicyHomepageQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryPolicyHomepageQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryPolicyHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryPolicyHomepage.infinite'] : ['LibraryPolicyHomepage.infinite', variables],
      (metaData) => fetcher<LibraryPolicyHomepageQuery, LibraryPolicyHomepageQueryVariables>(LibraryPolicyHomepageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryPolicyHomepageQuery.getKey = (variables?: LibraryPolicyHomepageQueryVariables) => variables === undefined ? ['LibraryPolicyHomepage.infinite'] : ['LibraryPolicyHomepage.infinite', variables];
;

export const LibraryRiskDetailDocument = `
    query LibraryRiskDetail($riskId: ID!) {
  risk(id: $riskId) {
    id
    autoid
    name
    isFollowed
    updatedAt
    type
    industry
    description
    mitigations {
      name
      description
    }
    sources {
      sourceName
      url
    }
  }
}
    `;
export const useLibraryRiskDetailQuery = <
      TData = LibraryRiskDetailQuery,
      TError = unknown
    >(
      variables: LibraryRiskDetailQueryVariables,
      options?: UseQueryOptions<LibraryRiskDetailQuery, TError, TData>
    ) =>
    useQuery<LibraryRiskDetailQuery, TError, TData>(
      ['LibraryRiskDetail', variables],
      fetcher<LibraryRiskDetailQuery, LibraryRiskDetailQueryVariables>(LibraryRiskDetailDocument, variables),
      options
    );
useLibraryRiskDetailQuery.document = LibraryRiskDetailDocument;


useLibraryRiskDetailQuery.getKey = (variables: LibraryRiskDetailQueryVariables) => ['LibraryRiskDetail', variables];
;

export const useInfiniteLibraryRiskDetailQuery = <
      TData = LibraryRiskDetailQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryRiskDetailQueryVariables,
      variables: LibraryRiskDetailQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryRiskDetailQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryRiskDetailQuery, TError, TData>(
      ['LibraryRiskDetail.infinite', variables],
      (metaData) => fetcher<LibraryRiskDetailQuery, LibraryRiskDetailQueryVariables>(LibraryRiskDetailDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryRiskDetailQuery.getKey = (variables: LibraryRiskDetailQueryVariables) => ['LibraryRiskDetail.infinite', variables];
;

export const LibraryRisksHomepageDocument = `
    query LibraryRisksHomepage($orderBy: JSON, $filterBy: JSON, $query: String) {
  library {
    risks(orderBy: $orderBy, filterBy: $filterBy, query: $query) {
      edges {
        node {
          id
          autoid
          riskId
          isFollowed
          name
          description
          updatedAt
          type
          industry
          mitigations {
            id
          }
        }
      }
    }
  }
}
    `;
export const useLibraryRisksHomepageQuery = <
      TData = LibraryRisksHomepageQuery,
      TError = unknown
    >(
      variables?: LibraryRisksHomepageQueryVariables,
      options?: UseQueryOptions<LibraryRisksHomepageQuery, TError, TData>
    ) =>
    useQuery<LibraryRisksHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryRisksHomepage'] : ['LibraryRisksHomepage', variables],
      fetcher<LibraryRisksHomepageQuery, LibraryRisksHomepageQueryVariables>(LibraryRisksHomepageDocument, variables),
      options
    );
useLibraryRisksHomepageQuery.document = LibraryRisksHomepageDocument;


useLibraryRisksHomepageQuery.getKey = (variables?: LibraryRisksHomepageQueryVariables) => variables === undefined ? ['LibraryRisksHomepage'] : ['LibraryRisksHomepage', variables];
;

export const useInfiniteLibraryRisksHomepageQuery = <
      TData = LibraryRisksHomepageQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryRisksHomepageQueryVariables,
      variables?: LibraryRisksHomepageQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryRisksHomepageQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryRisksHomepageQuery, TError, TData>(
      variables === undefined ? ['LibraryRisksHomepage.infinite'] : ['LibraryRisksHomepage.infinite', variables],
      (metaData) => fetcher<LibraryRisksHomepageQuery, LibraryRisksHomepageQueryVariables>(LibraryRisksHomepageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryRisksHomepageQuery.getKey = (variables?: LibraryRisksHomepageQueryVariables) => variables === undefined ? ['LibraryRisksHomepage.infinite'] : ['LibraryRisksHomepage.infinite', variables];
;

export const LibraryFilterOptionsDocument = `
    query LibraryFilterOptions($type: FilterableTargetType = Policy, $filters: [String!]!) {
  library {
    filterables(for: $type, from: $filters) {
      name
      counts
      options
    }
  }
}
    `;
export const useLibraryFilterOptionsQuery = <
      TData = LibraryFilterOptionsQuery,
      TError = unknown
    >(
      variables: LibraryFilterOptionsQueryVariables,
      options?: UseQueryOptions<LibraryFilterOptionsQuery, TError, TData>
    ) =>
    useQuery<LibraryFilterOptionsQuery, TError, TData>(
      ['LibraryFilterOptions', variables],
      fetcher<LibraryFilterOptionsQuery, LibraryFilterOptionsQueryVariables>(LibraryFilterOptionsDocument, variables),
      options
    );
useLibraryFilterOptionsQuery.document = LibraryFilterOptionsDocument;


useLibraryFilterOptionsQuery.getKey = (variables: LibraryFilterOptionsQueryVariables) => ['LibraryFilterOptions', variables];
;

export const useInfiniteLibraryFilterOptionsQuery = <
      TData = LibraryFilterOptionsQuery,
      TError = unknown
    >(
      pageParamKey: keyof LibraryFilterOptionsQueryVariables,
      variables: LibraryFilterOptionsQueryVariables,
      options?: UseInfiniteQueryOptions<LibraryFilterOptionsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<LibraryFilterOptionsQuery, TError, TData>(
      ['LibraryFilterOptions.infinite', variables],
      (metaData) => fetcher<LibraryFilterOptionsQuery, LibraryFilterOptionsQueryVariables>(LibraryFilterOptionsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteLibraryFilterOptionsQuery.getKey = (variables: LibraryFilterOptionsQueryVariables) => ['LibraryFilterOptions.infinite', variables];
;

export const LibraryFollowDocument = `
    mutation LibraryFollow($followableId: Int!, $followableType: LibraryCardType!) {
  followLibraryItem(followableId: $followableId, followableType: $followableType) {
    success
  }
}
    `;
export const useLibraryFollowMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LibraryFollowMutation, TError, LibraryFollowMutationVariables, TContext>) =>
    useMutation<LibraryFollowMutation, TError, LibraryFollowMutationVariables, TContext>(
      ['LibraryFollow'],
      (variables?: LibraryFollowMutationVariables) => fetcher<LibraryFollowMutation, LibraryFollowMutationVariables>(LibraryFollowDocument, variables)(),
      options
    );
useLibraryFollowMutation.getKey = () => ['LibraryFollow'];

export const LibraryUnfollowDocument = `
    mutation LibraryUnfollow($followableId: Int!, $followableType: LibraryCardType!) {
  unfollowLibraryItem(
    followableId: $followableId
    followableType: $followableType
  ) {
    success
  }
}
    `;
export const useLibraryUnfollowMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LibraryUnfollowMutation, TError, LibraryUnfollowMutationVariables, TContext>) =>
    useMutation<LibraryUnfollowMutation, TError, LibraryUnfollowMutationVariables, TContext>(
      ['LibraryUnfollow'],
      (variables?: LibraryUnfollowMutationVariables) => fetcher<LibraryUnfollowMutation, LibraryUnfollowMutationVariables>(LibraryUnfollowDocument, variables)(),
      options
    );
useLibraryUnfollowMutation.getKey = () => ['LibraryUnfollow'];

export const ResetPasswordDocument = `
    mutation ResetPassword($email: String!) {
  resetPassword(email: $email) {
    success
    error
  }
}
    `;
export const useResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>) =>
    useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
      ['ResetPassword'],
      (variables?: ResetPasswordMutationVariables) => fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, variables)(),
      options
    );
useResetPasswordMutation.getKey = () => ['ResetPassword'];

export const GetUserGroupsDocument = `
    query GetUserGroups {
  userGroups {
    externalId
    name
    explainerShort
    explainerLong
  }
}
    `;
export const useGetUserGroupsQuery = <
      TData = GetUserGroupsQuery,
      TError = unknown
    >(
      variables?: GetUserGroupsQueryVariables,
      options?: UseQueryOptions<GetUserGroupsQuery, TError, TData>
    ) =>
    useQuery<GetUserGroupsQuery, TError, TData>(
      variables === undefined ? ['GetUserGroups'] : ['GetUserGroups', variables],
      fetcher<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, variables),
      options
    );
useGetUserGroupsQuery.document = GetUserGroupsDocument;


useGetUserGroupsQuery.getKey = (variables?: GetUserGroupsQueryVariables) => variables === undefined ? ['GetUserGroups'] : ['GetUserGroups', variables];
;

export const useInfiniteGetUserGroupsQuery = <
      TData = GetUserGroupsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetUserGroupsQueryVariables,
      variables?: GetUserGroupsQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserGroupsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetUserGroupsQuery, TError, TData>(
      variables === undefined ? ['GetUserGroups.infinite'] : ['GetUserGroups.infinite', variables],
      (metaData) => fetcher<GetUserGroupsQuery, GetUserGroupsQueryVariables>(GetUserGroupsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGetUserGroupsQuery.getKey = (variables?: GetUserGroupsQueryVariables) => variables === undefined ? ['GetUserGroups.infinite'] : ['GetUserGroups.infinite', variables];
;

export const MeDocument = `
    query Me {
  user {
    firstName
    lastName
    title
    groups {
      externalId
      name
    }
    profileImageURL
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me'] : ['Me', variables],
      fetcher<MeQuery, MeQueryVariables>(MeDocument, variables),
      options
    );
useMeQuery.document = MeDocument;


useMeQuery.getKey = (variables?: MeQueryVariables) => variables === undefined ? ['Me'] : ['Me', variables];
;

export const useInfiniteMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      pageParamKey: keyof MeQueryVariables,
      variables?: MeQueryVariables,
      options?: UseInfiniteQueryOptions<MeQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me.infinite'] : ['Me.infinite', variables],
      (metaData) => fetcher<MeQuery, MeQueryVariables>(MeDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteMeQuery.getKey = (variables?: MeQueryVariables) => variables === undefined ? ['Me.infinite'] : ['Me.infinite', variables];
;

export const RemoveProfilePictureDocument = `
    mutation RemoveProfilePicture {
  removeProfilePicture {
    success
    error
  }
}
    `;
export const useRemoveProfilePictureMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveProfilePictureMutation, TError, RemoveProfilePictureMutationVariables, TContext>) =>
    useMutation<RemoveProfilePictureMutation, TError, RemoveProfilePictureMutationVariables, TContext>(
      ['RemoveProfilePicture'],
      (variables?: RemoveProfilePictureMutationVariables) => fetcher<RemoveProfilePictureMutation, RemoveProfilePictureMutationVariables>(RemoveProfilePictureDocument, variables)(),
      options
    );
useRemoveProfilePictureMutation.getKey = () => ['RemoveProfilePicture'];

export const FeatureFlagsDocument = `
    query FeatureFlags {
  featureFlags
}
    `;
export const useFeatureFlagsQuery = <
      TData = FeatureFlagsQuery,
      TError = unknown
    >(
      variables?: FeatureFlagsQueryVariables,
      options?: UseQueryOptions<FeatureFlagsQuery, TError, TData>
    ) =>
    useQuery<FeatureFlagsQuery, TError, TData>(
      variables === undefined ? ['FeatureFlags'] : ['FeatureFlags', variables],
      fetcher<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, variables),
      options
    );
useFeatureFlagsQuery.document = FeatureFlagsDocument;


useFeatureFlagsQuery.getKey = (variables?: FeatureFlagsQueryVariables) => variables === undefined ? ['FeatureFlags'] : ['FeatureFlags', variables];
;

export const useInfiniteFeatureFlagsQuery = <
      TData = FeatureFlagsQuery,
      TError = unknown
    >(
      pageParamKey: keyof FeatureFlagsQueryVariables,
      variables?: FeatureFlagsQueryVariables,
      options?: UseInfiniteQueryOptions<FeatureFlagsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<FeatureFlagsQuery, TError, TData>(
      variables === undefined ? ['FeatureFlags.infinite'] : ['FeatureFlags.infinite', variables],
      (metaData) => fetcher<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteFeatureFlagsQuery.getKey = (variables?: FeatureFlagsQueryVariables) => variables === undefined ? ['FeatureFlags.infinite'] : ['FeatureFlags.infinite', variables];
;


type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const AppInputOptionsNameSchema = z.nativeEnum(AppInputOptionsName);

export const AttachmentTargetSchema = z.nativeEnum(AttachmentTarget);

export const AuditLogEventSchema = z.nativeEnum(AuditLogEvent);

export const BookmarkStatusSchema = z.nativeEnum(BookmarkStatus);

export const BookmarkTypeSchema = z.nativeEnum(BookmarkType);

export const CapabilitiesSchema = z.nativeEnum(Capabilities);

export const EvaluationTypeSchema = z.nativeEnum(EvaluationType);

export const FilterableTargetTypeSchema = z.nativeEnum(FilterableTargetType);

export const GovernAttachmentFormatSchema = z.nativeEnum(GovernAttachmentFormat);

export const GovernDatasetModalitySchema = z.nativeEnum(GovernDatasetModality);

export const GovernDatasetPurposeSchema = z.nativeEnum(GovernDatasetPurpose);

export const GovernLifecycleStageSchema = z.nativeEnum(GovernLifecycleStage);

export const GovernSpaceRoleSchema = z.nativeEnum(GovernSpaceRole);

export const LibraryCardTypeSchema = z.nativeEnum(LibraryCardType);

export const LicensesSchema = z.nativeEnum(Licenses);

export const OrganizationStatusSchema = z.nativeEnum(OrganizationStatus);

export const RequestCancellationReasonSchema = z.nativeEnum(RequestCancellationReason);

export const RiskTreatmentSchema = z.nativeEnum(RiskTreatment);

export const RolesSchema = z.nativeEnum(Roles);

export const SortDirectionSchema = z.nativeEnum(SortDirection);

export const SortItemsBySchema = z.nativeEnum(SortItemsBy);

export const SystemPoliciesFilterSchema = z.nativeEnum(SystemPoliciesFilter);

export const TeamRoleSchema = z.nativeEnum(TeamRole);

export const TokenTypeSchema = z.nativeEnum(TokenType);

export const NotificationSourceEventTypeSchema = z.nativeEnum(NotificationSourceEventType);

export function AddOrganisationInputSchema(): z.ZodObject<Properties<AddOrganisationInput>> {
  return z.object({
    access: CapabilitiesSchema,
    address: z.lazy(() => UpdateMyOrganisationDetailsInput_AddressSchema().nullish()),
    consentToUserSwitching: z.boolean().default(false),
    invitations: z.array(z.lazy(() => AddOrganisationInput_InvitationsSchema())),
    license: LicensesSchema,
    logo: z.lazy(() => UpdateMyOrganisationDetailsInput_LogoSchema().nullish()),
    organisationName: z.string(),
    publicRegistryURL: z.string().nullish(),
    status: z.boolean(),
    systemLimit: z.number().default(5)
  })
}

export function AddOrganisationInput_InvitationsSchema(): z.ZodObject<Properties<AddOrganisationInput_Invitations>> {
  return z.object({
    email: z.string(),
    role: RolesSchema
  })
}

export function AddSystemDatasetInputSchema(): z.ZodObject<Properties<AddSystemDatasetInput>> {
  return z.object({
    datasetId: z.string(),
    description: z.string().nullish(),
    purpose: z.array(GovernDatasetPurposeSchema),
    systemId: z.string()
  })
}

export function AddSystemRiskInputSchema(): z.ZodObject<Properties<AddSystemRiskInput>> {
  return z.object({
    accept: z.string().nullish(),
    avoid: z.string().nullish(),
    consequences: z.string().nullish(),
    description: z.string().nullish(),
    externalId: z.string().nullish(),
    inherentRiskEvaluation: z.array(z.number()).nullish(),
    libraryRiskId: z.string().nullish(),
    marginalRiskLevel: z.string().nullish(),
    mitigate: z.array(z.lazy(() => AddSystemRiskInput_MitigateSchema())).nullish(),
    name: z.string(),
    owner: z.string().nullish(),
    residualRiskEvaluation: z.array(z.number()).nullish(),
    sources: z.array(z.string()).nullish(),
    systemId: z.string(),
    treatmentPlan: RiskTreatmentSchema.nullish(),
    types: z.array(z.string())
  })
}

export function AddSystemRiskInput_MitigateSchema(): z.ZodObject<Properties<AddSystemRiskInput_Mitigate>> {
  return z.object({
    description: z.string().nullish(),
    externalId: z.string().nullish(),
    name: z.string(),
    status: z.string().nullish()
  })
}

export function AddSystemSupplierInputSchema(): z.ZodObject<Properties<AddSystemSupplierInput>> {
  return z.object({
    documentIds: z.array(z.string()),
    provider: z.string(),
    systemId: z.string(),
    type: z.string()
  })
}

export function AddTeamMemberInputSchema(): z.ZodObject<Properties<AddTeamMemberInput>> {
  return z.object({
    email: z.string(),
    role: TeamRoleSchema,
    spaceId: z.string(),
    systemId: z.string()
  })
}

export function AddressSchema(): z.ZodObject<Properties<Address>> {
  return z.object({
    city: z.string().nullish(),
    country: z.string().nullish(),
    postalCode: z.string().nullish(),
    streetNameNumber: z.string().nullish()
  })
}

export function ApprovalStatusInputSchema(): z.ZodObject<Properties<ApprovalStatusInput>> {
  return z.object({
    date: definedNonNullAnySchema.nullish(),
    isApproved: z.boolean().nullish()
  })
}

export function ContactsSchema(): z.ZodObject<Properties<Contacts>> {
  return z.object({
    additionalContact: z.string().nullish(),
    mainContact: z.string().nullish()
  })
}

export function CreateDatasetInputSchema(): z.ZodObject<Properties<CreateDatasetInput>> {
  return z.object({
    cardOwnerId: z.string(),
    description: z.string().nullish(),
    modality: GovernDatasetModalitySchema,
    name: z.string(),
    owner: z.string(),
    subject: z.array(z.string()).nullish()
  })
}

export function CreateGovernAttachmentInputSchema(): z.ZodObject<Properties<CreateGovernAttachmentInput>> {
  return z.object({
    description: z.string().nullish(),
    format: GovernAttachmentFormatSchema,
    location: z.string().nullish(),
    name: z.string(),
    target: AttachmentTargetSchema,
    targetId: z.string(),
    type: z.string(),
    url: z.string().nullish()
  })
}

export function CreateGovernSystemInputSchema(): z.ZodObject<Properties<CreateGovernSystemInput>> {
  return z.object({
    capabilities: z.array(z.string()),
    countries: z.array(z.string()),
    functions: z.array(z.string()),
    industries: z.array(z.string()),
    name: z.string(),
    regions: z.array(z.string()),
    roles: z.array(z.string()),
    systemTypes: z.array(z.string()),
    tags: z.array(z.string()).nullish()
  })
}

export function CreateOrganizationDtoSchema(): z.ZodObject<Properties<CreateOrganizationDto>> {
  return z.object({
    address: AddressSchema().nullish(),
    capabilities: z.array(CapabilitiesSchema).nullish(),
    contacts: ContactsSchema().nullish(),
    license: z.string().nullish(),
    logo: definedNonNullAnySchema.nullish(),
    modelId: z.string().nullish(),
    name: z.string(),
    publicRegisterUrl: z.string().nullish(),
    status: z.boolean().default(true).nullish()
  })
}

export function CreateSpaceInputSchema(): z.ZodObject<Properties<CreateSpaceInput>> {
  return z.object({
    description: z.string().nullish(),
    functions: z.array(z.string()).nullish(),
    icon: z.string(),
    managers: z.array(z.string()),
    name: z.string(),
    regions: z.array(z.string()).nullish()
  })
}

export function CreateUserArgsSchema(): z.ZodObject<Properties<CreateUserArgs>> {
  return z.object({
    active: z.boolean().nullish(),
    country: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    organizationId: z.number(),
    passwordInput: z.string().nullish(),
    role: z.string()
  })
}

export function CreateUsersInputSchema(): z.ZodObject<Properties<CreateUsersInput>> {
  return z.object({
    organizationId: z.string(),
    users: z.array(z.lazy(() => SimpleUsersInputSchema()))
  })
}

export function EvaluationResultsByTaskOrderByFieldGqlSchema(): z.ZodObject<Properties<EvaluationResultsByTaskOrderByFieldGql>> {
  return z.object({
    columnName: z.string(),
    value: z.string().nullish()
  })
}

export function EvaluationResultsByTaskOrderByTypeSchema(): z.ZodObject<Properties<EvaluationResultsByTaskOrderByType>> {
  return z.object({
    direction: SortDirectionSchema,
    fields: z.array(EvaluationResultsByTaskOrderByFieldGqlSchema())
  })
}

export function InviteMemberInputSchema(): z.ZodObject<Properties<InviteMemberInput>> {
  return z.object({
    access: z.string(),
    emails: z.array(z.string()),
    spaces: z.array(z.string()).nullish()
  })
}

export function LifecycleInputTypeSchema(): z.ZodObject<Properties<LifecycleInputType>> {
  return z.object({
    Approved_for_deployment: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Approved_for_design_and_development: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Approved_for_retirement: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Deployment: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Design_and_development: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Initiation: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Operation_and_monitoring: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Re_evaluation: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Retirement: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    Verification_and_validation: z.lazy(() => ApprovalStatusInputSchema().nullish()),
    currentLifecycleStage: GovernLifecycleStageSchema
  })
}

export function RequestCancellationInputSchema(): z.ZodObject<Properties<RequestCancellationInput>> {
  return z.object({
    additionalInfo: z.string().nullish(),
    cancellationReason: RequestCancellationReasonSchema,
    contactEmail: z.string()
  })
}

export function SimpleUsersInputSchema(): z.ZodObject<Properties<SimpleUsersInput>> {
  return z.object({
    email: z.string(),
    role: RolesSchema
  })
}

export function UpdateDatasetInputSchema(): z.ZodObject<Properties<UpdateDatasetInput>> {
  return z.object({
    cardOwnerId: z.string(),
    dataCollectionMethods: z.string().nullish(),
    description: z.string().nullish(),
    externalId: z.string(),
    license: z.string().nullish(),
    linkToDataset: z.string().nullish(),
    modality: GovernDatasetModalitySchema,
    name: z.string(),
    owner: z.string(),
    personalData: z.array(z.string()).nullish(),
    preProcessingMethods: z.string().nullish(),
    source: z.string().nullish(),
    specialCategoriesPD: z.array(z.string()).nullish(),
    subject: z.array(z.string()).nullish(),
    tags: z.array(z.string()).nullish(),
    version: z.string().nullish()
  })
}

export function UpdateGovernAttachmentInputSchema(): z.ZodObject<Properties<UpdateGovernAttachmentInput>> {
  return z.object({
    description: z.string().nullish(),
    name: z.string(),
    type: z.string()
  })
}

export function UpdateMyOrganisationDefaultsInputSchema(): z.ZodObject<Properties<UpdateMyOrganisationDefaultsInput>> {
  return z.object({
    businessModels: z.array(z.string()).nullish(),
    industries: z.array(z.string()).nullish(),
    regions: z.array(z.string()).nullish()
  })
}

export function UpdateMyOrganisationDetailsInputSchema(): z.ZodObject<Properties<UpdateMyOrganisationDetailsInput>> {
  return z.object({
    address: z.lazy(() => UpdateMyOrganisationDetailsInput_AddressSchema().nullish()),
    logo: z.lazy(() => UpdateMyOrganisationDetailsInput_LogoSchema().nullish()),
    organisationName: z.string()
  })
}

export function UpdateMyOrganisationDetailsInput_AddressSchema(): z.ZodObject<Properties<UpdateMyOrganisationDetailsInput_Address>> {
  return z.object({
    city: z.string().nullish(),
    country: z.string().nullish(),
    postalCode: z.string().nullish(),
    street: z.string().nullish()
  })
}

export function UpdateMyOrganisationDetailsInput_LogoSchema(): z.ZodObject<Properties<UpdateMyOrganisationDetailsInput_Logo>> {
  return z.object({
    file_description: z.string().nullish(),
    file_name: z.string(),
    file_url: z.string()
  })
}

export function UpdateMyOrganisationSecurityInputSchema(): z.ZodObject<Properties<UpdateMyOrganisationSecurityInput>> {
  return z.object({
    memberInvite: z.boolean(),
    spaceManagerInvite: z.boolean()
  })
}

export function UpdateOrganisationConfigurationInputSchema(): z.ZodObject<Properties<UpdateOrganisationConfigurationInput>> {
  return z.object({
    access: CapabilitiesSchema,
    consentToUserSwitching: z.boolean().default(false),
    id: z.string(),
    license: LicensesSchema,
    publicRegistryURL: z.string().nullish(),
    status: z.boolean(),
    systemLimit: z.number().default(5)
  })
}

export function UpdateOrganisationDetailsInputSchema(): z.ZodObject<Properties<UpdateOrganisationDetailsInput>> {
  return z.object({
    address: z.lazy(() => UpdateMyOrganisationDetailsInput_AddressSchema().nullish()),
    id: z.string(),
    logo: z.lazy(() => UpdateMyOrganisationDetailsInput_LogoSchema().nullish()),
    organisationName: z.string()
  })
}

export function UpdateSpaceInputSchema(): z.ZodObject<Properties<UpdateSpaceInput>> {
  return z.object({
    address: z.string().nullish(),
    description: z.string().nullish(),
    functions: z.array(z.string()).nullish(),
    icon: z.string().nullish(),
    managers: z.array(z.string()).nullish(),
    name: z.string().nullish(),
    regions: z.array(z.string()).nullish()
  })
}

export function UpdateSystemDatasetInputSchema(): z.ZodObject<Properties<UpdateSystemDatasetInput>> {
  return z.object({
    datasetId: z.string(),
    description: z.string().nullish(),
    externalId: z.string(),
    purpose: z.array(GovernDatasetPurposeSchema)
  })
}

export function UpdateSystemSupplierInputSchema(): z.ZodObject<Properties<UpdateSystemSupplierInput>> {
  return z.object({
    documentIds: z.array(z.string()),
    externalId: z.string(),
    provider: z.string(),
    type: z.string()
  })
}

export function UpdateUserArgsSchema(): z.ZodObject<Properties<UpdateUserArgs>> {
  return z.object({
    active: z.boolean().nullish(),
    country: z.string().nullish(),
    email: z.string().nullish(),
    firstName: z.string().nullish(),
    groups: z.array(z.string()).nullish(),
    lastName: z.string().nullish(),
    organizationId: z.number().nullish(),
    passwordInput: z.string().nullish(),
    profileImageURL: z.string().nullish(),
    role: z.string().nullish(),
    title: z.string().nullish()
  })
}
