import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { createQueryParameterAtom } from './atoms/queryParameters';

export { createQueryParameterAtom };

// ok to be in local storage ( want to sync between tabs )
export const featureFlagsAtom = atomWithStorage('toggles', {
    _loaded: false,
    data: {},
    refreshed: new Date(),
});
export const sortByAtom = createQueryParameterAtom('sort', false);
export const filtersAtom = createQueryParameterAtom('filters', true);

/**
 * Global collaborative toggle
 */
export const liveblocksAtom = atom(true);

/**
 * Indicate for components if they are in room or not
 */
export const liveblocksRoomAtom = atom(true);
