import axios from 'axios';

import { konsole } from 'tools/hooks/konsole';

let fetch;

export const refreshFetcher = (axios) => {
    fetch = axios.create({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseURL: new URL(process.env.VITE_APP_API_URL!).toString(),
        withCredentials: true,
    });

    return fetch;
};

export const axiosCall = async (
    method,
    url,
    data: unknown = undefined,
    headers = {},
) => {
    if (!fetch) {
        fetch = refreshFetcher(axios);
    }

    url = allowV2(url);
    return await fetch({
        method,
        url,
        data,
        headers,
        withCredentials: true,
    })
        .then((response) => response?.data)
        .catch((error) => {
            konsole.error(method.toUpperCase(), url, error);
            throw error;
        });
};

export const legacyApi = async (
    method,
    url: string,
    body,
    _token?,
    doThrow?,
) => {
    let response;
    try {
        response = await api(method, url, body);
        return response;
    } catch (error) {
        if (error instanceof Error) {
            konsole.error('apiCall', error);
        }

        if (doThrow) {
            throw error;
        }

        const exception = error as any;
        konsole.error('apiCall: throwVersion is not set');
        if (exception.response) {
            exception.response.error = true;
        }
        return exception.response || exception;
    }
};

export const api = async function api(method, url, body?: any) {
    url = allowV2(url);
    return await axiosCall(method, url, body);
};

function allowV2(url: string) {
    if (url.startsWith('http')) {
        return url;
    }

    if (url.startsWith('/v2')) {
        return url;
    }

    if (url.startsWith('/v1')) {
        return url;
    }

    return `/v1${url.startsWith('/') ? url : '/' + url}`;
}
