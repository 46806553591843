import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAtom } from 'jotai';

import { locationStateAtom } from 'components/Menu/menuAtoms';

export function useLocationDerivedState() {
    const location = useLocation();
    const [state, dispatch] = useAtom(locationStateAtom);

    useEffect(() => {
        dispatch(location);
    }, [location]);

    return state;
}
