import React from 'react';

import { useAtomValue } from 'jotai';

import { featureFlagsAtom } from 'tools/atoms';

interface FlagResolverProps {
    flags?: string[] | string;
    render?: () => JSX.Element;
    fallback?: () => JSX.Element;
    children?: React.ReactNode;
}

export const useFlagValue = (flag) => {
    const { data: flags } = useAtomValue(featureFlagsAtom);
    return flags?.[flag] ?? false;
};

export const useFlagSValues = () => {
    const { data: flags } = useAtomValue(featureFlagsAtom);
    return flags || {};
};

export const FlagResolver: React.FC<FlagResolverProps> = (props) => {
    const { data: flags } = useAtomValue(featureFlagsAtom);
    const {
        flags: rawFlags = [],
        children,
        render = () => null,
        fallback = () => null,
    } = props;
    const flagsToCheck = Array.isArray(rawFlags) ? rawFlags : [rawFlags];

    const reject = () => {
        return fallback();
    };

    if (!flags) {
        return reject();
    }

    if (flagsToCheck.some((flag) => flags?.[flag] !== true)) {
        return reject();
    }

    return render() || <>{children}</>;
};
